// the location hash property acts as an additional query-string
// use it when the params could get really big
import { parse as parseQS, stringify as stringifyQS } from 'query-string'

import arrayFrom from '__lib__/array/from'
import memoizeLast from '__lib__/utils/memoizeLast'
import arrayDifference from '__lib__/array/difference'

const memoParseQS = memoizeLast(parseQS)

export const parse = (hash) =>
  typeof hash === 'string' ? memoParseQS(hash.slice(1)) : hash

const prependHash = (hash) => (hash ? `#${hash}` : hash)

export const stringify = (hash) =>
  typeof hash === 'object' ? prependHash(stringifyQS(hash)) : hash

export const hash = (fn) => (location) => ({
  ...location,
  hash: fn(parse(location.hash), location),
})

export default hash

export const normalize = stringify

export const set = (key, fn) => hash((hash) => ({ ...hash, [key]: fn(hash) }))

// hash & set are primary function, especially hash as every other is a wrapper around it

const setKeyFactory = (fn) => (key, value) => set(key, fn(key, value))

const keyConcatFn = (key, values) => (hash) =>
  hash[key] ? [...arrayFrom(hash[key]), ...values] : values

const keyDifferenceFn = (key, values) => (hash) =>
  hash[key] && arrayDifference(arrayFrom(hash[key]), values)

const keyDelFn = (key) => (hash) => undefined

export const keyConcat = setKeyFactory(keyConcatFn)
export const keyDifference = setKeyFactory(keyDifferenceFn)
export const del = setKeyFactory(keyDelFn)
