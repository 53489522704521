import React from 'react'
import { compose } from 'react-recompose'
import { withPropsOnChange } from 'react-recompose'

import Icon from 'Icon'
import styles from './styles.module.css'
import Transition from 'Transition'
import assign from '__lib__/react/assign'
import MountWithDelay from 'MountWithDelay'
import pickProps from '__lib__/react/pickProps'
import withClassName from '__lib__/react/withClassName'
import mountWithDelay from '__lib__/react/mountWithDelay'
import withoutProps from '__lib__/react/withoutProps'

const Loading = ({ type, ...props }) => (
  <Icon type={type || 'new/sync'} {...props} />
)

const EnhancedLoading = compose(
  mountWithDelay(80),
  withClassName({ innerClassName: styles.newSpin })
)(Loading)

export const LoadingWithContent = ({ children, className, style }) => (
  <div className={className} style={style}>
    <Loading className="mr2" /> {children || 'Loading...'}
  </div>
)

export const AbsoluteLoading = compose(
  pickProps(['id', 'className', 'style']),
  withClassName('absolute absolute-center')
)(EnhancedLoading)

// Remove invalid fragment props
const DefaultLoadingContainerComponent = ({ key, children }) => (
  <React.Fragment key={key}>{children}</React.Fragment>
)

const LoadingContent = ({
  loading,
  children,
  noTransition,
  loadingDelay,
  contentClassName,
  loadingClassName,
  loadingTransition,
  component: Component = 'div',
  loadingContainerComponent:
    LoadingContainerComponent = DefaultLoadingContainerComponent,
  ...props
}) => (
  <Component {...props}>
    <div className={contentClassName}>{children}</div>
    {noTransition ? (
      <LoadingContainerComponent delay={loadingDelay}>
        <EnhancedLoading className={loadingClassName} />
      </LoadingContainerComponent>
    ) : (
      loading && (
        <LoadingContainerComponent delay={loadingDelay}>
          <Transition {...loadingTransition}>
            <EnhancedLoading className={loadingClassName} />
          </Transition>
        </LoadingContainerComponent>
      )
    )}
  </Component>
)

const contentClassName = {
  className: 'relative',
  loadingClassName: [
    styles.loadingContentLoading,
    'absolute absolute-center circle pa2 c-shade-5',
  ],
  contentClassName: ({ loading }) => [
    styles.content,
    loading && styles.loadingContent,
  ],
}

const NoDelayFragment = withoutProps(['delay'])(React.Fragment)

const loadingContainerComponent = ({ loadingDelay }) => ({
  loadingContainerComponent: loadingDelay ? MountWithDelay : NoDelayFragment,
})

const EnhancedLoadingContent = compose(
  withClassName(contentClassName),
  withPropsOnChange(['loadingDelay'], loadingContainerComponent)
)(LoadingContent)

export default assign({
  Absolute: AbsoluteLoading,
  BaseContent: LoadingContent,
  Content: EnhancedLoadingContent,
  WithContent: LoadingWithContent,
})(EnhancedLoading)
