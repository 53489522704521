import View from '../View'
import Button from './Button'
import Modal from '../Modal'
import Dialog from '../Dialog'
import Transition from '__core__/Transition'
import hooks from '__hooks__'

const ConfirmContent = ({
  title,
  dialog,
  onConfirm,
  cancelText,
  confirmText,
  description,
  confirmButton,
}) => (
  <View style="user-select-none">
    <View style="w-full">
      <View.Text style="text-shade-7 text-lg font-bold">{title}</View.Text>
    </View>
    <View style="mt-4 w-full">
      <View.Text style="text-shade-5">{description}</View.Text>
    </View>
    <View style="flex-center justify-end w-full mt-6">
      <Dialog.Toggle
        style="mr-5"
        dialog={dialog}
        component={Button}
        text={cancelText || 'Cancel'}
      />
      {confirmButton || (
        <Button
          md
          danger
          plain
          onPress={onConfirm}
          text={confirmText || 'Confirm'}
        />
      )}
    </View>
  </View>
)

const ConfirmDialog = ({
  dialog,
  children,
  onConfirm,
  cancelText,
  confirmText,
  confirmButton,
  title = 'Are you sure?',
  description = 'This action cannot be undone',
  ...props
}) => (
  <View.Mobile.Fragment
    render={({ matches }) =>
      matches ? (
        <Modal
          dialog={dialog}
          styleRight={['w-full h-full', { background: 'rgba(0,0,0,0.666)' }]}
          bodyStyle="flex-center h-full w-full"
          transition="fade"
          {...props}
        >
          <Transition
            component={View}
            name="popFadeSmall"
            styleLeft="bg-drop-1 shadow-sm rounded-md p-5 min-w-9 max-w-10 border border-shade-1"
          >
            <ConfirmContent
              title={title}
              dialog={dialog}
              onConfirm={onConfirm}
              cancelText={cancelText}
              confirmText={confirmText}
              description={description}
              confirmButton={confirmButton}
            />
          </Transition>
          {children}
        </Modal>
      ) : (
        <Dialog
          withArrow
          mouseAnchor
          marginLeft={0}
          dialog={dialog}
          styleLeft="bg-drop-1 shadow-sm rounded-lg p-5 min-w-9 max-w-10 border border-shade-1"
          {...props}
        >
          <ConfirmContent
            title={title}
            dialog={dialog}
            onConfirm={onConfirm}
            cancelText={cancelText}
            confirmText={confirmText}
            description={description}
            confirmButton={confirmButton}
          />
          {children}
        </Dialog>
      )
    }
  />
)

const ConfirmButton = ({
  title,
  children,
  onCancel,
  onConfirm,
  placement,
  cancelText,
  description,
  confirmText,
  dialogProps,
  dialogStyle,
  confirmDialog,
  confirmButton,
  dialogClassName,
  component = Button,
  ...props
}) => (
  <>
    <Dialog.Toggle
      isAnchor
      component={component}
      dialog={confirmDialog}
      {...props}
    >
      {children}
    </Dialog.Toggle>
    <ConfirmDialog
      title={title}
      onCancel={onCancel}
      style={dialogStyle}
      onConfirm={onConfirm}
      dialog={confirmDialog}
      cancelText={cancelText}
      description={description}
      confirmText={confirmText}
      className={dialogClassName}
      confirmButton={confirmButton}
      placement={placement || 'top'}
      {...dialogProps}
    />
  </>
)

const onConfirm =
  ({ onConfirm, confirmDialog }) =>
  (event) =>
    Promise.resolve(onConfirm(event)).then(confirmDialog.close)

export default Dialog.withDialog('confirmDialog')(
  hooks(hooks.handler('onConfirm', onConfirm))(ConfirmButton)
)
