import * as R from 'ramda'
import hooks from '__hooks__'

import View from '__components__/View'
import Button from '__components__/Button'
import ThirdPartyButton, {
  EXTERNAL_LOGIN,
  externalLoginsProps,
} from './ThirdPartyButton'
import RegisterForm from './RegisterForm'
import Layout, { BackButton } from './Layout'
import {
  CloudPanel,
  DefaultBackdrop,
  GoogleDrivePanel,
  IntegrationsPanel,
  SignupBackdrop,
  SignupPanel,
} from './Panels'
import { DISPLAY_MODE } from './Login'
import { CloudHeader, GoogleDriveHeader, IntegrationsHeader } from './Header'

const SigninWithGoogle = ({ loginDetail, loginUrl, setShowRegisterForm }) => (
  <>
    <View style="flex flex-col flex-flex laptop:justify-center">
      <GoogleDriveHeader isRegister />
      <View style="flex flex-col items-center mt-5">
        <ThirdPartyButton
          action={loginDetail.url}
          name={loginDetail.id}
          {...externalLoginsProps[EXTERNAL_LOGIN.google]}
          text="Continue with Google"
          buttonStyle={['justify-start', { height: 50, width: 245 }]}
          textStyle="text-shade-4 font-semibold text-md laptop:text-lg"
        />
        <Button
          onPress={() => setShowRegisterForm(true)}
          style="rounded-full underline text-shade-4 mt-5"
        >
          More sign-up options
        </Button>
      </View>
    </View>

    <View style="flex flex-col items-center flex-stiff pt-6">
      <View.Text style="text-black font-semibold text-md">
        Or, already have an existing account?
      </View.Text>
      <Button
        lg
        outline
        component="a"
        href={loginUrl}
        style="rounded-full flex-center text-ink border-ink font-semibold mt-5"
      >
        Log in with an existing account
      </Button>
    </View>
  </>
)

const getExternalLogin = (externalLogins, name) =>
  externalLogins.find((item) => item.id === name)

const Register = ({
  data,
  signinWithGoogle,
  showRegisterForm,
  setShowRegisterForm,
  data: { loginUrl, displayMode },
  ...props
}) =>
  signinWithGoogle && !showRegisterForm ? (
    <Layout
      asset={GoogleDrivePanel}
      backdrop={DefaultBackdrop}
      rightContainerStyle="bg-drop-1 justify-start"
      {...props}
    >
      <SigninWithGoogle
        loginUrl={loginUrl}
        loginDetail={signinWithGoogle}
        setShowRegisterForm={setShowRegisterForm}
      />
    </Layout>
  ) : (
    <Layout
      asset={
        displayMode === DISPLAY_MODE.cloud
          ? CloudPanel
          : displayMode === DISPLAY_MODE.integrations
          ? IntegrationsPanel
          : SignupPanel
      }
      backdrop={SignupBackdrop}
      rightContainerStyle="bg-drop-1 justify-start"
      backButton={
        signinWithGoogle && (
          <BackButton
            text="Back to previous"
            onPress={() => setShowRegisterForm(false)}
          />
        )
      }
      {...props}
    >
      <RegisterForm
        header={
          displayMode === DISPLAY_MODE.cloud ? (
            <CloudHeader isRegister />
          ) : displayMode === DISPLAY_MODE.integrations ? (
            <IntegrationsHeader isRegister />
          ) : null
        }
        data={data}
      />
    </Layout>
  )

const signinWithGoogle = ({ data: { displayMode, externalLogins = [] } }) =>
  displayMode === DISPLAY_MODE.googledrive &&
  getExternalLogin(externalLogins, EXTERNAL_LOGIN.google)

export default hooks(
  hooks.assoc('signinWithGoogle', signinWithGoogle),
  hooks.boolState(
    'showRegisterForm',
    'setShowRegisterForm',
    R.propEq(signinWithGoogle, null)
  )
)(Register)
