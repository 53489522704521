import * as R from 'ramda'
import uuid from '__lib__/uuid'

export const isWebview = R.once(() => Boolean(window.chrome?.webview))

let webviewController = null
export const setWebviewController = (value) => (webviewController = value)
export const getWebviewController = () => webviewController

const MOCK = (messages) => {
  if (window) return
  const sendMessageFromHost = (type, data) =>
    window.dispatchEvent(
      new CustomEvent('webviewMessage', { detail: { type, data } })
    )
  window.chrome = {
    webview: {
      postMessage: (message) => {
        const { type, data } = JSON.parse(message)
        const { requestId } = data || {}
        requestId &&
          messages[type] &&
          sendMessageFromHost('response', {
            ...messages[type](data, type),
            requestId,
          })
      },
    },
  }
  setWebviewController('dbpdf-windows')
}

MOCK({
  getUIColorMode: () => ({ mode: 'dark' }),
  getAnalyticsDeviceId: () => ({ deviceId: 'testDeviceId' }),
  'auth/isAuthenticated': () => ({ isAuthenticated: false }),
  // 'subscription/getLicense': () => null,
  'subscription/getLicense': () => ({
    license: { planName: 'v2Tier2' },
  }),
  'subscription/getPlans': () => ({
    plans: [
      {
        planId: '9N5H6ZNZGRWW',
        currency: 'AUD',
        price: 1000,
        name: 'v2Tier2',
        period: 'month',
      },
      {
        planId: '9PNMCTCBDS3X',
        currency: 'AUD',
        price: 8600,
        name: 'v2Tier2',
        period: 'year',
      },
      {
        planId: '9PGF8M2R7C9C',
        currency: 'AUD',
        price: 2500,
        name: 'v2Tier3',
        period: 'month',
      },
      {
        planId: '9NKZFMSDGMV2',
        currency: 'AUD',
        price: 20900,
        name: 'v2Tier3',
        period: 'year',
      },
    ],
  }),
})

export const isControlledWebview = (value) =>
  isWebview() && (value ? R.equals(value) : Boolean)(getWebviewController())

export const sendWebviewMessage = (type, data) =>
  window.chrome.webview.postMessage(JSON.stringify({ type, data }))

export const receiveWebviewMessages = (type, fn) => {
  const handler = (event) => {
    console.log('[WEBVIEW] receiving message', event)
    return event.detail.type === type && fn(event.detail)
  }
  window.addEventListener('webviewMessage', handler)
  return () => window.removeEventListener('webviewMessage', handler)
}

export const setupWebviewMessaging = () => {
  if (!isWebview()) return
  window.sendWebviewMessage = (message) =>
    window.dispatchEvent(new CustomEvent('webviewMessage', { detail: message }))
  receiveWebviewMessages('ping', () => sendWebviewMessage('pong'))
  sendWebviewMessage('ready', { controlledSubscription: 0, webSubscription: 1 })
}

export const sendWebviewRequest = (type, data) =>
  new Promise((resolve) => {
    const requestId = uuid()
    console.log('[WEBVIEW] sending req', type, data)
    const stop = receiveWebviewMessages('response', (message) => {
      if (message.data.requestId === requestId) {
        stop()
        resolve(message.data)
      }
    })
    sendWebviewMessage(type, { ...data, requestId })
  })
