import React from 'react'
import { withPropsOnChange } from 'react-recompose'

import { getArrowStyle } from './positioning'

const baseStyle = {
  borderWidth: 0,
  position: 'absolute',
  borderColor: 'inherit',
  borderStyle: 'inherit',
  borderTopWidth: 'inherit',
  backgroundColor: 'inherit',
  borderLeftWidth: 'inherit',
  borderTopLeftRadius: '2px',
  borderBottomRightRadius: '100%',
}

const Arrow = ({ placement, ...props }) => <div {...props} />

// the boxShadow depends on were the actual arrow is positioned in the box,
// for topMiddle, the arrow will be bottom middle where we usually
// have our shadow, because of the rotation the offset becomes -1px -1px
// for bottomCenter, the arrow will be top and there will be no boxShadow
// unfortunately we cannot inherit the color and blur of the box shadow...

const style = ({ style, size, placement }) => ({
  style: {
    width: size || 13,
    height: size || 13,
    ...baseStyle,
    ...getArrowStyle(placement),
    ...style,
  },
})

export default withPropsOnChange(['style', 'placement', 'size'], style)(Arrow)
