const SlackIcon = ({ isDarkMode, ...props }) => (
  <svg
    width="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.37314 10.1129C3.37314 11.0421 2.62214 11.7931 1.69295 11.7931C0.763751 11.7931 0.0127563 11.0421 0.0127563 10.1129C0.0127563 9.18373 0.763751 8.43274 1.69295 8.43274H3.37314V10.1129ZM4.21323 10.1129C4.21323 9.18373 4.96423 8.43274 5.89342 8.43274C6.82262 8.43274 7.57361 9.18373 7.57361 10.1129V14.3134C7.57361 15.2426 6.82262 15.9936 5.89342 15.9936C4.96423 15.9936 4.21323 15.2426 4.21323 14.3134V10.1129Z"
      fill="#E01E5A"
    />
    <path
      d="M5.8934 3.36673C4.9642 3.36673 4.21321 2.61573 4.21321 1.68654C4.21321 0.757342 4.9642 0.00634766 5.8934 0.00634766C6.82259 0.00634766 7.57359 0.757342 7.57359 1.68654V3.36673H5.8934ZM5.8934 4.21955C6.82259 4.21955 7.57359 4.97055 7.57359 5.89974C7.57359 6.82894 6.82259 7.57993 5.8934 7.57993H1.68019C0.750994 7.57993 0 6.82894 0 5.89974C0 4.97055 0.750994 4.21955 1.68019 4.21955H5.8934Z"
      fill="#36C5F0"
    />
    <path
      d="M12.6269 5.89974C12.6269 4.97055 13.3779 4.21955 14.3071 4.21955C15.2363 4.21955 15.9873 4.97055 15.9873 5.89974C15.9873 6.82894 15.2363 7.57993 14.3071 7.57993H12.6269V5.89974ZM11.7868 5.89974C11.7868 6.82894 11.0358 7.57993 10.1066 7.57993C9.17739 7.57993 8.42639 6.82894 8.42639 5.89974V1.68654C8.42639 0.757342 9.17739 0.00634766 10.1066 0.00634766C11.0358 0.00634766 11.7868 0.757342 11.7868 1.68654V5.89974Z"
      fill="#2EB67D"
    />
    <path
      d="M10.1066 12.6332C11.0358 12.6332 11.7868 13.3842 11.7868 14.3134C11.7868 15.2426 11.0358 15.9936 10.1066 15.9936C9.17739 15.9936 8.42639 15.2426 8.42639 14.3134V12.6332H10.1066ZM10.1066 11.7931C9.17739 11.7931 8.42639 11.0421 8.42639 10.1129C8.42639 9.18373 9.17739 8.43274 10.1066 8.43274H14.3198C15.249 8.43274 16 9.18373 16 10.1129C16 11.0421 15.249 11.7931 14.3198 11.7931H10.1066Z"
      fill="#ECB22E"
    />
  </svg>
)

export default SlackIcon
