import Icon from '../Icon'
import hooks from '__hooks__'
import Button from './Button'
import { renderText } from '../withTextStyleChildren'

const iconText =
  ({
    text,
    icon,
    iconLeft,
    iconSize,
    iconStyle,
    iconComponent: IconComponent = Icon,
  }) =>
  (props) =>
    (
      <>
        {iconLeft && (
          <IconComponent
            icon={iconLeft}
            size={iconSize}
            styleLeft={text && 'mr-4'}
            styleRight={iconStyle}
            {...props}
          />
        )}
        {renderText(text, props)}
        {icon && (
          <IconComponent
            icon={icon}
            size={iconSize}
            styleLeft={text && 'ml-4'}
            styleRight={iconStyle}
            {...props}
          />
        )}
      </>
    )

export default hooks(
  hooks.consume(['icon', 'iconLeft', 'iconStyle', 'iconSize', 'iconComponent'])(
    hooks.assoc('text', iconText)
  )
)(Button, 'Button.Icon')
