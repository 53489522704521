import * as R from 'ramda'

import version from '__app__/version'
import config from '__config__'
import { parseJwt } from '__lib__/web/jwt'
import { getAuthToken } from '__api__/config'

const PATH = '/api/events/raw?clef'
const { seqKey, seqDomain } = config

const getUserId = () =>
  getAuthToken()
    .then(parseJwt)
    .then(R.propOr('Anonymous', 'sub'))
    .catch(() => 'Anonymous')

const defaultParams = (key) => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'X-Seq-ApiKey': key,
  },
})

const body = ({ message, userId, level, properties }) => ({
  body: JSON.stringify({
    userId,
    version,
    location: R.pathOr('Unknown', ['location', 'href'], window),
    '@l': level,
    '@mt': message,
    '@t': new Date().toISOString(),
    ...properties,
  }),
})

const track =
  ({ level }) =>
  (message, properties = {}) => {
    if (!seqKey || !seqDomain) {
      return Promise.reject('Missing key or domain.')
    }

    // Dont trigger a redirect to /login for errors on the acceptLink screen (FF bug)
    if(window.location.pathname.includes('acceptLink')) return

    return getUserId().then((userId) =>
      fetch(seqDomain + PATH, {
        ...defaultParams(seqKey),
        ...body({ message, userId, level, properties }),
      })
    )
  }

const trackError = track({ level: 'Error' })
const trackDebug = track({ level: 'Debug' })
const trackWarning = track({ level: 'Warning' })
const trackInformation = track({ level: 'Information' })

export { trackError, trackDebug, trackWarning, trackInformation }
