import * as Sentry from '@sentry/react'

import uuid from '__lib__/uuid'
import { dispatch } from '__store__/store/global'
import central from '__store__/central'
import { arrayWithout } from '__lib__/array/without'
import { objectWithout } from '__lib__/object/without'
import { fpGet, get, set, update } from '__lib__/immutable'

const MAX_MESSAGES = 5
const DEFAULT_TIMEOUT = 3000
const DEFAULT_ERROR_TIMEOUT = 9000

export const getMessage = (state, id) =>
  get(state, ['messages', 'collection', id])

export const getMessagesList = fpGet(['messages', 'list'])
export const getMessagesCollection = fpGet(['messages', 'collection'])

const addMessageInCollection = (state, id, message) =>
  set(state, ['messages', 'collection', id], message)

const addMessageInList = (state, id) =>
  update(state, ['messages', 'list'], (list) => (list ? [id, ...list] : [id]))

const hasExceededMaximum = (state) =>
  (getMessagesList(state) || []).length >= MAX_MESSAGES

export const removeLastMessage = (state) =>
  removeMessage(
    state,
    getMessagesList(state)[getMessagesList(state).length - 1]
  )

export const addMessage = (state, id, message) =>
  addMessageInList(
    addMessageInCollection(
      hasExceededMaximum(state) ? removeLastMessage(state) : state,
      id,
      message
    ),
    id
  )

const removeMessageFromCollection = (state, id) =>
  update(state, ['messages', 'collection'], objectWithout(id))

const removeMessageFromList = (state, id) =>
  update(state, ['messages', 'list'], arrayWithout(id))

export const removeMessage = (state, id) =>
  removeMessageFromList(removeMessageFromCollection(state, id), id)

const pushIntent = (
  message,
  { timeout = DEFAULT_TIMEOUT, ...options } = {}
) => {
  const id = uuid()
  dispatch('messagesPush', id, { ...message, id, ...options })
  const remove = () => dispatch('messagesRemove', id)
  timeout === 'none' || setTimeout(remove, timeout)
  return remove
}

const pushIntentFy = (props, defaultOptions) => (message, options) =>
  pushIntent({ ...props, children: message }, { ...defaultOptions, ...options })

const pushInfoIntent = pushIntentFy({ type: 'info' })
const pushSuccessIntent = pushIntentFy({ type: 'success' })
const pushCustomIntent = pushIntentFy({ type: 'custom' })

const pushErrorIntent = pushIntentFy(
  { type: 'error' },
  { timeout: DEFAULT_ERROR_TIMEOUT }
)

const pushCriticalIntent = (whileDoing, error) => {
  error && console.error(error)

  const sentryError = new Error(`Message critical notification ${whileDoing ?? 'without a reason.'}`)
  if (error) sentryError.cause = error
  Sentry.captureException(sentryError, (scope) => {
    scope
      .setTag('from', 'pushCriticalIntent')
      .setExtra('whileDoing', whileDoing)
      .setLevel('fatal')
  })

  return pushErrorIntent(
    `Oops, something went wrong${
      whileDoing ? ' ' + whileDoing : ''
    }, try again later or contact support`
  )
}

export const messagesPush = central.pair('messagesPush', pushIntent, addMessage)
export const messagesRemove = central.pair(
  'messagesRemove',
  null,
  removeMessage
)
export const messageInfo = central.pair('messagesPushInfo', pushInfoIntent)
export const messageError = central.pair('messagesPushError', pushErrorIntent)
export const messageSuccess = central.pair(
  'messagesPushSuccess',
  pushSuccessIntent
)
export const messageCritical = central.pair(
  'messagesPushCritical',
  pushCriticalIntent
)
export const messagesCustom = central.pair(
  'messagesPushCustom',
  pushCustomIntent
)

export const messageInfoAction = central.action('messagesPushInfo')
export const messageErrorAction = central.action('messagesPushError')
export const messageSuccessAction = central.action('messagesPushSuccess')
export const messageCriticalAction = central.action('messagesPushCritical')
export const messageCustomAction = central.action('messagesPushCustom')

export const messageAction = {
  info: messageInfoAction,
  error: messageErrorAction,
  success: messageSuccessAction,
  critical: messageCriticalAction,
  custom: messageCustomAction,
}
