import { compose } from 'react-recompose'
import { withHandlers } from 'react-recompose'

import withoutProps from './withoutProps'
import withPromiseLoadingState from './withPromiseLoadingState'

const handlerFy =
  (handlerName) =>
  (props) =>
  (...args) =>
    props.setLoadingFromPromise(props[handlerName](...args))

export const withLoadingHandlerState = (handlerName) =>
  compose(
    withPromiseLoadingState,
    withHandlers({ [handlerName]: handlerFy(handlerName) })
  )

export const withoutLoadingState = withoutProps([
  'setLoading',
  'setLoadingFromPromise',
])

//N.B: Make sure your handler returns a promise!!

export default (handlerName) =>
  compose(withLoadingHandlerState(handlerName), withoutLoadingState)
