import React from 'react'
import hooks from '__hooks__'

const renderComponent = (render, Component, props) =>
  render ? render(props) : <Component {...props} />

const detach = ({ dialog, anchor, ref }) =>
  dialog
    ? dialog.detachAnchor()
    : anchor
    ? anchor.detach(ref.current)
    : console.warn('Anchor with no dialog or anchor')

const attach = ({ dialog, anchor, ref }) => {
  dialog
    ? dialog.attachAnchor(ref.current)
    : anchor
    ? anchor.attach(ref.current)
    : console.warn('Anchor with no dialog or anchor')
  return () => detach({ dialog, anchor, ref })
}

const Anchor = ({
  render,
  dialog,
  anchor,
  innerComponent,
  component: Component = 'div',
  ...props
}) =>
  renderComponent(render, Component, {
    dialog,
    ...(innerComponent && { component: innerComponent }),
    ...props,
  })

export default hooks(hooks.bubbleRef('ref'), hooks.effect(attach))(Anchor)
