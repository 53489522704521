import hooks from '__hooks__'
import Layout from './Layout'
import View from '__components__/View'
import Button from '__components__/Button'

const ResetPasswordConfirmation = ({ data: { loginUrl, firstLogin } }) => (
  <Layout>
    <View styleLeft="flex-center flex-col">
      <View.Text style="text-xxl laptop:text-3xl text-semibold">
        Your password has been {firstLogin ? 'changed' : 'reset'}
      </View.Text>
      <View.Text style="text-md text-shade-4 mt-5">
        You are being redirected, if nothing happens,{' '}
        <Button primary component="a" href={loginUrl} text="click here" />
      </View.Text>
    </View>
  </Layout>
)

const onRedirect = ({ data: { loginUrl, delay } }) =>
  loginUrl && delay && setTimeout(() => (window.location = loginUrl), delay)

export default hooks(hooks.tapEffect(onRedirect))(ResetPasswordConfirmation)
