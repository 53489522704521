import round from '../maths/round'
import isString from '__lib__/utils/isString'
import arrayMapJoin from '__lib__/array/mapJoin'

const M = ({ x, y }) => `${round(x)},${round(y)}`
const L = ({ x, y }) => `${round(x)},${round(y)}`
const A = ({ rx, ry, t, la, s, x, y }) =>
  `${round(rx)},${round(ry)} ${round(t)},${round(la)} ${round(s)},${round(
    x
  )},${round(y)}`
const C = ({ x1, y1, x2, y2, x, y }) =>
  `${round(x1)},${round(y1)} ${round(x2)},${round(y2)} ${round(x)},${round(y)}`
const Q = ({ x1, y1, x, y }) =>
  `${round(x1)},${round(y1)} ${round(x)},${round(y)}`

const map = { M, L, A, C, Q }

export const pointToString = (point, type) => type + map[type](point)

export const nodeToString = (node) =>
  node.type + (map[node.type] ? map[node.type](node) : '')

export default (path) =>
  isString(path) ? path : arrayMapJoin(path, nodeToString, '')
