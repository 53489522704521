import React from 'react'
import * as R from 'ramda'

import Type from './Type'
import Form from '__core__/Form'
import Input from '__core__/Input'
import hooks from '__hooks__'
import Icon from '__components__/Icon'
import View from '__components__/View'
import upperFirst from '__lib__/string/upperFirst'

const StylingView = hooks(
  R.pick(['style', 'children', 'inputRef']),
  R.assoc('component', Input.Label)
)(View)

const ErrorView = ({ noIcon, children, ...props }) => (
  <View styleLeft="flex items-center" {...props}>
    {noIcon || (
      <Icon
        icon="errorSymbol"
        styleRight="text-3xs"
        styleLeft="mr-3 self-center text-danger-dark text-xxs"
      />
    )}
    {children}
  </View>
)

const renderComponent = (component, render, props, defaultComponent) =>
  component
    ? React.createElement(component, props)
    : render
    ? render(props)
    : React.createElement(defaultComponent, props)

const labelStyle = ({ invalid, disabled }) => [
  'font-semibold text-sm mb-4',
  invalid ? 'text-danger-dark' : disabled ? 'text-shade-3' : 'text-secondary',
]

const Label = ({ children, ...props }) =>
  R.is(String, children) ? (
    <View {...props}>{children}</View>
  ) : R.is(Function, children) ? (
    children(props)
  ) : (
    children || null
  )

const ELabel = hooks(
  hooks.consume(['invalid', 'disabled'])(hooks.style(labelStyle))
)(Label)

export { ELabel as Label }

const Field = ({
  label,
  style,
  error,
  danger,
  invalid,
  noError,
  inert,
  disabled,
  inputRef,
  tabIndex,
  inputStyle,
  labelStyle,
  errorStyle,
  renderInput,
  onValueChange,
  inputComponent,
  innerInputStyle,
  withoutErrorPlaceholder,
  innerComponent: Component = StylingView,
  errorComponent: ErrorComponent = ErrorView,
  ...props
}) => (
  <Component
    inputRef={inputRef}
    style={style}
    disabled={disabled || inert}
    {...props}
  >
    <ELabel style={labelStyle} invalid={invalid} disabled={disabled || inert}>
      {label}
    </ELabel>
    {renderComponent(
      inputComponent,
      renderInput,
      {
        disabled,
        inert,
        ref: inputRef,
        onValueChange,
        style: inputStyle,
        danger: danger || invalid,
        ...(tabIndex && { tabIndex }),
        ...(innerInputStyle && { inputStyle: innerInputStyle }),
        ...props,
      },
      Type
    )}
    {invalid && error ? (
      <ErrorComponent style={errorStyle}>{upperFirst(error)}</ErrorComponent>
    ) : (
      noError ||
      withoutErrorPlaceholder || (
        <ErrorComponent style={errorStyle} noIcon>
          &nbsp;
        </ErrorComponent>
      )
    )}
  </Component>
)

const errorStyle = 'text-danger-dark text-xxs my-2 font-semibold'

const style = ({ inert, disabled }) => ({
  web: { cursor: disabled ? 'not-allowed' : inert ? 'default' : 'pointer' },
})

const EField = hooks(
  hooks.bubbleRef('inputRef'),
  hooks.styles({ style, errorStyle }),
  hooks.rename('component', 'innerComponent'),
  R.assoc('component', Field)
  // Have to do that otherwise context doesn't work as it would be using
  // both old React context on the Form.Field with new React context
  // with the hooks, which doesn't work
)((props) => <Form.Field {...props} />)

EField.Label = ELabel

export default EField
