import * as Sentry from '@sentry/react'

import config from '__config__'
import version from '__app__/version'

const STAGING_ENVIRONMENT_REGEX = /us-(dev|qa)-(?<sentryEnvironment>[a-za-z0-9]+).+drawboard.com/
const BULLCLIP_ENVIRONMENT_REGEX =
  /^(?<sentryEnvironment>[a-zA-Z0-9_]+)-bullclip.drawboard.com/
const PRODUCTION_ENVIRONMENT_REGEX = /^(?<sentryEnvironment>\w+).drawboard.com/
/**
 * Calculates the environment from the browsers url location.
 * ```
 * https://us-dev-test2.projects.drawboard.com/ -> 'test2'
 * https://preprod-bullclip.drawboard.com/ -> 'preprod'
 * https://[pdf|projects].drawboard.com/ -> 'production'
 * * -> undefined
 * ```
 * @param {string} url
 * @returns {string|undefined}
 */
export const sentryEnvironmentFromHostname = (url) => {
  let exec = STAGING_ENVIRONMENT_REGEX.exec(url)
  let sentryEnvironment = exec?.groups?.sentryEnvironment
  if (sentryEnvironment) return sentryEnvironment

  exec = BULLCLIP_ENVIRONMENT_REGEX.exec(url)
  sentryEnvironment = exec?.groups?.sentryEnvironment
  if (sentryEnvironment) return sentryEnvironment

  exec = PRODUCTION_ENVIRONMENT_REGEX.exec(url)
  sentryEnvironment = exec?.groups?.sentryEnvironment
  if (sentryEnvironment) return 'production'

  return undefined
}

const init = () => {
  const isPdfOrProjects =
    import.meta.env.VITE_PROJECT === 'pdf' ||
    import.meta.env.VITE_PROJECT === 'projects'
  const isDev = import.meta.env.DEV

  const sentryPublicKey = config.assert('sentryPublicKey')
  const sentryProjectId = config.assert('sentryProjectId')

  if (isDev || !isPdfOrProjects || !sentryPublicKey || !sentryPublicKey) return

  const environment = sentryEnvironmentFromHostname(window.location.host)
  if (!environment) return

  const platform = import.meta.env.VITE_ANDROID ? 'android' : 'web'
  const buildNumber = import.meta.env.VITE_BUILD_NUMBER ?? 'unknown-build'

  Sentry.init({
    dsn: `https://${sentryPublicKey}@o946362.ingest.sentry.io/${sentryProjectId}`,
    environment,
    release: `${version}-${platform}-${buildNumber}`,
    // Sample 10% of exceptions
    sampleRate: environment === 'production' ? 0.1 : 1,
    // Performance tracing on 1% of clients
    // https://docs.sentry.io/concepts/key-terms/tracing/
    tracesSampleRate: environment === 'production' ? 0.01 : 1,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error', 'assert'],
      }),
    ],
    initialScope: {
      tags: {
        platform,
      },
    },
  })
}

export default init
