import React from 'react'
import { compose } from 'react-recompose'
import { withProps } from 'react-recompose'
import PropTypes from 'prop-types'

import Box from 'Box'
import styles from './styles.module.css'
import assign from '__lib__/react/assign'
import withSize from '__lib__/react/withSize'
import withTheme from '__lib__/react/withTheme'
import withoutProps from '__lib__/react/withoutProps'
import withClassName from '__lib__/react/withClassName'

const Button = ({
  inert,
  component,
  disabled,
  disabledEffect,
  activeClassName,
  ...props
}) => (
  <Box
    component={component || 'button'}
    disabled={disabled || inert}
    {...props}
  />
)

const className = ({
  flat,
  size,
  ghost,
  theme,
  inert,
  active,
  disabled,
  disabledEffect,
  activeClassName,
  ...props
}) => [
  styles.root,
  styles[theme],
  flat && styles.flat,
  ghost && styles.ghost,
  inert && styles.inert,
  active && [styles.active, activeClassName],
  disabled && styles.disabled,
  disabled && styles.fullyDisabled,
  disabledEffect && styles.disabled,
  active === false && styles.inactive,
]

const normalizedProps = ({ disabled, type }) => ({
  type: type || 'button',
  disabled: Boolean(disabled),
})

const propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
}

export default compose(
  assign({
    propTypes,
  }),
  withSize('md'),
  withTheme(['primary', 'grey', 'success', 'danger'], 'primary'),
  withClassName(className),
  withoutProps(['flat', 'ghost', 'theme', 'active']),
  withProps(normalizedProps)
)(Button)
