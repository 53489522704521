import * as R from 'ramda'
import hooks from '__hooks__'
import View from '__components__/View'

import Layout, { Header } from './Layout'

const MESSAGE = {
  error: 'error. Please try again shortly.',
  accepted: 'request accepted',
  declined: 'request declined',
  failed: 'request failed',
}

const LicenseTransfer = ({
  data: { decision, result, error },
  errorString,
}) => (
  <Layout>
    <Header
      title="Drawboard PDF"
      description={
        <>
          <View>
            License transfer&nbsp;
            {error
              ? MESSAGE['error']
              : result && decision === 'accept'
              ? MESSAGE['accepted']
              : result && decision !== 'decline'
              ? MESSAGE['declined']
              : MESSAGE['failed']}
          </View>
          {errorString && (
            <View style="text-center text-shade-5 text-md mt-5 font-bold">
              {errorString}
            </View>
          )}
        </>
      }
    />
  </Layout>
)

const getErrorString = ({ data: { formState } }) =>
  R.path([0, 'errors', 0])(formState)

export default hooks(hooks.value('errorString', getErrorString))(
  LicenseTransfer
)
