import hooks from '__hooks__'
import View from '__components__/View'
import assign from '__lib__/react/assign'
import { getMode } from '__app__/StyleProvider'

import OneDriveIcon from './OneDrive'
import GoogleDriveIcon from './GoogleDrive'
import SharePointIcon from './SharePoint'
import DropboxIcon from './Dropbox'
import SlackIcon from './SlackIcon'

const GoogleDrive = ({ isInvert, ...props }) => (
  <IconView component={GoogleDriveIcon} {...props} />
)

const Dropbox = ({ ...props }) => (
  <IconView component={DropboxIcon} {...props} />
)

const OneDrive = ({ ...props }) => (
  <IconView component={OneDriveIcon} {...props} />
)

const Slack = ({ ...props }) => <IconView component={SlackIcon} {...props} />

const SharePoint = ({ ...props }) => (
  <IconView component={SharePointIcon} {...props} />
)

const isDarkMode = () => (state) => getMode(state) === 'dark'

const status = ({ inert, disabled, inactive, selected }) => {
  if (selected) return 'active'

  if (inert || disabled || inactive) return 'inactive'
  return 'default'
}

const IconView = hooks(
  hooks.storeState('isDarkMode', isDarkMode),
  hooks.consume(['inert', 'disabled', 'inactive', 'selected'])(
    hooks.assoc('status', status)
  )
)(View)

export default assign({
  Slack,
  Dropbox,
  OneDrive,
  SharePoint,
  GoogleDrive,
})('IntegrationIcon')
