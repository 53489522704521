import assign from '__lib__/react/assign'
import Form from './Form'
import Field from './Field'
import Submit from './Submit'
import withForm from './withForm'

import email from './validate/email'
import number from './validate/number'
import length from './validate/length'
import regexp from './validate/regexp'
import required from './validate/required'
import notBlank from './validate/notBlank'
import maxLength from './validate/maxLength'
import numberBetween from './validate/numberBetween'

export default assign({
  Field,
  Submit,
  withForm,
  email,
  number,
  regexp,
  required,
  notBlank,
  maxLength,
  len: length,
  numberBetween,
})(Form)
