import hooks from '__hooks__'
import View from '__components__/View'

const stylize = (annotation, v) => Object.assign(annotation.style, v)

const runStrokeAnnotationAnimation = (annotations, index, timeout) => {
  if (!annotations[index]) return
  setTimeout(() => {
    const annotation = annotations[index]
    if (!annotation) return
    const attr = (name) => annotation.getAttribute(name)
    const duration = +attr(['data-stroke-annotation']) || 600
    const rest = +attr(['data-stroke-annotation-rest']) || 500
    const transition = `all ${duration}ms ease-in-out`
    stylize(annotation, { transition, strokeDashoffset: 0 })
    runStrokeAnnotationAnimation(annotations, index + 1, duration + rest)
  }, timeout)
}

const runStrokeAnnotationsAnimation = ({ ref }) => {
  const node = ref?.current || document
  const annotations = node.querySelectorAll('[data-stroke-annotation]')
  Array.from(annotations).forEach((annotation) => {
    const length = annotation.getTotalLength()
    stylize(annotation, { strokeDasharray: length, strokeDashoffset: length })
  })
  runStrokeAnnotationAnimation(annotations, 0, 1600)
}

export const hookRunStrokeAnimation = () =>
  hooks.tapEffect(runStrokeAnnotationsAnimation)

export default hooks(hooks.bubbleRef('ref'), hookRunStrokeAnimation())(View)
