export default {
  redOne: '#ff0000',
  redTwo: '#fc5a5a',
  redThree: '#ff7c57',
  redFour: '#c14522',
  redFive: '#822f2f',
  redSix: '#A5000E',

  yellowOne: '#f8e71c',
  yellowTwo: '#f8c11c',
  yellowThree: '#fda13b',
  yellowFour: '#f0963e',
  yellowFive: '#fd683b',

  greenOne: '#00e100',
  greenTwo: '#8fb536',
  greenThree: '#94ff5f',
  greenFour: '#16d59d',
  greenFive: '#78ffd7',
  greenSix: '#008850',

  blueOne: '#3676f5',
  blueTwo: '#36b5f5',
  blueThree: '#3496c7',
  blueFour: '#004b70',
  blueFive: '#204eff',
  blueSix: '#02369A',

  pinkOne: '#f278e6',
  pinkTwo: '#ba78e6',
  pinkThree: '#7670c2',
  pinkFour: '#ff6fa6',
  pinkFive: '#ff4583',
  pinkSix: '#FF57AE',

  greyOne: '#000000',
  greyTwo: '#6e6e6e',
  greyThree: '#d0d0d0',
  greyFour: '#e7e7e7',
  greyFive: '#ffffff',

  carmine: '#FF7578',
  orange: '#FFAE43',
  yellow: '#FFE557',
  green: '#5AE284',
  blue: '#68CEFF',
  purple: '#C190FF',
  black: '#000000',
  white: '#ffffff',
  gray: '#3E4554',

  carmine2: '#FF4D5C',
  orange2: '#FFA42B',
  yellow2: '#FFE13A',
  blue2: '#578EFF',
  purple2: '#A467F3',
  green2: '#37C35F',

  // not a real color but for toggle color 'none'
  none: 'none',

  red: '#FC0101',
  begonia: '#FF7578',
  bubbleGum: '#FCC5CC',
  mauvelous: '#F3A0A2',
  fuchsia: '#FC01FC',
  barbiePink: '#E5158C',
  imperialPurple: '#620231',
  maroon: '#7F0101',

  LemonGlacier: '#FCFC01',
  amber: '#FCBE01',
  naplesYellow: '#FCD35E',
  beer: '#F38E1E',
  vividTangelo: '#E87B23',
  chocolate: '#CF6127',
  metallicBrown: '#AA4C25',
  cinnamonStick: '#944221',

  electricGreen: '#01FC01',
  kiwi: '#90CE4F',
  teaGreen: '#C4ECCC',
  greenPigment: '#01AE4F',
  theGreen: '#017F01',
  swampland: '#1D592D',
  myrtle: '#1D421E',
  darkGreen: '#0A3317',

  theBlue: '#0101FC',
  frenchBlue: '#016FBE',
  duckEggBlue: '#C3F4FC',
  vividCerulean: '#01AEED',
  aqua: '#01FCFC',
  purpleHeart: '#6F309E',
  patriarch: '#7F017F',
  midnightMelancholia: '#01205F',

  theBlack: '#010101',
  darkCharcoal: '#323232',
  hardCoal: '#636363',
  industrialRevolution: '#737373',
  nickel: '#949494',
  fortressGrey: '#B7B7B7',
  discoBall: '#d4d4d4',
  greyPlacidity: '#DEDEE1',
}
