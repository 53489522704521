import React from 'react'
import * as R from 'ramda'
import { compose } from 'react-recompose'

import Transition from '../Transition'
import withContext from './withContext'
import assign from '__lib__/object/assign'
import lifecycle from '__lib__/react/lifecycle'
import { withChildContext } from './withContext'
import withHandlers from '__lib__/react/withHandlers'
import withStateUpdate from '__lib__/react/withStateUpdate'

// TODO
// const style = { pointerEvents: 'none' }

const BaseBackdrop = ({ style }) => (
  <Transition
    name="fade"
    component="div"
    style={{
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 41,
      position: 'fixed',
      background: 'rgba(0,0,0,0.666)',
      ...style,
    }}
  />
)

const Children = R.compose(R.defaultTo(null), R.prop('children'))

const Backdrop = ({
  children,
  activeCount,
  backdropStyle,
  component: Component = Children,
  backdropComponent: BackdropComponent = BaseBackdrop,
  ...props
}) => (
  <Component {...props}>
    {children}
    {Boolean(activeCount) && <BackdropComponent style={backdropStyle} />}
  </Component>
)

const noBackdropWarning = () =>
  console.warn(
    'Backdrop not activated because no backdrop was found in the context'
  )

const didMount = R.compose(
  R.call,
  R.defaultTo(noBackdropWarning),
  R.prop('activateBackdrop')
)
const willUnmount = R.compose(
  R.call,
  R.defaultTo(R.identity),
  R.prop('deactivateBackdrop')
)

const whileMounted = compose(
  withChildContext,
  lifecycle({ didMount, willUnmount })
)

const Fragment = whileMounted(R.compose(R.defaultTo(null), R.prop('children')))

const activate =
  ({ setActiveCount }) =>
  () =>
    setActiveCount((activeCount) => activeCount + 1)

const deactivate =
  ({ setActiveCount }) =>
  () =>
    setActiveCount((activeCount) => activeCount - 1)

export default compose(
  assign({ whileMounted, Fragment }),
  withStateUpdate('activeCount', 'setActiveCount', 0),
  withHandlers({ activate, deactivate }),
  withContext
)(Backdrop)
