import history from '__routes__/history'
import lifecycle from '__lib__/react/lifecycle'

const didMount = (props, instance) =>
  (instance.cleanupUpdateOnHistoryChange = history.listen(() =>
    instance.forceUpdate()
  ))

const willUnmount = (props, instance) => instance.cleanupUpdateOnHistoryChange()

export default lifecycle({ didMount, willUnmount })
