import arrayFrom from '../array/from'

const fn = (object, keys) => {
  const result = Object.assign({}, object)
  arrayFrom(keys).forEach((key) => delete result[key])
  return result
}

export const without = (keys) => (object) => fn(object, keys)
export const objectWithout = without

export default fn
