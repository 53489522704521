const SvgComponent = ({ fill = '#3054B4', ...props }) => (
  <svg
    width="48"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      d="M24 0C10.6875 0 0 10.6875 0 24C0 37.3125 10.6875 47.9999 24 47.9999C37.3126 47.9999 48 37.3125 48 24C48 10.6875 37.3126 0 24 0ZM24 43.8751C22.125 43.8751 19.6875 40.875 18 36H30.1874C28.3125 40.875 25.875 43.8751 24 43.8751ZM16.875 31.8749C16.5 29.4374 16.125 26.8125 16.125 24C16.125 21 16.5 18.1875 16.875 15.5625H31.1251C31.6875 18 31.875 20.8125 31.875 24C31.875 26.8125 31.4999 29.4374 31.1251 31.8749H16.875ZM4.12502 24C4.12502 21 4.87502 18.1875 5.99999 15.5625H12.75C12.1875 18.1875 12 21 12 24C12 26.8125 12.1875 29.4374 12.75 31.8749H5.8125C4.6875 29.4374 4.12502 26.8125 4.12502 24ZM24 4.125C25.875 4.125 28.3125 6.93749 30.0001 11.4375H18.1875C19.6875 6.93749 22.125 4.125 24 4.125ZM35.4375 15.5625H42.1876C43.3126 18.1875 44.0624 21 44.0624 24C44.0624 26.8125 43.5001 29.4374 42.3751 31.8749H35.625C36.0001 29.4374 36.3749 26.8125 36.3749 24C36.1876 21 35.8125 18.1875 35.4375 15.5625ZM39.375 11.4375H34.3125C33.5626 9.37501 32.8124 7.31249 31.875 5.81249C34.8751 7.12501 37.5002 9.00001 39.375 11.4375ZM16.125 5.81249C15.1875 7.50001 14.25 9.37501 13.6875 11.4375H8.62499C10.5 9.00001 13.125 7.12501 16.125 5.81249ZM8.24999 36H13.5C14.25 38.4375 15 40.4999 16.125 42.1875C12.9375 40.875 10.3125 38.8126 8.24999 36ZM31.875 42.1875C32.8124 40.4999 33.7501 38.25 34.5 36H39.7501C37.6874 38.8126 35.0624 40.875 31.875 42.1875Z"
      fill={fill}
    />
  </svg>
)

export default SvgComponent
