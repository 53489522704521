import regexp from './regexp'
import assign from '__lib__/object/assign'

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+$/
const ERROR = 'Not a valid email address'

export const isEmail = (string) => EMAIL_REGEX.test(string)

export const isDefinedEmail = (value, customMessage) =>
  value && regexp(EMAIL_REGEX, ERROR)(value, customMessage)

export default assign({ ERROR })(regexp(EMAIL_REGEX, ERROR))
