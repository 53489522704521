import React from 'react'
import View from '__components__/View'
import Button from '__components__/Button'
import PlatformIcon from '__views__/PlatformIcon'

const itemStyle = [
  { flex: '0 100%' },
  { laptop: { flex: '0 calc(50% - 16px)' } },
]
const Item = ({ logo, title, subTitle, ...props }) => (
  <Button
    component="a"
    styleLeft="border border-shade-1 rounded-lg flex-center p-6 w-full box flex laptop:flex-col g-5"
    {...props}
  >
    <View style="flex-stiff flex-center">{logo}</View>
    <View style="flex flex-col flex-flex laptop:text-center">
      <View.Text style="text-lg font-bold text-shade-7">{title}</View.Text>
      {subTitle && (
        <View.Text style="text-md text-shade-4 mt-2">{subTitle}</View.Text>
      )}
    </View>
  </Button>
)

const Clients = ({
  data: {
    microsoftUrl,
    iosUrl,
    webUrl,
    androidUrl = 'https://play.google.com/store/apps/details?id=com.drawboard.pdf',
    product
  },
  ...props
}) => (
  <View styleLeft="flex flex-wrap g-5" {...props}>
    <Item
      href={microsoftUrl}
      title="Windows App"
      subTitle="Surface Tablet, Desktop"
      logo={<PlatformIcon.Windows width={40} />}
      style={itemStyle}
    />
    <Item
      href={iosUrl}
      title="Apple App"
      subTitle="iPad, iPhone, Mac"
      logo={<PlatformIcon.Apple width={40} />}
      style={itemStyle}
    />
    {product === 'Projects' ? null : (
      <Item
        href={androidUrl}
        title="Android App"
        subTitle="Phone, Tablet"
        logo={<PlatformIcon.Android width={40} />}
        style={itemStyle}
      />)}
    <Item
      href={webUrl}
      title="Web Browser"
      subTitle="Chrome, Edge, Firefox, Safari"
      logo={<PlatformIcon.Web width={40} />}
      style={itemStyle}
    />
  </View>
)

export default Clients
