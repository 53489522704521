import { withProps } from 'react-recompose'
import { defaultProps } from 'react-recompose'

import Link from './Link'
// has to be after link import
import Box from 'Box'
import Icon from './Icon'
import Anchor from 'Anchor'
import Loading from 'Loading'
import Confirm from './Confirm'
import Message from './Message'
import WithPermission from 'WithPermission'
import withLoadingHandler from '__lib__/react/withLoadingHandler'

const loadingProps = ({ loading, disabled }) => ({
  component: Link,
  loading: !disabled && loading,
  disabled: loading || disabled,
})

const LoadingLink = withProps(loadingProps)(Loading.Content)
const LoadingClickLink = withLoadingHandler('onClick')(LoadingLink)

const AnchorLink = withProps({
  component: withProps({ component: 'a' })(Link),
})(Anchor)

const IconAnchorLink = withProps({
  component: withProps({ component: 'a' })(Icon),
})(Anchor)

const LinkWithPermission = defaultProps({ component: Link })(WithPermission)

const LinkIconWithPermission = withProps({ component: Icon })(WithPermission)

const unpermittedProps = ['to']

const LinkAnchorWithPermission = defaultProps({
  component: AnchorLink,
  unpermittedProps,
})(WithPermission)

const LoadingOnClickWithPermission = defaultProps({
  component: LoadingClickLink,
})(WithPermission)

export default Object.assign(Link, {
  Icon: Object.assign(Icon, {
    WithPermission: LinkIconWithPermission,
  }),
  Box: withProps({ component: Link })(Box),
  Anchor: Object.assign(AnchorLink, {
    Icon: IconAnchorLink,
    WithPermission: LinkAnchorWithPermission,
  }),
  WithPermission: Object.assign(LinkWithPermission, {
    Icon: LinkIconWithPermission,
    Anchor: LinkAnchorWithPermission,
    Loading: LoadingOnClickWithPermission,
  }),
  Loading: Object.assign(LoadingLink, {
    OnClick: LoadingClickLink,
    WithPermission: LoadingOnClickWithPermission,
  }),
  Confirm,
  Message,
})
