import lifecycle from '__lib__/react/lifecycle'

const withActionOnPropChangeAndMount = (
  keys,
  action,
  mapProps = (id) => id
) => {
  const didUpdate = (nextProps, prevProps, instance) =>
    (typeof keys === 'function'
      ? keys(nextProps, prevProps, instance)
      : keys.some((key) => nextProps[key] !== prevProps[key])) &&
    action(mapProps(nextProps), mapProps(prevProps), instance)

  return lifecycle({
    didUpdate,
    didMount: (props, instance) => didUpdate(props, {}, instance),
  })
}

export default withActionOnPropChangeAndMount
