import * as R from 'ramda'
import hooks from '__hooks__'
import Form from '__core__/Form'
import View from '__components__/View'
import Input from '__components__/Input'
import Button from '__components__/Button'
import upperFirst from '__lib__/string/upperFirst'
import {
  CloudPanel,
  DefaultBackdrop,
  DefaultPanel,
  GoogleDrivePanel,
  IntegrationsPanel,
  SignupBackdrop,
} from './Panels'
import { validateEmail } from './validateEmail'
import FormForgeryToken from './FormForgeryToken'
import { validatePassword } from './RegisterFinal'
import ThirdPartyButton, { externalLoginsProps } from './ThirdPartyButton'
import Layout, { BackButton, Footer, SignupButton } from './Layout'
import { CloudHeader, GoogleDriveHeader, IntegrationsHeader } from './Header'

export const DISPLAY_MODE = {
  cloud: 'cloud',
  googledrive: 'googledrive',
  integrations: 'integrations',
}

const Login = ({
  form,
  data: {
    formAction,
    forgotPasswordUrl,
    resendEmailVerificationUrl,
    registerUrl,
    externalLogins = [],
    organizationLoginUrl,
    organizationLoginEnabled,
    loginFailed,
    lockedOut,
    emailVerificationRequired,
    displayMode,
    usernamePasswordOnly
  },
}) => (
  <Layout
    rightContainerStyle="bg-drop-1 justify-start"
    asset={
      displayMode === DISPLAY_MODE.googledrive
        ? GoogleDrivePanel
        : displayMode === DISPLAY_MODE.cloud
        ? CloudPanel
        : displayMode === DISPLAY_MODE.integrations
        ? IntegrationsPanel
        : DefaultPanel
    }
    backdrop={
      displayMode === DISPLAY_MODE.googledrive
        ? SignupBackdrop
        : DefaultBackdrop
    }
    backButton={
      displayMode === DISPLAY_MODE.googledrive && (
        <BackButton component="a" href={registerUrl} text="Back to previous" />
      )
    }
  >
    <View style="flex items-center laptop:justify-center flex-col w-full flex-col flex-flex">
      {loginFailed || lockedOut || emailVerificationRequired ? (
        <View styleLeft="mb-5 text-center text-danger-dark text-sm font-semibold text-line-break">
          {lockedOut
            ? LOCKED_OUT_MESSAGE
            : emailVerificationRequired
            ? EMAIL_CONFIRMATION_MESSAGE
            : LOGIN_FAILED_MESSAGE}
        </View>
      ) : null}

      {displayMode === DISPLAY_MODE.googledrive ? (
        <GoogleDriveHeader />
      ) : displayMode === DISPLAY_MODE.cloud ? (
        <CloudHeader />
      ) : displayMode === DISPLAY_MODE.integrations ? (
        <IntegrationsHeader />
      ) : null}

      {usernamePasswordOnly ? null : 
        <View style="flex-center justify-around w-full">
          <View
            style="flex flex-col items-center flex-flex -mx-5 laptop:mt-5 w-full"
            // NOTE: implemented like this for IE
            styleRight={{ maxWidth: 220 * 2 + 8 * 3 }}
          >
            {externalLogins.map(({ id, url }) => (
              <ThirdPartyButton
                key={id}
                name={id}
                action={url}
                logoStyle="tablet:rotate-0"
                style="m-4"
                {...externalLoginsProps[id]}
              />
            ))}
          </View>
        </View>   
      }      
      {usernamePasswordOnly ? null : 
        <View style="laptop:mt-5">
          <View.Text style="text-md text-shade-4">OR</View.Text>
        </View>
      }      
      <Form
        form={form}
        method="post"
        style="w-full max-w-10"
        action={formAction}
        component={View.Form}
      >
        <View style="flex flex-col laptop:mt-5">
          <Input.Field
            lg
            autoFocus
            tabIndex="1"
            type="email"
            name="Input.Email"
            validate={validateEmail}
            label={(props) => (
              <View style="flex-center justify-between mb-2">
                <View.Text {...props}>Email</View.Text>
                {emailVerificationRequired ? (
                  <Button
                    tabIndex="3"
                    component="a"
                    text="Resend email verification"
                    href={resendEmailVerificationUrl}
                    textStyle="font-thin text-sm text-secondary"
                  />
                ) : null}
              </View>
            )}
            placeholder="E.g. john.smith@drawboard.com"
          />
          <Input.Field
            lg
            tabIndex="2"
            type="password"
            name="Input.Password"
            validate={validatePassword}
            label={(props) => (
              <View style="flex-center justify-between mb-2">
                <View.Text {...props}>Password</View.Text>
                <Button
                  tabIndex="3"
                  component="a"
                  text="Forgot password?"
                  href={forgotPasswordUrl}
                  textStyle="font-thin text-sm text-secondary"
                />
              </View>
            )}
            placeholder=""
          />
          <View style="flex-center laptop:mt-5">
            <FormForgeryToken />
            <Form.Submit
              xl
              plain
              secondary
              text="Continue"
              component={Button}
              style={['rounded-full', { width: 208 }]}
            />
          </View>
        </View>
      </Form>
    </View>
    {organizationLoginEnabled ? (
      <View style="laptop:mt-5">
        <Button
          component="a"
          href={organizationLoginUrl}
          style="rounded-full flex-center"
        >
          <View.Text style="text-secondary font-bold text-md ml-4">
            Sign in with organization account
          </View.Text>
        </Button>
      </View>
    ) : null}
    <Footer>
      <SignupButton text="New to Drawboard? " href={registerUrl} />
    </Footer>
  </Layout>
)

const INVALID_ATTEMPT = 'Invalid login attempt.'
const LOGIN_FAILED_MESSAGE =
  'Login failed. Please contact customer support for assistance'
const LOCKED_OUT_MESSAGE =
  "You've reached the maximum login attempts. \nPlease try again in a few minutes"
const EMAIL_CONFIRMATION_MESSAGE =
  'Please verify your email or use "Resend email verification" link \nto receive verification email'

const setFormErrors = ({ form, data: { formState = [] } }) =>
  form.setFieldsError(
    R.reduce(
      (fieldErrors, { key, errors }) =>
        (key === INVALID_ATTEMPT
          ? R.mergeLeft({
              'Input.Email': 'Invalid email',
              'Input.Password': 'Or password',
            })
          : R.identity)(fieldErrors),
      {},
      formState
    )
  )

export const initialState = ({ data: { formData } }) =>
  R.reduce(
    (state, key) => R.assoc('Input.' + upperFirst(key), formData[key], state),
    {},
    R.keys(formData || {})
  )

const sortExternalLogins = ({ data: { externalLogins = [] } }) =>
  externalLogins.sort(
    (a, b) =>
      externalLoginsProps[a.displayName].order -
      externalLoginsProps[b.displayName].order
  )

export default Form.withForm(
  'form',
  initialState
)(
  hooks(
    hooks.tapEffect(setFormErrors),
    hooks.tapEffect(sortExternalLogins, [])
  )(Login)
)
