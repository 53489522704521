import * as Sentry from '@sentry/react'
import { onAccessDenied } from './config'

/**
 *
 * @param {ResponseInfo} response
 * @returns
 */
const readBody = (response) => {
  try {
    const contentType = response.headers.get('Content-Type')
    return contentType && contentType.match(/application\/json/i)
      ? response.json()
      : response.text()
  } catch (error) {
    console.error('Error reading response', error)
    // https://drawboard.sentry.io/issues/5758328579
    Sentry.captureException(
      new Error('__api__/handle readBody(response): Couldn\'t read response.'),
      (scope) => {
        scope.setExtra('response', response)
      }
    )
    return null
  }
}

export const getHeaders = (response) => ({
  status: response.status,
  headers: Object.fromEntries(response.headers.entries()),
})

const getBody = (response) =>
  response.ok
    ? readBody(response)
    : response.status === 401
    ? onAccessDenied(response)
    : readBody(response)
        .then((body) => ({
          body,
          ok: response.ok,
          status: response.status,
          statusCode: response.status,
          statusText: response.statusText,
        }))
        .then((error) => Promise.reject(error))

export default getBody
