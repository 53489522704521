import lazy from '__lib__/utils/lazy'
import objectWithKeys from '__lib__/object/withKeys'

import types from './types'

export const apiTypeMap = (map, unfound) => {
  const typedMap = lazy(() => objectWithKeys(map, (_, type) => types[type]))
  const fn = (annotation) =>
    typedMap().hasOwnProperty(annotation.Type)
      ? typedMap()[annotation.Type]
      : unfound
  return Object.assign(fn, {
    invoke: (annotation, ...args) => fn(annotation)(annotation, ...args),
    map,
  })
}

export default (map, unfound) => {
  const fn = (annotation) =>
    map.hasOwnProperty(annotation.type) ? map[annotation.type] : unfound
  return Object.assign(fn, {
    invoke: (annotation, ...args) => fn(annotation)(annotation, ...args),
    map,
  })
}
