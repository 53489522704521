import timesReduce from '__lib__/utils/timesReduce'

const mapJoinDefault = (array, mapFn, joinString) =>
  !array.length
    ? ''
    : timesReduce(
        array.length - 1,
        (acc, n) => acc + joinString + mapFn(array[n + 1], n + 1, array),
        mapFn(array[0], 0, array)
      )

export const mapJoin = (mapFn) => (joinString) => (array) =>
  mapJoinDefault(array, mapFn, joinString)

export default mapJoinDefault
