const OneDriveIcon = ({ isDarkMode, status = 'default', ...props }) => (
  <svg
    width="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.52328 8.93512C9.52328 8.93512 12.8965 6.62866 12.9693 6.63361C12.7288 5.69515 12.2209 4.84673 11.5073 4.19149C10.7937 3.53624 9.90518 3.10237 8.94966 2.94261C7.99414 2.78285 7.01278 2.90408 6.12486 3.29157C5.23694 3.67905 4.48069 4.31611 3.94801 5.12531H3.99906L9.52328 8.93512Z"
      fill={
        status === 'active'
          ? '#DEDEE1'
          : status === 'inactive'
          ? isDarkMode
            ? '#333A47'
            : '#D4D4D8'
          : '#0364B8'
      }
    />
    <path
      d="M6.10056 5.72116C5.46934 5.33045 4.74143 5.12406 3.99907 5.12531H3.94802C3.22156 5.13481 2.5114 5.34196 1.89377 5.72454C1.27614 6.10713 0.77436 6.65069 0.442294 7.29688C0.110228 7.94308 -0.0395865 8.66751 0.00893123 9.39241C0.0574489 10.1173 0.302467 10.8153 0.717685 11.4115L6.09447 10.84L9.45967 7.73312L6.10056 5.72116Z"
      fill={
        status === 'active'
          ? '#F1F1F1'
          : status === 'inactive'
          ? isDarkMode
            ? '#434B5B'
            : '#DEDEDE'
          : '#0F78D4'
      }
    />
    <path
      d="M12.9693 6.63361C12.8962 6.62845 12.823 6.62578 12.7498 6.62561C12.3066 6.62546 11.8681 6.71623 11.4613 6.8923L9.45967 7.73426L11.8092 10.459L15.6114 11.418C15.8692 10.9392 16.0028 10.4034 16 9.85955C15.9971 9.31575 15.8578 8.78136 15.5949 8.30532C15.3321 7.82928 14.9539 7.4268 14.4952 7.13474C14.0365 6.84269 13.5118 6.67038 12.9693 6.63361Z"
      fill={
        status === 'active'
          ? '#F9F9F9'
          : status === 'inactive'
          ? isDarkMode
            ? '#343A46'
            : '#DBDBDB'
          : '#1490DF'
      }
    />
    <path
      d="M12.7727 9.71761L11.9429 9.22233L10.0403 8.08282L9.45966 7.73499L7.9285 8.3796L4.99495 9.61322L3.67981 10.1656L0.717682 11.4114C1.08567 11.9408 1.57632 12.3733 2.14774 12.6718C2.71916 12.9704 3.35436 13.1261 3.99907 13.1259H12.7498C13.3365 13.126 13.9123 12.9673 14.4161 12.6666C14.9199 12.3659 15.333 11.9344 15.6114 11.4179L12.7727 9.71761Z"
      fill={
        status === 'active'
          ? '#ffffff'
          : status === 'inactive'
          ? isDarkMode
            ? '#4C5467'
            : '#E8E8E8'
          : '#28A8EA'
      }
    />
  </svg>
)

export default OneDriveIcon
