import regexp from './regexp'
import assign from '__lib__/object/assign'

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+$/
const ERROR = 'is not a valid email'

export const isDefinedEmail = (value, customMessage) =>
  value && regexp(/^[^\s@]+@[^\s@]+$/, ERROR)(value, customMessage)

export default assign({ ERROR })(regexp(EMAIL_REGEX, ERROR))
