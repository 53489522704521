import config from '__config__'
import { save } from '__lib__/web/localStorage'

const autolink = () =>
  window.analytics &&
  window.analytics.ready(() => {
    save('km-ai', window.KM && window.KM.i())
    window.ga('require', 'linker')
    window.ga('linker:autoLink', [config.segmentAutolink])
  })

export default autolink
