import * as R from 'ramda'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import { setStore } from './global'
import stateReducer from './reducer'
import { setDispatch } from '../central'
import initialState from '../initialState'
import { reducer as centralReducer } from '../central'
import { persistStore, persistReducer } from './persist'
import uuid from '__lib__/uuid'

const broadcastId = uuid()
const broadcastChannel =
  typeof BroadcastChannel === 'undefined'
    ? undefined
    : new BroadcastChannel('storeActions')

const EXCLUDED_ACTIONS = [
  '@@INIT',
  '_STORE_INIT_',
  'persist/REHYDRATE',
  'persist/PERSIST',
]

const broadcastAction = (action) => {
  if (R.includes(action.type, EXCLUDED_ACTIONS)) return
  broadcastChannel?.postMessage(JSON.stringify({ id: broadcastId, action }))
}

const reducer = (state = initialState(), action) => {
  const newState = action.isCentral
    ? centralReducer(state, action)
    : stateReducer(state, action)
  import.meta.env.VITE_PROJECT === 'pdf' &&
    (action.isBroadcast || broadcastAction(action))
  return newState
}

const devTools =
  typeof window !== undefined &&
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__({
    trace: true,
    traceLimit: 25,
  })

const args = {}

const freezerHandler = {
  frozen: () => Promise.resolve(null),
  current: (next, action) => next(action),
}
const freezer = () => (next) => (action) => freezerHandler.current(next, action)

export const store = (args.store = createStore(
  persistReducer(reducer),
  compose(
    applyMiddleware(freezer, thunk.withExtraArgument(args)),
    devTools || R.identity
  )
))

store.dispatch({ type: '_STORE_INIT_' })

store.freeze = () => (freezerHandler.current = freezerHandler.frozen)
export const storePersistor = persistStore(store)
export const getState = store.getState.bind(store)
export const subscribe = store.subscribe.bind(store)
export const dispatch = store.dispatch.bind(store)

const dispatchFn = (action, ...rest) =>
  R.is(String, action)
    ? store.dispatch({ isCentral: true, type: action, payload: rest })
    : store.dispatch(action)

setDispatch(dispatchFn)
setStore({ dispatch: dispatchFn, getState, persistor: storePersistor })

window && (window.store = store)
window &&
  (window._stateSet = (state) =>
    store.dispatch({ type: 'internal/setState', payload: [state] }))

import.meta.env.VITE_PROJECT === 'pdf' &&
  broadcastChannel?.addEventListener('message', (event) => {
    const { id, action } = JSON.parse(event.data || {})
    id === broadcastId || store.dispatch({ isBroadcast: true, ...action })
  })

export default dispatchFn
