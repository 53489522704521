const SvgComponent = ({ fill = '#3DDB85', ...props }) => (
  <svg
    width="48"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      d="M35.0461 30.6828C33.944 30.6828 33.0475 29.7855 33.0475 28.6834C33.0475 27.5813 33.944 26.6848 35.0461 26.6848C36.1484 26.6848 37.0447 27.5813 37.0447 28.6834C37.0447 29.7855 36.1484 30.6828 35.0461 30.6828ZM12.9539 30.6828C11.8518 30.6828 10.9553 29.7855 10.9553 28.6834C10.9553 27.5813 11.8518 26.6848 12.9539 26.6848C14.0561 26.6848 14.9526 27.5813 14.9526 28.6834C14.9526 29.7855 14.0561 30.6828 12.9539 30.6828ZM35.7629 18.6428L39.7576 11.7244C39.9866 11.3267 39.8503 10.8188 39.4534 10.5892C39.0564 10.3602 38.5477 10.4964 38.3182 10.8934L34.2736 17.8994C31.1806 16.4878 27.7067 15.7016 24 15.7016C20.2933 15.7016 16.8195 16.4878 13.7265 17.8994L9.68188 10.8934C9.45224 10.4964 8.94364 10.3602 8.54665 10.5892C8.14964 10.8188 8.01276 11.3267 8.2424 11.7244L12.2371 18.6428C5.37772 22.3735 0.686317 29.3178 0 37.522H48C47.3131 29.3178 42.6217 22.3735 35.7629 18.6428Z"
      fill={fill}
    />
  </svg>
)

export default SvgComponent
