import * as R from 'ramda'
import React from 'react'
import hooks from '__hooks__'
import circularNext from '__lib__/array/circularNext'
import circularPrev from '__lib__/array/circularPrev'

const FOCUSABLE_SELECTOR = 'input, button, a, *[role="button"], *[role="input"]'

export const getClosestFocusable = (element) =>
  element.closest(FOCUSABLE_SELECTOR)

export const isElementFocusable = (element, { ancestors } = {}) =>
  element.matches(FOCUSABLE_SELECTOR) ||
  (ancestors && Boolean(element.closest(FOCUSABLE_SELECTOR)))

export const getFocusableElements = (element) =>
  isElementFocusable(element)
    ? [element, ...Array.from(element.querySelectorAll(FOCUSABLE_SELECTOR))]
    : Array.from(element.querySelectorAll(FOCUSABLE_SELECTOR))

export const getLabelFocusableElement = (element) =>
  isElementFocusable(element)
    ? element
    : element.querySelector('input') ||
      element.querySelector(FOCUSABLE_SELECTOR)

export const getFocusableElement = (element) =>
  isElementFocusable(element)
    ? element
    : element.querySelector(FOCUSABLE_SELECTOR)

export const focusElement = (element) => {
  if (element.contains(document.activeElement)) return true
  const focusableElement = getFocusableElement(element)
  return focusableElement ? (focusableElement.focus(), true) : false
}

const onMount = ({ ref, noAutoFocus }, propsRef) => {
  if (!ref.current) return
  const autoFocus = () => {
    propsRef.timeout && clearTimeout(propsRef.timeout)
    propsRef.timeout = setTimeout(() => {
      ref.current &&
        !ref.current.contains(document.activeElement) &&
        getFocusableElements(ref.current)?.[0]?.focus()
      propsRef.timeout = null
    }, 500)
  }
  noAutoFocus || autoFocus()
  const element = ref.current
  const onKeydown = (event) => {
    if (event.key !== 'Tab') return
    const element = ref.current
    const elements = getFocusableElements(element)
    const current = document.activeElement
    const getNext = event.shiftKey ? circularPrev : circularNext
    getNext(elements, current)?.focus()
    event.preventDefault()
  }
  element.addEventListener('keydown', onKeydown)
  return () => element.removeEventListener('keydown', onKeydown)
}

export default hooks.component(
  hooks.bubbleRef('ref'),
  hooks.consume(['noAutoFocus'])(hooks.effect(onMount, ['noAutoFocus'])),
  hooks.assoc('component', R.either(R.prop('component'), R.always('div')))
)('FocusTrap')
