import * as R from 'ramda'
import { branch } from 'react-recompose'
import { compose } from 'react-recompose'
import { withProps } from 'react-recompose'

import Button from './Button'
import Loading from 'Loading'
import styles from './styles.module.css'
import withClassName from '__lib__/react/withClassName'
import withPropsChangeDelay from '__lib__/react/withPropsChangeDelay'

const contentClassName = ({ loading, disabled }) => [
  styles.loadingContent,
  !disabled && loading && styles.loading,
]

const loadingClassName = ({ loading, disabled }) => [
  styles.loadingIcon,
  !disabled && loading && styles.loadingInProgressIcon,
]

const classNames = {
  contentClassName,
  loadingClassName,
  className: styles.loadingWrapper,
}

const props = ({ component, loading, inert }) => ({
  noTransition: true,
  inert: inert || loading,
  component: component || Button,
})

const hasStartedLoading = ({ loading }, { loading: prevLoading }) => ({
  loading: loading === true && !prevLoading,
})

export default compose(
  withProps(props),
  branch(
    R.prop('loadingDelay'),
    withPropsChangeDelay(hasStartedLoading, R.prop('loadingDelay'))
  ),
  withClassName(classNames)
)(Loading.BaseContent)
