import React from 'react'
import * as R from 'ramda'
import View from '__components__/View'

import icons from './icons'
import hooks from '__hooks__'
import assign from '__lib__/object/assign'

export const getIconViewBox = (name) => icons[name]?.viewBox

export const Path = ({ component: Component = 'path', icon, ...props }) =>
  icons[icon] ? (
    <Component
      d={icons[icon].path || icons[icon]}
      fillRule="inherit"
      {...props}
    />
  ) : null

const Icon = ({
  font,
  icon,
  size,
  viewBox,
  children,
  svgStyle,
  overflow,
  innerProps,
  viewBoxIcon,
  pathComponent = 'path',
  component: Component = View,
  svgComponent: SvgComponent = 'svg',
  ...props
}) => (
  <Component {...props}>
    <SvgComponent
      style={svgStyle}
      data-icon={icon}
      overflow={overflow}
      viewBox={
        viewBox ||
        (icons[viewBoxIcon] && icons[viewBoxIcon].viewBox) ||
        (icons[icon] && icons[icon].viewBox) ||
        '0 0 32 32'
      }
      {...innerProps}
    >
      {children}
      <Path component={pathComponent} icon={icon} />
    </SvgComponent>
  </Component>
)

const DEFAULT_SIZE = 16
const fontSizeToSvgSize = R.add(2)

const omitTextStyleProps = R.omit([
  'textShadowOffset',
  'color',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'textAlign',
  'textDecorationLine',
  'textShadowColor',
  'fontFamily',
  'textShadowRadius',
  'includeFontPadding',
  'textAlignVertical',
  'fontVariant',
  'letterSpacing',
  'textDecorationColor',
  'textDecorationStyle',
  'textTransform',
  'writingDirection',
])

const size = ({ size, style: { fontSize, width } }) =>
  size || fontSizeToSvgSize(fontSize) || width || DEFAULT_SIZE

const style = ({ font, style, size, style: { lineHeight } }) => [
  'flex flex-row items-center',
  // omitTextStyleProps(style || {}),
  style,
  { width: font ? '1em' : size },
  lineHeight && { height: lineHeight },
]

const svgStyle = ({ font, size, fill, style: { color } }) => [
  {
    fillRule: 'evenodd',
    width: font ? '1em' : size,
    maxHeight: size,
    fill: color || fill || 'currentColor',
  },
  { web: { color } },
]

const EIcon = hooks(
  hooks.style(),
  hooks.assoc('size', size),
  hooks.styles({ svgStyle }),
  hooks.assoc('style', style)
)(Icon)

export default assign({
  Path,
  // Morph,
})(EIcon)
