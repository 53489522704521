import React from 'react'

export const Logo = ({ auto, dark, light, color, ...props }) => (
  <svg
    width="13"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.92684 0.286009L0.211486 5.17868C-0.0704954 5.68668 -0.0704954 6.31201 0.211486 6.82001L2.92684 11.7127C3.07873 11.9867 3.41582 12.0807 3.67922 11.922L7.00853 9.92268C7.23411 9.78734 7.31166 9.48668 7.18156 9.25201L7.12965 9.15868C6.04466 7.20401 6.04466 4.79468 7.12965 2.83934L7.18156 2.74601C7.31166 2.51134 7.23475 2.21068 7.00853 2.07534L3.67922 0.0760084C3.41582 -0.0819916 3.07873 0.0120084 2.92684 0.286009Z"
      fill={auto ? '#74D688' : color || (light ? '#FFFFFF' : '#74D688')}
    />
    <path
      d="M12.7242 5.50335L10.5517 4.19868L8.80085 3.14735C8.56116 3.00335 8.25547 3.08868 8.11704 3.33801C7.20252 4.98535 7.20252 7.01468 8.11704 8.66201C8.25547 8.91135 8.56116 8.99668 8.80085 8.85268L10.5517 7.80135L12.7242 6.49601C13.0915 6.27468 13.0915 5.72401 12.7242 5.50335Z"
      fill={
        auto ? 'currentColor' : color || (dark || light ? '#FFFFFF' : '#182347')
      }
    />
  </svg>
)

export default Logo

export const LogoWatermark = ({ pathProps, ...props }) => (
  <svg width="185" viewBox="0 0 185 23" fill="none" {...props}>
    <g>
      <path
        d="M36.1135 16.5298C38.9353 16.5298 41.6173 15.0839 41.6173 11.0493C41.6173 7.01481 38.9353 5.54548 36.1135 5.54548H34.1543V16.5298H36.1135ZM31.0757 2.8866H36.3699C40.8945 2.8866 44.7423 5.59179 44.7423 11.0489C44.7423 16.506 40.8943 19.2121 36.3699 19.2121H31.0757V2.8866Z"
        fill="currentColor"
        {...pathProps}
      />
      <path
        d="M94.1879 13.3346C94.1879 11.1657 92.9287 9.74316 91.0161 9.74316C89.1034 9.74316 87.8444 11.1664 87.8444 13.3346C87.8444 15.5028 89.1036 16.9266 91.0161 16.9266C92.9285 16.9266 94.1879 15.5034 94.1879 13.3346ZM88.0309 17.7428V19.2121H85.1855V2.8866H88.0309V8.88008C88.707 7.92419 89.9427 7.27099 91.4825 7.27099C94.9576 7.27099 97.0331 9.74315 97.0331 13.3346C97.0331 16.926 94.9576 19.3986 91.4358 19.3986C90.0126 19.3986 88.707 18.769 88.0309 17.7417"
        fill="currentColor"
        {...pathProps}
      />
      <path
        d="M107.272 13.3349C107.272 11.2126 105.966 9.74351 104.03 9.74351C102.071 9.74351 100.765 11.2126 100.765 13.3349C100.765 15.4572 102.071 16.9268 104.03 16.9268C105.966 16.9268 107.272 15.457 107.272 13.3349ZM97.8735 13.3349C97.8735 9.83655 100.439 7.27092 104.03 7.27092C107.599 7.27092 110.164 9.83655 110.164 13.3349C110.164 16.8333 107.599 19.3989 104.03 19.3989C100.439 19.3989 97.8735 16.8333 97.8735 13.3349Z"
        fill="currentColor"
        {...pathProps}
      />
      <path
        d="M140.594 13.3348C140.594 11.166 139.335 9.74337 137.423 9.74337C135.51 9.74337 134.251 11.1666 134.251 13.3348C134.251 15.503 135.51 16.9266 137.423 16.9266C139.335 16.9266 140.594 15.5034 140.594 13.3348ZM131.406 13.3348C131.406 9.74337 133.481 7.27078 136.956 7.27078C138.496 7.27078 139.731 7.92419 140.408 8.88009V2.8866H143.253V19.2121H140.408V17.7428C139.731 18.7688 138.425 19.3997 137.003 19.3997C133.481 19.3997 131.406 16.9275 131.406 13.3356"
        fill="currentColor"
        {...pathProps}
      />
      <path
        d="M59.0516 16.8773C57.6167 16.8773 57.2292 16.194 57.2292 15.6929C57.2292 15.0556 57.5712 13.9472 59.6551 13.9472H62.4308V14.2467C62.4962 15.4316 61.5454 16.8773 59.0516 16.8773ZM66.067 16.8773C65.5657 16.8773 65.2241 16.6037 65.2241 16.1368V11.6044C65.2241 10.1457 64.7572 9.04114 63.8234 8.29058C62.8782 7.5505 61.6028 7.17473 59.9854 7.17473C57.2146 7.17473 54.7832 8.57905 54.8503 11.3996H57.6281V11.2859C57.6851 10.466 58.2319 9.74822 59.986 9.74822C62.2636 9.74822 62.4346 10.8983 62.4346 11.3996C62.4346 11.6388 62.3205 11.9124 61.8536 11.9124H59.5412C56.1246 11.9124 54.4278 13.6659 54.4278 15.6935C54.4278 17.8003 55.8742 19.4404 59.0516 19.4404C59.8463 19.4736 60.6378 19.3216 61.3636 18.9962C62.3955 18.504 62.7964 18.0378 62.9243 17.8534C63.2098 18.7783 64.1272 19.2129 65.4916 19.2129H66.5339V16.8773H66.067Z"
        fill="currentColor"
        {...pathProps}
      />
      <path
        d="M115.945 16.8774C114.51 16.8774 114.123 16.194 114.123 15.6927C114.123 15.0555 114.465 13.9473 116.549 13.9473H119.325V14.2466C119.39 15.4314 118.439 16.8774 115.945 16.8774ZM122.117 11.6045C122.117 10.1467 121.65 9.04208 120.716 8.29067C119.771 7.55017 118.495 7.17439 116.878 7.17439C114.107 7.17439 111.676 8.57893 111.743 11.3997H114.521V11.286C114.578 10.4659 115.124 9.74831 116.879 9.74831C119.156 9.74831 119.327 10.8984 119.327 11.3997C119.327 11.6387 119.213 11.912 118.746 11.912H116.435C113.018 11.912 111.321 13.6658 111.321 15.6932C111.321 17.8002 112.768 19.4403 115.945 19.4403C116.74 19.4735 117.532 19.3213 118.257 18.9959C118.611 18.8323 118.947 18.633 119.26 18.4011L119.249 19.2121H122.117L122.117 11.6045Z"
        fill="currentColor"
        {...pathProps}
      />
      <path
        d="M51.8847 9.95471C50.0655 9.95471 49.3426 11.5177 49.3426 13.7571V19.2191H46.4732V7.39566H49.342V9.47102C49.9249 7.97855 50.8349 7.39566 52.3505 7.39566H53.8096V9.95471H51.8847Z"
        fill="currentColor"
        {...pathProps}
      />
      <path
        d="M129.053 9.95471C127.234 9.95471 126.511 11.5177 126.511 13.7571V19.2191H123.642V7.39566H126.511V9.47102C127.094 7.97855 128.004 7.39566 129.52 7.39566H130.979V9.95471H129.053Z"
        fill="currentColor"
        {...pathProps}
      />
      <path
        d="M81.1831 7.42139L79.1213 15.5635L76.7611 7.42139H73.1965L70.8365 15.5635L68.7745 7.42139H65.8947L69.156 19.248H72.3665L74.9787 10.4741L77.5908 19.248H80.8016L84.0627 7.42139H81.1831Z"
        fill="currentColor"
        {...pathProps}
      />
      <path
        d="M152.845 10.7584C155.159 10.7584 156.023 9.30929 156.023 7.697C156.023 6.08472 155.159 4.63579 152.845 4.63579H148.685V10.7584H152.845ZM146.699 2.86015H152.845C156.093 2.86015 158.056 4.82293 158.056 7.697C158.056 10.5711 156.093 12.5343 152.845 12.5343H148.685V19.2175H146.699V2.86015Z"
        fill="currentColor"
        {...pathProps}
      />
      <path
        d="M164.553 17.4179C167.731 17.4179 170.675 15.5485 170.675 11.0388C170.675 6.52911 167.731 4.65937 164.553 4.65937H161.772V17.4179H164.553ZM159.786 2.86015H164.6C169.181 2.86015 172.756 5.82769 172.756 11.0384C172.756 16.2491 169.181 19.2167 164.6 19.2167H159.786V2.86015Z"
        fill="currentColor"
        {...pathProps}
      />
      <path
        d="M174.765 2.86015V19.2173H176.751V11.8567H183.458V10.0574H176.751V4.65937H185V2.86015H174.765Z"
        fill="currentColor"
        {...pathProps}
      />
    </g>
    <path
      d="M24.6526 10.1359L20.4904 7.73231L17.1359 5.79565C16.9155 5.66851 16.6537 5.63407 16.408 5.69992C16.1623 5.76577 15.9528 5.92651 15.8256 6.14679C14.9651 7.63735 14.5121 9.32815 14.5121 11.0493C14.5121 12.7704 14.9651 14.4612 15.8256 15.9518C15.9529 16.172 16.1624 16.3327 16.4081 16.3985C16.6538 16.4643 16.9156 16.4298 17.1359 16.3027L20.4904 14.3662L24.6526 11.9627C24.8129 11.8701 24.946 11.7369 25.0386 11.5766C25.1311 11.4163 25.1798 11.2344 25.1798 11.0493C25.1798 10.8642 25.1311 10.6823 25.0386 10.522C24.946 10.3616 24.8129 10.2285 24.6526 10.1359Z"
      fill="currentColor"
      {...pathProps}
    />
    <path
      d="M5.88771 0.527463L0.685492 9.53777C0.420189 9.99729 0.280518 10.5185 0.280518 11.0492C0.280518 11.5798 0.420189 12.101 0.685492 12.5605L5.88771 21.5711C5.95695 21.6911 6.04916 21.7963 6.15908 21.8807C6.269 21.9651 6.39447 22.0269 6.52832 22.0628C6.66217 22.0987 6.80178 22.1078 6.93916 22.0897C7.07655 22.0716 7.20901 22.0266 7.32899 21.9572L13.7067 18.2751C13.8096 18.2157 13.8998 18.1367 13.9721 18.0425C14.0445 17.9483 14.0975 17.8407 14.1283 17.726C14.159 17.6113 14.1669 17.4916 14.1514 17.3738C14.1359 17.2561 14.0973 17.1425 14.0379 17.0397L13.9387 16.8678C12.9172 15.0988 12.3795 13.092 12.3795 11.0493C12.3795 9.00649 12.9172 6.99973 13.9387 5.2307L14.0379 5.05864C14.0973 4.95579 14.1359 4.84226 14.1514 4.72451C14.1669 4.60677 14.159 4.48712 14.1282 4.37241C14.0975 4.2577 14.0444 4.15017 13.9721 4.05597C13.8998 3.96177 13.8096 3.88274 13.7067 3.8234L7.32899 0.14128C7.08664 0.00142468 6.79867 -0.0364586 6.52839 0.0359602C6.25812 0.108379 6.02766 0.285171 5.88771 0.527463Z"
      fill="#5AE284"
      {...pathProps}
    />
  </svg>
)

export const LogoCanvas = ({ ...props }) => (
  <svg
    width="15"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9236 5.24008L11.7791 4.02582L10.0508 3.04735C9.83806 2.92699 9.59679 2.86343 9.35272 2.86343C9.21123 2.86343 9.07437 2.88977 8.94222 2.93013C8.9392 2.91712 8.93925 2.90392 8.93579 2.89095C8.83987 2.53279 8.60856 2.23445 8.28446 2.05093L4.99847 0.190431C4.77851 0.065854 4.52857 0 4.27578 0C3.75868 0 3.27553 0.275189 3.0148 0.718214L0.334555 5.27067C-0.111518 6.02842 -0.111518 6.96981 0.334555 7.72754L3.01486 12.2801C3.27626 12.7241 3.75896 13 4.2745 13C4.52589 13 4.77465 12.9344 4.99389 12.8103L8.28441 10.9473C8.6085 10.7638 8.83982 10.4655 8.93573 10.1074C8.9392 10.0944 8.93909 10.0811 8.94222 10.0681C9.07432 10.1084 9.21117 10.1347 9.35267 10.1347C9.59673 10.1347 9.83806 10.0712 10.0506 9.95095L11.779 8.97236L13.9236 7.75808C14.3779 7.50087 14.6602 7.01847 14.6602 6.49913C14.6602 5.9798 14.3779 5.49737 13.9236 5.24008V5.24008ZM7.83296 10.1499L4.5425 12.0129C4.45771 12.0609 4.36555 12.0837 4.2745 12.0837C4.08765 12.0837 3.90594 11.9874 3.80449 11.8152L1.12419 7.2627C0.84679 6.79146 0.84679 6.2068 1.12419 5.73556L3.80449 1.183C3.90516 1.01191 4.08787 0.916307 4.27578 0.916307C4.36795 0.916307 4.46146 0.939349 4.54703 0.98781L7.83296 2.84828C8.05583 2.97448 8.13217 3.25391 8.00343 3.47245L7.95237 3.55925C6.88377 5.37481 6.88444 7.62726 7.95427 9.44215L8.00354 9.52573C8.13217 9.74427 8.05583 10.0237 7.83296 10.1499V10.1499ZM13.4721 6.96073L11.3276 8.17498L9.59936 9.15346C9.52151 9.1975 9.4365 9.21844 9.35267 9.21844C9.18192 9.21844 9.01582 9.13159 8.92432 8.97611C8.02188 7.44332 8.02188 5.55486 8.92432 4.02216V4.02207C9.01587 3.86662 9.18192 3.77974 9.35272 3.77974C9.43656 3.77974 9.52151 3.80068 9.59936 3.84473L11.3276 4.8232L13.4721 6.03746C13.8345 6.24265 13.8345 6.75556 13.4721 6.96073V6.96073Z"
      fill="#5AE284"
    />
  </svg>
)
