import React from 'react'
import * as R from 'ramda'
import { compose } from 'react-recompose'
import { withProps } from 'react-recompose'
import { withHandlers } from 'react-recompose'

// import Transition from 'Transition'
import { withFormContext } from './Form'
import assign from '__lib__/react/assign'
import lifecycle from '__lib__/react/lifecycle'
import onPropsChange from '__lib__/react/onPropsChange'

const FormField = ({
  form,
  error,
  dirty,
  invalid,
  persist,
  touched,
  validate,
  mapValue,
  showError,
  customError,
  defaultValue,
  shouldShowError,
  component: Component,
  ...props
}) => (
  <Component
    invalid={invalid}
    error={!dirty && customError ? customError : error || customError}
    {...props}
  />
)

const formStateProps = ({ form, name, error }) => {
  const props = form && form.getFieldProps(name)
  return { ...props, error: error || (props && props.error) }
}

const onValueChange =
  ({ form, name, onValueChange, mapValue = R.identity }) =>
  (value) => {
    const updatedForm = form && form.onFieldChange(name, mapValue(value))
    onValueChange && onValueChange(value, updatedForm, form)
  }

const onBlur =
  ({ onBlur, form, name }) =>
  (...args) => {
    onBlur && onBlur(...args)
    form && form.onFieldBlur(name, ...args)
  }

const didMount = (props, instance) =>
  props.form && props.form.mountField(instance)

const willUnmount = (props, instance) =>
  props.form && props.form.unmountField(instance)

const showErrorOnTouched = ({ touched, form }) =>
  touched || (form && form.isSubmitted)

const defaultShowError = ({ dirty, touched, form }) =>
  dirty || touched || (form && form.isSubmitted)

const invalid = ({ showError, invalid, ...props }) => ({
  invalid:
    invalid ||
    props.customError ||
    (props.error &&
      (!showError
        ? defaultShowError(props)
        : typeof showError === 'function'
        ? showError(props)
        : true)),
})

const onValueChanged = ({ form, name, value, mapValue = R.identity }) =>
  value !== undefined && form && form.onFieldChange(name, mapValue(value))

const showNoErrorFy = (value) => (props) =>
  defaultShowError(props) && props.error !== value

export default compose(
  assign({ showErrorOnTouched, defaultShowError, showNoErrorFy }),
  withFormContext,
  lifecycle({ didMount, willUnmount }),
  onPropsChange(['value'], onValueChanged),
  withProps(formStateProps),
  withProps(invalid),
  withHandlers({ onValueChange, onBlur })
)(FormField)
