import React from 'react'
import { compose } from 'react-recompose'

import styles from './styles.module.css'
import withSize from '__lib__/react/withSize'
import propsWithRef from '__lib__/react/propsWithRef'
import withClassName from '__lib__/react/withClassName'

const Box = ({ component, size, ...props }) =>
  React.createElement(component || 'div', propsWithRef(component, props))

const className = ({ size }) => [styles.root, styles[size]]

export default compose(withSize('md'), withClassName(className))(Box)
