import React from 'react'
import * as R from 'ramda'
import { pure } from 'react-recompose'
import { translations } from './translations'

const renderTranslation = (string, props) =>
  string
    .split(/\{(\w+)\}/g)
    .map((chunk, index) =>
      (index & 1) === 0
        ? chunk
            .split('\n')
            .map((chunk, index) =>
              index ? [<br key={index} />, chunk] : chunk
            )
        : !R.has(chunk, props)
          ? console.warn('[TRANSLATION] missing translation variable', chunk)
          : R.is(Object, props[chunk])
            ? React.cloneElement(props[chunk], { key: index })
            : props[chunk]
    )

const translate = (_name, { isPlural }, props) => {
  const name = R.is(Array, _name) ? _name.join('') : _name
  if (!R.is(String, name)) return name
  if (name.startsWith('!')) return name.slice(1)
  const t = translations[isPlural ? name + '.plural' : name]
  t || console.warn('[TRANSLATION] unknown translation for', name)
  return t
    ? renderTranslation(t, props)
    : import.meta.env.VITE_ENV === 'development'
      ? `MISSING TRANSLATION ${name}`
      : null
}

const T = ({ children, t, name, isPlural, ...props }) =>
  ((t || name || children) &&
    translate(t || name || children, { isPlural }, props)) ||
  null

export default pure(T)
