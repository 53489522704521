import config from '__config__'
import proxyConsole from './proxyConsole'
import proxyFetch from './proxyFetch'

const { seqKey, seqDomain } = config

const init = () => {
  if (seqKey && seqDomain) {
    proxyConsole()
    proxyFetch()
  }
}

export default init
