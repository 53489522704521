import * as R from 'ramda'
import * as paths from './paths'
import { getPathName } from '__lib__/web/location'

const filteredPathFunctions = R.pickBy(
  (_, key) => R.endsWith('Path', key),
  paths
)

const pathsArray = Object.values(filteredPathFunctions).flatMap((path) =>
  path && typeof path.route === 'function' ? getPathName(path.route()) : []
)

export default pathsArray
