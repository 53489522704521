import Form from '__core__/Form'
import View from '__components__/View'
import Input from '__components__/Input'
import Button from '__components__/Button'
import FormForgeryToken from './FormForgeryToken'
import Layout, { Header } from './Layout'

const ExternalLogin = ({ form, data: { formAction } }) => (
  <Layout>
    <View style="flex-center w-full flex-col flex-flex w-full">
      <Header
        title="Register"
        description={`You've been successfully authenticated \n just need a couple of extra`}
      />
      <Form
        form={form}
        method="post"
        style="w-full max-w-10"
        action={formAction}
        component={View.Form}
      >
        <View style="flex flex-col mt-6">
          <Input.Field
            lg
            autoFocus
            label="First Name"
            name="Input.FirstName"
            placeholder="Jane"
          />
          <Input.Field
            lg
            label="Last Name"
            name="Input.LastName"
            placeholder="Smith"
          />
          <View style="flex-center my-5">
            <FormForgeryToken />
            <Form.Submit
              lg
              plain
              brand
              component={Button}
              style="rounded-full"
              text="Register"
            />
          </View>
        </View>
      </Form>
    </View>
  </Layout>
)

export default Form.withForm('form')(ExternalLogin)
