export const invoke =
  (value) =>
  (...args) =>
    typeof value === 'function' ? value(...args) : value

export const invokePromise =
  (value) =>
  (...args) =>
    Promise.resolve(invoke(value)(...args))

export default invoke
