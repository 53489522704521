import React from 'react'

// REMEMBER: the placements here (e.g. top, bottom) are
// the ones of the dialog, not the arrow

// 58% = 100 - (SQRT2 * 100 - 100)
const placementStyleMap = {
  top: {
    bottom: 0,
    left: '50%',
    borderTopLeftRadius: '2px',
    transform: 'translate(-50%, 58%) rotate(-135deg)',
  },

  bottomLeftTopLeft: {
    top: 0,
    left: '10%',
    borderTopLeftRadius: '2px',
    transform: 'translate(-50%, -58%) translateY(2px) rotate(45deg)',
  },

  bottomRightTopRight: {
    top: 0,
    left: '90%',
    borderTopLeftRadius: '2px',
    transform: 'translate(-50%, -58%) translateY(2px) rotate(45deg)',
  },

  bottom: {
    top: 0,
    left: '50%',
    borderTopLeftRadius: '2px',
    transform: 'translate(-50%, -58%) rotate(45deg)',
  },

  bottomRightBottomLeft: {
    left: 0,
    top: '90%',
    borderBottomRightRadius: '2px',
    transform: 'translate(-50%, -50%) rotate(-45deg)',
  },

  right: {
    left: 0,
    top: '50%',
    borderBottomRightRadius: '2px',
    transform: 'translate(-50%, -50%) rotate(-45deg)',
  },

  left: {
    right: 0,
    top: '50%',
    borderTopLeftRadius: '2px',
    transform: 'translate(50%, -50%) rotate(135deg)',
  },
}

const getPlacementStyle = (placement) => placementStyleMap[placement]

const Arrow = ({ placement, style, size, children, arrowStyle, ...props }) => {
  const width = size || 15
  const height = size || 15
  const placementStyle = getPlacementStyle(placement)

  return placementStyle ? (
    <>
      <div
        style={{
          width: width,
          height: height,
          borderWidth: 0,
          // boxShadow: 'inherit',
          position: 'absolute',
          borderColor: 'inherit',
          borderStyle: 'inherit',
          borderTopWidth: 'inherit',
          backgroundColor: 'inherit',
          borderLeftWidth: 'inherit',
          ...placementStyle,
          ...arrowStyle,
        }}
        {...props}
      />
      <div
        style={{
          top: 1,
          left: 1,
          right: 1,
          bottom: 1,
          position: 'absolute',
          borderRadius: 'inherit',
          backgroundColor: 'inherit',
        }}
      />
      <div
        style={{
          display: 'flex',
          position: 'relative',
          maxHeight: 'inherit',
          flexDirection: 'column',
        }}
      >
        {children}
      </div>
    </>
  ) : (
    children
  )
}

export default Arrow
