import React from 'react'
import { compose } from 'react-recompose'
import { withHandlers } from 'react-recompose'

import Button from 'Button'
import Link from 'Link/Link'
import Dialog from './Dialog'
import autoFocus from '__lib__/react/autoFocus'
import withClassName from '__lib__/react/withClassName'

const style = { width: '375px', maxWidth: '100%' }

const AutoFocusLink = autoFocus({ delay: 300 })(Link)

const Confirm = ({
  title,
  cancel,
  confirm,
  onCancel,
  children,
  onConfirm,
  cancelText,
  confirmText,
  confirmButton,
  bodyClassName,
  footerClassName,
  withoutBackdrop,
  confirmComponent: ConfirmComponent = Button.Loading.OnClick,
  ...props
}) => (
  <Dialog {...props} withBackdrop={!withoutBackdrop}>
    <div style={style} className={bodyClassName}>
      {title && <div className="mb3 fw2 c-shade-5">{title}</div>}
      {children || 'Are you sure?'}
      <div className={footerClassName}>
        <div className="flex-center mla">
          <AutoFocusLink className="c-primary" onClick={cancel}>
            {cancelText || 'Cancel'}
          </AutoFocusLink>
          <ConfirmComponent
            className="ml4"
            onClick={confirm}
            theme={confirmButton}
          >
            {confirmText || 'Confirm'}
          </ConfirmComponent>
        </div>
      </div>
    </div>
  </Dialog>
)

const cancel =
  ({ dialog, cancel, onCancel }) =>
  (event) => {
    dialog.close()
    cancel && (cancel || onCancel)(event)
  }

const confirm =
  ({ dialog, confirm, onConfirm }) =>
  (event) => {
    const promise = (confirm || onConfirm) && (confirm || onConfirm)(event)
    return promise && promise.then ? promise.then(dialog.close) : dialog.close()
  }

export default compose(
  withClassName({ footerClassName: 'mt4 flex', bodyClassName: 'bbox ph4 pv3' }),
  withHandlers({ cancel, confirm })
)(Confirm)
