import React from 'react'

import Box from './Box'
import styles from './styles.module.css'
import withClassName from '__lib__/react/withClassName'

const Label = ({ isFloating, ...props }) => <div {...props} />

const BoxWithFloatingLabel = ({
  label,
  className,
  isFloating,
  floatingLabel,
  innerClassName,
  labelClassName,
  floatingClassName,
  floatingLabelClassName,
  component: Component = 'div',
  innerComponent: InnerComponent = Box,
  labelComponent: LabelComponent = Label,
  ...props
}) => (
  <Component className={className}>
    <LabelComponent className={labelClassName} isFloating={isFloating}>
      {label}
    </LabelComponent>
    <InnerComponent className={innerClassName} {...props} />
  </Component>
)

const labelClassName = ({
  isFloating,
  floatingLabelClassName,
  floatingLabelFloatingChildren,
}) => [
  'c-shade-4',
  styles.floatingLabel,
  isFloating && styles.floatingLabelFloating,
  isFloating && floatingLabelClassName,
]

const className = ({ isFloating, floatingClassName }) =>
  isFloating && floatingClassName

export default withClassName({ labelClassName, className })(
  BoxWithFloatingLabel
)
