export const translations = {
  'share.accept.title': 'What is Drawboard PDF?',
  'share.accept.description':
    'Drawboard PDF is your go-to app for PDF markup and manipulation. View and mark up in real-time or in your own time, across any device on Windows, iOS + Web and android with one account',
  'share.accept.invited': '{owner} has invited you to a PDF with {role}',
  'share.accept.shared': '{owner} has shared with you a PDF with {role}',
  'share.accept.role': '{role} access',
  'share.accept.authenticate': 'An account is needed to access this document',
  'share.accept.viewWebsite': 'View website',
  'share.accept.watchVideo': 'Watch video',

  'document.migrating.title': 'This document is being migrated',
  'document.migrating.description': 'It will be accessible shortly',
  'document.error.title': 'Could not open this document',
  'document.error.passwordRequired.description':
    'A password is required to open this document',
  'document.error.invalidPDF.description':
    'This document has an invalid PDF format',
  'document.error.sourceNotFound.description':
    'The source file could not be retrieved',
  'document.error.description':
    'Unexpected error, please retry or contact support',
  'document.notFound.title': 'Sorry, we cant open\nthis document',
  'document.notFound.description':
    "Please check you're logged into the correct account or check with your admin to regain access.",

  'document.openInUWP': 'Open in Windows App',
  'uploads.processingFailed':
    'Something went wrong while syncing your document, please retry later or contact support',
  'uploads.passwordRequired':
    'We could not open your document, a password is required',
  'uploads.invalidPDF':
    'We could not open your document, This document has an invalid PDF format',
  'uploads.fileTooLarge':
    'Your document ({fileSize}) is larger than your plan allows ({maxDocumentSize}/doc). Please reduce the size of your PDF',
  'uploads.licenseLimitExceeded':
    "You've reached your Cloud upload limit ({cloudUsageMax} PDFs). Delete PDFs from your Drawboard Cloud and try again, or upgrade your plan",
  'uploads.genericError':
    'There was an error uploading your file. Please retry later or contact support',
  'canvas.allPages.pagesCount': '{count} Page',
  'canvas.allPages.pagesCount.plural': '{count} Pages',
  'canvas.pagesOverview': 'Pages',
  'canvas.searchDrawer': 'Search',
  'canvas.learningHub.whatsNewHeading': "What's new",
  'canvas.learningHub.whatsNewSubheading':
    'Feature updates, Issues and announcements',
  'canvas.learningHub.supportCenterHeading': 'Support centre',
  'canvas.learningHub.supportCenterSubheading':
    'Can’t find what you’re looking for?',
  'canvas.learningHub.suggestionHeading': 'Make a suggestion',
  'canvas.learningHub.suggestionSubheading':
    'We’re all about improving Drawboard PDF',
  'canvas.learningHub.reportIssueHeading': 'Report issue',
  'canvas.learningHub.reportIssueSubheading': 'Tell us about the experience',
  'canvas.learningHub.androidSuggestionHeading': 'Send feedback',
  'canvas.learningHub.androidSuggestionSubheading':
    'Share any feedback or issues with our Android Beta',
  'canvas.learningHub.keyboardHeading': 'Keyboard shortcuts',
  'canvas.learningHub.keyboardSubheading':
    'Work more efficiently, with more precision!',
  'canvas.tools.length.name': 'Measure Length',
  'canvas.tools.polyLength.name': 'Polylength',
  'canvas.tools.rectangleArea.name': 'Rectangular Area',
  'canvas.tools.area.name': 'Polygon Area',
  'canvas.tools.calibration.name': 'Calibrate',
  'canvas.tools.callout.name': 'Callout',
  'canvas.textReview': 'Text Review Export',
  'pro.cloudStorage': 'Cloud Storage',
  'prop.largerCloudFiles': 'Larger Cloud Files',
  'canvas.grid.upgrade.head':
    'Upgrade for access to\ngrid and lined paper styles',
  'canvas.grid.upgrade.sub':
    'Need more advanced professional tool\nset, try subscriptions for 7 days free',
  'canvas.grid.upgrade.cta': 'Learn more',
  'canvas.grid.upgrade.tools': 'Plus more professional tools',
  'canvas.showAnnotations': 'Show annotations',
  'canvas.hideAnnotations': 'Hide annotations',
  'canvas.pageSettings.setAsDefaults.cta': 'Set as view default',
  'canvas.pageSettings.setAsDefaults.line': 'will apply upon open on new tabs',
  'canvas.layout.singlePage': 'Single Page',
  'canvas.layout.doublePage': 'Double Page',
  'canvas.layout.coverPage': 'Cover Page',
  'canvas.pageSettings': 'Page settings',
  'canvas.snapping': 'Toggle snapping',
  'canvas.grid': 'Page overlay',
  'canvas.zoomToPage': 'Zoom to page',
  'canvas.zoomToWidth': 'Zoom to width',
  'canvas.zoomToHeight': 'Zoom to height',
  'canvas.fitToPage': 'Fit to page',
  'canvas.fitToWidth': 'Fit to width',
  'canvas.fitToHeight': 'Fit to height',
  'canvas.currentZoom': 'Current zoom',
  'feedback.reportIssue.head': "We’re sorry you've\nencountered a problem!",
  'feedback.reportIssue.sub': "Tell us about it and we'll get right on it.",
  'feedback.suggestion.head': 'Let us know your suggestions',
  'feedback.suggestion.sub': "Tell us about it and we'll get right on it.",
  'feedback.reportIssue.form.name': 'Name',
  'feedback.reportIssue.form.email': 'Email',
  'feedback.reportIssue.form.where': 'Where did you experience this issue',
  'feedback.suggestion.form.where': 'What does this feedback relate to',
  'feedback.reportIssue.form.description': 'Description of the issue',
  'feedback.suggestion.form.description': 'Tell us more about your suggestion',
  'feedback.suggestion.android.form.description':
    'Please explain your suggestion or issue',
  'feedback.suggestion.android.form.what': 'What does this feedback relate to?',
  'feedback.suggestion.android.form.repro':
    'If possible, If you had an issue/bug, What are the rough steps needed for the bug to occur.',
  // 'feedback.reportIssue.form.description.placeholder':
  'feedback.reportIssue.form.consent.termsAndConditions':
    'Drawboard’s Terms & Conditions',
  'feedback.reportIssue.form.consent.privacyPolicy': 'Privacy Policy',
  'feedback.reportIssue.form.consent':
    'I understand that information about my device and this application that relate to this issue will be included in this report and acknowledge that I have read and understood {termsAndConditions} and {privacyPolicy}',
  'dashboard.cloudDocuments.guest.headLine':
    'Sync and Share your files with Drawboard Cloud',
  'dashboard.cloudDocuments.guest.subLine':
    'Log in or Create an account to get started',
  'dashboard.cloudDocuments.headLine':
    'Sync and Share your files with Drawboard Cloud',
  'dashboard.cloudDocuments.subLine':
    'Try uploading new documents to Drawboard Cloud',
  'dashboard.cloudDocuments.sharedBy.headLine':
    'Share your documents with ease using auto-sync',
  'dashboard.cloudDocuments.sharedBy.subLine':
    'You currently have no documents you have shared',
  'dashboard.cloudDocuments.sharedWith.headLine':
    'Share your documents with ease using auto-sync',
  'dashboard.cloudDocuments.sharedWith.subLine':
    'You currently have no documents shared with you',
  'dashboard.localDocuments.headLine': 'Work locally from your device',
  'dashboard.localDocuments.subLine':
    'Manually save your documents for more private and non collaborative tasks. Local files will appear here.',

  'dashboard.cloudDocuments.button.share': 'Share file',
  'dashboard.cloudDocuments.button.upload': 'Upload file',
  'dashboard.localDocuments.button.open': 'Open file',
  'dashboard.localDocuments.button.new': 'Create new',

  'dashboard.projects.headLine': 'Need to review\ndesigns as a team?',
  'dashboard.projects.subLine':
    'Collaborative design review software. Purpose-made\n for AEC teams!',
  'dashboard.upgrade.headLine': 'Need more\n storage?',
  'dashboard.upgrade.subLine': 'Never worry about\nstorage limits again',
  'dashboard.freeTier': 'Upgrade',
  'dashboard.panel.guest.head': 'Take your work\nto the next level',
  'dashboard.panel.guest.subLine':
    'Add sharing and professional\ntools to your new PDF workflow',
  'dashboard.panel.guest.cta': 'Learn about plans',
  'dashboard.subLine': 'Choose how you want to work with Drawboard PDF',
  'dashboard.headLine': 'Handle your PDFs your way',
  'dashboard.uploadDocument': 'Upload Cloud file',
  'dashboard.openDocument': 'Open', // Open from device
  'dashboard.featureGrid': 'Quick tools',
  'dashboard.uploadDocument.short': 'Upload',
  'dashboard.openDocument.short': 'Open', // Open file
  'dashboard.featureGrid.short': 'Quick tools',
  'dashboard.cloudDocument': 'Cloud file',
  'dashboard.localDocument': 'Unsaved file',
  'dashboard.cloudDocument.short': 'Drawboard',
  'dashboard.localDocument.short': 'Unsaved',
  'dashboard.cloudShare': 'Shared',
  'dashboard.document.lastOpenedDate': 'Last opened',
  'dashboard.document.createdDate': 'Created',
  'dashboard.document.pages': 'Pages',
  'dashboard.document.author': 'Author',
  'dashboard.document.tags': 'Tags',
  'dashboard.document.name': 'Name',
  'dashboard.document.type': 'Location',
  'dashboard.document.type.local': 'Temporary',
  'dashboard.document.type.cloud': 'Drawboard',
  'dashboard.document.type.local.tooltip.title': 'Unsaved temporary file',
  'dashboard.document.type.local.tooltip.description':
    'Your file is currently stored in your browser cache. If the cache is cleared, you will lose all information in your document',
  'dashboard.search.empty.headLine': 'No documents match on your dashboard',
  'dashboard.search.empty.subLine': 'Please broaden your search',
  'dashboard.tags.info.title': 'Organize all your documents {withTags}',
  'dashboard.tags.info.title.withTags': '{icon} with tags',
  'dashboard.tags.info.description':
    'Use tags for all your Cloud files, and easily access from any of your devices with one Drawboard PDF account',
  'dashboard.tags.info.createAccount.title': '1. Create account',
  'dashboard.tags.info.createAccount.description':
    'One account is all you need',
  'dashboard.tags.info.loggedInAccount.title': 'Active account',
  'dashboard.tags.info.addTagToDoc.title': '2. Add tags to your doc',
  'dashboard.tags.info.addTagToDoc.description':
    'Once your file is synced to the Cloud',
  'dashboard.tags.info.searchTag.title': '3. Powerful dashboard',
  'dashboard.tags.info.searchTag.description': 'Organize and search with ease',
  'dashboard.tags.info.LimitExceed.title':
    'You have exceeded your file quota of {cloudUsageMax} files',
  'dashboard.tags.info.LimitExceed.subtitle':
    ' To continue uploading files, upgrade for more storage',

  'dashboard.tags.info.LimitExceed.Button': 'Sync to cloud',
  'dashboard.tags.info.uploadToCloud.Button': 'Sync to cloud',

  'tools.remove': 'Remove',
  'tools.editTool': '{tool}',
  'tools.editSignatureTool': 'Signatures',
  'tools.sketchPad.standardZoom': 'Standard zoom {zoom}',
  'tools.sketchPad.currentZoom': 'Current zoom {zoom}',
  'tools.sketchPad.head': 'Try {tool}',
  'tools.sketchPad.body': 'Annotate with your tool here',
  'tools.sketchPad.clear': 'Clear',
  'tools.noneFound': 'No tools found',
  'tools.addFavorite': 'Choose new tool',
  'tools.addFavoriteTip.title': 'Drag & Drop',
  'tools.addFavoriteTip.description':
    '{head} - Add a tool from the list straight to your Tool bar',
  'tools.addFavoriteTip.upgrade.title.maxReached':
    'You’ve reached your 7 tool limit in your tool bar',
  'tools.addFavoriteTip.upgrade.description.maxReached':
    'Upgrade now to unlock unlimited tools + other powerful features',
  'tools.addFavoriteTip.upgrade.title':
    'Bulletproof your toolbar with more slots',
  'tools.addFavoriteTip.upgrade.description':
    'Add, customize & organize all your favorite tools your own way',
  'tools.addFavoriteTip.upgrade.button': 'Upgrade now',

  'tools.addFavoriteTip.promote.title': 'Something missing?',
  'tools.addFavoriteTip.promote.description':
    'Get an extensive range of tools with Drawboard PDF app',
  'tools.addFavoriteTip.promote.subtext': 'Also on {app} app',

  'tools.proModal.expiredHead': 'Oops, seems like your\naccount has expired',
  'tools.proModal.expiredBody':
    'To use our professional toolset, please\nupgrade your Drawboard PDF subscription',

  'tools.favModal.banner': 'Drawboard PDF update',
  'tools.favModal.title': 'Unlock unlimited toolbar positions',
  'tools.favModal.title.highlight': 'Your current limit: {count}',
  'tools.favModal.description':
    'Make the favorites toolbar your own with the right tool for the right job. Pro users can utilize unlimited tool positions. Our Basic users are limited to {count} positions.\n\nYou can still use any of the free tools available however, you will need to make space for them in your current favorites bar',
  'tools.favModal.description.button': 'Upgrade your subscription',
  'tools.favModal.button': 'Upgrade now',
  'tools.favModal.replace.title':
    '{icon} Or replace 7th tool with selected tool',
  'tools.favModal.replace.description':
    '(You can also replace tools in the tool properties)',
  'tools.favModal.signin.title': 'Already a pro user? {login}',
  'tools.favModal.signin.title.login': 'Log in',

  'tools.favTooltip.reminder.title':
    'A friendly reminder that you have reached your limit of 7 tools',
  'tools.favTooltip.reminder.description':
    'Upgrade now to unlock unlimited tools + other powerful features',
  'tools.favTooltip.reminder.subtext':
    'For now, you are still able to change/remove existing tools within your current tool bar',
  'tools.favTooltip.reminder.button': 'Upgrade now',
  'tools.favTooltip.restricted.title':
    'Unlock unlimited toolbar positions\n {highlight}',
  'tools.favTooltip.restricted.title.highlight': '(max of 7 exceeded)',
  'tools.favTooltip.restricted.description':
    'Basic users are limited to 7 tools.\nRemove or rearrange to make space or upgrade for more positions',
  'tools.favTooltip.restricted.button': 'Upgrade to Pro',
  'tools.favTooltip.pro.title': '{toolName} is a Pro tool',
  'tools.favTooltip.pro.description':
    'Upgrade to unlock the tool and other Pro tools',
  'tools.favTooltip.pro.button': 'Explore Pro',
  'tools.favTooltip.remove.button': 'Remove tool',
  'tools.signatures.upgrade.title': 'You’ve used {count}/{max} free signature',
  'tools.signatures.upgrade.description': 'Get Pro to add unlimited signatures',
  'tools.signatures.upgrade.button': 'Upgrade',

  'tools.replace.title': 'Replace tool with',

  'document.close': 'Close document',
  'document.closeAndReturn': 'Close and return to dashboard',
  'document.switchAccounts': 'Switch account',
  'document.accessRevoked.title': 'Document unavailable',
  'document.accessRevoked.title.plural': 'Documents unavailable',
  'document.accessRevoked.action': 'revoked or removed',
  'document.accessRevoked.description':
    'Your access has been {revokeAction}.\n Please check with the document owner to regain access.',
  'cloudUsage.countOutOfMax': '{count}/{max} files uploaded',
  'subscription.cancelTooltipDescription':
    'You’ve upgraded to a new plan but we’ve noticed your old plan is still active. Please cancel this plan or contact us to discuss any issues you may be having with cancellations',
  'subscription.manageSubscription': 'Manage plan',
  'subscription.manageTeamSubscription': 'Manage team',

  'subscription.pricePerMonthShort': '{amount}',
  'subscription.pricePerMonth': '{amount} per month',
  'subscription.pricePerYear': '{amount} billed annually',
  'subscription.pricePerYearDiscount': '30% off',
  'subscription.pricePerMonthDisclaimer': 'Discount not applicable',
  'subscription.logIn': 'Log in',
  'subscription.startTrial': 'Try for free*',
  'subscription.upgrade': 'Upgrade',
  'subscription.upgrade.moreStorage': 'Need more Cloud storage?',
  'subscription.upgrade.moreTools': 'Need more\nProfessional Tools?',
  'subscription.upgrade.benefitsToolsFree':
    'Add measurement tools + more Cloud storage to your new PDF workflow',
  'subscription.upgrade.benefitsStorageFree':
    'Add sharing and professional\ntools to your new PDF workflow',
  'subscription.upgrade.benefitsStoragePaid':
    'Never have to worry about\nstorage limits again',
  'subscription.upgrade.premiumHeading': "You're well equipped 👌",
  'subscription.upgrade.premiumSubHeading':
    "You've currently got full premium\naccess to all of Drawboard's offerings!",

  'subscription.footer.needHelp': 'Need help?',
  'subscription.footer.helpCenter': 'Go to Drawboard help centre',
  'subscription.footer.dbProjectsTitle':
    'Want to always stay on top of your complex projects?',
  'subscription.footer.dbProjectsSub': 'Markups, tracked and coordinated',
  'subscription.footer.dbProjectsCTA': 'Learn more',
  'subscription.renewDate': 'Renews {date}',
  'subscription.yourCurrentPlan': 'Your current plan',
  'confirm.close.allDocuments': 'Close all open documents ({count})?',
  'subscription.contactUs': 'Contact us',

  'templates.grid.overview.label': 'Template overview',
  'templates.grid.overview.title': 'Template overview',
  'templates.grid.overview.description':
    'Enhance your PDF and save time by using templates',

  'templates.grid.noteTaking.label': 'Notetaking',
  'templates.grid.noteTaking.title': 'Notetaking',
  'templates.grid.noteTaking.description':
    'Take notes with ease on blank and ruled paper',

  'templates.grid.gridsAndGraphs.label': 'Grids and Graphs',
  'templates.grid.gridsAndGraphs.title': 'Grids and Graphs',
  'templates.grid.gridsAndGraphs.description':
    'Create diagrams and drawings with precision',

  'templates.grid.music.label': 'Music',
  'templates.grid.music.title': 'Music',
  'templates.grid.music.description': ' ',

  'templates.grid.custom.label': 'Custom templates',
  'templates.grid.custom.title': 'Custom templates',
  'templates.grid.custom.description':
    'Add customized templates to help your workflow',

  'templates.grid.blank': 'Blank Page',
  'templates.grid.ruledCollege': 'Notebook paper',
  'templates.grid.ruledGregg': 'Gregg Ruled paper',
  'templates.grid.ruledPitman': 'Pitman Ruled paper',
  'templates.grid.cornelNote': 'Cornell Note',

  'templates.grid.quadrilleGraphLightBlueOneEighthInch': 'Engineering Grid',
  'templates.grid.graphLightBlueOneQuarterInch': 'Graph paper 1/4"',
  'templates.grid.graphLightBlueOneEighthInch': 'Graph paper 1/8"',
  'templates.grid.graphLightBlueOneCentimeter': 'Graph paper 1cm',
  'templates.grid.graphLightBlueOneHalfCentimeter': 'Graph paper 1/2cm',
  'templates.grid.gridLightBlueOneMillimeter': 'Graph paper 1mm',
  'templates.grid.dottedGraphOneCentimeter': 'Dotted paper 1cm',
  'templates.grid.dottedGraphHalfCentimeter': 'Dotted paper 5mm',
  'templates.grid.isometricGridLightBlueHalfInch': 'Isometric Grid 1/2"',
  'templates.grid.isometricGridLightBlueOneCentimeter': 'Isometric Grid 1cm"',
  'templates.grid.isometricDottedGraphTwoCentimeter': 'Isometric Dotted 2cm',
  'templates.grid.hexagonalGridOneInch': 'Hexagonal Grid 1"',
  'templates.grid.hexagonalGridOneHalfInch': 'Hexagonal Grid 1/2"',
  'templates.grid.hexagonalGridOneQuarterInch': 'Hexagonal Grid 1/4"',
  'templates.grid.hexagonalGridOneEightInch': 'Hexagonal Grid 1/8"',

  'templates.grid.pianoDoubleStaveSheet': 'Double Stave Sheet',
  'templates.grid.sixLineTabSheet': '6 Line Tab Sheet',
  'templates.grid.blankStaveSheet': 'Blank Stave Sheet',

  'templates.color.standard.label': 'Standard',
  'templates.color.standard.title': 'Standard Paper',
  'templates.color.standard.description': 'Commonly used colors',

  'templates.color.custom.label': 'Custom',
  'templates.color.custom.title': 'Custom',
  'templates.color.custom.description':
    'Use our set of colors or create your own',

  'templates.color.white': 'White',
  'templates.color.lightGrey': 'Light Grey',
  'templates.color.darkGrey': 'Dark Grey',
  'templates.color.charcoal': 'Charcoal',
  'templates.color.black': 'Black',
  'templates.color.beige': 'Beige',
  'templates.color.vintagePaper': 'Vintage Paper',
  'templates.color.butterPaper': 'Butter Paper',
  'templates.color.brownBag': 'Brown Bag',

  'templates.color.custom': 'Custom Color',
  'templates.grid.upgrade.title': 'Grids and lined paper styles',
  'templates.grid.upgrade.description': 'Try subscriptions for 7 days free.',
  'templates.grid.upgrade.button': 'Upgrade now',
  'templates.grid.custom.callout.title': 'Add your own templates',
  'templates.grid.custom.callout.description':
    'Save time by adding your own templates to use for different projects ',
  'templates.grid.custom.callout.button': 'Add template',

  'templates.color.custom.new.title': 'Create a template',
  'templates.color.custom.new.description':
    'Create your custom template to reuse in your PDFs',

  'templates.custom.upgrade.title': 'Fast-track your work with Templates',
  'templates.custom.upgrade.description':
    'Use our default templates or upload your own. Try subscriptions for 7 days free.',

  'document.blank.title': 'New Document',
  'document.blank.description': 'Create your custom settings',

  'submenu.section.open.title': 'Open',
  'submenu.section.open.folder.title': 'From your device',
  'submenu.section.open.folder.description': 'Select folder',

  'submenu.section.open.3rdParty.title': 'External cloud storage',
  'submenu.section.open.3rdParty.guest.title':
    'To access external integrations',
  'submenu.section.open.3rdParty.guest.description':
    'Continue with a Drawboard PDF account',
  'submenu.section.open.3rdParty.guest.signup': 'Create account',
  'submenu.section.open.3rdParty.guest.signin': 'Login',

  'submenu.section.create.title': 'Create',
  'submenu.section.create.new.title': 'Blank document',
  'submenu.section.create.new.description': 'New document',
  'submenu.section.create.notebookPaper.title': 'Notebook Paper',
  'submenu.section.create.notebookPaper.description': 'New document',
  'submenu.section.create.other.title': 'Other templates',
  'submenu.section.create.other.description': 'Grids, music + more',

  'submenu.section.tools.title': 'Document Builder',
  'submenu.section.tools.merge.title': 'Merge',
  'submenu.section.tools.merge.description': 'Combine documents together',
  'submenu.section.tools.split.title': 'Split',
  'submenu.section.tools.split.description': 'Detach individual pages',
  'submenu.section.tools.other.title': 'Other Doc manipulation',
  'submenu.section.tools.other.description': 'Extract, compress + more',

  'submenu.autoSync': 'auto-upload',
  'submenu.autoSync.on.title': 'Work across devices with {autoUpload}',
  'submenu.autoSync.on.description':
    'Activate to avoid losing any unsaved documents',
  'submenu.autoSync.off.title': 'Work across devices with {autoUpload}',
  'submenu.autoSync.off.description':
    'Activate to avoid losing any unsaved documents',

  'share.modal.invite.title': 'Invite to collaborate',
  'share.modal.invite.description':
    'Type an email address and press enter to add another',
  'share.modal.invite.footer.access': 'Anyone can access this link as a',
  'share.modal.invite.footer.copy': 'Copy link',
  'share.modal.shareWith.title': 'Collaborators',
  'share.modal.shareWith.description':
    'Edit users permissions within this document',
  'share.modal.setting.title': 'Link share settings',
  'share.modal.setting.description':
    'By revoking link, you are creating a new URL link',

  'commercial.modal.logo': 'for better business',
  'commercial.modal.warning.message':
    'Action required - You have {day} days remaining',
  'commercial.modal.warning.message.suspended':
    'Your access has been suspended',
  'commercial.modal.content.title.warning':
    'Your access to Drawboard PDF requires a commercial license',
  'commercial.modal.content.title.suspended':
    'Upgrade to a commercial license now to continue',
  'commercial.modal.content.description':
    'We’ve noticed you are a commercial user of our software per our updated Terms of Use',
  'commercial.modal.content.subtext':
    '(Have we got this wrong? {requestReview})',
  'commercial.modal.content.subtext.requestReview': 'Request review',
  'commercial.modal.content.nextSteps.title': 'Next steps:',
  'commercial.modal.content.nextSteps.description':
    'You and your team will need to upgrade to a commercial license here:',
  'commercial.modal.content.nextSteps.button': 'Contact Sales Team',
  'commercial.modal.content.nextSteps.alt.description':
    '{prefix} if this is just for yourself only,\nplease upgrade this account to Pro:',
  'commercial.modal.content.nextSteps.alt.description.prefix': 'OR',
  'commercial.modal.content.nextSteps.alt.button': 'Choose Pro plan',
  'commercial.modal.content.skip.content': 'Skip in {counter}s',
  'commercial.modal.content.skip.button': 'Skip',

  'commercial.modal.content.explained.title':
    'What’s in a commercial Drawboard PDF license?',
  'commercial.modal.content.explained.organizationManagement.title':
    'Organization Management',
  'commercial.modal.content.explained.organizationManagement.description':
    'Drawboard PDF allows for an organization to manage multiple ‘pools’ of licenses / seats. In the administration portal, admins can view available licenses, assign, revoke and remove licenses and accounts.',
  'commercial.modal.content.explained.centralizedBilling.title':
    'Centralized Billing',
  'commercial.modal.content.explained.centralizedBilling.description':
    'Many organizations require an invoice with a purchase order to unlock approved budget. With Drawboard PDF, customers can opt for an invoiced pool of licenses. Credit card, direct payments and other methods available.',
  'commercial.modal.content.explained.cloudStorage.title': 'Cloud Storage',
  'commercial.modal.content.explained.cloudStorage.description':
    'Drawboard PDF Cloud services are powered by Microsoft Azure. We are SOC2 and GDPR compliant. On request, Drawboard can include non-cloud versions of Drawboard PDF in your license for organizations with sensitivities to data storage type and location.',
  'commercial.modal.content.explained.azureAD.title': 'Azure AD SSO',
  'commercial.modal.content.explained.azureAD.link':
    'Active Directory Gallery app',
  'commercial.modal.content.explained.azureAD.description':
    'As a certified {link}, Drawboard PDF licenses are available to be accessed via Azure Active Directory Single Sign On (SSO). Azure AD can be used to provision users and groups in Drawboard PDF.',
  'commercial.modal.content.explained.distributionMethods.title':
    'Distribution methods',
  'commercial.modal.content.explained.distributionMethods.description':
    'As a valid partner, we can assist in distribution and delivery of our application onto the devices of your team. Choose from Slow or Fast track update schedules, or request a bespoke fork for custom requirements. Our onboarding engineers can assist with providing our application package in a number of formats to assist with installs at scale.',
  'commercial.modal.content.explained.subtext':
    'As per our current terms and conditions - it appears you are using our software for unlicensed commercial use - you have 7 days remaining to correct this. View full {terms}',
  'commercial.modal.content.explained.subtext.terms': 'Terms and conditions',
  'subscription.section.collaborateAndShare.title': 'Collaborate and share',
  'subscription.section.advanceInk.title': 'Advanced ink',
  'subscription.section.advancedTools.title': 'Advanced tools',
  'subscription.section.layoutAndSettings.title': 'Layout and settings',
  'subscription.section.modificationAndOrganization.title':
    'Modification & Organization',
  'subscription.section.corporate.title': 'Commercial',
  'subscription.features.appAccess.title': 'Drawboard app access',
  'subscription.features.appAccess.description':
    'View & edit your document across devices',
  'subscription.features.appAccess.description.short': 'View & edit documents',
  'subscription.features.liveSyncDocs.title': 'Live sync documents',
  'subscription.features.liveSyncDocs.description':
    'Sync PDFs across Windows, Web and Apple',
  'subscription.features.liveSyncDocs.description.short':
    'Sync PDFs across devices',
  'subscription.features.android.title': 'Android app',
  'subscription.features.android.description':
    'Access to the Pro-only Android app',
  'subscription.features.android.description.short':
    'Access to the Pro-only Android app',
  'subscription.features.emailUpload.title': 'Email upload',
  'subscription.features.emailUpload.description':
    'Send documents to upload, from your email',
  'subscription.features.emailUpload.description.short':
    'send documents to upload, from your email',
  'subscription.features.3rdPartyCloudSync.title': 'Third party cloud sync',
  'subscription.features.3rdPartyCloudSync.description':
    'Sync with other popular cloud services',
  'subscription.features.3rdPartyCloudSync.description.short':
    'Sync with popular cloud services',
  'subscription.features.passwordProtect.title': 'Password Protect',
  'subscription.features.passwordProtect.description':
    'Keep documents secured with passwords',
  'subscription.features.passwordProtect.description.short':
    'Add passwords to your PDFs',
  'subscription.features.colorPicker.title': 'Color picker',
  'subscription.features.colorPicker.description':
    'Create & save custom colors',
  'subscription.features.colorPicker.description.short':
    'Create & save custom colors',
  'subscription.features.smartInk.title': 'Ink to line',
  'subscription.features.smartInk.description': 'Draw perfect lines',
  'subscription.features.smartInk.description.short': 'Draw perfect lines',
  'subscription.features.hyperlink.title': 'Hyperlink',
  'subscription.features.hyperlink.description':
    'Place web or navigation links in your document',
  'subscription.features.hyperlink.description.short':
    'Place web or navigation links in your document',
  'subscription.features.callout.title': 'Callouts',
  'subscription.features.callout.description':
    'Bring attention to important information',
  'subscription.features.callout.description.short':
    'Bring out important information',
  'subscription.features.digitalProtractor.title': 'Digital protractor',
  'subscription.features.digitalProtractor.description':
    'Draw & measure angles in your document',
  'subscription.features.digitalProtractor.description.short':
    'Draw & measure angles',
  'subscription.features.lineStyles.title': 'Line styles',
  'subscription.features.lineStyles.description':
    'Access dotted & dashed styles',
  'subscription.features.lineStyles.description.short':
    'Access dotted & dashed styles',
  'subscription.features.measurementTools.title': 'Measurement tools',
  'subscription.features.measurementTools.description':
    'Calibrate and measure areas and lengths',
  'subscription.features.measurementTools.description.short':
    'Calibrate and measure areas and lengths',
  'subscription.features.snapshot.title': 'Snapshot',
  'subscription.features.snapshot.description':
    'Copy or remove snippets of PDF content in hi-res',
  'subscription.features.snapshot.description.short':
    'Copy or remove snippets of PDF content in hi-res',
  'subscription.features.markupLibrary.title': 'Mark-up library',
  'subscription.features.markupLibrary.description':
    'Save your favorite annotations',
  'subscription.features.markupLibrary.description.short':
    'Save your favorite annotations',
  'subscription.features.audio.title': 'Audio Notes',
  'subscription.features.audio.description':
    'Record or attach sound to your document',
  'subscription.features.audio.description.short': 'Add sound to your document',
  'subscription.features.templatesAndOverlays.title': 'Templates & Overlays',
  'subscription.features.templatesAndOverlays.description':
    'Create your own or choose from extensive library',
  'subscription.features.templatesAndOverlays.description.short':
    'Create your own or choose from extensive library',
  'subscription.features.alignDistribute.title': 'Align / Distribute',
  'subscription.features.alignDistribute.description':
    'Evenly organize objects in your document',
  'subscription.features.alignDistribute.description.short':
    'Organize objects in your document',
  'subscription.features.customKeyboardShortcuts.title':
    'Custom keyboard shortcuts',
  'subscription.features.customKeyboardShortcuts.description':
    'Set up your own shortcuts to work faster',
  'subscription.features.customKeyboardShortcuts.description.short':
    'Customize shortcuts to work faster',
  'subscription.features.multiWindow.title': 'Multi-window',
  'subscription.features.multiWindow.description':
    'work on multiple tabs & windows at the same time',
  'subscription.features.multiWindow.description.short':
    'work on multiple tabs & windows at the same time',
  'subscription.features.compression.title': 'Compression (low or high)',
  'subscription.features.compression.description':
    'Reduce the size of your document',
  'subscription.features.compression.description.short':
    'Reduce the size of your document',
  'subscription.features.officeConversion.title': 'Office conversion',
  'subscription.features.officeConversion.description':
    'Convert Microsoft Office documents to PDFs',
  'subscription.features.officeConversion.description.short':
    'Convert Microsoft Office documents',
  'subscription.features.exportTextReview.title': 'Export text review',
  'subscription.features.exportTextReview.description':
    'Export notes, comments, highlighted text',
  'subscription.features.exportTextReview.description.short':
    'Export notes, comments, highlights',
  'subscription.features.pressureSensitiveInk.title': 'Pressure sensitive ink',
  'subscription.features.pressureSensitiveInk.description':
    'Adapt brush size to pen pressure',
  'subscription.features.pressureSensitiveInk.description.short':
    'Adapt brush size to pen pressure',
  'subscription.features.detailedMarkupHistory.title':
    'Advanced Annotation History',
  'subscription.features.detailedMarkupHistory.description':
    'Group, sort, filter and export annotation data',
  'subscription.features.detailedMarkupHistory.description.short':
    'Group, sort, filter and export annotation data',
  'subscription.features.docBuilder.title': 'Document builder',
  'subscription.features.docBuilder.description':
    'Merge, Split & Extract from your docs',
  'subscription.features.docBuilder.description.short':
    'Merge, Split & Extract from your docs',
  'subscription.features.adminPortal.title': 'Admin portal',
  'subscription.features.adminPortal.description':
    'View & manage multiple licenses',
  'subscription.features.adminPortal.description.short':
    'View & manage multiple licenses',
  'subscription.features.dedicatedAccountManager.title':
    'Dedicated account manager',
  'subscription.features.dedicatedAccountManager.description':
    'Custom implementation and account support for your team',
  'subscription.features.dedicatedAccountManager.description.short':
    'Custom implementation and account support for your team',
  'subscription.features.projectsOffer.title': 'Projects offer',
  'subscription.features.projectsOffer.description':
    'Bundle with our Drawboard Projects product and save',
  'subscription.features.projectsOffer.description.short':
    'Bundle with our Drawboard Projects product and save',
  'subscription.features.singleSignOn.title': 'Single sign-on',
  'subscription.features.singleSignOn.description':
    'SSO via Azure Active Directory for streamlined rollout',
  'subscription.features.singleSignOn.description.short':
    'SSO via Azure Active Directory for streamlined rollout',
  'subscription.features.premiumSupport.title': 'Support',
  'subscription.features.premiumSupport.description':
    'Issues resolved by a dedicated expert',
  'subscription.features.premiumSupport.description.short':
    'Issues resolved by a dedicated expert',
  'subscription.features.appPackageDistributionMethods.title':
    'App distribution methods',
  'subscription.features.appPackageDistributionMethods.description':
    'Alternative versions & formats available',
  'subscription.features.appPackageDistributionMethods.description.short':
    'Alternative versions & formats available',
  'subscription.features.signaturesUnlimited.title': 'Signatures unlimited',
  'subscription.features.signaturesUnlimited.description':
    'Add & use unlimited signatures',
  'subscription.features.signaturesUnlimited.description.short':
    'Add & use unlimited signatures',
  'subscription.features.favoritesBarUnlimited.title':
    'Favorites bar unlimited',
  'subscription.features.favoritesBarUnlimited.description':
    'Add unlimited tools to your toolbar',
  'subscription.features.favoritesBarUnlimited.description.short':
    'Add unlimited tools to your toolbar',
  'subscription.features.redaction.title': 'Redaction',
  'subscription.features.redaction.description':
    'Permanently remove PDF content & metadata',
  'subscription.features.redaction.description.short':
    'Permanently remove PDF content & metadata',
  'subscription.features.plan.forTeams': 'For teams',
  'subscription.features.plan.comingSoon': 'Coming soon',
  'subscription.features.plan.support.basic': 'Basic support',
  'subscription.features.plan.support.premium': 'Premium support',
  'subscription.features.inkToShape.title': 'Ink to shape',
  'subscription.features.inkToShape.description':
    'Convert ink strokes into precise shapes',
  'subscription.features.inkToShape.description.short':
    'Convert ink strokes into precise shapes',
  'subscription.features.calligraphyPen.title': 'Calligraphy pen',
  'subscription.features.calligraphyPen.description':
    'Highly dynamic to replicate calligraphy',
  'subscription.features.calligraphyPen.description.short':
    'Highly dynamic to replicate calligraphy',
  'subscription.features.arrange.title': 'Arrange',
  'subscription.features.arrange.description':
    'Bring objects forward and back in reference to one another',
  'subscription.features.arrange.description.short':
    'Bring objects forward and back in reference to one another',
  'subscription.features.align.title': 'Align',
  'subscription.features.align.description':
    'Place and distribute objects neatly based on selected options',
  'subscription.features.align.description.short':
    'Place and distribute objects neatly based on selected options',
  'subscription.features.group.title': 'Group',
  'subscription.features.group.description':
    'Group & ungroup selected objects together',
  'subscription.features.group.description.short':
    'Group & ungroup selected objects together',
  'subscription.features.flip.title': 'Flip',
  'subscription.features.flip.description':
    'Invert objects on the horizontal or vertical axis',
  'subscription.features.flip.description.short':
    'Invert objects on the horizontal or vertical axis',
  'subscription.features.teamsIntegration.title': 'MS Teams integration',
  'subscription.features.teamsIntegration.description':
    'Select + share documents with collaborators within Teams',
  'subscription.features.teamsIntegration.description.short':
    'Select + share documents with collaborators within Teams',
  'subscription.features.snapToContent.title': 'Content snapping',
  'subscription.features.snapToContent.description':
    'Snap annotations to embedded PDF content',
  'subscription.features.snapToContent.description.short':
    'Snap annotations to embedded PDF content',
  'subscription.features.fonts.title': 'Fonts',
  'subscription.features.fonts.description':
    'Select from a range of fonts for text annotations',
  'subscription.features.fonts.description.short':
    'Select from a range of fonts for text annotations',
  'subscription.features.ocr.title': 'OCR',
  'subscription.features.ocr.description':
    'Search and select text from an image',
  'subscription.features.ocr.description.short':
    'Search and select text from an image',
  'subscription.features.ruler.title': 'Ruler',
  'subscription.features.ruler.description': 'Measure and draw exact lengths',
  'subscription.features.ruler.description.short':
    'Measure and draw exact lengths',
  'subscription.features.hatchPatterns.title': 'Hatch Patterns',
  'subscription.features.hatchPatterns.description':
    'Add textured patterns to shapes and areas',
  'subscription.features.hatchPatterns.description.short':
    'Add textured patterns to shapes and areas',
  'subscription.features.ai.title': 'Drawboard AI',
  'subscription.features.ai.description':
    'Perform powerful text editing capabilities with AI',
  'subscription.features.ai.description.short':
    'Perform powerful text editing capabilities with AI',
  'subscription.features.ai.tooltip.title': 'Drawboard AI credits',
  'subscription.features.ai.tooltip.description':
    'Credits are used to perform AI requests. {perMonth}',
  'subscription.features.ai.tooltip.description.perMonth':
    'Credits reset each month.',

  'subscription.footer.studentPlan': 'See student plan',
  'subscription.footer.startTrial.hint':
    '*7-day free trial and then recurring {paymentPeriod} payment.',
  'subscription.paymentPeriod.monthly': 'monthly',
  'subscription.paymentPeriod.yearly': 'annual',
  'subscription.footer.startTrial.pricing': 'All prices are in $USD',
  'subscription.footer.existingPlan': "Can't see your existing plan?",
  'subscription.footer.existingPlan.link': 'Read more',
  'subscription.plan.free.text': 'Free for personal use',
  'subscription.plan.free.startTrial': 'Get started',
  'subscription.maximumDocumentSize.unlimited': 'unlimited',
  'subscription.maximumDocumentSize.limited': 'limited',
  'subscription.maximumDocuments.unlimited': 'Unlimited docs',
  'subscription.maximumDocuments.limited': 'Limited docs',
  'subscription.maximumDocuments': '{count} docs',
  'subscription.maximumDocuments.unlimited.short': 'Unlimited',
  'subscription.maximumDocuments.limited.short': 'Limited',
  'subscription.maximumDocuments.short': '{count} docs',
  'subscription.maximumDocuments.tooltip.title': 'Cloud synching {limit}',
  'subscription.maximumDocuments.tooltip.limited':
    'Sync up to {count} documents and up to {size}/doc with Drawboard cloud',
  'subscription.maximumDocuments.tooltip.unlimited':
    'Sync up to unlimited documents with Drawboard cloud',
  'subscription.nextPayment': 'Next payment on',
  'subscription.enterprise.headline.title': 'Drawboard Enterprise',
  'subscription.enterprise.headline.description':
    'Created to service highly collaborative teams.\n\nDrawboard PDF Enterprise has features that make working in groups and tracking multi-touch documents and projects seamless.\n\nTeam specific features create an easy, frictionless workspace for collaborators (internal and external) to do their best work!',
  'subscription.enterprise.feature1':
    'Mark up PDFs as a team in real-time with live sync',
  'subscription.enterprise.feature2':
    'Manage users and track their annotations',
  'subscription.enterprise.feature3':
    'Collaborate with ease in a workspace customized for the way your team likes to work',
  'subscription.enterprise.feature4':
    'Full access to powerful PDF tools and features for every person invited to collaborate',
  'subscription.enterprise.feature5':
    'A new home for your PDFs with unlimited storage to keep your work where work is done',
  'subscription.enterprise.feature6':
    'Access your full library of projects and documents on any platform and any device type, wherever your team may be',

  'subscription.plan.basic.tooltip':
    "The basic tools for simple everyday use.\n\nA PDF annotations and viewing platform that lets you consume PDFs easier and faster than ever before. You'll get what Drawboard in known for - tools that feel good to use and an interface that's fluid and intuitive.\n\nUse this as your default PDF viewer and see why we've had over 8 millions downloads.",
  'subscription.plan.v2Tier1.tooltip':
    'Need more than the basics? - Pro Lite unlocks our most popular Advanced Tools\n\nGet more done with access to Page Templates, PDF builder so you can merge and split your docs, call out annotations, the Snapshot tool, and full multi-platform access!\n\nThis tier is for someone that views and interacts with PDFs often and doesn’t want to jump across multiple platforms to do what needs to be done. It’s a one-stop workspace for everything you need to work smarter with PDFs.',
  'subscription.plan.v2Tier2.tooltip':
    'Full access to Advanced Tools, plus full Store+Share capabilities.\n\nDo you need to collaborate with clients and colleagues? Want to take your work with you wherever you are, across any device?\n\nThe Pro Plus tier is designed for users who need the best tools AND a workspace that facilitates collaboration and makes organizing and storing files easy.',
  'subscription.plan.v2Tier3.tooltip':
    "Work without limits. This tier is for users who want to build workflows and a team workspace within Drawboard PDF. With unlimited storage, you can house everything you need directly within the workspace you're working from.\n\nPro Unlimited also has features that help you manage your team - with things like an Admin Portal, SSO and premium support.",

  'compulsory.modal.subtitle':
    'Create your account now to sync\nall your markups in real-time',
  'compulsory.modal.createAccount.button': 'Create an account',
  'compulsory.modal.login.button': 'Log in with account',
  'compulsory.modal.skip.button': 'Or use app without cloud syncing',

  'subscription.plan.buyTeamPlan': 'Get team pricing',
  'subscription.plan.teamPlan': 'Buy for team',
  'subscription.teamPlan.form.title': 'Create a team account',
  'subscription.teamPlan.form.description':
    'Enter your team admin details here',
  'subscription.teamPlan.form.numberOfLicenses.message':
    'If you need more than {allowanceCounts} licenses please {link}',
  'subscription.teamPlan.form.numberOfLicenses.contact': 'contact sales',
  'subscription.onTeamPlan': 'You‘re on team plan',

  'subscription.duplicated.modal.title': 'Duplicate Subscriptions',
  'subscription.duplicated.modal.description.chargebee.manual':
    'We have noticed that you have both a Drawboard PDF subscription under a {teamPlan} and your {personalPlan}.\n\nWe recommend you {cancelPlan}.',
  'subscription.duplicated.modal.description.chargebee.auto.highlight':
    'not be billed next cycle',
  'subscription.duplicated.modal.description.chargebee.auto':
    'We have noticed that you have both a Drawboard PDF subscription under a {teamPlan} and your {personalPlan}.\n\nWould you like us to automatically cancel your personal subscription? By choosing accept, we will remove your personal plan so you will {highlight}.',
  'subscription.duplicated.modal.description.store':
    'We have noticed that you have both a Drawboard PDF subscription under a {teamPlan} and your {personalPlan} via {platform}.\n\nWe recommend you {cancelPlan}.',
  'subscription.duplicated.modal.description.teamPlan': 'team plan',
  'subscription.duplicated.modal.description.personalPlan': 'personal plan',
  'subscription.duplicated.modal.description.cancelPlan':
    'cancel your existing subscription to avoid any double billing',

  'subscription.duplicated.modal.button.ignore': 'Ignore',
  'subscription.duplicated.modal.button.howTo': 'How to cancel plan',
  'subscription.duplicated.modal.button.accept': 'Accept',
  'subscription.duplicated.modal.button.manage': 'Manage plan',
  'subscription.trial.pro.text':
    'You are not eligible for a free trial because you are already a Pro User.',
  'subscription.trial.pro.callToAction': 'View subscription.',
  'subscription.trial.ineligible.text':
    'You are not eligible for a free trial because you have already completed your free trial.',
  'subscription.trial.ineligible.callToAction': 'Upgrade to start using Pro.',

  'setting.modal.profile.title': 'Personal info',
  'setting.modal.profile.subtitle': 'Update details here',
  'setting.modal.viewAndLayout.title': 'View & Layout',
  'setting.modal.viewAndLayout.subtitle': 'Have control of your app settings',
  'setting.modal.subscription.title.guest': 'Pro Subscription',
  'setting.modal.subscription.title': 'Manage Subscription',
  'setting.modal.admin.title': 'Admin Portal',
  'setting.modal.admin.subtitle': 'Manage your team plan',

  'setting.modal.profile.image.title': 'Adjust image',
  'setting.modal.profile.image.description':
    'Move image around until you are satisfied',
  'setting.modal.profile.image.confirm': 'Set profile image',
  'setting.modal.profile.image.change': 'Change image',

  'panel.projects.title.line1': 'Working with',
  'panel.projects.title.line2': 'large teams?',
  'panel.projects.item1.title': 'Unlimited projects',
  'panel.projects.item1.description':
    'Keep all project markups coordinated in one central location',
  'panel.projects.item2.title': 'Team and guest access',
  'panel.projects.item2.description':
    'Keep discussions on track with full context right on the drawings',
  'panel.projects.item3.title': 'Track and coordinate issues',
  'panel.projects.item3.description':
    'Assign responsibilities, set due dates and stay up-to-date with issues',
  'panel.projects.button': 'Contact us',
  'panel.upsell.title.line1': 'Get more done',
  'panel.upsell.title.line2': 'with',
  'panel.upsell.title.line3': 'Pro',
  'panel.upsell.button': 'Upgrade',
  'panel.upsell.item1.title': 'Smart Ink',
  'panel.upsell.item1.description':
    'Best-in-class pressure sensitivity and new calligraphy pen tips',
  'panel.upsell.item2.title': 'Unlimited Favorites Toolbar',
  'panel.upsell.item2.description': 'Build a workspace custom to you',
  'panel.upsell.item3.title': 'Multi-window canvas',
  'panel.upsell.item3.description':
    'View and mark up multiple documents at the same time.',
  'panel.upsell.v2.title.line1': 'A tool for',
  'panel.upsell.v2.title.line2': 'every job!',
  'panel.upsell.v2.item1': 'From custom fonts....',
  'panel.upsell.v2.item2': '....to audio notes.',
  'panel.upsell.v2.button': 'Explore Pro',
  'panel.proUpsell.title.line1': 'Work without limits.',
  'panel.proUpsell.item1.title': 'Full access to Advanced Tools',
  'panel.proUpsell.item1.description': 'Every tool to help you work faster',
  'panel.proUpsell.item2.title': 'Unlimited storage',
  'panel.proUpsell.item2.description': 'Sync PDFs without limits',
  'panel.proUpsell.item3.title': 'Admin portal',
  'panel.proUpsell.item3.description': 'Manage your team with ease',
  'panel.proUpsell.item4.title': 'Single sign-on',
  'panel.proUpsell.item4.description':
    'Securely & seamlessly sign in across platforms',
  'panel.proUpsell.item5.title': 'Premium support',
  'panel.proUpsell.item5.description': 'Get expert & tailored assistance',
  'panel.footer.platform': 'Available on',
  'panel.youtube.title.line1': 'Get all the',
  'panel.youtube.title.line2': 'tips and tricks',
  'panel.youtube.title.line3': 'to use Drawboard PDF \nlike a Pro',
  'panel.youtube.button': 'Go to Channel',
  'panel.teamCollaboration.title.line1': 'Need Drawboard PDF',
  'panel.teamCollaboration.title.line2': 'for a team?',
  'panel.teamCollaboration.description':
    'Markup on the same live workspace as the rest of your team with instant annotation syncing, cross-platform access and a UI that turns beginners into experts.',
  'panel.teamCollaboration.button': 'Explore team solutions',
  'panel.crossPlatformsUpsell.title.line1': 'Use Drawboard',
  'panel.crossPlatformsUpsell.title.line2': 'PDF across all',
  'panel.crossPlatformsUpsell.title.line3': 'your devices',
  'panel.crossPlatformsUpsell.button': 'Get to know your tools',
  'panel.retrial.tag': 'Go Pro',
  'panel.retrial.title': 'Activate a new \nfree trial',
  'panel.retrial.description1':
    'Explore all the new tools \nand features that have been \nreleased since you last tried \nDrawboard PDF Pro.',
  'panel.retrial.description2':
    'With dozens of updates \njust this year, there is a \nlot to discover!',
  'panel.retrial.button': 'Restart free trial',

  'panel.googleWorkspace.title': 'Unlock your \nPDF tools from \nGoogle Drive',
  'panel.googleWorkspace.item1.title': 'Access every tool you need',
  'panel.googleWorkspace.item1.description':
    'Open Google Drive files with Drawboard PDF and mark up freely',
  'panel.googleWorkspace.item2.title': 'See changes in Google Drive',
  'panel.googleWorkspace.item2.description':
    'All changes sync back to Google Drive in near real-time',
  'panel.googleWorkspace.item3.title': 'Keep files up-to-date',
  'panel.googleWorkspace.item3.description':
    'Always work on the latest version & avoid the hassle of duplicate files',
  'panel.googleWorkspace.button': 'Install integration',
  'panel.googleWorkspace.footer':
    'Get the integration on \nGoogle Workspace Marketplace',

  'panel.education.title': 'Free trial\n then 50% Off',
  'panel.education.description': 'for all teachers\n & students',
  'panel.education.line1': 'Claim your discount on Drawboard PDF Pro',
  'panel.education.line2': 'Get a plan for your entire school/university',
  'panel.education.button1': 'Get offer',
  'panel.education.button2': 'Learn more',

  'survey.headline.title': 'Welcome to Drawboard PDF (Pro)',
  'survey.headline.heading1': 'We’d love to know which feature',
  'survey.headline.heading2': 'influenced your decision to upgrade?',
  'survey.headline.subheading':
    'This is to help us with some user research so we can continue\ncreating awesome product users love to use!',
  'survey.features.moreShareDocs': 'More Store+Share documents',
  'survey.features.calloutTool': 'Callout tool feature',
  'survey.features.measurementTools': 'Measurement tools',
  'survey.features.customColor': 'Custom color palettes',
  'survey.features.blankDocTemplate': 'Blank document templates',
  'survey.features.mergingPDFs': 'Merging PDFs',
  'survey.features.lineStyles': 'Line styles',
  'survey.features.officeConversion': 'Office 365 conversion',
  'survey.features.signatures': 'Signatures',
  'survey.features.pressureSensitivity': 'Pressure sensitivity',
  'survey.features.textReviewExport': 'Text review export',
  'survey.features.audio': 'Audio notes',
  'survey.features.tags': 'Tags',
  'survey.features.snapshot': 'Snapshot',
  'survey.features.additionalCustomTools': 'Additional custom tools',
  'survey.features.androidApp': 'Android app',
  'survey.skip': 'Skip',
  'survey.done': 'Finish',

  'canvas.pages.actions.saveAsTemplate': 'Save as template',

  'canvas.search.panel.title': 'Search',
  'canvas.search.emptyState.title': 'Looking for a\nspecific word?',
  'canvas.search.emptyState.description':
    'Find a specific area of your PDF\nby searching for key terms',
  'canvas.search.noMatch.title': 'No match within\nthis document',
  'canvas.search.noMatch.description': 'please try a different term',
  'canvas.search.filter.caseSensitive': 'Case-sensitive',
  'canvas.search.filter.wholeWord': 'Match exact word',

  'canvas.bookmark.panel.title': 'Bookmarks',
  'canvas.bookmark.filter.expandAll': 'Expand all',
  'canvas.bookmark.filter.collapseAll': 'Collapse all',
  'canvas.bookmark.emptyState.title': 'No existing\nbookmarks found',
  'canvas.bookmark.emptyState.description':
    'Navigate to different parts of\nyour document with ease',
  'canvas.bookmark.emptyState.button': 'Add new bookmark',
  'canvas.bookmark.noMatch.title': 'No bookmarks match',
  'canvas.bookmark.noMatch.description': 'Please try a different term',
  'canvas.bookmark.add.topOfList': 'At the top of list',
  'canvas.bookmark.add.afterSelection': 'After selected bookmark',
  'canvas.bookmark.add.childOfSelection': 'Child of selected bookmark',
  'canvas.bookmark.add.bottomOfList': 'At the bottom of list',
  'canvas.bookmark.add.form.title': 'New bookmark',
  'canvas.bookmark.edit.form.title': 'Edit bookmark',
  'canvas.bookmark.form.label.title': 'Bookmark title',
  'canvas.bookmark.form.label.pageNumber': 'Page number (destination)',
  'canvas.bookmark.form.button.useCurrentPage': 'Use current page',
  'canvas.bookmark.form.button.cancel': 'Cancel',
  'canvas.bookmark.add.form.button.submit': 'Create',
  'canvas.bookmark.edit.form.button.submit': 'Update',
  'canvas.bookmark.delete.form.title': 'Deleting bookmark',
  'canvas.bookmark.delete.form.description':
    'You’re about to delete the bookmark {bookmark} and its children.',
  'canvas.bookmark.delete.form.button.cancel': 'No',
  'canvas.bookmark.delete.form.button.confirm': 'Yes',
  'canvas.bookmark.loading.title': 'Loading bookmarks',

  'canvas.annotationsHistory.panel.title': 'Markup List',
  'canvas.annotationsHistory.title': 'No markups or\nannotations found',
  'canvas.annotationsHistory.description':
    'Use your tool bar and start\nmarking up your document',

  'canvas.annotationsHistory.filter.title': 'Markup filters',
  'canvas.annotationsHistory.filter.author': 'Author',
  'canvas.annotationsHistory.filter.type': 'Type',
  'canvas.annotationsHistory.filter.color': 'Color',
  'canvas.annotationsHistory.filter.button.clear': 'Clear all',

  'contextMenu.page.pasteInPlace': 'Paste in place',
  'contextMenu.page.moveUp': 'Move page up',
  'contextMenu.page.moveDown': 'Move page down',
  'contextMenu.page.addBefore': 'Add page (before page {page})',
  'contextMenu.page.addAfter': 'Add page (after page {page})',
  'contextMenu.page.duplicate': 'Duplicate page',
  'contextMenu.page.delete': 'Delete page {page}',
  'contextMenu.page.rotate': 'Rotate page (Clockwise)',
  'contextMenu.page.rotateAnti': 'Rotate page (Counter-clockwise)',

  'contextMenu.page.addPageBefore': 'Add page (before page {page})',
  'contextMenu.page.addPageAfter': 'Add page (after page {page})',
  'contextMenu.page.duplicatePage': 'Duplicate page',
  'contextMenu.page.deletePage': 'Delete page {page}',
  'contextMenu.page.rotatePage': 'Rotate page (Clockwise)',
  'contextMenu.page.rotatePageAnti': 'Rotate page (Counter-clockwise)',
  'contextMenu.page.insertMarkup': 'Insert markup',

  'contextMenu.insert.image': 'Insert picture',
  'contextMenu.insert.note': 'Insert note',
  'contextMenu.insert.bookmark': 'Add bookmark',
  'contextMenu.insert.text': 'Add text',

  'contextMenu.annotations.save': 'Save to Markup Library',
  'contextMenu.annotations.save.toPersonal': 'Save to Personal',
  'contextMenu.annotations.save.toProject': 'Save to Project',
  'contextMenu.annotations.selectAll': 'Select all annotations',
  'contextMenu.annotations.selectAllText': 'Select all text',
  'contextMenu.annotations.group': 'Group',
  'contextMenu.annotations.ungroup': 'Ungroup',

  'contextMenu.arrange.arrange': 'Arrange',
  'contextMenu.arrange.bringForward': 'Bring forward',
  'contextMenu.arrange.sendBackward': 'Send backward',
  'contextMenu.arrange.bringToFront': 'Bring to front',
  'contextMenu.arrange.sendToBack': 'Send to back',

  'contextMenu.flip.flip': 'Flip',
  'contextMenu.flip.flipX': 'Flip vertically',
  'contextMenu.flip.flipY': 'Flip horizontally',
  'contextMenu.flip.flipXGroup': 'Flip selection vertically',
  'contextMenu.flip.flipYGroup': 'Flip selection horizontally',

  'contextMenu.align.align': 'Align',
  'contextMenu.align.alignTop': 'Align top',
  'contextMenu.align.alignRight': 'Align right',
  'contextMenu.align.alignBottom': 'Align bottom',
  'contextMenu.align.alignLeft': 'Align left',
  'contextMenu.align.alignCenterY': 'Align center horizontally',
  'contextMenu.align.alignCenterX': 'Align center vertically',

  'tab.contextMenu.closeTab': 'Close tab',
  'tab.contextMenu.closeOtherTabs': 'Close other tabs',
  'tab.contextMenu.closeAllTabs': 'Close all tabs',
  'tab.contextMenu.exportDocument': 'Export document',
  'tab.contextMenu.shareWithOthers': 'Share with others',
  'tab.contextMenu.sendToDocBuilder': 'Send to Document Builder',

  'marketing.windows.button.getApp': 'Get Windows app',
  'marketing.apple.button.getApp': 'Get Apple app',
  'marketing.windows.button.getApp.short': 'Get Windows',
  'marketing.apple.button.getApp.short': 'Get Apple',
  'marketing.banner.title':
    'Enhance your experience.\nGet more on Drawboard PDF app',
  'marketing.banner.description': 'Available on Apple and Windows',
  'marketing.annotationsHistory.panel.title': 'Get Markup List',
  'marketing.annotationsHistory.panel.description':
    'See the history of your documents’\nactivities. Available on our App',
  'marketing.banner.docBuilder.title':
    'Unlock more \n Document Builder \n capabilities on Windows',

  'upsell.tool.ink.title':
    'Get more advanced inking\nfeatures on the Drawboard app.',

  'upsell.tool.signature.title': 'Create account to save your signatures',
  'upsell.tool.signature.description': 'Sign contracts and forms with ease',

  'upsell.tool.line.title': 'Upgrade for more line styles',
  'upsell.tool.line.description': 'including dotted & dashed styles',
  'upsell.tool.border.title': 'Upgrade for more border styles',

  'upsell.title': 'Access an extensive range of tools with Drawboard PDF app',
  'upsell.app.download.subtext': 'Also available on {app}',
  'upsell.app.download.subtext.standalone': 'Available on {app}',
  'upsell.app.viewFeatureBreakdown': 'View app feature breakdown',
  'upsell.app.tool.exclusive': 'Exclusive to {app}',
  'upsell.app.tool.apple': 'Apple app',
  'upsell.app.tool.windows': 'Windows app',
  'upsell.app.tool.both': 'Apple & Windows app',
  'upsell.app.tool.exclusiveFeature': 'App exclusive feature',
  'upsell.app.tool.upgrade': 'Upgrade subscription',

  'upsell.feature.audio.title': 'Audio Notes',
  'upsell.feature.audio.description':
    'Record audio notes and attach them to sections of your PDFs! With Audio Notes, you can record a voice note or upload an audio file and pin it to any area of your PDF document. Perfect for making quick notes to yourself, to colleagues, to students or anyone you’re collaborating with.',
  'upsell.feature.detailedMarkupHistory.title': 'Markup history',
  'upsell.feature.detailedMarkupHistory.description':
    'Detailed Markup history helps you understand your documents’ changes and details including what annotations have been made, by whom, when, by what type, color and more!',
  'upsell.feature.redaction.title': 'Redaction',
  'upsell.feature.redaction.description':
    'With Redaction, you can permanently remove text or sections of your PDFs. Redact sensitive content like personal details or confidential information from any PDF.',
  'upsell.feature.snapshot.title': 'Snapshot',
  'upsell.feature.snapshot.description':
    'Snapshot tool allows you to cut or copy PDF content. You can remove flattened PDF content, or copy PDF content that can then be pasted in vectorised format elsewhere on your document - meaning it maintains quality at any zoom level.',
  'upsell.feature.hyperlink.title': 'Hyperlink',
  'upsell.feature.hyperlink.description':
    'Hyperlinks allow you to link to webpages, other document pages, and areas on other pages within your documents. Create your own navigation within your documents or link to important resources with Hyperlinks.',
  'upsell.feature.digitalProtractor.title': 'Protractor',
  'upsell.feature.digitalProtractor.description':
    'The Protractor tool allows you to find and annotate exact angles and to accurately draw and measure straight lines.',
  'upsell.feature.docBuilderApp.title': 'Document Builder',
  'upsell.feature.docBuilderApp.description':
    'Document Builder is an intuitive workspace where you can manipulate your documents in various ways. You can merge and split documents, rearrange, duplicate, delete, rotate pages and more.',
  'upsell.feature.imgFromCamera.title': 'Insert from camera',
  'upsell.feature.imgFromCamera.description':
    'If your device has an inbuilt camera you can take a photo to  insert in to your document!',
  'upsell.feature.laserPointer.title': 'Laser Pointer',
  'upsell.feature.laserPointer.description':
    'Use the laser pointer to seamlessly point out sections of your page.',
  'upsell.feature.lassoSelect.title': 'Lasso',
  'upsell.feature.lassoSelect.description':
    'Make accurate selection around irregular shapes or objects in your document.',
  'upsell.feature.multiWindow.title': 'Multi-window',
  'upsell.feature.multiWindow.description':
    'Multi Window allows you to view and work on multiple Drawboard PDF canvases at the same time. Compare documents side-by-side or work on multiple documents without having to flick between tabs.',
  'upsell.feature.note.title': 'Notes',
  'upsell.feature.note.description':
    'Add notes anywhere on your documents or attach them to any annotation.',
  'upsell.feature.markupLibrary.title': 'Markup Library',
  'upsell.feature.markupLibrary.description':
    'With Markup library, you can keep every annotation you use regularly at your fingertips. Save and store your markups and access them at any time, on any document.',
  'upsell.feature.textCleaner.title': 'Text Cleaner',
  'upsell.feature.textCleaner.description':
    'Get rid of text annotations such as underline and strike-through in your content.',
  'upsell.feature.pressureSensitiveInk.title': 'Pressure-sensitive Ink',
  'upsell.feature.pressureSensitiveInk.description':
    'Pressure sensitive ink offers an inking experience that feels and looks like pen pressed to paper. More pressure using your stylus = heavier ink and less pressure = lighter ink.',
  'upsell.feature.exportTextReview.title': 'Export text review',
  'upsell.feature.exportTextReview.description':
    'Text Review Export allows you to export all of your notes, comments, highlighted text and more into a CSV or TXT file for easy review.',
  'upsell.feature.colorPicker.title': 'Custom colors',
  'upsell.feature.colorPicker.description':
    'With Custom Colors, you get unlimited access to all colors to use with every tool. You can also use the Color Picker to pick colors from your document and use them for your pens, highlighters, shapes and other tools.',
  'upsell.feature.ruler.title': 'Ruler',
  'upsell.feature.ruler.description':
    'Just like using a real-life ruler or tape measure. Use the Ruler to measure elements on-page and draw freehand straight lines with ease. Measure in units that work for you and customize your ruler color and opacity.',
  'upsell.feature.smartInk.title': 'Ink to line',
  'upsell.feature.smartInk.description':
    'With Ink to line you can draw perfectly straight lines with one swift stroke of your pen. Transform your freehand pen lines into neat straight lines by simply drawing and holding. Easily toggle smart line recognition on and off to suit your workflow.',
  'upsell.feature.inkToShape.title': 'Ink to shape',
  'upsell.feature.inkToShape.description':
    'Create perfect squares, triangles, circles, pentagons and hexagons with your ink! Transform your freehand shapes into perfect polygons by simply drawing and holding.',
  'upsell.feature.calligraphyPen.title': 'Calligraphy Pen',
  'upsell.feature.calligraphyPen.description':
    'Create beautiful lettering with the Calligraphy pen. Hand write calligraphy ink strokes seamlessly with the feel of pen on paper.',
  'upsell.feature.copyAndPastePages.title': 'Copy and paste pages',
  'upsell.feature.copyAndPastePages.description':
    'Copy and paste pages within and between your documents! Duplicate pages, copy individual pages or select multiple pages to copy and paste into any document. Manipulate your documents in a way that works for you, directly from the canvas.',
  'upsell.feature.advancedPaste.title': 'Advanced Paste',
  'upsell.feature.advancedPaste.description':
    'With Advanced Paste, you can select any markup or group of markups and apply them to multiple pages in one go!',
  'upsell.feature.fonts.title': 'Fonts',
  'upsell.feature.fonts.description':
    'Choose from a wide selection of fonts for text annotations! Personalize the way you write and make your markups your own.',
  'upsell.feature.emailUpload.title': 'Email upload',
  'upsell.feature.emailUpload.description':
    'Get your email attachments automatically uploaded to your cloud account when you send or forward them to your unique Email Upload address.',
  'upsell.feature.customKeyboardShortcuts.title': 'Custom shortcuts',
  'upsell.feature.customKeyboardShortcuts.description':
    'Custom keyboard shortcuts allow you to set your own personalized keyboard shortcuts, helping you to tailor your workflow to how you work best. Save time, memory and finger strain.',
  'upsell.feature.merge.title': 'Merge',
  'upsell.feature.merge.description':
    'Merge documents and sets of pages in various ways defined by you. Build your PDF documents exactly how you need them.',
  'upsell.feature.split.title': 'Split',
  'upsell.feature.split.description':
    'Split and extract documents and pages in various ways defined by you. Build your PDF documents exactly how you need them.',
  'upsell.feature.compression.title': 'Compress',
  'upsell.feature.compression.description':
    'Compression allows you to lower the data size of your PDF documents. Mid-level compression is a free tool, while on our pro plans you get access to high and low compression options.',
  'upsell.feature.officeConversion.title': 'Convert',
  'upsell.feature.officeConversion.description':
    'Easily convert various file types to PDF. Convert Microsoft Word, Excel and PowerPoint documents into PDFs (.doc .docx .ppt .pptx .xls .xlsx). And convert PDF documents to PDF/A format for archiving.',
  'upsell.feature.callout.title': 'Callout',
  'upsell.feature.callout.description':
    'Callouts draw attention to an area of your design or document with a clear arrow and bounding box for text input.',
  'upsell.feature.calibration.title': 'Calibrate',
  'upsell.feature.calibration.description':
    'Calibrate Tool is part of our measurement toolset. Calibration is an important step in any measurement which first involves setting a known length relative to a distance on the screen. Once Calibration is set then all of the measurement tools become valuable.',
  'upsell.feature.length.title': 'Measure Length',
  'upsell.feature.length.description':
    'Measure Length is part of our measurement toolset. Measure the linear length of anything on the PDF canvas in any direction.',
  'upsell.feature.polyLength.title': 'Poly Length',
  'upsell.feature.polyLength.description':
    'Polylength is part of our measurement toolset. This tool allows you to measure a straight length with multiple points on the PDF canvas.',
  'upsell.feature.area.title': 'Polygon area',
  'upsell.feature.area.description':
    'Polygon Area is part of our measurement toolset. This tool allows you to measure the area of any shape defined by you on the canvas.',
  'upsell.feature.rectangleArea.title': 'Rectangle Area',
  'upsell.feature.rectangleArea.description':
    'Rectangle Area is part of our measurement toolset. This tool allows you to measure the rectangular area of anything on the PDF canvas.',
  'upsell.feature.lineStyles.title': 'Line styles',
  'upsell.feature.lineStyles.description':
    'Line Styles enable you to add distinctly different lines to your canvas. Apply dashed lines, dotted lines, or a combination of both and select from a range of line endings.',
  'upsell.feature.linedTemplates.title': 'Lined templates',
  'upsell.feature.linedTemplates.description':
    'Lined Templates are an excellent starting point for creating new documents and designs. Choose from a wide selection of ruled lines, grids, and patterns',
  'upsell.feature.pageOverlays.title': 'Page Overlays',
  'upsell.feature.pageOverlays.description':
    'Overlays allow you to markup with structure that is not part of your PDF document. Apply graph paper, engineering grid, notepaper overlays and more over your PDF documents.',
  'upsell.feature.favoritesBarUnlimited.title': 'Tool Slots',
  'upsell.feature.favoritesBarUnlimited.description':
    'Keep more tools in our favorites toolbar. Like a worker’s tool belt, your toolbar is designed to keep all the tools you need right at your fingertips. Get unlimited toolbar positions to personalize the way you work and get things done faster',
  'upsell.feature.signature.title': 'Unlimited signatures',
  'upsell.feature.signature.description':
    'Add more than 2 signatures to your Signature bank! Add unlimited signatures to use at any time, on any document. Sign documents with ease and save time.',
  'upsell.feature.snapToContent.title': 'Content snapping',
  'upsell.feature.snapToContent.description':
    'With Content Snapping, your markups snap to embedded content on any PDF document. This functionality is perfect for taking precise measurements of floor plans or technical drawings, or to simply keep your annotations neatly aligned to content on your PDFs.',
  'upsell.feature.signaturesUnlimited.title': 'Signatures',
  'upsell.feature.signaturesUnlimited.description':
    'Add more than 2 signatures to your Signature bank! Add unlimited signatures to use at any time, on any document. Sign documents with ease and save time.',
  'upsell.feature.ocr.title': 'Optical character recognition (OCR)',
  'upsell.feature.ocr.description':
    'Streamline your business operations by digitizing paper documents and automating data entry. Enhance accessibility and searchability of documents, boost productivity, and reduce errors. Harness OCR for improved efficiency, accuracy, and a competitive edge in the digital age.',
  'upsell.feature.hatchPatterns.title': 'Hatch patterns',
  'upsell.feature.hatchPatterns.description':
    'Need to distinguish between different elements on your page? Use hatch patterns to identify elements and materials on your drawings. Understand at a glance which areas are concrete, which are timber or tiling and so much more. Create endless combinations to fit how you work.\n\n Hatch patterns can be used to fill enclosed shapes on the canvas including rectangles, ellipses and clouds.',
  'upsell.feature.ai.title': 'Drawboard AI',
  'upsell.feature.ai.description':
    'Drawboard AI unlocks powerful text editing capabilities that take the effort out of writing and reviewing documents! Summarize or rephrase text, get word definitions instantly, translate documents, fix grammar and more! Need to review, edit or translate document text? Let Drawboard AI do the heavy-lifting for you.',
  'upsell.feature.align.title': 'Align',
  'upsell.feature.align.description':
    'Select multiple annotations and align them left, top, right or bottom. The Align tool is perfect for keeping annotations neatly aligned, or shifting markups into place. Use it to align as many annotations as you would like into vertical or horizontal lines.',
  'upsell.feature.arrange.title': 'Arrange',
  'upsell.feature.arrange.description':
    'Arrange overlapping objects exactly how you need them. Order your shapes, ink annotations, images, cut or copied PDF content any way you like. Bring objects backwards, forwards, to the front or to the back. Work better, your way.',
  'upsell.feature.flip.title': 'Flip',
  'upsell.feature.flip.description':
    'Flip any drawing, markup or annotation on your document horizontally or vertically. Modify your markups exactly how you need to.',

  'canvas.hyperlink.add.form.title': 'New hyperlink',
  'canvas.hyperlink.edit.form.title': 'Edit hyperlink',
  'canvas.hyperlink.form.label.linkTo': 'Link to',
  'canvas.hyperlink.form.label.web': 'Web address (URL)',
  'canvas.hyperlink.form.label.pageNumber': 'Page number (destination)',
  'canvas.hyperlink.form.label.area': 'Area in doc',
  'canvas.hyperlink.form.button.useCurrentPage': 'Use current page',
  'canvas.hyperlink.form.button.cancel': 'Cancel',
  'canvas.hyperlink.form.button.create': 'Create',
  'canvas.hyperlink.form.button.chooseArea': 'Choose Area',
  'canvas.hyperlink.form.button.update': 'Update',

  'canvas.hyperlink.appearance.solid': 'Solid',
  'canvas.hyperlink.appearance.dashed': 'Dashed',
  'canvas.hyperlink.appearance.underline': 'Underline',
  'canvas.hyperlink.appearance.none': 'None',
  'canvas.hyperlink.type.website': 'Website',
  'canvas.hyperlink.type.page': 'Page',
  'canvas.hyperlink.type.area': 'Area',

  'canvas.toolProperty.hyperlink.style.none.title': 'Color & Thickness',
  'canvas.toolProperty.hyperlink.style.none.description':
    'Properties not applicable with the this option',

  'canvas.toolProp.ink.basic.title': 'Basic',
  'canvas.toolProp.ink.basic.description': 'Fixed width',
  'canvas.toolProp.ink.pro.title': 'Pro',
  'canvas.toolProp.ink.pro.description': 'Variable width',
  'canvas.toolProp.ink.calligraphy.title': 'Calligraphy',
  'canvas.toolProp.ink.calligraphy.description': 'Dynamic width',
  'canvas.toolProp.ink.pressure.title': 'Ink pressure',
  'canvas.toolProp.ink.pressure.description': 'Sensitivity level',
  'canvas.toolProp.ink.toLine.title': 'Ink to line',
  'canvas.toolProp.ink.toLine.description': 'Smart line recognition',
  'canvas.toolProp.ink.toShape.title': 'Ink to shape',
  'canvas.toolProp.ink.toShape.description': 'Smart shape recognition',

  'canvas.toolProp.preview.oversize.title': 'No preview available',
  'canvas.toolProp.preview.oversize.description':
    'Please try this tool on the canvas',
  'canvas.toolProp.preview.text.description':
    'Place text on canvas for sizing accuracy',

  'canvas.toolProp.signature.empty.title': 'Create your signature',
  'canvas.toolProp.signature.empty.description': 'For fast and easy signing',
  'canvas.toolProp.signature.empty.button': 'Create',

  'canvas.toolIntro.bookmark.one.title': 'Add a bookmark',
  'canvas.toolIntro.bookmark.one.description':
    'Click on the page you\n want to Bookmark',
  'canvas.toolIntro.bookmark.two.title': 'Find your bookmark',
  'canvas.toolIntro.bookmark.two.description':
    'Find bookmarked pages in\n the Bookmarks tab on the\n left hand side bar',
  'canvas.toolIntro.image.title':
    'Click anywhere on the page to insert an image',
  'canvas.toolIntro.image.description':
    'If your device has an inbuilt camera on Phone/Tablet, you can directly take a photo and insert it straight onto your document.',
  'subscription.trial.tag': 'New trial activation',
  'subscription.trial.title': 'Start a new free trial',
  'subscription.trial.description':
    'A lot of new tools and features have been released since your last free trial, see what’s new!',
  'subscription.trial.v2Tier1.description':
    'Get more than the basics.\n Unlock the most popular\n Advanced Tools.',
  'subscription.trial.v2Tier2.description':
    'Collaborate like a pro with full\n access to Advanced Tools and\n complete Store+Share capabilities.',
  'subscription.trial.v2Tier3.description':
    'Work without limits, with\n unbarred access to everything\n plus unlimited storage.',
  'subscription.trial.v2Tier1.action': 'Pro Lite free trial',
  'subscription.trial.v2Tier2.action': 'Pro Plus free trial',
  'subscription.trial.v2Tier3.action': 'Pro Unlimited free trial',
  'document.external.readonly.title':
    'Action Required (File Access Limitation)',
  'document.external.readonly.description':
    'This PDF {title}, hosted on {sourceName}, currently restricts your ability to edit due to its set permissions. This situation often occurs when the file is shared without editing rights. To proceed, you have three options:',
  'document.external.readonly.changePermissions.title': 'Change Permissions',
  'document.external.readonly.changePermissions.description':
    'Modify the file’s permissions on {sourceName} to enable editing. Ideal if you require changes to the original document.',
  'document.external.readonly.changePermissions.button':
    'How to modify permissions',
  'document.external.readonly.keepCopy.title': 'Keep a Copy',
  'document.external.readonly.keepCopy.description':
    'Keep an editable copy within Drawboard without any syncing ability with {sourceName}',
  'document.external.readonly.keepCopy.button': 'Keep Editable Copy',
  'document.external.readonly.viewOnly.title': 'View-Only Mode',
  'document.external.readonly.viewOnly.description':
    'Continue accessing the document without editing capabilities.',
  'document.external.readonly.viewOnly.button': 'Proceed in View-Only',

  'document.external.conflict.title':
    'We’ve identified a document version conflict',
  'document.external.conflict.description':
    'External changes have been made to the document {title} at the source location on {sourceName}. As you have changes made in Drawboard PDF this presents a version conflict.',
  'document.external.conflict.overwrite.title': 'Keep Drawboard PDF version',
  'document.external.conflict.overwrite.description':
    'Selecting this option will overwrite the external changes in {sourceName}, and preserve any changes made in Drawboard PDF.',
  'document.external.conflict.overwrite.button': 'Overwrite {sourceName} file',
  'document.external.conflict.disableSync.title':
    'Disable Syncing to {sourceName}',
  'document.external.conflict.disableSync.description':
    'Changes made to your document will not save back to {sourceName}. You can continue to edit and annotate on Drawboard PDF.',
  'document.external.conflict.disableSync.button': 'Disable Syncing',

  'document.external.notFound.title':
    'You no longer have access to this document',
  'document.external.notFound.description':
    'The document {title} at the source location on {sourceName} has either been removed, or updates to the permissions have occurred recently.',
  'document.external.notFound.keep.title': 'Save Drawboard PDF version',
  'document.external.notFound.keep.description':
    'Selecting this option will retain the last saved copy from your edits here and continue working on Drawboard PDF.',
  'document.external.notFound.keep.button': 'Save copy Drawboard PDF',
  'document.external.notFound.keep.footer':
    'This file will no longer contain any syncing ability with {sourceName}.',
  'document.external.notFound.delete.title': 'Remove file from Drawboard PDF',
  'document.external.notFound.delete.description':
    'As you no longer have external syncing access (and prefer not to save a Drawboard PDF copy) - we recommend deleting the file from your dashboard.',
  'document.external.notFound.delete.button': 'Delete file',
  'document.external.incorrectAccount.title':
    'This document does not seem to match the correct {sourceName} account',
  'document.external.incorrectAccount.description':
    'This document was opened & originally authorized with the following {sourceName} account:',
  'document.external.incorrectAccount.button': 'Re-authorize this account',
  'document.external.incorrectAccount.button.alt': 'Re-authorize account',
  'document.external.incorrectAccount.footer':
    'To continue working on the most updated version from {sourceName}',
  'document.external.actionRequired.tag': 'Action required',

  'document.external.status.syncing': 'Sync in progress...',
  'document.external.status.synced': 'Auto-synced',
  'document.external.status.disabled': 'Auto-sync disabled',

  'document.external.limitExceed.title': '100% of storage used',
  'document.external.limitExceed.description':
    'You’ve reached your storage limit, please free up space or upgrade to get more storage with {planName} and continue:',
  'document.external.limitExceed.description.unlimited':
    'You’ve reached your storage limit, please free up space or upgrade to get unlimited storage with {planName} and continue:',
  'document.external.limitExceed.statement1':
    'Syncing with 3rd party cloud services',
  'document.external.limitExceed.statement2':
    'Access documents across your devices',
  'document.external.limitExceed.statement3':
    'Share and collaborate with your team',
  'document.external.limitExceed.button': 'Get more storage',
  'document.external.sizeExceed.title': 'File Size Limit Exceeded',
  'document.external.sizeExceed.description':
    'The file is exceeds the maximum allowance for your current plan ({size} per document). To proceed, either reduce the file size or consider upgrading your plan to unlock higher file size upload limits.',
  'document.external.sizeExceed.button': 'Upgrade',
  'document.external.invalid.title': 'Corrupted or Unsupported File',
  'document.external.invalid.description':
    'The file {fileName} is invalid or not supported. Please choose another file.',
  'document.external.invalid.button': 'Ok',

  'document.banner.temporary.title': 'Document currently unsaved {description}',
  'document.banner.temporary.title.exported':
    'Export another copy to your device {description}',
  'document.banner.temporary.sync.description':
    '(upload file to Drawboard cloud and avoid losing any changes)',
  'document.banner.temporary.sync.button': 'Upload file',
  'document.banner.temporary.export.description':
    '(export a copy to your device)',
  'document.banner.temporary.export.lastExport': '(last exported {date})',
  'document.banner.temporary.export.button': 'Export file',

  'header.localDocument.cta': 'Upload document',
  'header.localDocument.cta.uploading': 'Uploading to cloud...',
  'header.localDocument.cta.syncing': 'Syncing to cloud...',
  'header.localDocument.cta.error': 'Fail to upload',
  'header.localDocument.title': 'Upload to cloud to avoid losing your work',
  'header.localDocument.subtitle': 'Document currently unsaved*',
  'header.localDocument.dialog.title':
    'Upload to the cloud to start {syncing} all your documents',
  'header.localDocument.dialog.title.syncing': 'syncing',
  'header.localDocument.dialog.description':
    'All your current unsaved changes are only stored in your browser cache',
  'header.localDocument.dialog.info':
    'You’re at risk losing your data, if cache is cleared',

  'header.localDocument.upload.title': 'Upload to cloud',
  'header.localDocument.upload.description':
    'Store your file on the cloud for access across multiple devices',
  'header.localDocument.upload.button': 'Upload file',
  'header.localDocument.uploadSync.title': 'Upload + Share',
  'header.localDocument.uploadSync.description':
    'Collaborate with other users to view/edit this document via uploading to the cloud',
  'header.localDocument.uploadSync.button': 'Share file',

  'header.cloudDocument.share.title': 'Share & collaborate with real time sync',
  'header.cloudDocument.share.subtitle': 'Used by individuals or in a team',
  'header.cloudDocument.share.button': 'Share',
  'header.3rdParty.dialog.title':
    'Document is now {syncing} across {sourceName} and Drawboard PDF Cloud',
  'header.3rdParty.dialog.title.syncing': 'syncing in real-time',
  'header.3rdParty.dialog.description':
    'Download our native apps to continue working across all your devices in real time',
  'header.3rdParty.dialog.button': 'Download',

  'document.external.promotion.title':
    'Enjoy real time sync with\n {sourceName} & Drawboard PDF',
  'document.external.promotion.subtitle1':
    'Full suite of essential and advanced tools that you need to markup and annotate PDFs',
  'document.external.promotion.subtitle2':
    'Work faster, across any device with our native apps whilst still connected to {sourceName}',
  'document.external.promotion.footer.title': 'Real time collaboration',
  'document.external.promotion.footer.description':
    'Collaborate with other users to view\n and edit documents in real time',
  'document.external.promotion.download.button': 'Download app',
  'document.external.promotion.share.button': 'Share document',

  'document.external.promotion.banner.title':
    'Document is now {sync} across {sourceName} & Drawboard PDF Cloud',
  'document.external.promotion.banner.title.sync': 'syncing in real-time',
  'document.external.promotion.banner.description':
    'Download our native apps to continue working across all your devices in real time',
  'document.external.promotion.banner.button': 'Download',
  'document.external.promotion.banner.noShow': 'Don’t show me again',
  'document.external.promotion.banner.download.button': 'Download',
  'document.status.banner.opening': 'Opening file from {sourceName}...',

  'document.extension.promotion.banner.title':
    'View, Markup and Share web documents seamlessly with Drawboard PDF',
  'document.extension.promotion.banner.description':
    'Download our {browser} Extension plugin and enjoy all the power of PDF markup tools',
  'document.extension.promotion.banner.button': 'Install Extension',
}
