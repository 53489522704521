const SvgComponent = ({ fill = '#000000', ...props }) => (
  <svg
    width="48"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      d="M42.7489 16.3722C42.5306 16.5059 37.3345 19.3274 37.3345 25.5836C37.5794 32.7183 43.8918 35.2204 44 35.2204C43.8918 35.3541 43.047 38.6288 40.5448 42.0616C38.559 45.0188 36.3549 48 33.008 48C29.8243 48 28.6814 46.0292 25.0079 46.0292C21.063 46.0292 19.9467 48 16.9263 48C13.5793 48 11.212 44.8588 9.11792 41.9296C6.39742 38.0958 4.08508 32.0793 4.00345 26.3024C3.94843 23.2412 4.54826 20.2321 6.07089 17.6762C8.21996 14.108 12.0567 11.6857 16.2466 11.6058C19.4569 11.4999 22.314 13.7624 24.2732 13.7624C26.1508 13.7624 29.661 11.6058 33.6326 11.6058C35.3469 11.6076 39.9184 12.1129 42.7489 16.3722ZM24.0017 10.9946C23.4303 8.19912 25.0079 5.40356 26.4773 3.62032C28.3549 1.46375 31.3203 0 33.8775 0C34.0408 2.79554 33.0062 5.53728 31.157 7.53408C29.4978 9.69064 26.6406 11.3141 24.0017 10.9946Z"
      fill={fill}
    />
  </svg>
)

export default SvgComponent
