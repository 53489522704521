import * as R from 'ramda'
import Central from '__store__/central'
import toolIsFavorite from '__canvas__/tool/isFavorite'
import { get, set, pair, assign } from '__lib__/immutable'
import { updateAnnotation } from '__canvas__/tool/annotation'
import { propertiesFromAnnotation } from '__canvas__/tool/annotation'
import all from '__canvas__/tool/all'

const toolPath = (id) => ['canvasTools', id]
export const [getTools, setTools] = pair(['canvasTools'])
export const [getCurrentTool, baseSetCurrentTool] = pair(['canvasCurrentTool'])
export const [getPreviousTool, setPreviousTool] = pair(['canvasPreviousTool'])
export const [getTool, baseSetTool] = pair(toolPath)
export const [getToolsAnnotation, setToolsAnnotation] = pair([
  'canvasToolsAnnotation',
])

export const getActiveTool = (state) => getTool(state, getCurrentTool(state))

export const getToolProperty = (state, id, ...property) =>
  get(getTool(state, id), property)

export const setToolProperties = (state, id, properties) =>
  assign(state, toolPath(id), properties)

export const setToolProperty = (state, id, property, value) =>
  setToolProperties(state, id, { [property]: value })

export const setCurrentToolWithMaybeProperties = (state, id, properties) =>
  baseSetCurrentTool(
    properties ? setToolProperties(state, id, properties) : state,
    id
  )

export const updateAnnotationWithToolIfAny = (state, toolId) => {
  const annotation = getToolsAnnotation(state)

  return annotation
    ? setToolsAnnotation(
        state,
        updateAnnotation(getTool(state, toolId), annotation)
      )
    : state
}

// @GC: I tested with all the tools and they appear to be working
// but not sure what `updateAnnotationWithToolIfAny` does.  Any ideas?
export const updateTool = (state, tool, properties) => {
  const updatedTool = setToolProperties(state, tool.id, properties)
  return updateAnnotationWithToolIfAny(updatedTool, tool.id)
}

export const updateToolFromAnnotation = (state, toolId, annotation) =>
  setToolProperties(state, toolId, propertiesFromAnnotation(annotation))

export const getLoadingTools = (state) => get(state, ['canvasLoadingTools'])

export const getLoadingTool = (state, id) =>
  get(state, ['canvasLoadingTools', id]) || 0

export const toggleLoadingTool = (state, id, toggle) =>
  set(
    state,
    ['canvasLoadingTools', id],
    getLoadingTool(state, id) + (toggle ? 1 : -1)
  )

const setPreviousToolToCurrent = (state) => {
  const tool = getCurrentTool(state)
  return tool ? setPreviousTool(state, tool) : state
}

export const setCurrentTool = (state, id, properties) => {
  const tool = getTool(state, id) || all[id]
  const setToolWithProperties = (state) =>
    setCurrentToolWithMaybeProperties(state, id, properties)
  const setTool = (state) => baseSetTool(state, id, tool)
  const fn = tool
    ? R.compose(setToolWithProperties, setPreviousToolToCurrent, setTool)
    : R.identity
  return fn(state)
}

Central.pair('canvasToolUpdate', null, updateTool)
Central.pair('canvasCurrentToolSet', null, setCurrentTool)
Central.pair('canvasToolSetProperty', null, setToolProperty)
Central.pair('canvasToolSetProperties', null, setToolProperties)
Central.pair('canvasToolsAnnotationSet', null, setToolsAnnotation)
Central.pair('canvasPreviousToolSet', null, setPreviousTool)

export const canvasSetCurrentToolAction = Central.action('canvasCurrentToolSet')
export const canvasToolUpdateAction = Central.action('canvasToolUpdate')
export const canvasToolSetPropertiesAction = Central.action(
  'canvasToolSetProperties'
)
export const canvasSetPreviousToolAction = Central.action(
  'canvasPreviousToolSet'
)
