export const pick = (keys) => (object) => {
  if (!object) return {}
  const result = {}
  keys.forEach((key) => object[key] && (result[key] = object[key]))
  return result
}

export default (object, keys) => {
  if (!object) return {}
  const result = {}
  keys.forEach((key) => object[key] && (result[key] = object[key]))
  return result
}
