import lazy from '__lib__/fn/lazy'
import { getNavigatorData } from './os'

export default lazy(() => {
  try {
    return /(Mac|iPhone|iPad)/i.test(getNavigatorData())
  } catch (e) {
    return false
  }
})
