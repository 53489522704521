import * as R from 'ramda'

import {
  composeGenerators,
  generatePropertyFy as prop,
  generatePropertiesFy as props,
} from './utils'

// prettier-ignore
export default composeGenerators(
  prop('transition', 'transition'),
  prop('transform', ''),
  prop('opacity', 'opacity'),
  prop('color', 'text', 'textColor'),
  prop('backgroundColor', 'bg'),
  prop('borderColor', 'border'),
  prop('borderTopColor', 'border-t', 'borderColor'),
  prop('borderLeftColor', 'border-l', 'borderColor'),
  prop('borderRightColor', 'border-r', 'borderColor'),
  prop('borderBottomColor', 'border-b', 'borderColor'),
  prop('borderStyle', 'border'),
  prop('borderWidth', 'border'),
  prop('borderTopWidth', 'border-t', 'borderWidth'),
  prop('borderLeftWidth', 'border-l', 'borderWidth'),
  prop('borderRightWidth', 'border-r', 'borderWidth'),
  prop('borderBottomWidth', 'border-b', 'borderWidth'),
  prop('borderRadius', 'rounded'),
  prop('borderTopLeftRadius', 'rounded-tl', 'borderRadius'),
  prop('borderTopRightRadius', 'rounded-tr', 'borderRadius'),
  prop('borderBottomLeftRadius', 'rounded-bl', 'borderRadius'),
  prop('borderBottomRightRadius', 'rounded-br', 'borderRadius'),
  prop(['borderTopLeftRadius', 'borderTopRightRadius'], 'rounded-t', 'borderRadius'),
  prop(['borderTopLeftRadius', 'borderBottomLeftRadius'], 'rounded-l', 'borderRadius'),
  prop(['borderTopRightRadius', 'borderBottomRightRadius'], 'rounded-r', 'borderRadius'),
  prop(['borderBottomLeftRadius', 'borderBottomRightRadius'], 'rounded-b', 'borderRadius'),
  prop('flexDirection', 'flex'),
  prop('justifyContent', 'justify'),
  prop('justifySelf', 'justify-self'),
  prop('alignItems', 'items'),
  prop('alignSelf', 'self'),
  prop('alignContent', 'content'),
  prop('flexWrap', 'flex'),
  prop('flexGrow', 'flex-grow'),
  prop('flexShrink', 'flex-shrink'),
  prop(['flexGrow', 'flexShrink'], 'flex', 'flex'),
  prop('zIndex', 'z'),
  prop('position', ''),
  prop('top', 'top'),
  prop('left', 'left'),
  prop('right', 'right'),
  prop('bottom', 'bottom'),
  prop('display', ''),
  prop('width', 'w'),
  prop('minWidth', 'min-w'),
  prop('maxWidth', 'max-w'),
  prop('height', 'h'),
  prop('minHeight', 'min-h'),
  prop('maxHeight', 'max-h'),
  prop('flexBasis', 'flex-basis'),
  prop('overflow', 'overflow'),
  prop('resizeMode', 'image'),
  prop('tintColor', 'image'),
  prop('overlayColor', 'image-overlay'),
  prop('backfaceVisibility', 'backface'),
  props('shadow'),
  prop('elevation', 'elevation'),
  prop('shadowColor', 'shadow'),
  prop('shadowOffset', 'shadow'),
  prop('shadowRadius', 'shadow'),
  prop('shadowOpacity', 'shadow'),
  prop('gap', 'g'),
  prop('margin', 'm'),
  prop('marginTop', 'mt', 'margin'),
  prop('marginLeft', 'ml', 'margin'),
  prop('marginRight', 'mr', 'margin'),
  prop('marginBottom', 'mb', 'margin'),
  prop(['marginLeft', 'marginRight'], 'mx', 'margin'),
  prop(['marginTop', 'marginBottom'], 'my', 'margin'),
  prop('padding', 'p'),
  prop('paddingTop', 'pt', 'padding'),
  prop('paddingLeft', 'pl', 'padding'),
  prop('paddingRight', 'pr', 'padding'),
  prop('paddingBottom', 'pb', 'padding'),
  prop(['paddingLeft', 'paddingRight'], 'px', 'padding'),
  prop(['paddingTop', 'paddingBottom'], 'py', 'padding'),
  prop('fontSize', 'text'),
  prop('fontStyle', 'font'),
  prop('fontWeight', 'font'),
  prop('lineHeight', 'leading'),
  prop('textAlign', 'text'),
  prop('textTransform', ''),
  prop('textDecoration', ''),
  // NATIVE
  // prop('textDecorationLine', ''),
  prop('fontFamily', 'font'),
  prop('fontVariant', 'font'),
  prop('writingDirection', ''),
  prop('letterSpacing', 'tracking'),
  prop('textShadowColor', 'text-shadow'),
  prop('textShadowOffset', 'text-shadow'),
  prop('textShadowRadius', 'text-shadow'),
  prop('textDecorationColor', 'text-line'),
  prop('textDecorationStyle', 'text-line'),
  R.view(R.lensProp('globals')),
)
