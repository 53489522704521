import { mapProps } from 'react-recompose'

const size =
  (defaultValue) =>
  ({ xs, sm, lg, xlg, md, size, ...props }) => ({
    size:
      size || size === null
        ? size
        : md
        ? 'md'
        : sm
        ? 'sm'
        : xs
        ? 'xs'
        : lg
        ? 'lg'
        : xlg
        ? 'xlg'
        : defaultValue,
    ...props,
  })

export default (defaultValue) => mapProps(size(defaultValue))
