import React from 'react'
import * as R from 'ramda'

import Label from './Label'
import hooks from '__hooks__'

const Input = ({
  ref,
  name,
  type,
  value,
  inert,
  onChange,
  disabled,
  children,
  autoFocus,
  inputStyle,
  inputProps,
  placeholder,
  defaultValue,
  afterChildren,
  onValueChange,
  inputClassName,
  onFocus,
  inputComponent: InputComponent = 'input',
  ...props
}) => (
  <Label inputRef={ref} {...props}>
    {children}
    <InputComponent
      size="1"
      ref={ref}
      name={name}
      type={type}
      value={value}
      readOnly={inert}
      style={inputStyle}
      disabled={disabled}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      className={inputClassName}
      {...inputProps}
    />
    {afterChildren}
  </Label>
)

const onPress =
  ({ onPress, preventAutoFocus, ref }) =>
  (event) => {
    onPress && onPress(event, ref.current)
    preventAutoFocus ||
      (ref.current && ref.current.focus && ref.current.focus())
  }

const autoFocus = ({ autoFocus, autoFocusDelay, ref }) =>
  autoFocus &&
  (autoFocus !== true ||
    (window.innerWidth > 1024 && !import.meta.env.VITE_ANDROID)) &&
  setTimeout(() => ref.current && ref.current.focus(), autoFocusDelay || 0)

const onFocus =
  ({ onFocus, autoSelect, ref }) =>
  (event) => {
    autoSelect && ref.current.select()
    onFocus && onFocus(event, ref.current)
  }

const onChange =
  ({ onValueChange, onChange }) =>
  (event) => {
    onChange && onChange(event)
    onValueChange && onValueChange(event.target.value)
  }

export default hooks(
  hooks.bubbleRef('ref'),
  hooks.handler('onPress', onPress),
  hooks.handler('onFocus', onFocus),
  hooks.tapEffect(autoFocus, ['autoFocus']),
  hooks.handler('onChange', onChange),
  R.omit([
    'onValueChange',
    'defaultValue',
    'preventAutoFocus',
    'autoFocus',
    'autoSelect',
    'autoFocusDelay',
  ])
)(Input)
