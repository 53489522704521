import * as R from 'ramda'

import Layout from './Layout'
import Form from '__core__/Form'
import hooks from '__hooks__'
import View from '__components__/View'
import Input from '__components__/Input'
import length from 'Form/validate/length'
import Button from '__components__/Button'
import required from 'Form/validate/required'
import FormForgeryToken from './FormForgeryToken'
import regexp from '__core__/Form/validate/regexp'
import { privacyPolicyUrl } from '__routes__/external.pdf'
import { SignupBackdrop, SignupPanel } from './Panels'

const requireAtLeast = (r, message) =>
  regexp(r, 'Requires at least one ' + message)

const requireDigit = requireAtLeast(/\d/, 'digit')
const requireLowercase = requireAtLeast(/[a-z]/, 'lowercase character')
const requireUppercase = requireAtLeast(/[A-Z]/, 'uppercase character')

const requiredUniqueChars = (value) =>
  R.uniq(R.split('', value || '')).length < 4 &&
  'Requires at least 4 unique characters'

export const validatePassword = [
  required,
  length(8, 100),
  requireDigit,
  requireLowercase,
  requireUppercase,
  requiredUniqueChars,
]

const AcceptInvite = ({
  form,
  onPasswordChange,
  isPasswordConfirmationValid,
  data: { inviteId, email, formAction },
}) => (
  <Layout asset={SignupPanel} backdrop={SignupBackdrop}>
    <View style="flex-center w-full flex-col flex-flex">
      <View.Text style="font-bold mb-4" styleLeft={{ fontSize: 32 }}>
        First things first!
      </View.Text>
      <View style="flex-center items-center w-full">
        <View.Text style="text-shade-3 text-lg text-wrap-none text-ellipsis">
          {email || 'john.smith@drawboard.com'}
        </View.Text>
      </View>
      <Form
        form={form}
        style="w-full max-w-10"
        method="post"
        action={formAction}
        component={View.Form}
      >
        <View style="flex flex-col mt-6">
          <Input.Field
            noError
            type="hidden"
            name="Input.InviteId"
            defaultValue={inviteId}
            inputComponent="input"
            style="self-center"
          />
          <View style="flex flex-col laptop:flex-row">
            <Input.Field
              lg
              autoFocus
              label="First name"
              validate={required}
              name="Input.FirstName"
              placeholder="e.g. John"
              style="flex-flex mt-4 laptop:mr-4"
            />
            <Input.Field
              lg
              label="Last name"
              validate={required}
              name="Input.SurName"
              placeholder="e.g. Smith"
              style="flex-flex mt-4 laptop:ml-4"
            />
          </View>
          <Input.Field
            lg
            style="mt-4"
            placeholder=""
            type="password"
            label="Password"
            name="Input.Password"
            validate={validatePassword}
            onValueChange={onPasswordChange}
          />
          <Input.Field
            lg
            style="mt-4"
            placeholder=""
            type="password"
            label="Confirm password"
            validate={validatePassword}
            name="Input.ConfirmPassword"
            onValueChange={onPasswordChange}
            error={
              !isPasswordConfirmationValid &&
              "Password confirmation doesn't match password"
            }
          />
          <View style="flex-center flex-col tablet:flex-row mt-5 mb-4">
            <View.Text style="text-shade-4 tablet:mr-1 text-center flex-stiff">
              By continuing, you are agreeing to the Drawboard
            </View.Text>
            <Button
              secondary
              component="a"
              target="_blank"
              text="Privacy Policy"
              href={privacyPolicyUrl}
              style="mt-4 tablet:mt-0 tablet:ml-3 flex-stiff"
            />
          </View>
          <View style="flex-center mt-5">
            <FormForgeryToken />
            <Form.Submit
              lg
              plain
              secondary
              component={Button}
              style="rounded-full"
              text="Agree and continue"
            />
          </View>
        </View>
      </Form>
    </View>
  </Layout>
)

export const onPasswordChange =
  ({ setIsPasswordConfirmationValid }) =>
  (_, form) => {
    const state = form.getFieldValues()
    setIsPasswordConfirmationValid(
      !state['Input.ConfirmPassword'] ||
        state['Input.Password'] === state['Input.ConfirmPassword']
    )
  }

const initialState = ({ data: { formState } }) =>
  R.reduce(
    (fieldErrors, { key, value }) => R.assoc(key, value, fieldErrors),
    [],
    formState
  )

const setFormErrors = ({ form, data: { formState } }) =>
  form.setFieldsError(
    R.reduce(
      (fieldErrors, { key, errors }) =>
        R.assoc(key || 'Input.Email', errors[0].errorMessage, fieldErrors),
      [],
      formState
    )
  )

export default R.compose(
  hooks.hoc(
    hooks.state(
      'isPasswordConfirmationValid',
      'setIsPasswordConfirmationValid',
      true
    )
  ),
  Form.withForm('form', initialState, {
    isValid: R.prop('isPasswordConfirmationValid'),
  })
)(
  hooks(
    hooks.tapEffect(setFormErrors),
    hooks.handler('onPasswordChange', onPasswordChange),
    R.omit(['setIsPasswordConfirmationValid'])
  )(AcceptInvite)
)
