import React from 'react'
import * as R from 'ramda'
import { compose } from 'react-recompose'
import { Text } from 'react-with-style'
import { withProps } from 'react-recompose'
import withoutProps from '__lib__/react/withoutProps'

export const renderText = (text, { textComponent, ...props }) =>
  textComponent ? (
    React.createElement(textComponent, props)
  ) : R.is(String, text) ? (
    <Text {...props}>{text}</Text>
  ) : R.is(Function, text) ? (
    text(props)
  ) : text ? (
    React.createElement(text, props)
  ) : null

// merge text component with textStyle into children
export default ({
  textName = 'text',
  styleName = 'style',
  childrenName = 'children',
  textStyleName = 'textStyle',
  textProps = R.compose(R.objOf(styleName), R.prop(textStyleName)),
} = {}) =>
  compose(
    withProps((props) => ({
      [childrenName]: (props[childrenName] || props[textName]) && (
        <>
          {renderText(props[textName], textProps(props))}
          {props[childrenName]}
        </>
      ),
    })),
    withoutProps([textName])
  )

// merge text component with textStyle into children
export const useTextStyleChildren =
  ({
    textName = 'text',
    styleName = 'style',
    childrenName = 'children',
    textStyleName = 'textStyle',
    componentName = 'textComponent',
    beforeChildrenName = 'beforeChildren',
    textProps = (props) =>
      R.compose(
        R.assoc('textComponent', props.textComponent),
        R.objOf(styleName),
        R.prop(textStyleName)
      )(props),
  } = {}) =>
  ({
    [textName]: text,
    [childrenName]: children,
    [beforeChildrenName]: beforeChildren,
    ...props
  }) => ({
    ...props,
    [childrenName]: (children || text) && (
      <>
        {beforeChildren}
        {renderText(text, textProps(props))}
        {children}
      </>
    ),
  })
