import * as R from 'ramda'
import hooks from '__hooks__'
import Form from '__core__/Form'
import View from '__components__/View'
import Input from '__components__/Input'
import Button from '__components__/Button'
import PlatformIcon from '__views__/PlatformIcon'
import FormForgeryToken from './FormForgeryToken'

export const EXTERNAL_LOGIN = {
  google: 'Google',
  apple: 'Apple',
  microsoft: 'Microsoft',
}

const InputComponent = hooks(R.omit(['onValueChange']))('input')

export const externalLoginsProps = {
  [EXTERNAL_LOGIN.google]: {
    order: 0,
    withShadow: true,
    text: 'Sign in with Google',
    logo: <PlatformIcon.Google width={18} />,
    buttonStyle: 'justify-start',
    textStyle: 'font-semibold text-shade-3',
    logoStyle: [{ width: 40 }, 'flex-center'],
  },
  [EXTERNAL_LOGIN.apple]: {
    order: 1,
    text: 'Sign in with Apple',
    logo: <PlatformIcon.Apple width={15} style="text-white" />,
    buttonStyle: 'bg-black',
    textStyle: 'text-white font-semibold',
    logoStyle: 'mr-4',
  },
  [EXTERNAL_LOGIN.microsoft]: {
    order: 2,
    text: 'Sign in with Microsoft',
    logo: <PlatformIcon.Windows width={18} style="ml-1" />,
    buttonStyle: 'border border-shade-1 justify-start',
    textStyle: ['font-semibold', { color: '#5E5E5E' }],
    logoStyle: [{ width: 40 }, 'flex-center'],
  },
}

const ThirdPartyButton = ({
  name,
  text,
  logo,
  form,
  action,
  textStyle,
  logoStyle,
  withShadow,
  buttonStyle,
  ...props
}) => (
  <Form
    form={form}
    method="post"
    action={action}
    component={View.Form}
    styleLeft="flex-flex"
    {...props}
  >
    <FormForgeryToken />
    <Input.Field
      noError
      name="name"
      type="hidden"
      defaultValue={name}
      inputComponent={InputComponent}
    />
    <Button
      plain
      value={name}
      type="submit"
      name="provider"
      component="button"
      styleLeft={[
        'flex bg-white border-transparent rounded-full',
        { height: 44, width: 210 },
        withShadow && {
          boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)`,
        },
      ]}
      styleRight={buttonStyle}
    >
      <View styleLeft="flex-stiff" style={logoStyle}>
        {logo}
      </View>
      <View.Text styleLeft="text-md" style={textStyle}>
        {text}
      </View.Text>
    </Button>
  </Form>
)

export default Form.withForm('form')(ThirdPartyButton)
