import React from 'react'
import { compose } from 'react-recompose'
import { withProps } from 'react-recompose'

import styles from './styles.module.css'
import { paths } from './icons.js'
import assign from '__lib__/react/assign'
import withSize from '__lib__/react/withSize'
import withoutProps from '__lib__/react/withoutProps'
import withClassName from '__lib__/react/withClassName'

const Path = ({ icon, ...props }) => (
  <path d={paths[icon]} fillRule="evenodd" {...props} />
)

const Icon = ({
  icon,
  innerId,
  children,
  innerStyle,
  innerClassName,
  ...props
}) => (
  <span {...props} data-icon={icon}>
    <svg
      id={innerId}
      width="32px"
      height="32px"
      style={innerStyle}
      viewBox="0 0 32 32"
      className={innerClassName}
    >
      {paths[icon] && <Path icon={icon} />}
      {children}
    </svg>
  </span>
)

const innerClassName = ({ crop, size, color }) => [
  styles.inner,
  styles[size],
  crop && styles.crop,
  color && styles.color,
]

const icon = ({ icon, type }) => ({
  icon: icon || type,
})

export const withIconSize = withSize(null)

export const iconSizes = {
  xs: parseInt(styles['--icon-size-xs']),
  sm: parseInt(styles['--icon-size-sm']),
  md: parseInt(styles['--icon-size-md']),
  lg: parseInt(styles['--icon-size-lg']),
}

export default compose(
  assign({ sizes: iconSizes, Path }),
  withIconSize,
  withProps(icon),
  withClassName({ innerClassName }),
  withoutProps(['autoScale', 'color', 'size', 'crop'])
)(Icon)
