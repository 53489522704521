const SvgComponent = ({ fill, ...props }) => (
  <svg
    width="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4798 9.27999C28.9864 9.27999 32.0264 11.2267 33.7597 12.8533L40.5331 6.23999C36.3731 2.37333 30.9598 0 24.4798 0C15.0931 0 6.98646 5.38666 3.03979 13.2266L10.7998 19.2533C12.7464 13.4666 18.1331 9.27999 24.4798 9.27999V9.27999Z"
      fill={fill || '#EA4335'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.5199 24.5332C47.5199 22.5599 47.3599 21.1199 47.0133 19.6266H24.48V28.5332H37.7066C37.44 30.7466 36 34.0799 32.8 36.3199L40.3733 42.1865C44.9066 37.9999 47.5199 31.8399 47.5199 24.5332V24.5332Z"
      fill={fill || '#4285F4'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8266 28.7467C10.32 27.2533 10.0266 25.6533 10.0266 24C10.0266 22.3467 10.32 20.7467 10.8 19.2533L3.03998 13.2267C1.41331 16.48 0.47998 20.1333 0.47998 24C0.47998 27.8667 1.41331 31.52 3.03998 34.7733L10.8266 28.7467V28.7467Z"
      fill={fill || '#FBBC05'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4799 48C30.9599 48 36.3999 45.8667 40.3733 42.1867L32.7999 36.32C30.7733 37.7334 28.0533 38.72 24.4799 38.72C18.1333 38.72 12.7466 34.5334 10.8266 28.7467L3.06665 34.7734C7.01331 42.6134 15.0933 48 24.4799 48V48Z"
      fill={fill || '#34A853'}
    />
  </svg>
)

export default SvgComponent
