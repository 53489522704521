import { trackError, trackWarning } from './'

const ERRORS = {
  notSupported: (method) =>
    `Tried to proxy 'window.console.${method}' but it is not available.`,
  already: (method) =>
    `Tried to proxy 'window.console.${method}' but it has already been proxied.`,
}

const proxyConsoleMethod = (method, callback) => {
  if (!window.console || !window.console[method])
    return window.console.warn(ERRORS.notSupported(method))
  if (window.console[method].__seqProxy)
    return window.console.warn(ERRORS.already(method))

  window.console[method].__seqProxy = true

  const base = window.console[method]
  window.console[method] = function () {
    callback(arguments)
    return base.apply(window.console, arguments)
  }
}

const proxyConsole = () => {
  proxyConsoleMethod('warn', (args) => {
    trackWarning('Console warning: ' + JSON.stringify(Array.from(args)))
  })
  proxyConsoleMethod('error', (args) => {
    trackError('Console error: ' + JSON.stringify(Array.from(args)))
  })
}

export default proxyConsole
