import * as R from 'ramda'
import Base from './Base'
import hooks from '__hooks__'
import { hookDisableFileDropper } from './hookDisableFileDropper'

export const MIME_TYPES = {
  pdf: 'application/pdf',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  png: 'image/png',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  bmp: 'image/bmp',
  gif: 'image/gif',
}

const onFileInputChange =
  ({ onPick, onValueChange, inputProps: { accept, multiple } }) =>
  ({ target }) => {
    const isAccepted = R.compose(R.includes(R.__, accept), R.prop('type'))
    const files = Array.from(target.files).filter(isAccepted)
    if (onPick) {
      files.length && onPick(files)
      target.value = ''
    } else if (onValueChange) {
      files.length && onValueChange(multiple ? files : files[0])
    }
  }

const inputProps = ({ accept, multiple }) => ({
  accept: !accept
    ? 'application/pdf'
    : R.is(Array, accept)
    ? accept.map(R.prop(R.__, MIME_TYPES)).join(',')
    : accept,
  ...(multiple && { multiple: true }),
})

const onDragEnter =
  ({ onDragging, inputProps }) =>
  (event) => {
    if (!onDragging) return
    const item = event.dataTransfer.items[0]
    const type = item && item.kind === 'file' && item.type
    onDragging(R.includes(type, inputProps.accept) ? 'ok' : 'ko')
  }

const dragOff =
  ({ onDragging }) =>
  () =>
    onDragging && onDragging(null)

export default hooks(
  hooks.default('type', 'file'),
  hooks.assoc('inputStyle', ({ inputStyle }) => [
    'opacity-0 absolute absolute-fill full',
    { cursor: 'pointer' },
    inputStyle,
  ]),
  hooks.consume(['accept', 'multiple'])(
    hooks.memo('inputProps', inputProps, ['accept', 'multiple'])
  ),
  hooks.consume(['onDragging'])(
    R.when(R.prop('onDragging'), hookDisableFileDropper),
    hooks.handler('onDragEnter', onDragEnter),
    hooks.handler('onDragLeave', dragOff),
    hooks.handler('onDrop', dragOff)
  ),
  hooks.consume(['onPick', 'onValueChange'])(
    hooks.handler('onChange', onFileInputChange)
  )
)(Base)
