const sharedConfig = {
  sentryPublicKey: 'f1d7cfacde5940b4908258f21a152a32',
  sentryProjectId: '5895294',
}

export const test1 = {
  ...sharedConfig,
  env: 'test1',
  url: {
    base: 'https://us-dev-test1.projects.drawboard.com',
    apiBase: 'https://us-dev-test1.projects.api.drawboard.com',
    api: 'https://us-dev-test1.projects.api.drawboard.com/api',
    io: 'wss://us-dev-test1.projects.api.drawboard.com',
    storage: 'https://dbprojectsusdevtest1.blob.core.windows.net/',
  },
  segment: 'nWlSu0ISvcC8EpkeaKFgkzeHortsNfGJ',
  app_name: 'TODO',
  segment_autolink: 'drawboard.com',
  app_insights: 'ecd35914-5b09-4835-9afa-66dd40ba1b50',
  dropboxAppKey: 'zi16vh3lllpwmlj',
  gapiClientId:
    '447996203770-r58ouje3fbo5j2h3h5mb5oj8rcu9odkj.apps.googleusercontent.com',
  onedriveClientId: 'c52dee6b-1918-441f-83b2-f7f8f824ecb8',
  seqDomain: 'https://us-dev-logs.bullclip.com',
  seqKey: 'Ptq0mzp97wCKTNpXVfcM',
  procore: {
    appClientId:
      'e67516fd0080e76bb393ffd81d277daa9ec66ffa9c04c76099d43d77ac9a374c',
  },
  autodesk: { appClientId: 'B2xoNGYz1fqgdQudAvwGBteynGISvxM0' },
  aconex: { appClientId: '0d71d3df-547b-49c1-87ca-fc0788ef4b73' },
  authorityFqdn: 'us-dev-identity.projects.sso.drawboard.com', // The domain of the OIDC authority
  clientId: 'web_client', // The client ID for this application
}

export const test2 = {
  ...sharedConfig,
  env: 'test2',
  url: {
    base: 'https://us-dev-test2.projects.drawboard.com',
    apiBase: 'https://us-dev-test2.projects.api.drawboard.com',
    api: 'https://us-dev-test2.projects.api.drawboard.com/api',
    io: 'wss://us-dev-test2.projects.api.drawboard.com',
    storage: 'https://dbprojectsusdevtest2.blob.core.windows.net/',
  },
  segment: 'nWlSu0ISvcC8EpkeaKFgkzeHortsNfGJ',
  app_name: 'TODO',
  segment_autolink: 'drawboard.com',
  app_insights: '8bed57f1-a8b7-4c5e-81af-e06a28e4379b',
  dropboxAppKey: 'zi16vh3lllpwmlj',
  gapiClientId:
    '447996203770-r58ouje3fbo5j2h3h5mb5oj8rcu9odkj.apps.googleusercontent.com',
  onedriveClientId: 'c52dee6b-1918-441f-83b2-f7f8f824ecb8',
  seqDomain: 'https://us-dev-logs.bullclip.com',
  seqKey: 'Ptq0mzp97wCKTNpXVfcM',
  procore: {
    appClientId:
      'e67516fd0080e76bb393ffd81d277daa9ec66ffa9c04c76099d43d77ac9a374c',
  },
  autodesk: { appClientId: 'B2xoNGYz1fqgdQudAvwGBteynGISvxM0' },
  aconex: { appClientId: '0d71d3df-547b-49c1-87ca-fc0788ef4b73' },
  authorityFqdn: 'us-dev-identity.projects.sso.drawboard.com', // The domain of the OIDC authority
  clientId: 'web_client', // The client ID for this application
}

export const test3 = {
  env: 'test3',
  url: {
    base: 'https://us-dev-test3.projects.drawboard.com',
    apiBase: 'https://us-dev-test3.projects.api.drawboard.com',
    api: 'https://us-dev-test3.projects.api.drawboard.com/api',
    io: 'wss://us-dev-test3.projects.api.drawboard.com',
    storage: 'https://dbprojectsusdevtest3.blob.core.windows.net/',
  },
  segment: 'nWlSu0ISvcC8EpkeaKFgkzeHortsNfGJ',
  app_name: 'TODO',
  segment_autolink: 'drawboard.com',
  app_insights: '36e353c6-331a-4eaf-85fc-ab31a0c15067',
  dropboxAppKey: 'zi16vh3lllpwmlj',
  gapiClientId:
    '447996203770-r58ouje3fbo5j2h3h5mb5oj8rcu9odkj.apps.googleusercontent.com',
  onedriveClientId: 'c52dee6b-1918-441f-83b2-f7f8f824ecb8',
  seqDomain: 'https://us-dev-logs.bullclip.com',
  seqKey: 'Ptq0mzp97wCKTNpXVfcM',
  sentryPublicKey: 'f1d7cfacde5940b4908258f21a152a32',
  sentryProjectId: 'o946362',
  procore: {
    appClientId:
      'e9a61f27b33f7f28caa257651c6e8deb27889f005aaa536164de6f379a6ef757',
  },
  autodesk: {
    appClientId: 'B2xoNGYz1fqgdQudAvwGBteynGISvxM0',
  },
  aconex: {
    appClientId: '0d71d3df-547b-49c1-87ca-fc0788ef4b73',
  },
  authorityFqdn: 'us-dev-identity.projects.sso.drawboard.com', // The domain of the OIDC authority
  clientId: 'web_client', // The client ID for this application
}

export const identity = {
  ...sharedConfig,
  env: 'identity',
  url: {
    base: 'https://us-dev-identity.projects.drawboard.com',
    apiBase: 'https://us-dev-identity.projects.api.drawboard.com',
    api: 'https://us-dev-identity.projects.api.drawboard.com/api',
    io: 'wss://us-dev-identity.projects.api.drawboard.com',
    storage: 'https://dbprojectsusdevidentity.blob.core.windows.net/',
  },
  segment: 'nWlSu0ISvcC8EpkeaKFgkzeHortsNfGJ',
  app_name: 'TODO identity',
  segment_autolink: 'drawboard.com',
  app_insights: 'ecd35914-5b09-4835-9afa-66dd40ba1b50',
  dropboxAppKey: 'zi16vh3lllpwmlj',
  gapiClientId:
    '447996203770-r58ouje3fbo5j2h3h5mb5oj8rcu9odkj.apps.googleusercontent.com',
  onedriveClientId: 'c52dee6b-1918-441f-83b2-f7f8f824ecb8',
  seqDomain: 'https://us-dev-logs.bullclip.com',
  seqKey: 'Ptq0mzp97wCKTNpXVfcM',
  procore: {
    appClientId:
      'e67516fd0080e76bb393ffd81d277daa9ec66ffa9c04c76099d43d77ac9a374c',
  },
  autodesk: { appClientId: 'B2xoNGYz1fqgdQudAvwGBteynGISvxM0' },
  aconex: { appClientId: '0d71d3df-547b-49c1-87ca-fc0788ef4b73' },
  authorityFqdn: 'us-dev-identity.projects.sso.drawboard.com', // The domain of the OIDC authority
  clientId: 'web_client', // The client ID for this application
}

// Fallback to identity if unspecified.
export const development = identity

export const preprod = {
  ...sharedConfig,
  env: 'preprod',
  url: {
    base: 'https://preprod-bullclip.drawboard.com',
    apiBase: 'https://preprod-api.bullclip.com',
    api: 'https://preprod-api.bullclip.com/api',
    io: 'wss://preprod-api.bullclip.com',
    storage: 'https://dbprojectsusqapreprod.blob.core.windows.net/',
  },
  segment: 'TptydqCvvm0OlC8MvuZcOwdGmi0gr2Dg',
  app_name: 'TODO',
  segment_autolink: 'drawboard.com',
  app_insights: 'ca04c73f-bf00-47ae-a566-39070fd019b3',
  dropboxAppKey: 'zi16vh3lllpwmlj',
  gapiClientId:
    '447996203770-r58ouje3fbo5j2h3h5mb5oj8rcu9odkj.apps.googleusercontent.com',
  onedriveClientId: 'a370efa5-f030-4d71-b12b-f82b141d1a6a',
  seqDomain: 'https://us-dev-logs.bullclip.com',
  seqKey: 'Ptq0mzp97wCKTNpXVfcM',
  procore: {
    appClientId:
      'e9a61f27b33f7f28caa257651c6e8deb27889f005aaa536164de6f379a6ef757',
  },
  autodesk: {
    appClientId: 'B2xoNGYz1fqgdQudAvwGBteynGISvxM0',
  },
  aconex: {
    appClientId: '0d71d3df-547b-49c1-87ca-fc0788ef4b73',
  },
  authorityFqdn: 'us-dev-identity.projects.sso.drawboard.com', // The domain of the OIDC authority
  clientId: 'web_client', // The client ID for this application
}

// Not working on localhost, not sure of exact cause
export const production = {
  ...sharedConfig,
  env: 'production',
  url: {
    base: 'https://projects.drawboard.com',
    apiBase: 'https://api-au.bullclip.com',
    api: 'https://api-au.bullclip.com/api',
    io: 'wss://api-au.bullclip.com',
    storage: 'https://dbprojectsusprodprod.blob.core.windows.net/',
  },
  segment: 'TRbYg2fA6Wkk657lX3UohVlrB6nb5y30',
  app_name: 'TODO',
  segment_autolink: 'drawboard.com',
  app_insights: '43c53733-1648-4f16-99f1-c6deea50a76e',
  dropboxAppKey: 'zi16vh3lllpwmlj',
  gapiClientId:
    '985770490325-qc6fvln2qa3pionjhmlonr90jso27med.apps.googleusercontent.com',
  onedriveClientId: '415aadd8-254c-40af-b36e-2cdd094d5b19',
  seqDomain: 'https://us-prod-logs.bullclip.com/',
  seqKey: 'Ym7BZS32e31BFh8C8QYw',
  procore: {
    appClientId:
      '9189c74187a9dd281fa2b9cce3e23b6fabdaaa6c2972176db58c62ae6cff5a74',
  },
  autodesk: {
    appClientId: 'B2xoNGYz1fqgdQudAvwGBteynGISvxM0',
  },
  aconex: {
    appClientId: '0d71d3df-547b-49c1-87ca-fc0788ef4b73',
  },
  authorityFqdn: 'us-dev-identity.projects.sso.drawboard.com', // The domain of the OIDC authority
  clientId: 'web_client', // The client ID for this application
}

export const devBullclip = {
  env: 'devBullclip',
  url: {
    base: 'https://dev-bullclip.drawboard.com',
    apiBase: 'https://dev-api.drawboard.com',
    api: 'https://dev-api.drawboard.com/api',
    io: 'wss://dev-api.drawboard.com',
    storage: 'https://dbprojectsusdevdev.blob.core.windows.net/',
  },
  segment: 'nWlSu0ISvcC8EpkeaKFgkzeHortsNfGJ',
  app_name: 'TODO',
  segment_autolink: 'drawboard.com',
  app_insights: '944107cb-c79c-4dbc-bb33-5bf2da16a161',
  dropboxAppKey: 'zi16vh3lllpwmlj',
  gapiClientId:
    '447996203770-r58ouje3fbo5j2h3h5mb5oj8rcu9odkj.apps.googleusercontent.com',
  onedriveClientId: 'c52dee6b-1918-441f-83b2-f7f8f824ecb8',
  seqDomain: 'https://us-dev-logs.bullclip.com',
  seqKey: 'Ptq0mzp97wCKTNpXVfcM',
  sentryPublicKey: 'f1d7cfacde5940b4908258f21a152a32',
  sentryProjectId: '5895294',
  procore: {
    appClientId:
      'e9a61f27b33f7f28caa257651c6e8deb27889f005aaa536164de6f379a6ef757',
  },
  autodesk: {
    appClientId: 'B2xoNGYz1fqgdQudAvwGBteynGISvxM0',
  },
  aconex: {
    appClientId: '0d71d3df-547b-49c1-87ca-fc0788ef4b73',
  },
  authorityFqdn: 'us-dev-identity.projects.sso.drawboard.com', // The domain of the OIDC authority
  clientId: 'web_client', // The client ID for this application
}

export default {
  development,
  test1,
  test2,
  test3,
  devBullclip,
  preprod,
}
