import * as R from 'ramda'

import hooks from '__hooks__'
import Input from '__core__/Input'
import { hookMountedRef } from '__components__/Button/Base'
import hookOnKeyFocus from '__components__/Button/hookOnKeyFocus'

const inputStyle = ({ disabled }) => [
  disabled && { web: { cursor: 'not-allowed' } },
  { web: 'outline-none' },
]

const onBlur =
  ({ setIsFocus, mounted }) =>
  () =>
    setTimeout(() => mounted.current && setIsFocus(false))

const onMouseEnter =
  ({ setIsHover, mounted }) =>
  () =>
    setTimeout(() => mounted.current && setIsHover.true())

const onMouseLeave =
  ({ mounted, setIsHover, setIsPressed }) =>
  () =>
    setTimeout(() => mounted.current && setIsHover.false())

const style = ({
  style,
  inert,
  isFocus,
  name,
  isHover,
  disabled,
  hoverStyle,
  focusStyle,
  keyFocusStyle,
}) => ({
  style: [
    style,
    !inert && isHover && hoverStyle,
    !inert && isFocus && focusStyle,
    !inert && isFocus === 'key' && keyFocusStyle,
    disabled && { web: { cursor: 'not-allowed' } },
  ],
})

export default hooks(
  hooks.boolState('isHover', 'setIsHover'),
  hooks.boolState('isFocus', 'setIsFocus'),
  hooks.tap(hookMountedRef('mounted'))(
    hookOnKeyFocus,
    hooks.assign(style),
    hooks.styles({ style: null, inputStyle }),
    hooks.decorator('onBlur', onBlur),
    hooks.decorator('onMouseEnter', onMouseEnter),
    hooks.decorator('onMouseLeave', onMouseLeave)
  ),
  R.omit([
    'isHover',
    'isFocus',
    'setIsFocus',
    'setIsHover',
    'hoverStyle',
    'focusStyle',
    'keyFocusStyle',
  ])
)(Input, 'InputBase')
