import * as R from 'ramda'
import Input from '__components__/Input'
import hooks from '__hooks__'

const getToken = R.once(() => {
  try {
    return document.querySelector(
      '[data-data] > input[name="__RequestVerificationToken"]'
    ).value
  } catch (e) {
    return ''
  }
})

const InputComponent = hooks(R.omit(['onValueChange']))('input')

const FormForgeryToken = (props) => (
  <Input.Field
    noError
    type="hidden"
    defaultValue={getToken()}
    inputComponent={InputComponent}
    name="__RequestVerificationToken"
    {...props}
  />
)

export default FormForgeryToken
