import React from 'react'
import * as R from 'ramda'
import { Provider } from 'react-redux'

import resetStyles from '__css__/reset.module.css'
import hooks from '__hooks__'
import Messages from 'Messages'
import Login from '__screens__/Login'
import LoginOrganization from '__screens__/LoginOrganization'
import Logout from '__screens__/Logout'
import Landing from '__screens__/Landing'
import Register from '__screens__/Register'
import ConfirmEmail from '__screens__/ConfirmEmail'
import ResetPassword from '__screens__/ResetPassword'
import RegisterFinal from '__screens__/RegisterFinal'
import IdentityError from '__screens__/IdentityError'
import ExternalLogin from '__screens__/ExternalLogin'
import ForgotPassword from '__screens__/ForgotPassword'
import { RootStyleProvider } from '__app__/StyleProvider'
import LicenseTransfer from '__screens__/LicenseTransfer'
import FatalAppleError from '__screens__/FatalAppleError'
import ConfirmEmailChange from '__screens__/ConfirmEmailChange'
import FatalExternalError from '__screens__/FatalExternalError'
import RegisterConfirmation from '__screens__/RegisterConfirmation'
import ExternalNameRequired from '__screens__/ExternalNameRequired'
import ResetPasswordConfirmation from '__screens__/ResetPasswordConfirmation'
import ForgotPasswordConfirmation from '__screens__/ForgotPasswordConfirmation'
import AcceptInvite from '__screens__/AcceptInvite'
import InvitationError from '__screens__/InvitationError'
import atomsStyles from '__css__/atoms/index.module.css'

// keep these otherwise the styles will not be included in the build
Object.assign(resetStyles, {})
Object.assign(atomsStyles, {})

const App = ({ store, data, screenComponent: Screen }) => (
  <Provider store={store}>
    <RootStyleProvider style={{ width: '100%', height: '100%' }}>
      {Screen && <Screen data={data} />}
      <Messages />
    </RootStyleProvider>
  </Provider>
)

// REMOVE THESE TWO
// Logout
// ResetPasswordConfirmation

const getDataElement = R.once(() => document.querySelector('[data-data]'))

const safeJsonAttribute = (value, defaultValue = {}) => {
  try {
    return (value && JSON.parse(value)) || defaultValue
  } catch (e) {
    return defaultValue
  }
}

const getData = () => {
  try {
    const attributes = getDataElement().attributes
    const attribute = (attr) =>
      attributes['data-' + attr] && attributes['data-' + attr].value
    const screen = attribute('screen')
    const data = safeJsonAttribute(attribute('data'))
    const state = safeJsonAttribute(attribute('state'))
    const allData = { ...data, state, screen }

    console.log(allData)
    return allData
  } catch (e) {
    console.error('Error retrieving server data', e)
    return {}
  }
}

const screensMap = {
  login: Login,
  loginOrganization: LoginOrganization,
  logout: Logout,
  landing: Landing,
  register: Register,
  confirmEmail: ConfirmEmail,
  resetPassword: ResetPassword,
  registerFinal: RegisterFinal,
  externalLogin: ExternalLogin,
  identityError: IdentityError,
  forgotPassword: ForgotPassword,
  licenseTransfer: LicenseTransfer,
  fatalAppleError: FatalAppleError,
  confirmEmailChange: ConfirmEmailChange,
  fatalExternalError: FatalExternalError,
  registerConfirmation: RegisterConfirmation,
  externalNameRequired: ExternalNameRequired,
  resetPasswordConfirmation: ResetPasswordConfirmation,
  forgotPasswordConfirmation: ForgotPasswordConfirmation,
  acceptInvite: AcceptInvite,
  invitationError: InvitationError,
}

export default hooks(
  hooks.memo('data', getData, []),
  hooks.assoc(
    'screenComponent',
    R.compose(R.prop(R.__, screensMap), R.path(['data', 'screen']))
  )
)(App)
