import Icon from '__components__/Icon'
import View from '__components__/View'
import {
  DefaultBackdrop,
  DefaultPanel,
  SignupBackdrop,
  SignupPanel
} from './Panels'
import Layout, { Footer, Header, LoginButton } from './Layout'

const RegisterConfirmation = ({ data: { loginUrl, product } }) => (
  <Layout asset={
    product === 'Projects'
      ? DefaultPanel
      : SignupPanel
  } backdrop={
    product === 'Projects'
      ? DefaultBackdrop
      : SignupBackdrop
  }>
    <View style="flex-flex flex-center flex-col w-full">
      <Icon icon="mail" styleLeft="text-shade-3" style={{ fontSize: 140 }} />
      <Header
        title="Verify your email"
        description={
          product === 'Projects'
            ? "To start collaborating with your team, please click on the verification link"
            : "To start marking up your PDFs, please click on the verification link sent to your email"
        }
      />
    </View>
    <Footer>
      <LoginButton text="Already verified your account? " href={loginUrl} />
    </Footer>
  </Layout>
)

export default RegisterConfirmation
