import View from '__components__/View'
import Layout from './Layout'

const InvitationError = ({ data: { errorMessage } }) => (
  <Layout>
    <View.Text style="text-center text-shade-5 text-lg tablet:text-xl laptop:mx-7 mt-5 font-semibold">
      {errorMessage}
    </View.Text>
  </Layout>
)

export default InvitationError
