import * as R from 'ramda'

import Base from './Base'
import hooks from '__hooks__'
import useTheme from '../useTheme'

const style = ({
  xs,
  sm,
  md,
  lg,
  xl,
  isBox,
  inert,
  plain,
  danger,
  primary,
  brand,
  success,
  pro,
  light,
  dark,
  carmine,
  outline,
  disabled,
  secondary,
}) => [
  {
    web: [{ cursor: disabled ? 'not-allowed' : inert ? 'default' : 'pointer' }],
  },
  outline && 'border-shade-3',
  isBox && 'border border-2 border-solid flex-center',
  isBox && !disabled && 'shadow-input',

  'rounded-md',
  (plain || outline) && 'rounded-full',
  { fontFamily: 'inherit' },

  xs && 'px-3 h-xs',
  sm && 'px-5 h-sm',
  md && 'px-5 h-md',
  lg && ['h-lg', { paddingLeft: 22, paddingRight: 22 }],
  xl && 'px-6 h-xl',

  plain && !disabled && { backgroundColor: '{darkBlue}', dark: 'bg-white' },
  plain && !disabled && pro && 'bg-yellow',
  plain && !disabled && brand && 'bg-brand',
  plain && !disabled && success && 'bg-success',
  plain && !disabled && danger && 'bg-danger',
  plain && !disabled && primary && 'bg-primary',
  plain && !disabled && dark && 'bg-dark',
  plain && !disabled && light && 'bg-light',
  plain && !disabled && carmine && 'bg-carmine',
  plain && disabled && 'bg-shade-2',

  outline && !disabled && primary && 'border-primary',
  outline && !disabled && secondary && 'border-secondary',

  isBox && !disabled && pro && 'border-yellow',
  isBox && !disabled && brand && 'border-brand',
  isBox && !disabled && success && 'border-success',
  isBox && !disabled && danger && 'border-danger',
  isBox && !disabled && primary && 'border-primary',
  isBox && !disabled && light && 'border-light',
  isBox && !disabled && dark && 'border-dark',
  isBox && !disabled && carmine && 'border-carmine',
  isBox && !disabled && secondary && 'border-secondary',
  isBox && !outline && disabled && 'border-shade-2',
  isBox && outline && disabled && ['bg-shade-2', { borderColor: '#3e4554' }],
]

const textStyle = ({
  sm,
  md,
  lg,
  xl,
  pro,
  isBox,
  disabled,
  brand,
  success,
  danger,
  primary,
  light,
  dark,
  plain,
  outline,
  carmine,
  secondary,
}) => [
  sm && 'text-md',
  md && 'text-md',
  lg && 'text-lg',
  xl && 'text-lg',

  'font-semibold text-shade-7',

  plain && !disabled && 'text-white dark:text-shade-1',
  plain && !disabled && pro && 'text-black',
  plain && !disabled && light && 'text-black',
  plain && !disabled && brand && 'text-white',
  plain && !disabled && success && 'text-white',
  plain && !disabled && danger && 'text-white',
  plain && !disabled && primary && 'text-white',
  plain && !disabled && light && 'text-white',
  plain && !disabled && dark && 'text-white',
  plain && !disabled && carmine && 'text-white',
  plain && disabled && 'text-white',

  !plain && !disabled && brand && 'text-brand',
  !plain && !disabled && success && 'text-success',
  !plain && !disabled && danger && 'text-danger',
  !plain && !disabled && light && 'text-light',
  !plain && !disabled && dark && 'text-black',
  // !plain && disabled && { color: '#3e4554' },
  !plain && disabled && 'text-shade-3',

  // !plain && !disabled && !outline && primary && 'text-brand-light',
  !plain && !disabled && primary && 'text-primary',

  isBox && 'flex-shrink-none',
  outline && !disabled && primary && 'text-primary',
  outline && !disabled && secondary && 'text-secondary',
]

const hoverStyle = ({
  isBox,
  outline,
  plain,
  disabled,
  danger,
  primary,
  brand,
  success,
  light,
  dark,
  pro,
  carmine,
  noHoverStyle,
  softHoverStyle,
}) =>
  noHoverStyle
    ? null
    : isBox
    ? !disabled && [
        brand && 'border-brand-light',
        success && 'border-success-dark',
        danger && 'border-danger-dark',
        primary && 'border-primary-light',
        light && 'border-light-light',
        dark && 'border-dark-light',
        pro && 'border-yellow-light',
        carmine && 'border-carmine-light',

        plain && brand && 'bg-brand-light',
        plain && success && 'bg-success-dark',
        plain && danger && 'bg-danger-dark',
        plain && primary && 'bg-primary-light',
        plain && light && 'bg-light-light',
        plain && dark && 'bg-dark-light',
        plain && pro && 'bg-yellow-light',
        plain && carmine && 'bg-carmine-light',

        outline && primary && { backgroundColor: '#f3f6fc' },
      ]
    : { opacity: softHoverStyle ? 0.83 : 0.75 }

const hoverTextStyle = ({
  outline,
  disabled,
  danger,
  primary,
  brand,
  success,
  light,
  dark,
  noHoverStyle,
}) =>
  noHoverStyle
    ? null
    : !disabled &&
      outline && [
        brand && 'text-brand-light',
        success && 'text-success-dark',
        danger && 'text-danger-dark',
        primary && 'text-primary-light',
        light && 'text-light-light',
        dark && 'text-dark-light',
      ]

const pressedStyle = ({
  isBox,
  disabled,
  plain,
  danger,
  primary,
  brand,
  success,
  light,
  dark,
  pro,
  carmine,
  noPressedStyle,
}) =>
  noPressedStyle
    ? null
    : !disabled && isBox
    ? [
        brand && 'border-brand-dark',
        success && 'border-success',
        danger && 'border-danger',
        primary && 'border-primary-dark',
        light && 'border-light-dark',
        dark && 'border-dark-dark',
        plain && pro && 'bg-yellow-dark',
        carmine && 'border-carmine-dark',

        plain && brand && 'bg-brand-dark',
        plain && success && 'bg-success-light',
        plain && danger && 'bg-danger-light',
        plain && primary && 'bg-primary-dark',
        plain && light && 'bg-light-dark',
        plain && dark && 'bg-dark-dark',
        plain && pro && 'bg-yellow-dark',
        plain && carmine && 'bg-carmine-dark',

        !plain && brand && 'bg-brand',
        !plain && success && 'bg-success',
        !plain && danger && 'bg-danger',
        !plain && primary && 'bg-primary-dark',
        !plain && light && 'bg-light',
        !plain && dark && 'bg-dark',
        !plain && pro && 'bg-yellow',
        !plain && carmine && 'bg-carmine',
      ]
    : { opacity: 0.5 }

const pressedTextStyle = ({
  disabled,
  danger,
  primary,
  brand,
  success,
  light,
  dark,
  outline,
  noPressedStyle,
}) =>
  disabled ||
  noPressedStyle || [
    !outline && success && 'text-success',
    !outline && danger && 'text-danger',

    outline && brand && 'text-white',
    outline && success && 'text-white',
    outline && danger && 'text-white',
    outline && primary && 'text-white',
    outline && dark && 'text-white',
    outline && light && 'text-dark',
  ]

const focusStyle = ({
  isBox,
  plain,
  outline,
  brand,
  primary,
  success,
  danger,
  disabled,
  noFocusStyle,
}) =>
  noFocusStyle
    ? null
    : !disabled &&
      isBox && [
        plain && brand && 'bg-brand-light',
        plain && success && 'bg-success-dark',
        plain && danger && 'bg-danger-dark',
        plain && primary && 'bg-primary-light',
        danger && 'border-danger-light ',
        success && 'border-success-dark',
        primary && 'border-primary-light',
      ]

const keyFocusStyle = ({
  outlineFocus,
  disabled,
  danger,
  brand,
  primary,
  success,
  outline,
}) => [
  'outline-none',
  !disabled && [
    outlineFocus ? 'shadow-outline' : 'shadow-inline',
    success &&
      (outlineFocus ? 'shadow-outline-success' : 'shadow-inline-success'),
    brand && (outlineFocus ? 'shadow-outline-brand' : 'shadow-inline-brand'),
    danger && (outlineFocus ? 'shadow-outline-danger' : 'shadow-inline-danger'),
    primary &&
      (outlineFocus ? 'shadow-outline-primary' : 'shadow-inline-primary'),
  ],
]

const focusTextStyle = ({ isBox, disabled }) => [
  !isBox && !disabled && 'underline',
]

const isBox = R.either(R.prop('plain'), R.prop('outline'))

const outlineFocus = R.ifElse(
  R.compose(R.identical(false), R.prop('outlineFocus')),
  R.F,
  R.either(R.prop('outlineFocus'), R.prop('isBox'))
)

const isLoading = ({ isLoading, loading }) => isLoading || loading

export default hooks(
  useTheme.size(),
  useTheme.color(),
  hooks.assoc('isBox', isBox),
  hooks.assoc('outlineFocus', outlineFocus),
  hooks.styles({
    style,
    textStyle,
    hoverStyle,
    focusStyle,
    pressedStyle,
    keyFocusStyle,
    focusTextStyle,
    hoverTextStyle,
    pressedTextStyle,
  }),
  hooks.consume(['loading'])(hooks.assoc('isLoading', isLoading)),
  R.omit([
    'plain',
    'isBox',
    'square',
    'outline',
    'secondary',
    'outlineFocus',
    'noPressedStyle',
    'noHoverStyle',
    'carmine',
    'softHoverStyle',
    'noFocusStyle',
  ]),
  useTheme.omit
)(Base, 'Button')
