import toString from './toString'
import fromString from './fromString'

const mapPoint =
  (map) =>
  ({ x, y, ...rest }) => ({ ...map(x, y), ...rest })

const PATH_SEGMENT_MAP = {
  M: mapPoint,
  L: mapPoint,
  A: mapPoint,
  C:
    (map) =>
    ({ x, y, x1: bx1, y1: by1, x2: bx2, y2: by2, ...rest }) => {
      const { x: x1, y: y1 } = map(bx1, by1)
      const { x: x2, y: y2 } = map(bx2, by2)
      return { x1, y1, x2, y2, ...map(x, y), ...rest }
    },
  Q:
    (map) =>
    ({ x, y, x1: bx1, y1: by1, ...rest }) => {
      const { x: x1, y: y1 } = map(bx1, by1)
      return { x1, y1, ...map(x, y), ...rest }
    },
}

export const pathMap = (path, fn, map = PATH_SEGMENT_MAP) =>
  path.map((segment) => map[segment.type](fn)(segment))

const mapValue =
  (map) =>
  ({ x, y, ...rest }) => ({ x: map(x), y: map(y), ...rest })

const PATH_SEGMENT_VALUE_MAP = {
  M: mapValue,
  L: mapValue,
  A: mapValue,
  C:
    (map) =>
    ({ x, y, x1: bx1, y1: by1, x2: bx2, y2: by2, ...rest }) => ({
      x1: map(bx1),
      y1: map(by1),
      x2: map(bx2),
      y2: map(by2),
      x: map(x),
      y: map(y),
      ...rest,
    }),
  Q:
    (map) =>
    ({ x, y, x1: bx1, y1: by1, ...rest }) => ({
      x1: map(bx1),
      y1: map(by1),
      x: map(x),
      y: map(y),
      ...rest,
    }),
}

export const pathMapValue = (path, fn, map = PATH_SEGMENT_VALUE_MAP) =>
  path.map((segment) => map[segment.type]?.(fn)(segment) || segment)

export { fromString as pathFromString }
export { toString as pathToString }
