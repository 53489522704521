import React from 'react'

import refProps from '__lib__/react/refProps'

class DialogAnchor extends React.Component {
  setRef = (ref) => {
    this.ref = ref
    this.ref && this.attach()
  }

  attach = () =>
    this.props.dialog
      ? this.props.dialog.attachAnchor(this.ref)
      : this.props.anchor.attach(this.ref)

  componentWillUnmount() {
    this.props.dialog
      ? this.props.dialog.detachAnchor()
      : this.props.anchor.detach(this.ref)
  }

  componentDidUpdate({ dialog }) {
    if (this.props.dialog === dialog) return
    this.props.dialog.detachAnchor()
    dialog.attachAnchor(this.ref)
    setTimeout(() => this.props.dialog && this.props.dialog.updatePosition())
  }

  render() {
    const {
      dialog,
      anchor,
      passDialog,
      component: Component = 'div',
      ...props
    } = this.props
    const dialogProps = passDialog ? { dialog } : null

    return (
      <Component
        {...dialogProps}
        {...props}
        {...refProps(Component, this.setRef)}
      />
    )
  }
}

export default DialogAnchor
