import View from '__components__/View'
import Form from '__core__/Form'
import Input from '__components__/Input'
import Button from '__components__/Button'
import required from 'Form/validate/required'
import FormForgeryToken from './FormForgeryToken'
import Layout, { Header } from './Layout'

const ExternalNameRequired = ({ form, returnUrl }) => (
  <Layout>
    <Header
      title="Thank you for signing up"
      description="We just need your name before we can continue"
    />

    <Form
      form={form}
      method="post"
      style="w-full max-w-10"
      action={returnUrl}
      component={View.Form}
    >
      <View style="flex flex-col mt-6">
        <Input.Field
          lg
          autoFocus
          label="First Name"
          placeholder="Jane"
          validate={required}
          name="firstName"
        />
        <Input.Field
          lg
          label="Last Name"
          placeholder="Smith"
          validate={required}
          name="surname"
        />
        <View style="flex-center my-5">
          <FormForgeryToken />
          <Form.Submit
            lg
            plain
            brand
            component={Button}
            style="rounded-full"
            text="Finish"
          />
        </View>
      </View>
    </Form>
  </Layout>
)

export default Form.withForm('form')(ExternalNameRequired)
