import Icon from '__components__/Icon'
import View from '__components__/View'
import Layout, { Footer, Header, LoginButton } from './Layout'

const ForgotPasswordConfirmation = ({ data: { loginUrl } }) => (
  <Layout>
    <View style="flex-center w-full flex-col flex-flex">
      <Icon icon="mail" styleLeft="text-shade-3" style={{ fontSize: 140 }} />
      <Header
        title="Please check your email to reset your password"
        titleStyle="text-lg"
      />
    </View>
    <Footer>
      <LoginButton text="Password already reset?" href={loginUrl} />
    </Footer>
  </Layout>
)

export default ForgotPasswordConfirmation
