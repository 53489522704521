import Logo from '@drawboard/lib-dbpdf/Logo'
import View from '__components__/View'
import StyledIcon from '__views__/StyledIcon'

const Integration = ({ integrationComponent, ...props }) => (
  <View styleLeft="flex-center" {...props}>
    <StyledIcon
      withShadow
      component={integrationComponent}
      style={{ zIndex: 1 }}
    />
    <StyledIcon component={Logo} style="-ml-5" withShadow />
  </View>
)

export default Integration
