import React from 'react'
import { compose } from 'react-recompose'
import { mapProps } from 'react-recompose'

import styles from './styles.module.css'
import propsWithRef from '__lib__/react/propsWithRef'
import withClassName from '__lib__/react/withClassName'

const Link = ({
  inert,
  onClick,
  disabled,
  withoutLinkStyling,
  noHoverEffect,
  noFocusEffect,
  opacitySection,
  activeClassName,
  noDisabledEffect,
  inactiveClassName,
  component: Component = 'button',
  ...props
}) => (
  <Component
    disabled={disabled || inert}
    onClick={disabled ? null : onClick}
    {...propsWithRef(Component, props)}
  />
)

const className = ({
  blue,
  inert,
  active,
  disabled,
  withoutLinkStyling,
  noHoverEffect,
  noFocusEffect,
  activeClassName,
  noDisabledEffect,
  inactiveClassName,
}) => [
  withoutLinkStyling || styles.root,
  inert && styles.inert,
  active && activeClassName,
  noFocusEffect || styles.focusEffect,
  noHoverEffect || styles.hoverEffect,
  active === false && inactiveClassName,
  disabled && !noDisabledEffect && styles.disabled,
]

const type = ({ type, component, ...props }) =>
  !type && !component
    ? { type: 'button', ...props }
    : { type, component, ...props }

export default compose(mapProps(type), withClassName(className))(Link)
