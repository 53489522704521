import ReactDOM from 'react-dom'

import { getState } from '__store__/store/global'
import lifecycle from '__lib__/react/lifecycle'
import { getBreakpoint } from '__store__/ui/breakpoints'

export default ({ select, noPreventScroll, delay, focusOnMobile } = {}) =>
  lifecycle({
    didMount: (_, instance) => {
      const element = ReactDOM.findDOMNode(instance)
      const focus = () => {
        element &&
          (focusOnMobile || getBreakpoint(getState()) !== 0) &&
          element.focus({ preventScroll: !noPreventScroll })
        select && element && element.select()
      }
      delay ? setTimeout(focus, delay) : focus()
    },
  })
