import * as R from 'ramda'
import lowerFirst from '__lib__/string/lowerFirst'
import upperFirst from '__lib__/string/upperFirst'

// NOTE
// when adding a new type make sure to find all typeMap|annotationTypeMap
// it will be the starting point of the new annotation type implementation
// then add it as a Canvas/Page/Annotation
// then probably a tool for it in Canvas/Page/Tool
const types = {
  ink: 1,
  ellipse: 2,
  rectangle: 4,
  line: 5,

  text: 16,
  cloud: 18,
  polygon: 20,
  polyLine: 24,
  stamp: 29,

  length: 40,
  area: 41,
  rectangleArea: 42,
  callout: 43,
  image: 44,

  documentReference: 666,

  textStrike: 667,
  textSquiggly: 668,
  textUnderline: 669,
  textHighlight: 670,

  note: 671,
  polyLength: 672,
}

const invert = R.once(() => R.map(R.head, R.invert(types)))

const pdfApiMap = {
  TextInsert: 'Text',
  PolygonArea: 'Area',
  Polyline: 'PolyLine', // fix inconsistency in UWP
}

const invertPdfApiMap = {
  text: 'textInsert',
  area: 'polygonArea',
}

export const typeFromApi = (apiType, format = import.meta.env.VITE_PROJECT) =>
  format === 'pdf'
    ? lowerFirst(pdfApiMap[apiType] || apiType)
    : invert()[apiType]

export const typeToApi = (type, format = import.meta.env.VITE_PROJECT) =>
  format === 'pdf' ? upperFirst(invertPdfApiMap[type] || type) : types[type]

export default types
