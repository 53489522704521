/**
 * @module Environment Configs
 *
 * Contains logic to load environment specific config variables.
 *
 * - Used by OctopusCD when deploying (window.customEnv)
 * - Can also be used to configure localhost environment (i.e.
 *   to switch between test1 and test2)
 *
 * @see './configs.pdf.js' Configs by environment for pdf
 * @see './configs.projects.js' Configs by environment for projects
 *
 * ## When deployed
 *
 * When deployed to a staging environment / production the write-config.sh will add a
 * `window.customEnv` variable to the global scope that will be used instead.
 * These deployment customEnv configs are set within Octopus CD.
 *
 * ## Maintenance / How to update?
 *
 * If these environment variables need to be updated you can go to the website for
 * that env, i.e. https://projects.drawboard.com/, and search for `window.customEnv`
 * in the HTML.  Copy that struct into the config.[pdf|projects].js files.
 *
 * Probably a good idea to edit the variables in OctopusCD + the `write-config.sh` script,
 * perform a deployment, and then copy the values back into this file when done to keep
 * everything in sync.
 */

import allConfigs from './configs'

const getCurrentEnvironment = () => {
  const localEnv = localStorage.getItem('env')
  if (allConfigs[localEnv]) return localEnv

  return import.meta.env.VITE_ENV || 'development'
}

const env = getCurrentEnvironment()

export const currentConfig = {
  env,
  ...allConfigs[env],
  ...window.customEnv,
}

/**
 * @param {string} name
 * @returns {string|undefined}
 */
const assertOne = (name) =>
  currentConfig[name] ||
  console.warn(
    `__config__ assert: "${name}" does not exist in the config.[pdf|projects].js or the customEnv.`
  )

/**
 * Returns the config for that variable and throws a warning if it's missing.
 * @param {string|strings[]} names
 * return {string}
 */
export const assert = (names) =>
  Array.isArray(names) ? names.map(assertOne) : assertOne(names)

export default {
  ...currentConfig,
  assert,
}
