import * as R from 'ramda'
import { v4, v5 } from 'uuid'

const uuid = R.compose(
  R.invoker(2, 'replace')(/-/g, ''),
  R.invoker(0, 'toLowerCase'),
  v4
)

export const uuidDashed = R.compose(R.invoker(0, 'toLowerCase'), v4)
export const uuidStandard = R.compose(R.invoker(0, 'toUpperCase'), v4)

const standardizeUuid = (uuid) =>
  uuid.replace(
    /^(.{8})(.{4})(.{4})(.{4})(.{12})$/,
    (_, a, b, c, d, e) => `${a}-${b}-${c}-${d}-${e}`
  )

export const uuidFromSeed = (str, seed) => uuid(v5(str, standardizeUuid(seed)))

export const UID_REGEXP_CHARS = '\\w\\d-'

export default uuid
