export const required = (errorMessage) => (value) => {
  if (typeof value === 'string' && value.trim().length === 0) {
    return errorMessage
  }

  if (Array.isArray(value) && value.length === 0) {
    return errorMessage
  }

  return !value && errorMessage
}

export const definedFy = (errorMessage) => (value) =>
  value === '' && errorMessage

// undefined form values end up being ''
export const defined = definedFy('required')

export default Object.assign(required('required'), { error: 'required' })
