export const copyToClipboard = (content) => {
  const element = window.document.createElement('textarea')
  element.value = content
  element.setAttribute('readonly', '') // preventsFocus
  element.style = { position: 'absolute', left: '-9999px' }
  document.body.appendChild(element)
  element.select()
  document.execCommand('copy')
  document.body.removeChild(element)
}

export default copyToClipboard
