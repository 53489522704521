export const TOOL_ID = {
  select: 'select',
  pan: 'pan',
  line: 'line',
  stamp: 'stamp',
  eraser: 'eraser',
  eraserSelect: 'eraserSelect',
  markupLibrary: 'markupLibrary',
  textSelect: 'textSelect',
  pinIssue: 'pinIssue',
  callout: 'callout',
  cloud: 'cloud',
  ink: 'ink',
  highlighter: 'highlighter',
  textStrike: 'textStrike',
  signature: 'signature',
  textSquiggly: 'textSquiggly',
  textHighlight: 'textHighlight',
  textUnderline: 'textUnderline',
  arrow: 'arrow',
  rectangle: 'rectangle',
  ellipse: 'ellipse',
  polygon: 'polygon',
  polyLine: 'polyLine',
  note: 'note',
  text: 'text',
  image: 'image',
  calibration: 'calibration',
  length: 'length',
  polyLength: 'polyLength',
  area: 'area',
  rectangleArea: 'rectangleArea',
  bookmark: 'bookmark',
  textCleaner: 'textCleaner',
  imgFromCamera: 'imgFromCamera',
  audio: 'audio',
  lassoSelect: 'lassoSelect',
  laserPointer: 'laserPointer',
  snapshot: 'snapshot',
  redaction: 'redaction',
  hyperlink: 'hyperlink',
  comment: 'comment',
}

export const TOOL_SORT_ORDER = [
  TOOL_ID.select,
  TOOL_ID.lassoSelect,
  TOOL_ID.textSelect,
  TOOL_ID.eraserSelect,

  TOOL_ID.ink,
  TOOL_ID.highlighter,
  TOOL_ID.laserPointer,
  TOOL_ID.eraser,

  TOOL_ID.line,
  TOOL_ID.arrow,
  TOOL_ID.polyLine,
  TOOL_ID.rectangle,
  TOOL_ID.ellipse,
  TOOL_ID.polygon,
  TOOL_ID.cloud,

  TOOL_ID.text,
  TOOL_ID.callout,
  TOOL_ID.textHighlight,
  TOOL_ID.textUnderline,
  TOOL_ID.textSquiggly,
  TOOL_ID.textStrike,
  TOOL_ID.textCleaner,

  TOOL_ID.calibration,
  TOOL_ID.length,
  TOOL_ID.polyLength,
  TOOL_ID.area,
  TOOL_ID.rectangleArea,

  TOOL_ID.note,
  TOOL_ID.image,
  TOOL_ID.bookmark,
  TOOL_ID.imgFromCamera,
  TOOL_ID.hyperlink,
  TOOL_ID.audio,
  TOOL_ID.snapshot,
  TOOL_ID.signature,
  TOOL_ID.stamp,
]

export const TOOL_GROUP = {
  select: 'select',
  pen: 'pen',
  shape: 'shape',
  text: 'text',
  measure: 'measure',
  form: 'form',
  insert: 'insert',
}

export const GROUP_SORT_ORDER = [
  TOOL_GROUP.pen,
  TOOL_GROUP.text,
  TOOL_GROUP.shape,
  TOOL_GROUP.measure,
  TOOL_GROUP.form,
  TOOL_GROUP.insert,
  TOOL_GROUP.select,
]

export const GROUP_NAME = {
  [TOOL_GROUP.pen]: 'Free-form',
  [TOOL_GROUP.shape]: 'Shape',
  [TOOL_GROUP.text]: 'Review',
  [TOOL_GROUP.measure]: 'Measure',
  [TOOL_GROUP.form]: 'Redaction',
  [TOOL_GROUP.insert]: 'Insert',
  [TOOL_GROUP.select]: 'Select',
}

export const PRE_DEFINED_TOOLS = [
  TOOL_ID.pan,
  TOOL_ID.select,
  TOOL_ID.lassoSelect,
  TOOL_ID.eraserSelect,
  TOOL_ID.textSelect,
  TOOL_ID.markupLibrary,
  TOOL_ID.comment,
]
