import React from 'react'
import { createFactory } from 'react'
import invoke from '__lib__/fn/invoke'
import { getPosition } from './positioning'

const NO_ANCHOR_ERROR =
  'You have to pass an event to dialog.open or there must be an anchor.'

export default (name, { anchor, isOpen = false } = {}) =>
  (Component) => {
    const factory = createFactory(Component)
    return class WithDialog extends React.Component {
      update = (dialog, callback, ...args) => {
        this.didUnmount ||
          this.setState({ dialog: { ...this.state.dialog, ...dialog } })
        callback && callback(this.props, ...args)
      }

      constructor(props) {
        super(props)

        const prop = (name) => this.dialog.props[name]

        const getAnchorFromProps = () =>
          anchor && this.props[anchor] && this.props[anchor].get()

        const getAnchor = (event) =>
          (prop('bodyAnchor') && document.body) ||
          getAnchorFromProps() ||
          this.anchor ||
          (event && event.target)

        const getMargin = () => {
          const marginProp = prop('margin')
          return marginProp === undefined
            ? { x: prop('marginLeft'), y: prop('marginTop') }
            : typeof marginProp === 'number'
            ? { x: marginProp, y: marginProp }
            : marginProp
        }

        const open = (event) => {
          if (!this.dialog) return
          const anchor = getAnchor(event)
          if (!anchor) return console.warn(NO_ANCHOR_ERROR)
          const position =
            prop('position') ||
            getPosition(anchor, prop('placement'), getMargin())
          // make sure the event has propagated before opening
          prop('onOpen') && prop('onOpen')(this.props)
          setTimeout(() =>
            this.update({ anchor, isHidden: false, isOpen: true, position })
          )
        }

        const anchorUpdated = (anchor) => {
          if (prop('nonSticky')) return
          const position =
            prop('position') ||
            getPosition(anchor, prop('placement'), getMargin())
          ;(position.left !== this.state.dialog.position.left ||
            position.top !== this.state.dialog.position.top) &&
            this.update({ anchor, position })
        }

        const updatePosition = () => {
          if (!this.dialog || !this.state.dialog.isOpen) return
          const anchor = getAnchor(null)
          if (!anchor) return console.warn(NO_ANCHOR_ERROR)
          anchorUpdated(anchor)
        }

        const close = (event) => {
          this.currentAnchor = null
          this.detachScroll && this.detachScroll()
          this.dialog &&
            (!prop('preventClose') ||
              !prop('preventClose')(this.props, event, this.state.dialog)) &&
            this.update(
              { isOpen: false, isHidden: false },
              prop('onClose'),
              event
            )
        }

        const attachAnchor = (anchor) => (this.anchor = anchor)

        const detachAnchor = () => attachAnchor(null)

        const attachDialog = (dialog) => {
          const hasDialog = this.dialog
          this.dialog = dialog
          this.state.dialog.instance = dialog
          !hasDialog && invoke(isOpen)(props) && setTimeout(() => open())
        }

        const toggle = (...args) =>
          this.state.dialog.isOpen ? close(...args) : open(...args)

        const hide = () => this.update({ isHidden: true })
        const show = () => this.update({ isHidden: false })

        this.state = {
          dialog: {
            open,
            hide,
            show,
            close,
            toggle,
            attachDialog,
            attachAnchor,
            detachAnchor,
            updatePosition,
            isOpen: false,
            position: null,
            isHidden: false,
          },
        }
      }

      componentWillUnmount() {
        this.didUnmount = true
        this.detachScroll && this.detachScroll()
      }

      render() {
        return factory({
          ...this.props,
          [name]: this.state.dialog,
        })
      }
    }
  }
