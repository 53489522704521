import * as R from 'ramda'
import { shortcut, deleteActions, modifiers } from '__lib__/web/shortcut'

const snapModifiers = modifiers('shift')
const lassoModifiers = modifiers('ctrl/meta')

export const back = shortcut('arrowLeft', 'shift', 'ctrl/meta')

// Tools
export const toolsShortcuts = {
  ink: shortcut('i'),
  line: shortcut('l'),
  text: shortcut('t'),
  cloud: shortcut('c'),
  arrow: shortcut('a'),
  stamp: shortcut('v'),
  image: shortcut('y'),
  eraser: shortcut('z'),
  select: shortcut('s'),
  callout: shortcut('o'),
  polygon: shortcut('p'),
  ellipse: shortcut('e'),
  rectangle: shortcut('r'),
  snap: shortcut('n', 'shift'),
  area: shortcut('p', 'shift'),
  length: shortcut('l', 'shift'),
  textSelect: shortcut('s', 'shift'),
  rectangleArea: shortcut('r', 'shift'),
}

export const pan = shortcut('space')
// export const pan = {
//   ...shortcut('space'),
//   getFormattedKeys: R.always(['hold space']),
// }

export const draw = shortcut('d')

// Draw
export const circleModifiers = modifiers('shift')
export const squareModifiers = modifiers('shift')
export const drawSnapToAngleModifiers = snapModifiers
export const eraserThick = shortcut('shift')
export const eraserLassoModifiers = lassoModifiers

// Selection
export const addToSelectionModifiers = modifiers('shift')
export const removeFromSelectionModifiers = modifiers('alt')
export const selectAllAnnotations = shortcut('a', 'ctrl/meta')
export const selectionLassoModifiers = lassoModifiers
export const selectionDelete = deleteActions

// Transformations
export const perfectShapeModifiers = modifiers('shift')
export const scalePreservingRatioModifiers = modifiers('shift')
export const scaleFromCenterModifiers = modifiers('alt')
export const transformWithProperties = shortcut('k')
export const scaleArrow = shortcut('arrowUpDown', 'ctrl/alt')
export const rotateArrow = shortcut('arrowLeftRight', 'ctrl/alt')
export const rotateSnapToAngleModifiers = modifiers('shift')
export const rotateFreeModifiers = modifiers('alt')
export const snapToggleModifiers = modifiers('ctrl/meta')
export const translateArrow = shortcut('arrows')
export const alternateSnapKeyModifiers = modifiers('alt')
export const vertexSnapToAngleModifiers = modifiers('shift')
export const arrowTransformStepModifiers = modifiers('shift')

export const drawingScroll = shortcut('mousewheel', 'ctrl')
export const drawingZoom = shortcut('mousewheel')
export const documentScroll = shortcut('mousewheel')
export const documentZoom = shortcut('mousewheel', 'ctrl')

// Viewport
export const viewportScroll = shortcut('mousewheel')
export const viewportMouseZoom = shortcut('mousewheel', 'ctrl')
export const viewportHorizontalScroll = shortcut('mousewheel', 'shift')
export const viewportPanZoom = shortcut('mousedown', 'shift')
export const viewportTranslateUp = shortcut('arrowUp')
export const viewportTranslateDown = shortcut('arrowDown')
export const viewportTranslateLeft = shortcut('arrowLeft', 'shift')
export const viewportTranslateRight = shortcut('arrowRight', 'shift')

// Misc
export const enterFullScreen = shortcut('f', 'ctrl/meta', 'shift')
export const zoomIn = shortcut('arrowUp', 'ctrl/meta')
export const zoomIn2 = shortcut('plus')
export const zoomOut = shortcut('arrowDown', 'ctrl/meta')
export const zoomOut2 = shortcut('minus')
export const zoom = shortcut('arrowUpDown', 'ctrl/meta')
export const undo = shortcut('z', 'ctrl/meta')
export const redo = shortcut('z', 'ctrl/meta', 'shift')
export const copy = shortcut('c', 'ctrl/meta')
export const cut = shortcut('x', 'ctrl/meta')
export const paste = shortcut('v', 'ctrl/meta')
export const pasteInPlace = shortcut('v', 'ctrl/meta', 'shift')
export const favorites = shortcut('0-9')
export const search = shortcut('f', 'ctrl/meta')
export const navigateToPrevTab = shortcut(
  'open bracket',
  'shift/ctrl',
  'alt/meta'
)
export const navigateToNextTab = shortcut(
  'close bracket',
  'shift/ctrl',
  'alt/meta'
)
export const goToPage = shortcut('g', 'shift')

export const navigateToFirstPage = shortcut('home')
export const navigateToFirstPage2 = shortcut('arrowUp', 'meta')
export const navigateToLastPage = shortcut('end')
export const navigateToLastPage2 = shortcut('arrowDown', 'meta')
export const navigateToPrevPage = shortcut('arrowUp', 'shift')
export const navigateToNextPage = shortcut('arrowDown', 'shift')
export const navigateToPrevPage3 = shortcut('arrowLeft')
export const navigateToNextPage3 = shortcut('arrowRight')
export const navigateToPrevPage2 = shortcut('pageUp')
export const navigateToNextPage2 = shortcut('pageDown')
export const navigatePages = shortcut('arrowUpDown', 'shift')
export const navigateToPrevFile = shortcut('arrowLeft', 'shift')
export const navigateToNextFile = shortcut('arrowRight', 'shift')
export const navigateFiles = shortcut('arrowLeftRight', 'shift')
