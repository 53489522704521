import Button from '__components__/Button'
import Layout, { Header } from './Layout'

const Content = ({ title, description, children }) => (
  <Layout>
    <Header title={title} description={description} titleStyle="text-xl" />
    {children}
  </Layout>
)

const FALLBACK_URL = 'https://pdf.drawboard.com'
const FALLBACK_URL_PROJECTS = 'https://projects.drawboard.com'

const ConfirmEmail = ({ data }) =>
  data.succeeded ? (
    <Content title="Thank you for confirming your email">
      <Button
        lg
        plain
        secondary
        component="a"
        style="rounded-full font-semibold text-white mt-5"
        href={data.redirectUrl || (data.product === 'Projects' ? FALLBACK_URL_PROJECTS : FALLBACK_URL)}
      >
        Return to {data.product === 'Projects' ? 'Drawboard Projects' : 'Drawboard PDF'}
      </Button>
    </Content>
  ) : (
    <Content
      title="Email confirmation has expired"
      description={`Please try to login and use "Resend email verification" link to receive another verification email`}
    />
  )

export default ConfirmEmail
