import * as R from 'ramda'
import React from 'react'
import hooks from '__hooks__'

// the component could be used to fit anywhere as long as it is
// provided an outer box, for now the window is sufficient
const getOuterBox = (node) =>
  node
    ? node.getBoundingClientRect()
    : {
        top: 0,
        left: 0,
        right: window.innerWidth,
        bottom: window.innerHeight,
        width: window.innerWidth,
        height: window.innerHeight,
      }

const getInnerBox = (node) => {
  // translate box the innerBox with our already applied translation
  const x = node.screenBoundariesMargin?.x || 0
  const y = node.screenBoundariesMargin?.y || 0
  const { top, left, right, bottom, width, height } =
    node.transitionTargetBoundingClientRect || node.getBoundingClientRect()

  return {
    width,
    height,
    top: top - y,
    left: left - x,
    right: right - x,
    bottom: bottom - y,
  }
}

const computeAxis = (iLength, iMin, iMax, oLength, oMin, oMax, margin) =>
  iLength > oLength
    ? oMin - iMin
    : iLength > oLength - margin
      ? oMin - iMin + margin
      : iMin < oMin + margin
        ? oMin - iMin + margin
        : iMax > oMax - margin
          ? oMax - iMax - margin
          : 0

export const computeYAxis = (
  { height, top, bottom },
  { height: oHeight, top: oTop, bottom: oBottom },
  margin
) => computeAxis(height, top, bottom, oHeight, oTop, oBottom, margin)

export const computeXAxis = (
  { width, left, right },
  { width: oWidth, left: oLeft, right: oRight },
  margin
) => computeAxis(width, left, right, oWidth, oLeft, oRight, margin)

export const getDelta = (innerBox, outerBox, margin = 0) => {
  const top = computeYAxis(innerBox, outerBox, margin)
  const left = computeXAxis(innerBox, outerBox, margin)
  return { top, left }
}

const update = ({
  onScreenUpdate = R.identity,
  margin = 0,
  ref,
  outerRef,
  nodeRef,
  parent,
}) => {
  const node =
    nodeRef?.current || (parent && ref?.current) || ref?.current.parentNode
  if (!node) return false
  const outerNode = outerRef?.current || (parent && node.parentNode)
  const outerBox = getOuterBox(outerNode)
  const innerBox = getInnerBox(node)
  const { left, top } = getDelta(innerBox, outerBox, margin)
  const { left: x, top: y } = onScreenUpdate({ left, top })
  node.screenBoundariesMargin = { x, y }
  Object.assign(node.style, { marginLeft: `${x}px`, marginTop: `${y}px` })
  return Boolean(left || top)
}

const onResize = (props, propsRef) => {
  const hasUpdate = update(props)
  if (hasUpdate) {
    const { ref, nodeRef } = props
    const node = nodeRef?.current || ref?.current
    const opacity = node.style.opacity
    node.style.opacity = 0
    setTimeout(() => (node.style.opacity = opacity))
  }
  const onResize = () => setTimeout(() => update(propsRef.current), 300)
  window.addEventListener('resize', onResize)
  return () => window.removeEventListener('resize', onResize)
}

const onUpdate = ({ ref }, propsRef) => {
  if (!ref.current) return
  if (ref.current.boundariesUpdateFrame) return
  let frame = null
  frame = requestAnimationFrame(() => {
    ref.current.boundariesUpdateFrame = null
    update(propsRef.current)
  })
  ref.current.boundariesUpdateFrame = frame
  return () => cancelAnimationFrame(frame)
}

export default hooks.component(
  hooks.bubbleRef('ref'),
  hooks.consume([
    'onScreenUpdate',
    'updateOnChange',
    'nodeRef',
    'margin',
    'parent',
    'outerRef',
  ])(
    hooks.layoutEffect(onResize, []),
    R.when(
      R.prop('updateOnChange'),
      hooks.layoutEffect(onUpdate, ['updateOnChange'])
    ),
    R.when(
      R.prop('updateOnChanges'),
      hooks.layoutEffect(onUpdate, R.prop('updateOnChanges'))
    )
  )
)('ScreenBoundaries')
