import React from 'react'

import Link from './Link'
import Toggle from 'Dialog/Toggle'
import Confirm from 'Dialog/Confirm'
import withDialog from 'Dialog/withDialog'

const ConfirmLink = ({
  title,
  children,
  onCancel,
  onConfirm,
  placement,
  cancelText,
  confirmText,
  dialogProps,
  confirmButton,
  confirmDialog,
  dialogClassName,
  component = Link,
  ...props
}) => (
  <Toggle dialog={confirmDialog} isAnchor component={component} {...props}>
    {children}
    <Confirm
      withArrow
      title={title}
      onCancel={onCancel}
      onConfirm={onConfirm}
      dialog={confirmDialog}
      cancelText={cancelText}
      confirmText={confirmText}
      className={dialogClassName}
      confirmButton={confirmButton}
      placement={placement || 'top'}
      {...dialogProps}
    />
  </Toggle>
)

export default withDialog('confirmDialog')(ConfirmLink)
