import * as R from 'ramda'

const queryPart = (key, value) =>
  value === undefined
    ? ''
    : value === null
    ? key
    : Array.isArray(value)
    ? value.map((val) => key + '=' + encodeURIComponent(val)).join('&')
    : key + '=' + encodeURIComponent(value)

export const buildQuery = (query) =>
  typeof query === 'string'
    ? query
    : R.reject(
        R.identical(''),
        Object.keys(query).map((key) => queryPart(key, query[key]))
      ).join('&')

export const appendQueryParams = (href = '', params = {}) => {
  const url = new URL(href)
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  )
  return url.toString()
}

const buildPath = (route, params, isRoot) =>
  isRoot
    ? route
    : route.replace(/:[^/]+/g, (name) => {
        const param = name.match(/:(\w+)(.*)?/)[1]
        return params[param]
      })

const buildAncestorsPath = (parent, params) =>
  parent
    ? buildAncestorsPath(parent.parent, params) +
      (parent.isRoot ? '' : '/') +
      buildPath(
        parent.subPath,
        { ...parent.partialParams, ...params },
        parent.isRoot
      )
    : ''

const path = (subPath, params, parent, isRoot) =>
  isRoot
    ? buildPath(subPath, params, isRoot)
    : buildAncestorsPath(parent, params) +
      '/' +
      buildPath(subPath, params, isRoot)

export const hash = (query) => (query ? '#' + buildQuery(query) : '')
export const query = (query) => (query ? '?' + buildQuery(query) : '')

const getAbsoluteRoute = (subPath, params, parent, isRoot) =>
  (parent
    ? getAbsoluteRoute(parent.subPath, params, parent.parent, parent.isRoot)
    : '') +
  (isRoot ? '' : '/') +
  (params && subPath && subPath.replace
    ? subPath.replace(/:[^/]+/g, (name) => {
        const param = name.match(/:(\w+)(.*)?/)[1]
        return params[param] || name
      })
    : subPath)

const partialPath = (partialParams) => (subPath, parent, isRoot) =>
  Object.assign(
    (params, searchQuery, hashQuery) =>
      path(subPath, { ...partialParams, ...params }, parent, isRoot) +
      query(searchQuery) +
      hash(hashQuery),
    {
      parent,
      isRoot,
      subPath,
      partialParams,
      url: (params, searchQuery, hashQuery) =>
        window.location.origin +
        path(subPath, { ...partialParams, ...params }, parent, isRoot) +
        query(searchQuery) +
        hash(hashQuery),
      partial: (newPartialParams) =>
        partialPath({ ...partialParams, ...newPartialParams })(
          subPath,
          parent,
          isRoot
        ),
      route: (params) =>
        getAbsoluteRoute(
          subPath,
          { ...partialParams, ...params },
          parent,
          isRoot
        ),
    }
  )

export default partialPath({})

export const getUrlFromPath = (url) => {
  const queryIndex = url.indexOf('?')
  const hashIndex = url.indexOf('#')
  return url.slice(
    0,
    queryIndex === -1 && hashIndex === -1
      ? undefined
      : queryIndex === -1
      ? hashIndex
      : queryIndex
  )
}

export const urlMatchesRoute = (path, route) => {
  const routeParts = route.split('/')
  const pathnameParts = getUrlFromPath(path).split('/')
  return (
    pathnameParts.length === routeParts.length &&
    routeParts.every(
      (part, index) => part.startsWith(':') || pathnameParts[index] === part
    )
  )
}

export const mapQuery =
  (map) =>
  (path) =>
  (params, query, ...args) =>
    path(params, map(query, params, ...args), ...args)

export const mapParams =
  (map) =>
  (path) =>
  (params, ...args) =>
    path(map(params, ...args), ...args)
