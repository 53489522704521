import Central from '__store__/central'
import { fpToggleUI, setUIIntent } from '__store__/ui/lib'

const KEY = 'navOpen'
const setNavIntent = setUIIntent.bind(null, KEY)
const openNavIntent = setNavIntent.bind(null, true)
const closeNavIntent = setNavIntent.bind(null, false)

Central.intent('navOpen', openNavIntent)
Central.intent('navClose', closeNavIntent)
Central.intent('navSet', setNavIntent)
Central.pair('navToggle', null, fpToggleUI(KEY))
