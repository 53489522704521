import { compose } from 'react-recompose'
import { defaultProps } from 'react-recompose'

import Dialog from '__core__/Dialog'
import assign from '__lib__/object/assign'
import { withStyleProps } from 'react-with-style'

const zIndex = 1000

// Dialog.zIndex is being used to render the backdrop for the dialog
export default compose(
  assign({ zIndex }),
  assign(Dialog),
  defaultProps({ screenMargin: 8, zIndex }),
  withStyleProps(({ zIndex }) => ({
    style: 'relative',
    containerStyle: { zIndex },
  }))
)(Dialog)
