import React from 'react'
import * as R from 'ramda'
import ReactDOM from 'react-dom'
import { withProps } from 'react-recompose'

import invoke from '__lib__/fn/invoke'
import assign from '__lib__/object/assign'
import * as transitions from './transitions'

const getTransitionEvent = R.once(() => {
  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  }
  const el = document.createElement('fakeElement')
  const match = Object.keys(transitions).find((t) => el.style[t] !== undefined)
  return match ? transitions[match] : ''
})

const eventIsNotRelated = ({ target, propertyName }, node, changesLayout) =>
  (!changesLayout &&
    propertyName !== 'transform' &&
    propertyName !== 'opacity') ||
  target !== node

const getTransitionDuration = (node) => {
  const style = getComputedStyle(node)
  const n = (value) => 1000 * (parseFloat(value) || 0)
  return n(style.transitionDuration) + n(style.transitionDelay)
}

const listenTransitionEnd = (node, changesLayout, callback) => {
  let hasCaughtEvent = false
  const eventName = getTransitionEvent()
  const theoreticalTransitionEnd = getTransitionDuration(node)
  const action = () => {
    if (hasCaughtEvent) return
    hasCaughtEvent = true
    callback()
    cleanup()
  }
  const transitionEndListener = (event) => {
    if (eventIsNotRelated(event, node, changesLayout)) return
    action()
  }
  node.addEventListener(getTransitionEvent(), transitionEndListener)
  // const safetyNetTimeout = null
  const safetyNetTimeout = setTimeout(action, theoreticalTransitionEnd + 100)
  const cleanup = () => {
    node.removeEventListener(eventName, transitionEndListener)
    clearTimeout(safetyNetTimeout)
  }
}

const phaseEvents = {
  enter: 'onEnter',
  leave: 'onLeave',
  'enter-active': 'onEnterActive',
  'leave-active': 'onLeaveActive',
}

const transit = (node, transition, phase, options, cancel, _backStyle) => {
  const style = transition[phase]
  const backStyle =
    _backStyle || R.mapObjIndexed((_, name) => node.style[name], style)
  const transform =
    (backStyle.transform || style.transform) &&
    backStyle.transform + ' ' + style.transform
  Object.assign(node.style, style, { transform })
  options[phaseEvents[phase]] && options[phaseEvents[phase]](node, { cancel })
  node.getBoundingClientRect() // forces the style to be applied to the node
  return backStyle
}

const applyTransition = (node, transition, phase, options, propsRef, then) => {
  if (!node || !node.classList) return
  let hasFinished = false
  let backStyle = null
  const onFinish = () => {
    if (hasFinished) return
    const nodeStyle = propsRef.props.nodeStyle
      ? R.pick(R.keys(backStyle), propsRef.props.nodeStyle)
      : backStyle
    nodeStyle && Object.assign(node.style, nodeStyle)
    node.transitionTargetBoundingClientRect = null
    hasFinished = true
    then && then()
  }
  node.transitionTargetBoundingClientRect = node.getBoundingClientRect()
  backStyle = transit(node, transition, phase, options, onFinish)
  setTimeout(() => {
    const nodeStyle = propsRef.props.nodeStyle
      ? R.pick(R.keys(backStyle), propsRef.props.nodeStyle)
      : backStyle
    transit(node, transition, phase + 'Active', options, onFinish, nodeStyle)
    listenTransitionEnd(node, options.changesLayout, onFinish)
  })
  return onFinish
}

const getScrolledNodes = (node) => node.querySelectorAll('div')

const updateScroll = (node, clone) =>
  clone &&
  ((clone.scrollTop = node.scrollTop), (clone.scrollLeft = node.scrollLeft))

const fixScroll = (node, clone) => {
  const cloneScrolledNodes = getScrolledNodes(clone)
  Array.prototype.forEach.call(getScrolledNodes(node), (node, index) =>
    updateScroll(node, cloneScrolledNodes[index])
  )
}

const replaceNode = (node) => {
  if (!node.parentNode) return null
  const clone = node.cloneNode(true)
  // Allows children to use CSS selector to disable animations
  // @see Collapsible animations
  clone.setAttribute('data-transition-clone', '')
  node.transitionClone = clone
  node.parentNode.insertBefore(clone, node)
  fixScroll(node, clone)
  const prevent = (event) => event.preventDefault()
  clone.addEventListener('mousedown', prevent)
  clone.addEventListener('touchstart', prevent)
  clone.addEventListener('keydown', prevent)
  clone.addEventListener('wheel', prevent)
  node.style.display = 'none'
  return clone
}

class Transition extends React.Component {
  getTransition = (phase) => {
    const { props } = this
    return !props.noName
      ? (props.transition && props.transition(phase, props)) ||
          transitions[invoke(props[phase + 'Name'])(props, this, phase)] ||
          invoke(props.custom)(props, this, phase) ||
          transitions[invoke(props.name)(props, this, phase)] ||
          transitions['fade']
      : null
  }

  enter = () => {
    const { props } = this
    this.node = ReactDOM.findDOMNode(this)
    const disabled = invoke(props.disabled)(props, this.node)
    if (props.noEnter || disabled || !this.node) return
    const run = () =>
      applyTransition(
        this.node,
        this.getTransition('enter'),
        'enter',
        props,
        this,
        props.onEnterDone && props.onEnterDone
      )
    if (!this.props.delay) return run()
    this.node.style.opacity = 0
    this.delayEnter = setTimeout(() => {
      this.node.style.opacity = ''
      run()
    }, this.props.delay)
  }

  runEnter = () => {
    this.node.style.display = ''
    this.attachNodeBack && this.attachNodeBack()
    this.enter()
  }

  leave = () => {
    const { props } = this
    const disabled = invoke(props.disabled)(props, this.node)
    if (props.noLeave || disabled || !this.node) return
    this.node = replaceNode(this.node)
    if (!this.node) return
    const onDone = () => {
      this.node.parentNode && this.node.parentNode.removeChild(this.node)
      props.onLeaveDone && props.onLeaveDone()
    }
    applyTransition(
      this.node,
      this.getTransition('leave'),
      'leave',
      props,
      this,
      onDone
    )
  }

  runLeave = () => {
    const { props } = this
    if (props.noLeave || !this.node) return
    const nodeBeforeLeaving = this.node
    const parentNode = this.node.parentNode
    this.node = replaceNode(this.node)
    this.attachNodeBack = () => parentNode.appendChild(nodeBeforeLeaving)
    this.node.remove()
    this.attachNodeBack = null
    if (!this.node) return
    const onDone = () => {
      this.node.parentNode && this.node.parentNode.removeChild(this.node)
      this.node = nodeBeforeLeaving
      props.onLeaveDone && props.onLeaveDone()
    }
    applyTransition(
      this.node,
      this.getTransition('leave'),
      'leave',
      props,
      onDone
    )
  }

  componentDidMount() {
    this.enter()
  }

  componentWillUnmount() {
    this.leave()
    this.delayEnter && clearTimeout(this.delayEnter)
  }

  UNSAFE_componentWillUpdate(props) {
    if (
      this.props.onPropChange &&
      this.props.onPropChange !== props.onPropChange
    ) {
      this.leave()
      this.updateLeave = true
    }
  }

  componentDidUpdate() {
    if (this.updateLeave) {
      setTimeout(() => this.enter())
      this.updateLeave = false
    }
  }

  render() {
    const {
      wrap,
      name,
      delay,
      custom,
      duration,
      noEnter,
      noLeave,
      onClone,
      onEnter,
      onLeave,
      children,
      disabled,
      enterName,
      leaveName,
      transition,
      onEnterDone,
      onLeaveDone,
      nodeStyle,
      component: Component = React.Fragment,
      ...props
    } = this.props
    return <Component {...props}>{children}</Component>
  }
}

const Fade = withProps({ name: 'fade' })(Transition)
const FadeIn = withProps({ noLeave: true })(Fade)

export default assign({ Fade, FadeIn })(Transition)
