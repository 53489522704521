import * as R from 'ramda'
import React, { createFactory } from 'react'

export default (handlers) => (Component) => {
  const factory = createFactory(Component)
  return class WithHandlers extends React.Component {
    constructor(props) {
      super(props)
      this.handlers = R.map(
        (handler) =>
          (...args) =>
            handler(this.props, this, this.handlers)(...args),
        handlers
      )
    }
    render() {
      return factory({ ...this.props, ...this.handlers })
    }
  }
}
