const GoogleDriveIcon = ({ fill, isDarkMode, status = 'default', ...props }) =>
  status === 'default' ? (
    <svg
      width="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.20962 13.1042L1.91523 14.323C2.06186 14.5796 2.27262 14.7812 2.52005 14.9278L5.04009 10.5659H0C0 10.8499 0.0733104 11.134 0.219931 11.3906L1.20962 13.1042Z"
        fill="#0066DA"
      />
      <path
        d="M8 5.43414L5.47995 1.07217C5.23253 1.21879 5.02176 1.42039 4.87514 1.67698L0.219931 9.74112C0.076007 9.99218 0.000191627 10.2765 0 10.5659H5.04009L8 5.43414Z"
        fill="#00AC47"
      />
      <path
        d="M13.48 14.9278C13.7274 14.7812 13.9381 14.5796 14.0848 14.323L14.378 13.819L15.7801 11.3906C15.9267 11.134 16 10.8499 16 10.5659H10.9595L12.0321 12.6735L13.48 14.9278Z"
        fill="#EA4335"
      />
      <path
        d="M8 5.43413L10.52 1.07217C10.2726 0.925544 9.98854 0.852234 9.6953 0.852234H6.30469C6.01145 0.852234 5.72737 0.934708 5.47995 1.07217L8 5.43413Z"
        fill="#00832D"
      />
      <path
        d="M10.9599 10.5659H5.04009L2.52004 14.9278C2.76747 15.0745 3.05154 15.1478 3.34478 15.1478H12.6552C12.9484 15.1478 13.2325 15.0653 13.48 14.9278L10.9599 10.5659Z"
        fill="#2684FC"
      />
      <path
        d="M13.4525 5.70905L11.1248 1.67698C10.9782 1.42039 10.7675 1.21879 10.52 1.07217L7.99999 5.43414L10.9599 10.5659H15.9908C15.9908 10.2818 15.9175 9.99771 15.7709 9.74112L13.4525 5.70905Z"
        fill="#FFBA00"
      />
    </svg>
  ) : (
    <svg
      width="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 9.68758H11.0036L5.79901 0.964691H10.7954L16 9.68758Z"
        fill={
          status === 'active'
            ? '#ffffff'
            : status === 'inactive'
            ? isDarkMode
              ? '#3E4554'
              : '#CACBCE'
            : fill
        }
      />
      <path
        d="M3.30106 15.0353L5.79926 10.7077L15.956 10.562L13.4578 14.8896L3.30106 15.0353Z"
        fill={
          status === 'active'
            ? '#ffffff'
            : status === 'inactive'
            ? isDarkMode
              ? '#3E4554'
              : '#CACBCE'
            : fill
        }
      />
      <path
        d="M2.4982 14.7113L0 10.3837L4.95216 1.51508L7.45037 5.84269L2.4982 14.7113Z"
        fill={
          status === 'active'
            ? '#ffffff'
            : status === 'inactive'
            ? isDarkMode
              ? '#3E4554'
              : '#CACBCE'
            : fill
        }
      />
    </svg>
  )

export default GoogleDriveIcon
