import SharePointDefaultIcon from './SharePoint.svg?react'
import SharePointAltIcon from './SharePointAlt.svg?react'

const SharePointIcon = ({ isDarkMode, status = 'default', ...props }) =>
  status === 'default' ? (
    <SharePointDefaultIcon {...props} />
  ) : (
    <SharePointAltIcon opacity={status === 'inactive' ? 0.5 : 1} {...props} />
  )

export default SharePointIcon
