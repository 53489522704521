import hooks from '__hooks__'
import View from '__components__/View'
import Button from '__components__/Button'
import Layout, { Header } from './Layout'

const Logout = ({ data: { postLogoutRedirectUri } }) => (
  <Layout>
    <Header
      title="Logged out"
      description={
        <View.Text>
          You are being redirected, if nothing happens,{' '}
          <Button
            primary
            component="a"
            text="click here"
            href={postLogoutRedirectUri}
          />
        </View.Text>
      }
    />
  </Layout>
)

const onRedirect = ({ data: { postLogoutRedirectUri } }) =>
  postLogoutRedirectUri && (window.location = postLogoutRedirectUri)

export default hooks(hooks.tapEffect(onRedirect))(Logout)
