import Dialog from './Dialog'
import Anchor from './Anchor'
import Toggle from './Toggle'
// import Native from './Native'
import Provider from './Provider'
import RootProvider from './RootProvider'
import withDialog from './withDialog'
import withAnchor from './withAnchor'
import assign from '__lib__/object/assign'

export default assign({
  Anchor,
  Toggle,
  Provider,
  RootProvider,
  withDialog,
  withAnchor,
  // Native,
})(Dialog)
