import * as R from 'ramda'

import tool from './'
import lazy from '__lib__/utils/lazy'
import palette from '__canvas__/color/palette'
import trackSegment from '__lib__/telemetry/segment/track'

export const propertiesList = lazy(() => [
  'fill',
  'type',
  'zOrder',
  'stroke',
  'scaleId',
  'content',
  'endStyle',
  'blendMode',
  'linkStyle',
  'strokeJoin',
  'startStyle',
  'strokeWidth',
  'strokeStyle',
  'fillOpacity',
  'borderStyle',
  'strokeOpacity',
])

export const properties = (tool) => R.pick(propertiesList())(tool)
export const annotationProperties = properties

export const updateAnnotation = (tool, annotation) =>
  tool.name === 'signature'
    ? annotation
    : { ...annotation, ...annotationProperties(tool) }

export const propertiesFromAnnotation = properties

export default (name, properties) =>
  tool(name, {
    type: name,

    ...(import.meta.env.VITE_PROJECT === 'pdf' || {
      endStyle: 0,
      startStyle: 0,
    }),

    scaleId: null,
    strokeWidth: 1,
    strokeStyle: 0,
    fillOpacity: 0,
    segmentName: name,
    strokeOpacity: 1,
    category: properties.group,
    fill: palette.redOne,
    isForAnnotation: true,
    stroke: palette.redOne,
    shortVisibleName: properties.visibleName || name,
    ...properties,
  })
