import React from 'react'
import * as R from 'ramda'
import ReactDOM from 'react-dom'
import { branch } from 'react-recompose'
import { compose } from 'react-recompose'
import { withHandlers } from 'react-recompose'
import { renderNothing } from 'react-recompose'
import { withPropsOnChange } from 'react-recompose'

import Arrow from './Arrow'
import hooks from '__hooks__'
import Backdrop from '../Backdrop'
import Transition from '../Transition'
import FocusTrap from '__core__/FocusTrap'
import { hookDialogContext } from './Provider'
import { DialogContext } from './RootProvider'
import { getTranslate } from './positioning'
import lifecycle from '__lib__/react/lifecycle'
import { withStyleProps } from 'react-with-style'
import ScreenBoundaries from '../ScreenBoundaries'
import onPropsChange from '__lib__/react/onPropsChange'
import withContext, { withChildContext } from './withContext'
import stopPropagation from '__canvas__/event/stopPropagation'
import withActionOnChange from '__lib__/react/withActionOnChange'
import { useEventEffect, useEventOutEffect } from '__core__/EventProvider'
import withHooks from '__lib__/react/withHooks'

const useOnKeyDown = ({ dialog, dialogInstance, withoutEscape }) =>
  useEventEffect(
    'onKeyDown',
    (event) =>
      !withoutEscape &&
      event.key === 'Escape' &&
      (!dialogInstance.childDialogs ||
        !dialogInstance.childDialogs.find(
          R.view(R.lensPath(['props', 'dialog', 'isOpen']))
        )) &&
      dialog.close({ escape: true })
  )

const useEventOutEffects = ({
  dialog,
  onClickOut,
  withoutClickout,
  withoutScrollout,
  dialogInstance,
}) => {
  const onEvent = (event) =>
    isNodeFromChildren(event.target, dialog.instance.childDialogs) ||
    (onClickOut
      ? onClickOut(event) && dialog.close(event)
      : dialog.close(event))
  const onScroll = (event) =>
    withoutClickout ||
    withoutScrollout ||
    onEvent(Object.assign(event, { isScrollOut: true }))
  const onClick = (event) =>
    withoutClickout || onEvent(Object.assign(event, { isClickOut: true }))
  useEventOutEffect('onClick', onClick, () => dialogInstance.transition.node)
  useEventOutEffect('onScroll', onScroll, () => dialogInstance.transition.node)
}

const pointerIsolatedProps = {
  onMouseUp: stopPropagation,
  onMouseMove: stopPropagation,
  onMouseDown: stopPropagation,
  onTouchEnd: stopPropagation,
  onTouchMove: stopPropagation,
  onTouchStart: stopPropagation,
}

const Content = ({
  style,
  margin,
  dialog,
  onOpen,
  onClose,
  nodeRef,
  children,
  setFocus,
  withArrow,
  placement,
  marginTop,
  marginLeft,
  onClickOut,
  arrowStyle,
  arrowSize,
  notIsolated,
  noAutoFocus,
  mouseAnchor,
  screenMargin,
  parentDialog,
  preventClose,
  withBackdrop,
  dynamicWidth,
  transitionFn,
  fallbackWidth,
  staticContext,
  dynamicHeight,
  setTransition,
  withoutEscape,
  noStayOnScreen,
  containerStyle,
  dialogInstance,
  withoutClickout,
  withoutScrollout,
  wrapperClassName,
  transitionProps,
  withoutBackdropMouseEvents,
  transition = 'slideFadeTopSmall',
  component: Component = ScreenBoundaries,
  containerComponent: ContainerComponent = 'div',
  ...props
}) => {
  const onKeyDown = useOnKeyDown({ dialog, dialogInstance, withoutEscape })
  useEventOutEffects({
    withoutClickout,
    onClickOut,
    withoutScrollout,
    dialogInstance,
    dialog,
  })
  return (
    <Transition
      delay={1}
      name={transition}
      ref={setTransition}
      transition={transitionFn}
      disabled={transition === 'none'}
      {...transitionProps}
    >
      <FocusTrap
        data-dialog
        ref={nodeRef}
        onKeyDown={onKeyDown}
        style={containerStyle}
        noAutoFocus={noAutoFocus}
        {...(notIsolated || pointerIsolatedProps)}
      >
        <Component
          style={style}
          component="div"
          nodeRef={nodeRef}
          margin={screenMargin || 0}
          // Needed to allow use of new tailwind classes with vars defined per view mode
          className="light"
          {...props}
        >
          {withBackdrop && (
            <Backdrop.Fragment
              withoutMouseEvents={withoutBackdropMouseEvents}
            />
          )}
          {withArrow ? (
            <Arrow
              style={style}
              size={arrowSize}
              placement={placement}
              arrowStyle={arrowStyle}
            >
              {children}
            </Arrow>
          ) : (
            children
          )}
        </Component>
      </FocusTrap>
    </Transition>
  )
}

const Dialog = ({
  top,
  left,
  zIndex,
  context,
  position,
  noBorder,
  bodyAnchor,
  dynamicWidth,
  withHiddenParent,
  containerComponent = 'section',
  ...props
}) =>
  ReactDOM.createPortal(
    <Content containerComponent={containerComponent} {...props} />,
    context.current || window.document.body
  )

const identity = (id) => id

const numberDynamicDimension = (number) => (value) => value + number

const mapDynamicDimension = (value) =>
  !value
    ? undefined
    : value === true
      ? identity
      : typeof value === 'number'
        ? numberDynamicDimension(value)
        : value

const dimension = (dim, dimensionFn, anchor, fallback) =>
  dimensionFn
    ? mapDynamicDimension(dimensionFn)(
        anchor.getBoundingClientRect()[dim] || fallback || 0
      )
    : undefined

const containerStyle = ({
  top,
  left,
  zIndex,
  placement,
  dynamicWidth,
  screenMargin,
  dynamicHeight,
  containerStyle,
  fallbackWidth = 0,
  dialog: { position, anchor },
}) =>
  position && {
    position: 'absolute',
    zIndex: zIndex || 42,
    top: top || position.top || 'auto',
    left: left || position.left,
    transform: getTranslate(placement),
    maxWidth: `calc(100vw - ${2 * screenMargin || 0}px)`,
    maxHeight: `calc(100vh - ${2 * screenMargin || 0}px)`,
    height: dimension('height', dynamicHeight, anchor, 0),
    width: dimension('width', dynamicWidth, anchor, fallbackWidth),
    ...containerStyle,
  }

const isNotOpen = ({ dialog: { isOpen } }) => !isOpen

const didMount = (
  { openOnMount, parentDialog, dialog: { attachDialog, open } },
  instance
) => {
  attachDialog(instance)
  openOnMount && setTimeout(open)
  parentDialog && parentDialog.registerChildDialog(instance)
}

const isNodeFromChild = (target) => (child) => {
  const node =
    (child.transition && child.transition.node) || ReactDOM.findDOMNode(child)
  return (
    (node && node.contains(target)) ||
    isNodeFromChildren(target, child.childDialogs)
  )
}

const isNodeFromChildren = (target, childDialogs) =>
  childDialogs && childDialogs.some(isNodeFromChild(target))

const registerChildDialog = (_props, instance) => (dialog) =>
  (instance.childDialogs = R.concat([dialog], instance.childDialogs || []))

const hasVisibilityChanged = (
  { dialog: { isHidden, isOpen } },
  { dialog: { isHidden: prevIsHidden, isOpen: prevIsOpen } = {} }
) => isHidden !== prevIsHidden && isOpen === prevIsOpen

const unregisterChildDialog = (_props, instance) => (dialog) =>
  (instance.childDialogs = R.without([dialog], instance.childDialogs || []))

const willUnmount = ({ parentDialog }, instance) =>
  parentDialog && parentDialog.unregisterChildDialog(instance)

const setTransition =
  ({ dialogInstance }) =>
  (ref) =>
    ref && (dialogInstance.transition = ref)

const setFocus =
  ({ dialogInstance }) =>
  (ref) =>
    (dialogInstance.focus = ref)

const onVisibilityChanged = ({ dialogInstance, dialog: { isHidden } }) =>
  isHidden
    ? dialogInstance.transition.runLeave()
    : dialogInstance.transition.runEnter()

const onPlacementChange = ({ dialog }) => dialog && dialog.updatePosition()

const didMountOpen = ({ parentDialog, withHiddenParent }) => {
  withHiddenParent && parentDialog && parentDialog.props.dialog.hide()
}

const willUnmountOpen = ({ parentDialog, withHiddenParent }) =>
  withHiddenParent && parentDialog && parentDialog.props.dialog.show()

const onResize = (_, propsRef) => {
  const onResize = () => propsRef.current.dialog?.updatePosition()
  window.addEventListener('resize', onResize)
  return () => window.removeEventListener('resize', onResize)
}

const onScreenUpdate =
  ({
    onScreenUpdate,
    updatedPlacement,
    setUpdatedState,
    placement,
    basePlacement,
    transition,
    altTransition,
  }) =>
  ({ left, top }) => {
    if (onScreenUpdate) return onScreenUpdate({ left, top })
    const setState = (newPlacement) =>
      setUpdatedState({
        placement: newPlacement,
        transition: newPlacement === basePlacement ? transition : altTransition,
      })
    if (left && placement === 'left') {
      setState('right')
      return { left: 0, top }
    } else if (left && placement === 'right') {
      setState('left')
      return { left: 0, top }
    } else if (left && placement === 'leftTopRightTop') {
      setState('rightTopLeftTop')
      return { left: 0, top }
    } else if (left && placement === 'rightTopLeftTop') {
      setState('leftTopRightTop')
      return { left: 0, top }
    }
    return { left, top }
  }

const placement = R.either(
  R.path(['updatedState', 'placement']),
  R.prop('placement')
)
const transition = R.either(
  R.path(['updatedState', 'transition']),
  R.prop('transition')
)

export default compose(
  withChildContext,
  withHooks(
    hooks.state('updatedState', 'setUpdatedState', null),
    hooks.assoc('basePlacement', R.prop('placement')),
    hooks.assoc('placement', placement),
    hooks.assoc('transition', transition),
    hookDialogContext('dialog')
  ),
  withStyleProps({ containerStyle: '', style: '' }),
  lifecycle({ didMount, willUnmount }, 'dialogInstance', {
    registerChildDialog,
    unregisterChildDialog,
  }),
  withActionOnChange(['placement'], onPlacementChange),
  withContext,
  branch(isNotOpen, renderNothing),
  lifecycle({ didMount: didMountOpen, willUnmount: willUnmountOpen }),
  onPropsChange(hasVisibilityChanged, onVisibilityChanged),
  withHandlers({ setTransition, setFocus }),
  withHooks(
    hooks.context('context', DialogContext),
    hooks.effect(onResize),
    hooks.ref('nodeRef'),
    hooks.consume([
      'updatedState',
      'setUpdatedState',
      'basePlacement',
      'altTransition',
    ])(
      hooks.handler('onScreenUpdate', onScreenUpdate),
      hooks.memo('containerStyle', containerStyle, ['dialog', 'containerStyle'])
    )
  )
)(Dialog)
