import React from 'react'
import { createFactory } from 'react'
import invoke from '__lib__/fn/invoke'
import { getPosition } from './positioning'
import { getClosestFocusable } from '../FocusTrap'

const NO_ANCHOR_ERROR =
  'You have to pass an event to dialog.open or there must be an anchor.'

export default (name, { anchor, isOpen = false } = {}) =>
  (Component) => {
    const factory = createFactory(Component)
    return class WithDialog extends React.Component {
      update = (dialog, callback, ...args) => {
        this.didUnmount ||
          this.setState({ dialog: { ...this.state.dialog, ...dialog } })
        callback && callback(this.props, ...args)
      }

      constructor(props) {
        super(props)

        const prop = (name) =>
          this.dialog &&
          (this.state.dialog?.props?.[name] ?? this.dialog.props[name])

        const getAnchorFromProps = () =>
          anchor && this.props[anchor] && this.props[anchor].get()

        const getMouseAnchor = (event) => {
          const { clientX: x, clientY: y } = event
          const S = 28
          return {
            getBoundingClientRect: () => ({
              width: S,
              height: S,
              top: y - S / 2,
              left: x - S / 2,
              right: x + S / 2,
              bottom: y + S / 2,
            }),
          }
        }

        const getAnchor = (event) =>
          (prop('mouseAnchor') && event && getMouseAnchor(event)) ||
          (prop('bodyAnchor') && document.body) ||
          getAnchorFromProps() ||
          this.anchor

        const getMargin = () => {
          const marginProp = prop('margin')
          return marginProp === undefined
            ? { x: prop('marginLeft'), y: prop('marginTop') }
            : typeof marginProp === 'number'
              ? { x: marginProp, y: marginProp }
              : marginProp
        }

        const open = (event, openState) => {
          const anchor = getAnchor(event)
          const position =
            prop('position') ||
            (!anchor && { left: 0, top: 0 }) ||
            getPosition(anchor, prop('placement'), getMargin())

          // make sure the event has propagated before opening
          prop('onOpen') && prop('onOpen')(this.props, event, openState)
          const openTarget = document.activeElement
          setTimeout(() =>
            this.update({
              anchor,
              position,
              openTarget,
              isOpen: true,
              isHidden: false,
              openState: openState,
            })
          )
        }
        const anchorUpdated = (anchor) => {
          if (prop('nonSticky')) return
          const position =
            prop('position') ||
            getPosition(anchor, prop('placement'), getMargin())
          ;(position.left !== this.state.dialog.position.left ||
            position.top !== this.state.dialog.position.top) &&
            this.update({ anchor, position })
        }

        const updatePosition = () => {
          if (!this.dialog || !this.state.dialog.isOpen) return
          const anchor = getAnchor(null)
          if (!anchor) return console.warn(NO_ANCHOR_ERROR)
          anchorUpdated(anchor)
        }

        const close = (event) => {
          if (!this.state.dialog.isOpen) return
          this.currentAnchor = null
          this.detachScroll && this.detachScroll()
          if (!event || !event.isClickOut) {
            const { openTarget } = this.state.dialog
            const target = openTarget && getClosestFocusable(openTarget)
            target && target.focus()
          }
          ;(!prop('preventClose') ||
            !prop('preventClose')(this.props, event, this.state.dialog)) &&
            this.update(
              { isOpen: false, isHidden: false, openState: null },
              prop('onClose'),
              event
            )
        }

        const attachAnchor = (anchor) => (this.anchor = anchor)

        const detachAnchor = () => attachAnchor(null)

        const attachDialog = (dialog) => {
          const hasDialog = this.dialog
          this.dialog = dialog
          this.state.dialog.instance = dialog
          !hasDialog && invoke(isOpen)(props) && setTimeout(() => open())
        }

        const toggle = (...args) =>
          this.state.dialog.isOpen ? close(...args) : open(...args)

        const hide = () => this.update({ isHidden: true })
        const show = () => this.update({ isHidden: false })

        this.state = {
          dialog: {
            open,
            hide,
            show,
            name,
            close,
            toggle,
            attachDialog,
            attachAnchor,
            detachAnchor,
            updatePosition,
            isOpen: false,
            isHidden: false,
            position: { left: 0, top: 0 },
          },
        }
      }

      componentWillUnmount() {
        this.didUnmount = true
        this.detachScroll && this.detachScroll()
      }

      render() {
        return factory({
          ...this.props,
          [name]: this.state.dialog,
        })
      }
    }
  }
