import { withHandlers } from 'react-recompose'

import objectWithValues from '__lib__/object/withValues'

export default (decorators) =>
  withHandlers(
    objectWithValues(decorators, (decorator, name) => (props) => (...args) => {
      decorator(props)(...args)
      return props[name] && props[name](...args)
    })
  )
