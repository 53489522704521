import React from 'react'
import * as R from 'ramda'
import Base from '__core__/Button'
// import withTextStyleChildren from '../withTextStyleChildren'

import hooks from '__hooks__'
import View from '__components__/View'
import styles from './styles.module.css'
import hookOnKeyFocus from './hookOnKeyFocus'
import Loading from '__components__/Loading'
import Transition from '__core__/Transition'
import { useTextStyleChildren } from '__components__/withTextStyleChildren'

const ButtonBase = ({
  isHover,
  children,
  isLoading,
  textStyle,
  loadingText,
  renderChildren,
  loadingViewStyle,
  showLoadingOverlay = true,
  ...props
}) => (
  <Base {...props}>
    {showLoadingOverlay && isLoading && (
      <Transition>
        <View
          style={loadingViewStyle}
          className={styles.loading}
          styleLeft="absolute absolute-fill flex-center"
        >
          <Loading style={textStyle} />
          {loadingText && (
            <View.Text styleLeft="ml-5" style={textStyle}>
              {loadingText}
            </View.Text>
          )}
        </View>
      </Transition>
    )}
    {children}
    {renderChildren && renderChildren({ isHover })}
  </Base>
)

const style = ({
  style,
  inert,
  isFocus,
  isHover,
  isPressed,
  isLoading,
  hoverStyle,
  focusStyle,
  pressedStyle,
  loadingStyle,
  keyFocusStyle,
}) => [
  'relative',
  style,
  !inert && isFocus && focusStyle,
  !inert && isFocus === 'key' && keyFocusStyle,
  !inert && isHover && hoverStyle,
  !inert && isPressed && pressedStyle,
  isLoading && loadingStyle,
]

const textStyle = ({
  inert,
  isFocus,
  isHover,
  isPressed,
  textStyle,
  focusTextStyle,
  hoverTextStyle,
  pressedTextStyle,
}) => [
  textStyle,
  !inert && isHover && hoverTextStyle,
  !inert && isFocus && focusTextStyle,
  !inert && isPressed && pressedTextStyle,
]

const onMouseEnter =
  ({ setIsHover, mounted }) =>
  () =>
    setTimeout(() => mounted.current && setIsHover.true())

const onMouseLeave =
  ({ mounted, setIsHover, setIsPressed }) =>
  () =>
    setTimeout(() => {
      if (!mounted.current) return
      setIsHover.false()
      setIsPressed.false()
    })

const onBlur =
  ({ setIsFocus, mounted }) =>
  () =>
    setTimeout(() => mounted.current && setIsFocus(false))

const onPointerDown =
  ({ setIsPressed, mounted }) =>
  () => {
    let pressedAt = performance.now()
    setIsPressed.timeout && clearTimeout(setIsPressed.timeout)
    setTimeout(() => mounted.current && setIsPressed.true())
    const onPointerUp = () => {
      setIsPressed.timeout = setTimeout(
        () => mounted.current && setIsPressed.false(),
        Math.max(0, 100 - (performance.now() - pressedAt))
      )
      document.body.removeEventListener('mouseup', onPointerUp)
      document.body.removeEventListener('touchend', onPointerUp)
    }
    document.body.addEventListener('mouseup', onPointerUp)
    document.body.addEventListener('touchend', onPointerUp)
  }

const isHover = ({ isHover, incomingIsHover }) => isHover || incomingIsHover

const isLoading = ({ isLoading, isLoadingOnPress }) =>
  isLoading || isLoadingOnPress

const inert = ({ isLoading, inert }) => inert || isLoading

const onPress =
  ({ onPress, setIsLoadingOnPress, mounted, inert }) =>
  (...args) => {
    const result = !inert && onPress && onPress(...args)
    return result && result.then && setIsLoadingOnPress.promise(result, mounted)
  }

const pressedProps = ({ pressedProps, ...props }) => ({
  ...props,
  ...pressedProps,
})

export const hookMountedRef = (name) =>
  R.pipe(
    hooks.value(name, () => ({ current: false })),
    hooks.effect(({ [name]: ref }) => {
      ref.current = true
      return () => (ref.current = false)
    })
  )

export default hooks(
  hooks.rename('isHover', 'incomingIsHover'),
  hooks.state('isFocus', 'setIsFocus', false),
  hooks.boolState('isHover', 'setIsHover', false),
  hooks.boolState('isPressed', 'setIsPressed', false),
  hooks.boolState('isLoadingOnPress', 'setIsLoadingOnPress', false),
  R.when(R.prop('isPressed'), pressedProps),
  hooks.assoc('isHover', isHover),
  hooks.assoc('isLoading', isLoading),
  hooks.assoc('disabled', R.either(R.prop('disabled'), R.prop('isLoading'))),
  hooks.assoc('inert', inert),
  hooks.assoc('style', style),
  hooks.assoc('textStyle', textStyle),
  hooks.styles({ textStyle: null, style: null }),
  hooks.tap(hookMountedRef('mounted'))(
    hooks.handler('onPress', onPress),
    hooks.decorator('onBlur', onBlur),
    hooks.decorator('onPointerDown', onPointerDown),
    hooks.decorator('onMouseEnter', onMouseEnter),
    hooks.decorator('onMouseLeave', onMouseLeave),
    hookOnKeyFocus
  ),
  useTextStyleChildren(),
  R.omit([
    'inert',
    'isFocus',
    'isPressed',
    'buttonRef',
    'hoverStyle',
    'focusStyle',
    'setIsHover',
    'setIsFocus',
    'pressedProps',
    'pressedStyle',
    'setIsPressed',
    'keyFocusStyle',
    'focusTextStyle',
    'hoverTextStyle',
    'incomingIsHover',
    'pressedTextStyle',
    'isLoadingOnPress',
    'setIsLoadingOnPress',
  ])
)(ButtonBase)
