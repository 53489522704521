import * as R from 'ramda'
import hooks from './core'
import objectDebugDiff from '__lib__/object/debugDiff'

const debug = (name) =>
  R.tap((props) => {
    const { propsRef } = hooks.ref('propsRef')({})
    propsRef.current = props
    const propsDiff =
      propsRef.prev && objectDebugDiff(propsRef.prev, propsRef.current)
    propsRef.prev &&
      console.info(
        name,
        ...(Object.keys(propsDiff).length > 0
          ? ['props changed: ', propsDiff]
          : ['nothing changed'])
      )
    propsRef.prev = props
  })

export default { debug }
