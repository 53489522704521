import Button from '__components__/Button'
import { supportRequestUrl } from '__routes__/external.id'
import Layout, { Header } from './Layout'

const FatalExternalError = () => (
  <Layout>
    <Header
      titleStyle="text-lg"
      title="Unfortunately, an unrecoverable error has been encountered whilst creating
      your account, and we are unable to continue."
      description={
        <Button
          component="a"
          target="_blank"
          href={supportRequestUrl}
          textStyle="text-center text-primary text-lg font-regular"
          text="Please contact support"
        />
      }
    />
  </Layout>
)

export default FatalExternalError
