import React from 'react'
import * as R from 'ramda'
import ReactDOM from 'react-dom'
import { branch } from 'react-recompose'
import { compose } from 'react-recompose'
import { withHandlers } from 'react-recompose'
import { renderNothing } from 'react-recompose'
import { withPropsOnChange } from 'react-recompose'

import Arrow from './Arrow'
import Backdrop from 'Backdrop'
import ClickOut from 'ClickOut'
import styles from './styles.module.css'
import Transition from 'Transition'
import StayOnScreen from 'StayOnScreen'
import assign from '__lib__/react/assign'
import { getTranslate } from './positioning'
import lifecycle from '__lib__/react/lifecycle'
import arrayWithout from '__lib__/array/without'
import withClassName from '__lib__/react/withClassName'
import onPropsChange from '__lib__/react/onPropsChange'
import withContext, { withChildContext } from './withContext'
import stopPropagation from '__canvas__/event/stopPropagation'
import withActionOnChange from '__lib__/react/withActionOnChange'

const Content = ({
  style,
  margin,
  dialog,
  onOpen,
  onClose,
  children,
  withArrow,
  placement,
  nonSticky,
  marginTop,
  marginLeft,
  innerStyle,
  arrowStyle,
  noBoxShadow,
  parentDialog,
  preventClose,
  withBackdrop,
  dynamicWidth,
  fallbackWidth,
  dynamicHeight,
  setTransition,
  backdropStyle,
  noStayOnScreen,
  containerStyle,
  dialogInstance,
  withoutClickout,
  wrapperClassName,
  screenMargin = 16,
  containerClassName,
  stopKeyDown,
  withoutBackdropMouseEvents,
  backdropContainerClassName,
  containerComponent: Container,
  transition = 'slide-top-fade-sm',
  ...props
}) => (
  <Transition
    wrap
    data-dialog
    style={style}
    data-cy-dialog
    name={transition}
    ref={setTransition}
    className={wrapperClassName}
  >
    {withBackdrop && (
      <Backdrop.Fragment
        backdropStyle={backdropStyle}
        withoutMouseEvents={withoutBackdropMouseEvents}
      />
    )}
    <Container
      style={containerStyle}
      onClick={stopPropagation}
      onKeyDown={stopPropagation}
      className={containerClassName}
    >
      {noStayOnScreen ? (
        <ClickOut style={innerStyle} {...props}>
          {withArrow && <Arrow style={arrowStyle} placement={placement} />}
          {children}
        </ClickOut>
      ) : (
        <StayOnScreen
          withScroll
          margin={16}
          component={ClickOut}
          style={innerStyle}
          {...props}
        >
          {withArrow && <Arrow style={arrowStyle} placement={placement} />}
          {children}
        </StayOnScreen>
      )}
    </Container>
  </Transition>
)

const Dialog = ({
  top,
  left,
  deltaX,
  deltaY,
  position,
  noBorder,
  bodyAnchor,
  dynamicWidth,
  withoutEscape,
  withHiddenParent,
  containerComponent = 'section',
  ...props
}) =>
  ReactDOM.createPortal(
    <Content containerComponent={containerComponent} {...props} />,
    document.querySelector('#app-root')
  )

const identity = (id) => id

const numberDynamicDimension = (number) => (value) => value + number

const mapDynamicDimension = (value) =>
  !value
    ? undefined
    : value === true
      ? identity
      : typeof value === 'number'
        ? numberDynamicDimension(value)
        : value

const dimension = (dim, dimensionFn, anchor, fallback) =>
  dimensionFn
    ? mapDynamicDimension(dimensionFn)(
        anchor.getBoundingClientRect()[dim] || fallback || 0
      )
    : undefined

const style = ({
  top,
  left,
  style,
  placement,
  dynamicWidth,
  dynamicHeight,
  containerStyle,
  fallbackWidth = 0, //Had to add this override because sometimes the rect width is 0
  dialog: { position, anchor },
}) => ({
  style: position && {
    width: dimension('width', dynamicWidth, anchor, fallbackWidth),
    height: dimension('height', dynamicHeight, anchor, 0),
    ...style,
    top: top || position.top,
    left: left || position.left,
  },
  containerStyle: {
    ...containerStyle,
    transform: getTranslate(placement),
  },
})

const isNotOpen = ({ dialog: { isOpen } }) => !isOpen

const didMount = (
  { openOnMount, parentDialog, dialog: { attachDialog, open } },
  instance
) => {
  attachDialog(instance)
  openOnMount && setTimeout(open)
  parentDialog && parentDialog.registerChildDialog(instance)
}

const isNodeFromChild = (target) => (child) => {
  const node =
    (child.transition && child.transition.node) || ReactDOM.findDOMNode(child)
  return (
    (node && node.contains(target)) ||
    isNodeFromChildren(target, child.childDialogs)
  )
}

const isNodeFromChildren = (target, childDialogs) =>
  childDialogs && childDialogs.some(isNodeFromChild(target))

const onClickOut =
  ({
    onClickOut,
    withoutEscape,
    dialogInstance,
    withoutClickout,
    dialog: { close, isHidden },
  }) =>
  (event) => {
    if (
      !isHidden &&
      (!withoutClickout ||
        (!withoutEscape && event.type === 'keydown') ||
        event.type === 'scroll') &&
      // IE fires body focus when the currently focused element is removed from the DOM
      (event.type !== 'focusin' || event.target !== document.body) &&
      !isNodeFromChildren(event.target, dialogInstance.childDialogs)
    ) {
      close({ isClickout: event.type || true, target: event.target })
      return onClickOut && onClickOut(event)
    }
    return { clickoutIgnored: true }
  }

const mainClassName = ({ noBorder, withBackdrop, noBoxShadow }) => [
  styles.main,
  !noBorder && !withBackdrop && styles.border,
  !noBoxShadow && styles.boxShadow,
]

const className = {
  className: mainClassName,
  containerClassName: styles.container,
  wrapperClassName: [styles.wrapper, 'fixed z-index-10 events-none'],
}

const registerChildDialog = (_props, instance) => (dialog) =>
  (instance.childDialogs = R.concat([dialog], instance.childDialogs || []))

const hasVisibilityChanged = (
  { dialog: { isHidden, isOpen } },
  { dialog: { isHidden: prevIsHidden, isOpen: prevIsOpen } = {} }
) => isHidden !== prevIsHidden && isOpen === prevIsOpen

const unregisterChildDialog = (_props, instance) => (dialog) =>
  (instance.childDialogs = arrayWithout(instance.childDialogs || [], dialog))

const willUnmount = ({ parentDialog }, instance) =>
  parentDialog && parentDialog.unregisterChildDialog(instance)

const setTransition =
  ({ dialogInstance }) =>
  (ref) =>
    ref && (dialogInstance.transition = ref)

const onVisibilityChanged = ({ dialogInstance, dialog: { isHidden } }) =>
  isHidden
    ? dialogInstance.transition.runLeave()
    : dialogInstance.transition.runEnter()

const onPlacementChange = ({ dialog }) => dialog && dialog.updatePosition()

const didMountOpen = ({ parentDialog, withHiddenParent }) =>
  withHiddenParent && parentDialog && parentDialog.props.dialog.hide()

const willUnmountOpen = ({ parentDialog, withHiddenParent }) =>
  withHiddenParent && parentDialog && parentDialog.props.dialog.show()

export default compose(
  assign({ reflowsOnPropsChange: StayOnScreen.reflowsOnPropsChange }),
  withChildContext,
  lifecycle({ didMount, willUnmount }, 'dialogInstance', {
    registerChildDialog,
    unregisterChildDialog,
  }),
  withContext,
  withClassName(className),
  branch(isNotOpen, renderNothing),
  lifecycle({ didMount: didMountOpen, willUnmount: willUnmountOpen }),
  withActionOnChange(['placement'], onPlacementChange),
  withPropsOnChange(['dialog', 'style', 'containerStyle'], style),
  onPropsChange(hasVisibilityChanged, onVisibilityChanged),
  withHandlers({ onClickOut, setTransition })
)(Dialog)
