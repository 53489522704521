import lazy from '__lib__/fn/lazy'
import styles from '__css__/variables.module.css'

const match = (query, isDefault) =>
  window.matchMedia ? window.matchMedia(query) : { matches: Boolean(isDefault) }

// window reference have to be lazy
export const mediaQueries = lazy(() => [
  { mq: match(styles['--bp-smaller']), value: 0 },
  { mq: match(styles['--bp-small']), value: 1 },
  { mq: match(styles['--bp-medium'], true), value: 2 },
  { mq: match(styles['--bp-large']), value: 3 },
])

export default () => mediaQueries().find(({ mq }) => mq.matches).value
