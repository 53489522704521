import React from 'react'
import hooks from '__hooks__'
import View from '__components__/View'
import Icon from '__components__/Icon'
import Button from '__components__/Button'
import Transition from '__core__/Transition'
import { LogoWatermark } from '@drawboard/lib-dbpdf/Logo'
import { hookRunStrokeAnimation } from '__components__/AnimatedStroke'

import { DefaultBackdrop, DefaultPanel } from './Panels'

const Left = ({ asset: Asset, backdrop: Backdrop, assetStyle }) => (
  <View
    styleLeft="flex-stiff flex-center flex-col relative overflow-hidden"
    styleRight={[
      'w-full',
      {
        desktop: { width: 520 },
        laptop: { width: 440 },
      },
      'bg-cover',
    ]}
  >
    <View style="absolute absolute-fill flex-center overflow-hidden hidden laptop:flex">
      {React.createElement(Backdrop || DefaultBackdrop, {
        preserveAspectRatio: 'xMidYMid slice',
        style: { minWidth: '100%', minHeight: '100%' },
      })}
    </View>
    <View
      style="full flex-col flex items-center relative"
      styleRight={assetStyle}
    >
      <View style="hidden laptop:flex-center full">
        {React.createElement(Asset || DefaultPanel)}
      </View>
    </View>
  </View>
)

export const Header = ({
  title,
  titleStyle,
  description,
  descriptionStyle,
  ...props
}) => (
  <View
    styleLeft="flex flex-col text-center items-center text-shade-7 max-w-10 w-full"
    {...props}
  >
    <View
      styleLeft="font-semibold text-xxl laptop:text-3xl max-w-full text-line-break leading-xxl"
      style={titleStyle}
    >
      {title}
    </View>
    {description && (
      <View
        styleLeft="text-md mt-3 max-w-full text-line-break"
        style={descriptionStyle}
      >
        {description}
      </View>
    )}
  </View>
)

export const Footer = ({ children, ...props }) => (
  <View styleLeft="flex-center mt-6 flex-stiff" {...props}>
    {children}
  </View>
)

const FooterLink = ({ text, linkText, ...props }) => (
  <Button component="a" styleLeft="text-md" {...props}>
    {text && <View.Text style="text-shade-4 mr-3">{text}</View.Text>}
    <View.Text style="text-ink font-semibold underline">{linkText}</View.Text>
  </Button>
)

export const LoginButton = ({ ...props }) => (
  <FooterLink linkText="Log in" {...props} />
)

export const SignupButton = ({ ...props }) => (
  <FooterLink linkText="Create an account" {...props} />
)

export const BackButton = ({ text, ...props }) => (
  <Button styleLeft="flex items-center" {...props}>
    <View
      styleLeft="flex-center rounded-full bg-shade-0 border border-shade-1"
      style={{ width: 30, height: 30 }}
    >
      <Icon icon="chevronDown" style="rotate-1/4 text-shade-4" size={10} />
    </View>
    <View.Text style="text-shade-4 font-semibold text-xs hidden laptop:flex ml-4">
      {text}
    </View.Text>
  </Button>
)

const Layout = ({
  asset,
  backdrop,
  children,
  backButton,
  assetStyle,
  contentStyle,
  rightContainerStyle,
  ...props
}) => (
  <View
    styleLeft={[
      'flex flex-col laptop:flex-row text-md full flex-scroll',
      { height: '100vh' },
    ]}
    {...props}
  >
    <Left backdrop={backdrop} asset={asset} assetStyle={assetStyle} />
    <View
      styleLeft="flex flex-col flex-flex w-full laptop:flex-scroll min-w-9"
      style={rightContainerStyle}
    >
      <View
        styleLeft="flex-stiff w-full flex-center relative"
        style="h-7 laptop:h-auto"
      >
        {backButton && (
          <Transition component={View} style="absolute top-5 left-5">
            {backButton}
          </Transition>
        )}
        <View component={LogoWatermark} styleLeft="laptop:hidden" />
      </View>
      <View
        styleLeft="flex-flex flex items-center laptop:justify-center flex-col px-6 py-5 laptop:py-6"
        style={contentStyle}
      >
        {children}
      </View>
    </View>
  </View>
)

export default hooks(hookRunStrokeAnimation())(Layout)
