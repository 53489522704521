import M from '../path/m'
import L from '../path/l'
import Z from '../path/z'
import annotationNew from '../annotation/new'
import property from '__lib__/object/property'
import matrixIdentity from '../matrix/identity'
import arrayToObject from '__lib__/array/toObject'
import matrixOfTranslation from '../matrix/ofTranslation'

const rect = () =>
  annotationNew({
    zOrder: 0,
    strokeWidth: 2,
    fill: '#ff4f68',
    strokeOpacity: 1,
    stroke: '#ff4f68',
    type: 'rectangle',
    matrix: matrixIdentity,
    bounds: { left: 0, top: 0, right: 120, bottom: 60 },
    geometry: [M(0, 0), L(0, 60), L(120, 60), L(120, 0), Z()],
  })

const text = ({ y, ...props }) =>
  annotationNew({
    zOrder: 0,
    type: 'text',
    strokeJoin: 1,
    strokeWidth: 11,
    strokeOpacity: 1,
    stroke: '#ff4f68',
    matrix: matrixOfTranslation(0, 16 + y),
    bounds: { left: 0, top: 0, right: 120, bottom: 20 },
    ...props,
  })

// The ID is used for segment tracking to differenciate preset/custom
const textPreset = (lines) => ({
  id: `stamp-preset-text-${lines.join(' ')}`,
  annotation: annotationNew({
    zOrder: 0,
    type: 'stamp',
    childAnnotations: [
      rect(),
      ...lines.map((content, index) =>
        text({ content, y: lines.length === 2 ? 10 - index * 18 : 0 })
      ),
    ],
  }),
})

export default arrayToObject(
  [
    textPreset(['         DRAFT ISSUE']),
    textPreset(['          FOR REVIEW']),
    textPreset(['        CHECK PRINT']),
    textPreset(['        PRELIMINARY', '                ISSUE']),
    textPreset(['           APPROVED']),
    textPreset(['   FOR INFORMATION', '                  ONLY']),
    textPreset(['               REVISE', '       AND RESUBMIT']),
    textPreset(['              REVISED']),
    textPreset(['            REJECTED']),
    textPreset(['             NOT FOR', '      CONSTRUCTION']),
    textPreset(['                  FOR', '      CONSTRUCTION']),
    textPreset(['             AS-BUILT']),
  ],
  property('id')
)
