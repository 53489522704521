import identity from '../utils/identity'

export default (array, keyFn, valueFn) => {
  const result = {}
  keyFn || (keyFn = identity)
  valueFn || (valueFn = identity)
  array.forEach((value, index) => {
    const key = keyFn(value, index)
    if (key) result[key] = valueFn(value, key, index)
  })
  return result
}
