import { loadScript } from '__lib__/web/script'

export default () =>
  window.location.hostname === 'pdf.drawboard.com' &&
  loadScript('https://www.googletagmanager.com/gtag/js?id=G-DC3RNCLWJ0').then(
    () => {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      if (!window.gtag) window.gtag = gtag

      gtag('js', new Date())
      gtag('config', 'G-DC3RNCLWJ0')

      // Event snippet for open pdf.drawboard.com conversion page - Nathan Field
      gtag('config', 'AW-958289895')
      gtag('event', 'conversion', {
        send_to: 'AW-958289895/PoeACI6q5tQCEOev-cgD',
      })
    }
  )
