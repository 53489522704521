import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'react-recompose'
import { withProps } from 'react-recompose'
import { getContext } from 'react-recompose'
import { withContext } from 'react-recompose'
import { withHandlers } from 'react-recompose'

import invoke from '__lib__/fn/invoke'
import lifecycle from '__lib__/react/lifecycle'

const DefaultComponent = 'form'

const Form = ({
  form,
  innerRef,
  doValidate,
  noAutoComplete,
  submitOnInvalid,
  noStopPropagation,
  component: Component = DefaultComponent,
  ...props
}) => (
  <Component
    ref={innerRef}
    name={form.name}
    noValidate={!doValidate}
    autoComplete={noAutoComplete && 'off'}
    {...props}
  />
)

const childContextTypes = { form: PropTypes.object }

const getChildContext = ({ form }) => ({ form })

export const withFormContext = getContext(childContextTypes)

const onSubmit =
  ({
    form,
    method,
    onSubmit,
    onHttpSubmit,
    submitOnInvalid,
    noStopPropagation,
  }) =>
  (event) => {
    if (!event) return
    const submit = onHttpSubmit || onSubmit
    event && (onHttpSubmit || method || event.preventDefault())
    event && (noStopPropagation || event.stopPropagation())
    if (!submit) return
    return form.submit(submit, submitOnInvalid)
  }

const propTypes = {
  noAutoComplete: PropTypes.bool,
}

const didMountFy = (fieldName) => (props, instance) =>
  props.form.addListenerForFieldChange(
    invoke(fieldName)(props),
    (instance.onFieldChange = () => instance.forceUpdate())
  )

const willUnmountFy = (fieldName) => (props, instance) =>
  props.form.removeListenerForFieldChange(
    invoke(fieldName)(props),
    instance.onFieldChange
  )

const withFieldListening = (fieldName) =>
  lifecycle({
    didMount: didMountFy(fieldName),
    willUnmount: willUnmountFy(fieldName),
  })

const withFieldProps = (fieldName) =>
  withProps((props) => props.form.getFieldProps(invoke(fieldName)(props)))

const didMount = ({ form }, instance) => form.setFormInstance(instance)
const willUnmount = ({ form }) => form.setFormInstance(null)

export default Object.assign(
  compose(
    withHandlers({ onSubmit }),
    lifecycle({ didMount, willUnmount }),
    withContext(childContextTypes, getChildContext)
  )(Form),
  {
    propTypes,
    withFieldProps,
    withFieldListening,
    withContext: withFormContext,
  }
)
