import { compose } from 'react-recompose'
import { withProps } from 'react-recompose'
import withClassName from '__lib__/react/withClassName'
import Icon from 'Icon'
import Link from 'Link'
import styles from './styles.module.css'

const typeIcons = {
  info: 'info',
  error: 'errorSymbol',
  success: 'tickSymbol',
}

const Message = ({
  info,
  type,
  icon,
  error,
  toggle,
  success,
  children,
  ...props
}) =>
  type === 'custom' ? (
    typeof children === 'function' ? (
      children({ onClose: toggle })
    ) : (
      children
    )
  ) : (
    <div {...props}>
      <div className="flex items-center pv2 ph1">
        {typeIcons[type] && (
          <Icon sm type={typeIcons[type]} className="flex flex-0-0" />
        )}
        <div className="ph3">
          {typeof children === 'function'
            ? children({ onClose: toggle })
            : children}
        </div>
        {toggle && (
          <Link.Icon
            icon="new/cancel"
            onClick={toggle}
            className="flex flex-0-0 c-white"
          />
        )}
      </div>
    </div>
  )

const type = ({ type, info, error, success }) => ({
  type: type || (info ? 'info' : error ? 'error' : success ? 'success' : null),
})

const className = ({ type }) => [
  styles.messageRoot,
  styles[type],
  'fs3 fw2 mt2',
]

export default compose(withProps(type), withClassName(className))(Message)
