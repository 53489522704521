import View from '__components__/View'
import PanelContent from '../Content'
import Underline from './underline.svg?react'

const CloudPanel = () => (
  <PanelContent
    title={
      <>
        <View style="relative">
          <View.Text>Benefits of syncing</View.Text>
          <View style="absolute -right-3 -bottom-4 flex">
            <Underline />
          </View>
        </View>
        <View.Text>all your documents</View.Text>
      </>
    }
  />
)

export default CloudPanel
