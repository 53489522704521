import Base from './Base'
import useTheme from '../useTheme'
import { compose } from 'react-recompose'
import { mapProps } from 'react-recompose'
import { withStyleProps } from 'react-with-style'
import withTextStyleChildren from '../withTextStyleChildren'
import withoutProps from '__lib__/react/withoutProps'
import withClassName from '__lib__/react/withClassName'
import styles from './styles.module.css'

export const keyFocusStyle = ({
  danger,
  primary,
  success,
  disabled,
  inlineFocus,
}) => [
  'outline-none',
  !disabled && [
    inlineFocus ? 'shadow-inline' : 'shadow-outline',
    danger && (inlineFocus ? 'shadow-inline-danger' : 'shadow-outline-danger'),
    success &&
      (inlineFocus ? 'shadow-inline-success' : 'shadow-outline-success'),
    primary &&
      (inlineFocus ? 'shadow-inline-primary' : 'shadow-outline-primary'),
  ],
]

const focusStyle = ({ noFocusStyle, disabled, danger, primary, success }) =>
  noFocusStyle || [
    'outline-none',
    !disabled && [
      'border-secondary',
      { backgroundColor: '#15191F', light: { backgroundColor: '#F3F6FC' } },
      danger && [
        'border-danger',
        {
          backgroundColor: '{danger-focus}',
          dark: 'bg-transparent',
        },
      ],
      success && 'border-success',
      primary && 'border-primary',
    ],
  ]

const style = ({
  sm,
  md,
  lg,
  xl,
  plain,
  square,
  danger,
  primary,
  success,
  disabled,
}) => [
  'border px-1 border-shade-1 flex flex-row flex-center w-full text-shade-5 shadow-input',
  square || 'rounded-md',
  sm && 'h-sm',
  md && 'h-md',
  lg && 'h-lg',
  xl && 'h-xl',
  plain && 'bg-drop-1',
  disabled && 'bg-drop-1 border-0 text-shade-3',
  danger && 'text-shade-7',
  plain && !disabled && success && 'bg-success',
  plain && !disabled && danger && 'bg-danger',
  plain && !disabled && primary && 'bg-primary',
  plain && disabled && 'bg-shade-2',
  !disabled && success && 'border-success',
  !disabled && danger && 'border-danger',
  !disabled && primary && 'border-primary',
]

// const textStyle = ({ sm, md, lg, disabled, success, danger, primary }) => [
//   sm && 'text-sm',
//   md && 'text-md',
//   lg && 'text-lg',
//   'text-shade-7',
//   !disabled && success && 'text-success font-semibold',
//   !disabled && danger && 'text-danger font-semibold',
//   !disabled && primary && 'text-primary font-semibold',
//   disabled && 'text-shade-4 font-semibold',
// ]

const hoverStyle = ({ disabled, danger, primary, success }) =>
  disabled || (success && 'border-success')

// const hoverTextStyle = ({ disabled, danger, primary, success }) =>
// !disabled && [
//   success && 'text-success-light',
//   danger && 'text-danger-light',
//   primary && 'text-primary-light',
// ]

const inputStyle = ({ md, sm, lg, xl }) => [
  'bg-transparent flex-grow flex-shrink m-0 px-5 py-0 w-full h-full border-0 text-shade-7 min-w-5',
  sm && 'text-xs',
  md && 'text-sm',
  lg && ['text-md', { paddingLeft: 18, paddingRight: 18 }],
  xl && ['text-lg', { paddingLeft: 20, paddingRight: 20 }],
  { fontFamily: 'inherit' },
]

export default compose(
  mapProps(useTheme.size('md')),
  withStyleProps({
    style,
    // textStyle,
    hoverStyle,
    focusStyle,
    inputStyle,
    keyFocusStyle,
  }),
  withTextStyleChildren(),
  withTextStyleChildren({
    textName: 'afterText',
    childrenName: 'afterChildren',
  }),
  withClassName(styles.box),
  mapProps(useTheme.omit),
  withoutProps(['inlineFocus', 'noFocusStyle'])
)(Base)
