import { createFactory, Component } from 'react'

import tap from '__lib__/fn/tap'
import invoke from '__lib__/fn/invoke'
import catchTap from '__lib__/fn/catchTap'

/**
 *
 *
 * HOC for Boolean component state -
 * Adds state and a [setName] Function Object to the props.
 * [setName] can be called with the value, or it has true,false,toggle functions
 * assigned to it.
 * @param {*} name
 * @param {*} setName
 * @param {*} initialValue
 */
const withBoolState = (name, setName, initialValue) => (Base) => {
  const factory = createFactory(Base)
  return class WithBoolState extends Component {
    constructor(props) {
      super(props)
      this.state = {
        [name]: invoke(initialValue)(props),
      }
    }
    setValue = (value) =>
      !this.unmounted &&
      value !== this.props.value &&
      (this.setState({ [name]: value }), true)

    componentWillUnmount() {
      this.unmounted = true
    }

    set = Object.assign((value) => this.setValue(value), {
      true: () => this.setValue(true),
      false: () => this.setValue(false),
      toggle: () => this.setValue(!this.state[name]),
      promise: (promise) => {
        this.setValue(true)
        return promise
          .then(tap(() => this.setValue(false)))
          .catch(catchTap(() => this.setValue(false)))
      },
    })

    render() {
      return factory({ ...this.props, ...this.state, [setName]: this.set })
    }
  }
}

export default withBoolState
