const EASING = `ease-in-out`
// const mdTranslation = `64px`
const MD_DURATION = 250
const SM_DURATION = 200
const XS_DURATION = 130
const SMOOTH_DURATION = 500
const t = (duration = MD_DURATION, easing = EASING, delay = 0) =>
  `opacity ${duration}ms ${easing} ${delay}ms, transform ${duration}ms ${easing} ${delay}ms`

const mdT = t()
const lgT = t(300)
const smT = t(SM_DURATION)
const xsT = t(XS_DURATION)
const smoothT = t(SMOOTH_DURATION)

const fadeFy = (transition) => ({
  enter: { opacity: 0 },
  enterActive: { opacity: 1, transition },
  leave: { opacity: 1 },
  leaveActive: { opacity: 0, transition },
})

const popFactory = ({ opacity, scale, transition = mdT }) => ({
  enter: {
    transform: `scale(${scale})`,
    opacity: 0,
    transformOrigin: 'center',
  },
  enterActive: { transform: `scale(1)`, opacity: 1, transition },
  leave: { transform: `scale(1)`, transformOrigin: 'center' },
  leaveActive: { transform: `scale(${scale})`, opacity, transition },
})

export const slideFadeFy = ({ x = 0, y = 0, transition = mdT }) => ({
  enter: { opacity: 0, transform: `translate(${x}, ${y})` },
  enterActive: { opacity: 1, transform: `translate(0, 0)`, transition },
  leave: { opacity: 1, transform: `translate(0, 0)` },
  leaveActive: { opacity: 0, transform: `translate(${x}, ${y})`, transition },
})

export const slideFy = ({ x = 0, y = 0, transition = mdT }) => ({
  enter: { transform: `translate(${x}, ${y})` },
  enterActive: { transform: `translate(0, 0)`, transition },
  leave: { transform: `translate(0, 0)` },
  leaveActive: { transform: `translate(${x}, ${y})`, transition },
})

export const pop = popFactory({ opacity: 1, scale: 0 })
export const popFade = popFactory({ opacity: 0, scale: 0 })
export const popFadeSmall = popFactory({
  opacity: 0,
  scale: 0.96,
  transition: t(300),
})
export const popFadeSuperQuick = popFactory({
  opacity: 0,
  scale: 0.99,
  transition: t(200, 'cubic-bezier(0.64, 0.57, 0.67, 1.53)'),
})
export const fade = fadeFy(mdT)
export const fadeQuick = fadeFy(smT)
export const fadeSuperQuick = fadeFy(xsT)
export const antiJitter = {
  enter: { opacity: 0 },
  enterActive: { opacity: 1, transition: t(XS_DURATION, EASING, 0) },
  leave: { opacity: 1 },
  leaveActive: { opacity: 0, transition: t(XS_DURATION, EASING, 150) },
}

export const delay = fadeFy('opacity 0ms linear 33ms')
export const slideFadeTop = slideFadeFy({ y: '100%', transition: mdT })
export const slideFadeLeft = slideFadeFy({ x: '100%', transition: mdT })
export const slideFadeRight = slideFadeFy({ x: '-100%', transition: mdT })
export const slideFadeBottom = slideFadeFy({ y: '-100%', transition: mdT })
export const slideFadeTopSmall = slideFadeFy({ y: '24px', transition: mdT })
export const slideFadeTopSmallQuick = slideFadeFy({
  y: '24px',
  transition: xsT,
})
export const slideFadeTopSmooth = slideFadeFy({
  y: '16px',
  transition: smoothT,
})
export const slideFadeLeftSmooth = slideFadeFy({
  x: '16px',
  transition: smoothT,
})
export const slideFadeRightSmooth = slideFadeFy({
  x: '-16px',
  transition: smoothT,
})
export const slideFadeBottomSmooth = slideFadeFy({
  y: '-16px',
  transition: smoothT,
})

// export const slideFadeTopSmall = slideFadeFy({
//   y: '24px',
//   transition: `opacity ${5000}ms ${easing}, transform ${5000}ms ${easing}`,
// })
export const slideFadeLeftSmall = slideFadeFy({ x: '24px', transition: mdT })
export const slideFadeRightSmall = slideFadeFy({ x: '-24px', transition: mdT })
export const slideFadeBottomSmall = slideFadeFy({ y: '-24px', transition: mdT })
export const slideFadeLeftSmallQuick = slideFadeFy({
  x: '24px',
  transition: xsT,
})
export const slideFadeRightSmallQuick = slideFadeFy({
  x: '-24px',
  transition: xsT,
})
export const slideFadeBottomSmallQuick = slideFadeFy({
  y: '-24px',
  transition: xsT,
})
export const slideLeftSmall = slideFy({ x: '24px', transition: smT })
export const slideRightSmall = slideFy({ x: '-24px', transition: smT })
export const slideLeft = slideFy({ x: '100%', transition: mdT })
export const slideRight = slideFy({ x: '-100%', transition: mdT })
export const slideRightQuick = slideFy({ x: '-100%', transition: smT })
export const slideRightLong = slideFy({ x: '-100%', transition: lgT })
export const slideTop = slideFy({ y: '100%', transition: mdT })
export const slideBottom = slideFy({ y: '-100%', transition: mdT })
export const slideBottomQuick = slideFy({ y: '-100%', transition: smT })
export const noop = slideFy({ y: 0 })
