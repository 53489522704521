export const privacyPolicyUrl = 'https://www.drawboard.com/terms'
export const supportUrl =
  'https://support.drawboard.com/hc/en-us/categories/202700817-Drawboard-PDF'
export const contactUrl = 'https://www.drawboard.com/pdf/business-contact-us'
export const requestReviewUrl =
  'https://support.drawboard.com/hc/en-us/requests/new'
export const termsUrl = 'https://www.drawboard.com/terms'
export const azureMarketplaceUrl =
  'https://azuremarketplace.microsoft.com/en-us/marketplace/apps/aad.drawboardpdf?exp=ubp8&tab=Overview'
export const projectsUrl = 'https://www.drawboard.com/projects'
export const studentUrl = 'https://www.drawboard.com/solutions/for-students'
export const appDownloadUrl = 'https://www.drawboard.com/download'
export const macOsDownloadUrl =
  'https://apps.apple.com/au/app/drawboard-pdf/id1086503728'
export const existingPlanUrl =
  'https://support.drawboard.com/hc/en-us/articles/4409163761423-Understanding-our-Subscription-Model-'
export const youtubeChannelUrl =
  'https://www.youtube.com/@Drawboardcompany/videos'
export const productTourUrl = 'https://www.drawboard.com/pdf/product-tour'
export const teamSolutionUrl = 'https://www.drawboard.com/solutions/teams'
export const retrialUrl = 'https://pdf.drawboard.com/start-trial'
export const googleWorkspaceAppUrl =
  'https://workspace.google.com/marketplace/app/drawboard_pdf/723463480401'
export const chromeExtensionUrl =
  'https://chromewebstore.google.com/detail/drawboard-pdf-annotate-me/bmninhlkpnllbioeiflnedibkeifpmkm'
export const edgeExtensionUrl =
  'https://microsoftedge.microsoft.com/addons/detail/drawboard-pdf-annotate-/nlmfkamldlkfomcbohjlfalpmppjnnon'
export const educationPromotionUrl =
  'https://www.drawboard.com/pdf/back-to-school-with-drawboard-pdf'
export const educationLearnMoreUrl =
  'https://www.drawboard.com/pdf/educational-institutions'
export const organizationUpgradeReadMorePath = null
