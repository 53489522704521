import './logs'
import React from 'react'
import * as R from 'ramda'
import moment from 'moment'
import ReactDOM from 'react-dom'
import history from '__routes__/history'
import { store, storePersistor } from '__store__/store'
import telemetryInit from '__lib__/telemetry/init'
import logoutConfirmation from '__routes__/logoutConfirmation'
import '__lib__/utils/tapLog'
import version from './version'
import breakpoints from '__lib__/breakpoints'
import copyToClipboard from '__lib__/web/copyToClipboard'
import config from '__config__'
import Splash from '__components__/Splash'

const initialize = ({ color }) => {
  window.moment = moment
  window.setImmediate || (window.setImmediate = window.setTimeout)
  Object.assign(window, { __appHistory__: history, R })

  window._copyStoreState = () =>
    copyToClipboard(JSON.stringify(store.getState(), null, 2))

  // mockConfig.isEnabled || register()
  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker
  //     .getRegistrations()
  //     .then(R.forEach(R.invoker(0, 'unregister')))
  // }

  import.meta.env.VITE_ENV === 'production' &&
    console.log(
      `%cDrawboard ${import.meta.env.VITE_PROJECT.toUpperCase()} ${version} - ${
        config.env
      }`,
      `background: ${color}; color:#14234B; border-radius:2px; padding:3px 5px 1px 5px; margin-bottom:2px; font-size:11px; font-weight: 600;`
    )

  window.__clearApp__ = () => {
    storePersistor && storePersistor.purge()
    indexedDB.deleteDatabase('documentStore')
    indexedDB.deleteDatabase('documentDataStore')
    indexedDB.deleteDatabase('documentPageThumbnailStore')
  }
  window.__resetApp__ = (goToIndex = true) => {
    window.__clearApp__()
    goToIndex && (window.location = '/')
    setTimeout(() => window.location.reload(), 300)
  }

  breakpoints.initialize()
}

const app = (App, params = {}) => {
  window.reactAppInitialized || initialize(params)
  const node = import.meta.env.VITE_PRICING
    ? document
        .querySelector('#pdf-app-pricing')
        .shadowRoot.querySelector('#root')
    : document.getElementById('root')

  ReactDOM.render(<App store={store} />, node, () => {
    if (!window.reactAppInitialized) {
      Splash.hide(true)
      telemetryInit()
      window.addEventListener('beforeunload', (event) =>
        logoutConfirmation(event)
      )
    }
    window.reactAppInitialized = true
  })
}

export default app
