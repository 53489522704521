import * as R from 'ramda'
import React from 'react'

import Anchor from './Anchor'
import hooks from '__hooks__'
import { hookDialogContext } from './Provider'

const Toggle = ({
  dialog,
  isAnchor,
  openDialog,
  innerComponent,
  passToggleDialog,
  blockEventBubbling,
  component: Component,
  ...props
}) =>
  isAnchor ? (
    <Anchor
      dialog={dialog}
      component={Component}
      innerComponent={innerComponent}
      {...props}
    />
  ) : (
    <Component
      {...(innerComponent && { component: innerComponent })}
      {...props}
    />
  )

// const didMount = (_, instance) => {
//   const node = ReactDOM.findDOMNode(instance)
//   const handler = (event) =>
//     instance.props.dialog.isOpen && event.stopPropagation()
//   node && node.addEventListener('mousedown', handler)
//   node && node.addEventListener('touchstart', handler)
//   node && node.addEventListener('focusin', handler)
//   Object.assign(instance, { node, handler })
// }

// const willUnmount = (_, { node, handler }) => {
//   node && node.removeEventListener('mousedown', handler)
//   node && node.removeEventListener('touchstart', handler)
//   node && node.removeEventListener('focusin', handler)
// }

const onPress =
  ({ dialog, dialogState, disabled }) =>
  (event) =>
    !disabled && dialog.toggle(event, dialogState?.())

export default hooks(
  hookDialogContext('dialog'),
  hooks.consume(['withLongPress', 'dialogState'])(
    R.ifElse(
      R.prop('withLongPress'),
      hooks.decorator('onLongPress', onPress),
      hooks.decorator('onPress', onPress)
    )
  )
)(Toggle)
