import { compose } from 'react-recompose'
import { mapProps } from 'react-recompose'
import { renameProps } from 'react-recompose'
import { withHandlers } from 'react-recompose'

import tap from '__lib__/fn/tap'
import catchTap from '__lib__/fn/catchTap'
import withBoolState from '__lib__/react/withBoolState'

const setLoadingFromPromise =
  ({ setLoading }) =>
  (promise) => {
    if (!promise || !promise.then) return promise
    setLoading(true)
    const done = () => setLoading(false)
    return promise.then(tap(done)).catch(catchTap(done))
  }

const setLoading =
  ({ setLoading, onLoadingChange }) =>
  (value) =>
    setLoading(value) && onLoadingChange && onLoadingChange(value)

const loading = ({ baseLoading, loading, ...props }) => ({
  ...props,
  loading: baseLoading || loading,
})

export default compose(
  renameProps({ loading: 'baseLoading' }),
  withBoolState('loading', 'setLoading', false),
  withHandlers({ setLoading }),
  withHandlers({ setLoadingFromPromise }),
  mapProps(loading)
)
