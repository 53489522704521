import React from 'react'
import * as R from 'ramda'

import styles from './styles'
import hooks from '__hooks__'
import assign from '__lib__/react/assign'
import generateUtilities from 'react-with-style/utilities'
import { StyleProvider, StyleContext } from 'react-with-style'
import config, { SIZES } from 'react-with-style/utilities/config'

export { styles }

const breakpoints = import.meta.env.VITE_ANDROID
  ? [
      ['mobile', R.T],
      ['phone', ({ width }) => width < 500],
      ['tablet', ({ width }) => width >= 500],
      ['laptop', R.F],
      ['desktop', R.F],
      ['monitor', R.F],
    ]
  : [
      ['mobile', ({ width }) => width < 1024],
      ['phone', ({ width }) => width < 500],
      ['tablet', ({ width }) => width >= 500],
      ['laptop', ({ width }) => width >= 1024],
      ['desktop', ({ width }) => width >= 1440],
      ['monitor', ({ width }) => width >= 1800],
    ]

export const getBreakpointsIndex = (breakpoints) =>
  4 -
  Number(breakpoints.has('phone')) -
  Number(breakpoints.has('tablet')) -
  Number(breakpoints.has('laptop')) -
  Number(breakpoints.has('desktop'))

const directives = {
  dark: ({ theme }) => theme === 'dark',
  light: ({ theme }) => theme === 'light',
  projects: R.always(import.meta.env.VITE_PROJECT === 'projects'),
}

const outlineShadow = (color, lightColor = 'white') => ({
  boxShadow: `0px 0px 0px 2px ${lightColor},
    0px 0px 0px 2px ${lightColor},
    0px 0px 0px 2px ${lightColor},
    0px 0px 0px 2px ${lightColor},
    0px 0px 0px 2px ${lightColor},
    0px 0px 0px 4px ${color}`,
})

const inlineShadow = (color) => ({
  boxShadow: `inset 0px 0px 0px 2px ${color}`,
})

const outlineShadows = (colors, lightColor) =>
  R.reduce(
    (acc, name) =>
      R.merge(
        {
          ['shadow-inline-' + name]: inlineShadow(colors[name]),
          ['shadow-inline-thin' + name]: inlineShadow(colors[name]),
          ['shadow-outline-' + name]: outlineShadow(colors[name], lightColor),
          ['shadow-outline-thin' + name]: outlineShadow(
            colors[name],
            lightColor
          ),
        },
        acc
      ),
    {},
    ['brand', 'primary', 'danger', 'success']
  )

const defaultConfig = R.once(() => config())

export const extraColors = {
  yellow: '#FFE557',
  darkBlue: '#14234B',
  'yellow-dark': '#EDAE00',
  'danger-focus': '#FEF6F6',
  highlight: 'rgba(255,215,0,0.2)',
}

export const brandColors =
  import.meta.env.VITE_PROJECT === 'projects'
    ? { brand: '#FF4C50', 'brand-dark': '#FF4C50', 'brand-light': '#FF4C50' }
    : {
        brand: '#23A868',
        'brand-dark': '#14234B',
        'brand-light': '#5AE284',
      }

const commonColors = {
  white: 'rgb(255,255,255)',
  black: 'rgb(0,0,0)',
  transparent: 'rgba(0,0,0,0)',

  'emphasize-1': '#F7F9FD', // ink-50
  'emphasize-2': '#D7DFF4', // ink-100

  primary:
    import.meta.env.VITE_PROJECT === 'pdf' ? '#219DE3' : 'rgb(39,109,215)', // 276DD7
  'primary-light':
    import.meta.env.VITE_PROJECT === 'pdf' ? '#1F3674' : 'rgb(90,144,225)', // 5a90e1
  'primary-dark': 'rgb(35,98,193)', // 2362c1

  success: 'rgb(0,192,131)', // 00C083
  'success-dark': 'rgb(0,167,114)', // 00a772
  'success-light': 'rgb(0,207,141)', // 00cf8d

  danger: 'rgb(230,86,61)', // E6563D
  'danger-light': 'rgb(255,136,102)', // ff8866
  'danger-dark': 'rgb(215,47,0)', // d72f00

  ink: '#14234B',
  'ink-700': '#070D1C',
  'ink-600': '#0D1631',
  'ink-400': '#3054B4',
  'ink-300': '#5E7ED4',
  'ink-200': '#C7D2F0',
  'ink-100': '#D7DFF4',
  'ink-50': '#F7F9FD',

  carmine: '#FF7578',
  'carmine-dark': '#FF4C50',
  'carmine-light': '#FF8F91',

  yellow: '#FFCD00',
  'yellow-dark': '#EDAE00',
  'yellow-light': '#FFE557',

  // gray: '#3E4554',

  ...brandColors,
}

const themesColors = {
  light: {
    'drop-1': '#F9F9F9',
    'drop-2': '#FFFFFF',
    'shade-0': '#F1F1F1',
    'shade-1': '#DEDEE1',
    'shade-2': '#CACBCE',
    'shade-3': '#878E97',
    'shade-4': '#626975',
    'shade-5': '#2B313C',
    'shade-6': '#212730',
    'shade-7': '#15191F',
    secondary: '#14234B',
    'secondary-2': '#113A8E',
    'ink-50': '#F7F9FD',
    'ink-100': '#D7DFF4',
    gray: '#DEDEE1',
  },
  dark: {
    'drop-1': '#212730',
    'drop-2': '#15191F',
    'shade-0': '#303030',
    'shade-1': '#2B313C',
    'shade-2': '#626975',
    'shade-3': '#878E97',
    'shade-4': '#CACBCE',
    'shade-5': '#DEDEE1',
    'shade-6': '#F1F3F5',
    'shade-7': '#FFFFFF',
    secondary: '#F1F3F5',
    'secondary-2': '#113A8E',
    'ink-50': '#14234B',
    'ink-100': '#3054B4',
    gray: '#3E4554',
  },
}

const transition = () => {
  const paint =
    'background-color ease-in-out 250ms, border-color ease-in-out 250ms, color ease-in-out 250ms, stroke ease-in-out 250ms, fill ease-in-out 250ms, box-shadow ease-in-out 100ms, transform ease-in-out 250ms, opacity ease-in-out 250ms'
  return {
    paint,
    none: 'none',
    // slow: 'all ease-in-out 2000ms',
    // slow: 'all ease-in-out 1000ms',
    slow: 'all ease-in-out 250ms',
    quick: 'all ease-in-out 200ms',
    '': 'all ease-in-out 250ms',
    'bg-quick': paint + ', background ease-in-out 200ms',
    bg: paint,
    'transform-quick': 'transform ease-in-out 200ms',
    // paint + ', transform ease-in-out 250ms',
    perf: 'opacity ease-in-out 250ms, transform ease-in-out 250ms',
  }
}

const utilities = (colors, globals, fn = R.identity) => {
  const defaults = defaultConfig()
  return generateUtilities(
    config(
      fn({
        fontWeight: {
          thin: '300',
          regular: '400',
          semibold: '600',
          bold: '600',
          heavy: '700',
        },
        colors,
        globals: {
          ...defaults.globals,
          ...globals,
          ...outlineShadows(colors, colors['drop-2']),
          'border-color-inherit': { borderColor: 'inherit', transition: '' },
          'outline-none': { outlineWidth: 0 },
          'shadow-inline': inlineShadow(colors['primary']),
          'shadow-outline': outlineShadow(colors['primary'], colors['drop-1']),
          'shadow-input': { boxShadow: '' },
          'transform-center': { transformOrigin: '50% 50%' },
          'max-h-inherit': { maxHeight: 'inherit' },
        },
        transition: transition(),
      })
    )
  )
}

const themeUtilities = (name, specialProps) =>
  utilities(
    { ...commonColors, ...themesColors[name] },
    {
      light: {
        'shadow-xs': { boxShadow: '0 1px 3px 2px rgba(21, 25, 31, 0.1)' },
        'shadow-sm': { boxShadow: '0 3px 6px 2px rgba(21, 25, 31, 0.15)' },
        'shadow-md': { boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)' },
      },
      dark: {
        'shadow-xs': {
          boxShadow: `0px 1px 1px 0px rgb(0 0 0 / 22%),
            0px 2px 1px -1px rgb(0 0 0 / 16%),
            0px 1px 3px 0px rgb(0 0 0 / 28%)`,
        },
        'shadow-sm': {
          boxShadow: `0px 2px 2px 0px rgb(0 0 0 / 22%),
            0px 3px 1px -2px rgb(0 0 0 / 16%),
            0px 1px 5px 0px rgb(0 0 0 / 28%)`,
        },
        'shadow-md': {
          boxShadow: `0px 8px 10px 1px rgb(0 0 0 / 22%),
            0px 3px 14px 2px rgb(0 0 0 / 16%),
            0px 5px 5px -3px rgb(0 0 0 /280%)`,
        },
      },
    }[name],
    specialProps
  )

const themesUtilities = {
  dark: R.once(() =>
    themeUtilities(
      'dark',
      R.over(
        R.lensProp('globals'),
        R.merge({ blend: { mixBlendMode: 'darken' } })
      )
    )
  ),
  light: R.once(() =>
    themeUtilities(
      'light',
      R.over(
        R.lensProp('globals'),
        R.merge({ blend: { mixBlendMode: 'lighten' } })
      )
    )
  ),
}

const theme = (theme) => ({
  directives,
  breakpoints,
  globals: { theme },
  utilities: themesUtilities[theme](),
  constants: {
    '': {
      ...SIZES,
      ...extraColors,
      ...commonColors,
      ...themesColors[theme],
    },
    color: themesColors[theme],
  },
})

const themes = {
  dark: theme('dark'),
  light: theme('light'),
}

const RootStyleProvider = ({ children, value, ...props }) => (
  <div id="app-root" className={styles.root} {...props}>
    <StyleProvider value={value} children={children} />
  </div>
)

const contextValue = ({ mode }) => themes[mode === 'dark' ? 'dark' : 'light']

const hookValue = hooks.consume(['mode'])(hooks.assoc('value', contextValue))

const onMount = ({
  value: {
    constants: { color },
  },
}) => (document.querySelector('html').style.backgroundColor = color['drop-2'])

export const hookTheme = (name, fn = R.path(['globals', 'theme'])) =>
  hooks.tap(hooks.context('themeContext', StyleContext))(
    hooks.assoc(name, (props) => fn(props.themeContext(), props))
  )

export default assign({
  Root: hooks(
    hookValue,
    import.meta.env.VITE_PROJECT !== 'projects'
      ? hooks.tapEffect(onMount, ['value'])
      : R.identity
  )(RootStyleProvider),
})(hooks(hookValue)(StyleProvider))
