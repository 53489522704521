import * as R from 'ramda'

import { makePercents, makePow2s, negate } from './utils'

export const SPACINGS = {
  0: 0,
  ...makePow2s(R.range(0, 7)),
}

const LABEL_SIZES = {
  xxs: 24,
  xs: 26,
  sm: 30,
  md: 36,
  lg: 42,
  xl: 50,
}

const FONT_SIZES = {
  '3xs': 9,
  xxs: 11,
  xs: 12,
  sm: 13,
  md: 15,
  lg: 18,
  xl: 21,
  xxl: 24,
  '3xl': 28,
  '4xl': 32,
}

export const SIZES = {
  0: 0,
  full: '100%',
  auto: 'auto',
  fit: 'fit-content',
  ...LABEL_SIZES,
  ...makePercents(12),
  ...makePow2s(R.range(0, 11)),
}

const BASE_POSITIONS = {
  '1/2': '50%',
  full: '100%',
  ...SPACINGS,
}

const POSITIONS = Object.assign(BASE_POSITIONS, negate(BASE_POSITIONS))

const COLORS = {
  black: '#000',
  white: '#fff',
  transparent: 'transparent',
  'gray-50': '#f9f9f9',
  'gray-100': '#dedee1',
  'gray-200': '#cacbce',
  'gray-300': '#878e97',
  'gray-400': '#626975',
  'gray-500': '#3e4554',
  'gray-600': '#2b313c',
  'gray-700': '#212730',
  'gray-800': '#15191f',
  'green-100': '#e7ffed',
  'green-200': '#cfffdc',
  'green-300': '#9ef2b4',
  'green-400': '#5ae284',
  'green-500': '#00ad60',
  'green-600': '#008850',
  'red-100': '#ffebef',
  'red-200': '#ffcdd7',
  'red-300': '#ffb3c0',
  'red-400': '#ff7578',
  'red-500': '#e8404e',
  'red-600': '#ac0023',
  'purple-100': '#f0ecfb',
  'purple-200': '#e0d7fc',
  'purple-300': '#d0b3fd',
  'purple-400': '#c190ff',
  'purple-500': '#8c47e6',
  'purple-600': '#40256c',
  'blue-100': '#e3fbfd',
  'blue-200': '#d1fbff',
  'blue-300': '#aaf0ff',
  'blue-400': '#68ceff',
  'blue-500': '#3372f1',
  'blue-600': '#113a8e',
  'orange-100': '#fcebd4',
  'orange-200': '#ffdfb1',
  'orange-300': '#ffc884',
  'orange-400': '#ffae43',
  'orange-500': '#f58318',
  'orange-600': '#cf3800',
  'yellow-100': '#faf6dd',
  'yellow-200': '#fcf5c1',
  'yellow-300': '#ffec8c',
  'yellow-400': '#ffe557',
  'yellow-500': '#ffcd00',
  'yellow-600': '#edae00',
}

export default ({
  sizes = SIZES,
  colors = COLORS,
  spacings = SPACINGS,
  positions = POSITIONS,
  ...config
} = {}) => ({
  display: {
    flex: 'flex',
    grid: 'grid',
    block: 'block',
    hidden: 'none',
    inline: 'inline',
    'inline-block': 'inline-block',
  },

  // TODO
  // .visible	visibility: visible;
  // .invisible	visibility: hidden;

  width: sizes,
  maxWidth: sizes,
  minWidth: sizes,

  height: sizes,
  maxHeight: sizes,
  minHeight: sizes,

  overflow: {
    scroll: 'scroll',
    hidden: 'hidden',
    visible: 'visible',
  },

  image: null,
  tintColor: null,
  overlayColor: null,
  backfaceVisibility: null,

  elevation: null,
  shadowColor: null,
  shadowOffset: null,
  shadowRadius: null,
  shadowOpacity: null,

  shadow: {
    none: { boxShadow: 'none' },
    xs: {
      boxShadow:
        '0 2px 2px 0 rgba(0,0,0,0.07), 0 3px 1px -2px rgba(0,0,0,0.06), 0 1px 5px 0 rgba(0,0,0,0.1)',
    },
    sm: {
      boxShadow:
        '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
    },
    md: {
      boxShadow:
        '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)',
    },
    lg: {
      boxShadow:
        '0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)',
    },
    xl: {
      boxShadow:
        '0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2)',
    },
  },

  textColor: colors,
  borderColor: colors,
  backgroundColor: colors,

  gap: spacings,
  padding: spacings,
  margin: { auto: 'auto', ...spacings, ...negate(spacings) },

  opacity: {
    0: 0,
    1: 1,
  },

  borderRadius: {
    none: 0,
    xs: 1,
    sm: 2,
    md: 4,
    lg: 8,
    xl: 12,
    full: 9999,
  },

  borderWidth: {
    0: 0,
    1: 1,
    2: 2,
    3: 4,
  },

  borderStyle: {
    solid: 'solid',
    dashed: 'dashed',
    dotted: 'dotted',
  },

  flexDirection: {
    row: 'row',
    col: 'column',
    'row-reverse': 'row-reverse',
    'col-reverse': 'column-reverse',
  },

  justifyContent: {
    end: 'flex-end',
    center: 'center',
    start: 'flex-start',
    evenly: 'space-evenly',
    around: 'space-around',
    between: 'space-between',
  },

  justifySelf: {
    end: 'flex-end',
    center: 'center',
    stretch: 'stretch',
    start: 'flex-start',
  },

  alignItems: {
    end: 'flex-end',
    center: 'center',
    stretch: 'stretch',
    start: 'flex-start',
    baseline: 'baseline',
  },

  alignSelf: {
    end: 'flex-end',
    center: 'center',
    stretch: 'stretch',
    start: 'flex-start',
  },

  alignContent: {
    end: 'flex-end',
    center: 'center',
    stretch: 'stretch',
    start: 'flex-start',
    around: 'space-around',
    between: 'space-between',
  },

  // TW not-italic
  fontStyle: {
    'style-normal': 'normal',
    italic: 'italic',
  },

  fontWeight: {
    thin: '100',
    'ultra-light': '200',
    light: '300',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    heavy: '800',
    black: '900',
  },

  lineHeight: {
    none: '',
    tight: 1.25,
    snug: 1.375,
    normal: 1.5,
    relaxed: 1.625,
    loose: 2,
    ...R.map((v) => 8 + v + 'px', FONT_SIZES),
  },

  textAlign: {
    'align-auto': 'auto',
    left: 'left',
    right: 'right',
    center: 'center',
    justify: 'justify',
  },

  // NATIVE
  // textDecorationLine
  textDecoration: {
    'text-line-none': 'none',
    underline: 'underline',
    'line-through': 'line-through',
    'underline-through': 'underline line-through',
  },

  textTransform: {
    'text-transform-none': 'none',
    uppercase: 'uppercase',
    lowercase: 'lowercase',
    capitalize: 'capitalize',
  },

  flexWrap: {
    wrap: 'wrap',
    'no-wrap': 'no-wrap',
  },

  flexGrow: {
    '': 1,
    1: 1,
    0: 0,
    none: 0,
  },

  flexShrink: {
    '': 1,
    1: 1,
    0: 0,
    none: 0,
  },

  zIndex: {
    0: 0,
    10: 10,
    20: 20,
    30: 30,
    40: 40,
    50: 50,
    auto: 'auto',
  },

  position: {
    sticky: 'sticky',
    relative: 'relative',
    absolute: 'absolute',
    fixed: 'fixed',
  },

  top: positions,
  left: positions,
  right: positions,
  bottom: positions,

  fontSize: FONT_SIZES,

  transition: {
    none: 'none',
    paint:
      'background-color ease-in-out 300ms, border-color ease-in-out 300ms, color ease-in-out 300ms, fill ease-in-out 300ms, box-shadow ease-in-out 200ms',
    slow: 'all ease-in-out 300ms',
    quick: 'all ease-in-out 200ms',
    '': 'all ease-in-out 250ms',
    'bg-quick': 'background ease-in-out 200ms',
    bg: 'background ease-in-out 250ms',
    'transform-quick': 'transform ease-in-out 200ms',
    perf: 'opacity ease-in-out 250m, transform ease-in-out 250ms',
  },

  transform: {
    'rotate-0': 'rotate(0deg)',
    'rotate-1/4': 'rotate(90deg)',
    'rotate-1/2': 'rotate(180deg)',
    'rotate-3/4': 'rotate(270deg)',
    '-rotate-1/4': 'rotate(-90deg)',
    '-rotate-1/2': 'rotate(-180deg)',
    '-rotate-3/4': 'rotate(-270deg)',
    'flip-y': 'scale(1, -1)',
    'flip-x': 'scale(-1, 1)',
    'translate-x-1/2': 'translateX(50%)',
    'translate-y-1/2': 'translateY(50%)',
    '-translate-x-1/2': 'translateX(-50%)',
    '-translate-y-1/2': 'translateY(-50%)',
    'translate-x': 'translateX(100%)',
    'translate-y': 'translateY(100%)',
    '-translate-x': 'translateX(-100%)',
    '-translate-y': 'translateY(-100%)',
    'translate-xy-1/2': 'translateX(50%) translateY(50%)',
    '-translate-xy-1/2': 'translateX(-50%) translateY(-50%)',
  },

  flexBasis: sizes,

  textShadowOffset: null,
  textShadowColor: null,
  fontFamily: null,
  textShadowRadius: null,
  fontVariant: null,
  letterSpacing: null,
  textDecorationColor: null,
  textDecorationStyle: null,
  writingDirection: null,

  globals: {
    'flex-1': { order: 1 },
    'flex-2': { order: 2 },
    'flex-3': { order: 3 },
    'flex-flex': { flexGrow: 1, flexShrink: 1 },
    'flex-stiff': { flexGrow: 0, flexShrink: 0 },
    box: { boxSizing: 'border-box' },
    'box-content': { boxSizing: 'content-box' },

    full: { width: '100%', height: '100%' },

    border: {
      borderWidth: 1,
      borderStyle: 'solid',
    },
    'border-b': {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
    },
    'border-t': {
      borderTopWidth: 1,
      borderTopStyle: 'solid',
    },
    'border-l': {
      borderLeftWidth: 1,
      borderLeftStyle: 'solid',
    },
    'border-r': {
      borderRightWidth: 1,
      borderRightStyle: 'solid',
    },
    'border-b-none': { borderBottomWidth: 0 },
    'border-t-none': { borderTopWidth: 0 },
    'border-l-none': { borderLeftWidth: 0 },
    'border-r-none': { borderRightWidth: 0 },

    'flex-center': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    'absolute-center': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    'absolute-x-center': {
      left: '50%',
      transform: 'translateX(-50%)',
    },
    'absolute-y-center': {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    'absolute-fill': {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    'text-ellipsis': { overflow: 'hidden', textOverflow: 'ellipsis' },
    'text-clip': { overflow: 'hidden', textOverflow: 'clip' },
    'text-wrap-none': { whiteSpace: 'nowrap' },
    'text-wrap': { whiteSpace: 'initial' },
    'text-line-break': { whiteSpace: 'pre-line' },
    'text-break': { wordBreak: 'break-word' },
    'events-none': { pointerEvents: 'none' },
    'events-all': { pointerEvents: 'all' },
    'cursor-auto': { cursor: 'auto' },
    'cursor-default': { cursor: 'default' },
    'cursor-grab': { cursor: 'grab' },
    'cursor-normal': { cursor: 'normal' },
    'cursor-pointer': { cursor: 'pointer' },
    'cursor-not-allowed': { cursor: 'not-allowed' },
    center: { alignItems: 'center', justifyContent: 'center' },
    'flex-scroll': { overflowX: 'hidden' },
    'flex-scroll-x': { overflowY: 'hidden' },
    'overflow-y-hidden': { overflowY: 'hidden' },
    'bg-cover': { backgroundSize: 'cover' },
    'fill-current': { fill: 'currentColor' },
    'user-select-none': { userSelect: 'none' },
    'g-x': { gap: '0.3em' },
    'g-y': { gap: '0.8em' },
    'origin-center': { transformOrigin: 'center center' },
  },

  ...config,
})
