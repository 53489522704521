import * as R from 'ramda'
import hooks from '__hooks__'
import View from '__components__/View'

const textShimmerStyle = {
  animationDuration: '1.5s',
  animationDirection: 'alternate',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
  animationName: 'shimmerMaskAnimation',
  dark: {
    color: 'white',
    maskImage:
      'linear-gradient(to right, rgba(255,255,255,0.2), #fff, rgba(255,255,255,0.2))',
    WebkitMaskImage:
      'linear-gradient(to right, rgba(255,255,255,0.2), #fff, rgba(255,255,255,0.2))',
  },
  light: {
    color: 'black',
    maskImage:
      'linear-gradient(to right, rgba(0,0,0,0.2), #000, rgba(0,0,0,0.2))',
    WebkitMaskImage:
      'linear-gradient(to right, rgba(0,0,0,0.2), #000, rgba(0,0,0,0.2))',
  },
  WebkitMaskSize: '500% 100%',
  WebkitMaskRepeat: 'no-repeat',
}

const textStyleRight = ({ isLoading, styleRight }) => [
  (isLoading || isLoading === undefined) && textShimmerStyle,
  styleRight,
]

export default hooks(
  hooks.assoc('styleRight', textStyleRight),
  R.omit(['isLoading'])
)(View.Text, 'LoadingText')
