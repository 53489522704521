import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'react-recompose'
import { withProps } from 'react-recompose'

import $ from '__store__'
import styles from './styles.module.css'
import { getUI } from '__store__/ui'
import Transition from 'Transition'
import assign from '__lib__/react/assign'
import lifecycle from '__lib__/react/lifecycle'
import withClassName from '__lib__/react/withClassName'
import withStore from '__lib__/react/withStore'
import withoutProps from '__lib__/react/withoutProps'

const style = { width: '100%', height: '100%', overflow: 'hidden' }

const BackdropScreen = compose(
  withClassName(['fixed absolute-fill z-index-10 bg-grey-8', styles.fade]),
  withProps({ wrap: true, custom: styles.fade })
)(Transition)

const OpacityBackdrop = ({
  dispatch,
  backdropStyle,
  backdropOptions: { isActive, withoutMouseEvents, customStyle } = {},
  ...props
}) => (
  <React.Fragment>
    <div {...props} style={style} />
    {isActive && (
      <BackdropScreen
        style={{ ...backdropStyle, ...customStyle }}
        className={withoutMouseEvents && 'events-none'}
      />
    )}
  </React.Fragment>
)

const mapState = (state) => ({
  backdropOptions: getUI(state, 'backdrop'),
})

const didMount = (
  { withoutMouseEvents, store, backdropStyle: customStyle = {}, ...rest },
  instance
) => {
  const state = getUI(store.getState(), 'backdrop')
  if (state && state.isActive) return
  instance.hasSetBackdrop = true
  $.uiSet('backdrop', { isActive: true, withoutMouseEvents, customStyle })
}

const willUnmount = (_, { hasSetBackdrop }) =>
  hasSetBackdrop && $.uiSet('backdrop', { isActive: false })

const whileMounted = compose(
  withStore,
  lifecycle({ didMount, willUnmount }),
  withoutProps(['store'])
)

const Fragment = whileMounted(({ children }) => children || null)

export default compose(
  assign({ whileMounted, Fragment }),
  connect(mapState)
)(OpacityBackdrop)
