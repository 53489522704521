import View from '__components__/View'
import Layout from './Layout'

const ConfirmEmailChange = ({ data: { statusMessage = '' } }) => (
  <Layout>
    <View.Text
      styleLeft={{ fontSize: 34, lineHeight: '48px' }}
      style="text-center font-semibold laptop:mx-7"
    >
      {statusMessage}
    </View.Text>
  </Layout>
)

export default ConfirmEmailChange
