import React from 'react'
import ReactDOM from 'react-dom'
import { compose } from 'react-recompose'
import { withPropsOnChange } from 'react-recompose'

import Anchor from './Anchor'
import lifecycle from '__lib__/react/lifecycle'
import componentHOC from '__lib__/react/component'
import withDecorators from '__lib__/react/withDecorators'

const Toggle = ({
  dialog,
  isAnchor,
  openDialog,
  passToggleDialog,
  blockEventBubbling,
  component: Component,
  ...props
}) => <Component {...props} {...(passToggleDialog && { dialog })} />

const didMount = (_, instance) => {
  const node = ReactDOM.findDOMNode(instance)
  const handler = (event) =>
    instance.props.dialog.isOpen && event.stopPropagation()
  node && node.addEventListener('mousedown', handler)
  node && node.addEventListener('touchstart', handler)
  node && node.addEventListener('focusin', handler)
  Object.assign(instance, { node, handler })
}

const willUnmount = (_, { node, handler }) => {
  node && node.removeEventListener('mousedown', handler)
  node && node.removeEventListener('touchstart', handler)
  node && node.removeEventListener('focusin', handler)
}

const onClick =
  ({ dialog, openDialog, disabled, blockEventBubbling }) =>
  (event, ...args) => {
    if (blockEventBubbling) {
      event.preventDefault()
      event.stopPropagation()
    }
    !disabled &&
      dialog &&
      (!dialog.isOpen && openDialog !== undefined
        ? openDialog && openDialog()
        : dialog.toggle(event, ...args))
  }

const component = ({ isAnchor, component, dialog }) => ({
  component: isAnchor
    ? componentHOC(<Anchor dialog={dialog} component={component} />)
    : component,
})

export default compose(
  lifecycle({ didMount, willUnmount }),
  withDecorators({ onClick }),
  withPropsOnChange(['isAnchor', 'component', 'dialog'], component)
)(Toggle)
