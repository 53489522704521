import React from 'react'
import propsWithRef from './propsWithRef'
import objectWithValues from '__lib__/object/withValues'

const lifecycleMethodFactory = (fn) =>
  function () {
    return fn(this.props, this)
  }

const didUpdateFactory = (fn) =>
  function (prevProps) {
    return fn(this.props, prevProps, this)
  }

const addMethod = (Klass, name, fn, methodFactory) =>
  fn && (Klass.prototype[name] = methodFactory(fn))

export default (
    { didMount, didUpdate, willUnmount },
    withInstance,
    handlers,
    initialize
  ) =>
  (Component) => {
    const factory = React.createFactory(Component)
    class Lifecycle extends React.Component {
      constructor(props, context) {
        super(props, context)
        initialize && initialize(props, this)
        handlers &&
          Object.assign(
            this,
            objectWithValues(
              handlers,
              (fn) =>
                (...args) =>
                  fn(this.props, this)(...args)
            )
          )
      }
    }

    addMethod(Lifecycle, 'componentDidMount', didMount, lifecycleMethodFactory)
    addMethod(
      Lifecycle,
      'componentWillUnmount',
      willUnmount,
      lifecycleMethodFactory
    )
    addMethod(Lifecycle, 'componentDidUpdate', didUpdate, didUpdateFactory)

    handlers &&
      Object.assign(
        Lifecycle.prototype,
        objectWithValues(
          handlers,
          (fn) =>
            function (...args) {
              return fn(this.props, this)(...args)
            }
        )
      )
    Lifecycle.prototype.render = function () {
      return factory(
        propsWithRef(Component, {
          ...this.props,
          ...this.state,
          ...(withInstance ? { [withInstance]: this } : null),
        })
      )
    }

    return Lifecycle
  }
