import View from '__components__/View'
import StyledIcon from '__views__/StyledIcon'
import { Header } from '__screens__/Layout'
import Cloud from './cloud.svg?react'

const CloudHeader = ({ isRegister }) => (
  <View style="flex flex-col text-center text-shade-7 items-center justify-center">
    <StyledIcon withShadow component={Cloud} style="mb-5" />
    <Header
      title={
        <View style="flex flex-col">
          <View>
            To start <View.Text style={{ color: '#219DE3' }}>syncing</View.Text>{' '}
            your
          </View>
          <View>document to the cloud</View>
        </View>
      }
      description={
        isRegister
          ? 'Create a Drawboard PDF account'
          : 'Choose from your log in options'
      }
    />
  </View>
)

export default CloudHeader
