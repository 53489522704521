import React from 'react'
import * as R from 'ramda'
import withStyle, { withStyleProp, useStyle } from './core'
export * from './core'

export { withStyle }

const component = (displayName, defaultNode) =>
  Object.assign(
    React.forwardRef(
      (
        { component: Component = defaultNode, styleLeft, styleRight, ...props },
        ref
      ) => {
        const { styles } = useStyle(null, { styleLeft, styleRight, ...props })
        return <Component ref={ref} {...props} {...styles} />
      }
    ),
    { displayName }
  )

const invoke = (invoke, props) =>
  !invoke ? null : R.is(String, invoke) ? (
    <Text {...props} children={invoke} />
  ) : (
    invoke(props)
  )

export const Text = Object.assign(component('Text', 'span'), { invoke })
export const Form = component('Form', 'form')
export const Button = withStyleProp('button')

export const View = Object.assign(component('View', 'div'), {
  Text,
  Form,
  Button,
})
