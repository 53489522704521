import React from 'react'
import { compose } from 'react-recompose'
import { withHandlers } from 'react-recompose'

import LinkIcon from 'Link/Icon'
import Icon from 'Icon'
import Dialog from './Dialog'
import withClassName from '__lib__/react/withClassName'

const PermissionDialog = ({
  label,
  dialog,
  children,
  onClose,
  innerClassName,
  ...props
}) => (
  <Dialog dialog={dialog} {...props}>
    <div className={innerClassName}>
      <div className="flex-center mb3">
        <span className="c-danger fw2">
          <Icon icon="new/error" className="mr2" /> Permission error
        </span>
        <LinkIcon
          icon="new/cancel"
          onClick={onClose}
          className="mla c-shade-4"
        />
      </div>
      {label && `You do not have permission to ${label}.`}
      {children}
      <div className="mt3">Please contact your administrator.</div>
    </div>
  </Dialog>
)

const onClose =
  ({ dialog, onClose }) =>
  () => {
    dialog.close()
    onClose && onClose()
  }

export default compose(
  withClassName({
    innerClassName: 'ph4 pv3',
    className: 'layout-w1',
  }),
  withHandlers({ onClose })
)(PermissionDialog)
