/**
 * @module store.central
 *
 * Contains methods to define "intents" and "handlers".
 *
 * An intent is simply a global function in the store.
 * Handlers transform the store from one state to another.  They are not exactly
 * "reducers" because they only handle a single message type.
 *
 * Typically you will define an intent and a handler together using Central.pair(),
 * use the intent to fetch some data and call `dispatch(handler)` to call the handler
 * to commit a state change to the redux store.
 */

import initialState from './initialState'
import identity from '__lib__/utils/identity'

export const intents = {}

const handlers = {}
let dispatch = null

/**
 * @typedef {(...args: unknown[]) => unknown} CentralIntentFn
 * @typedef {(store: StoreState, ...payload: unknown[]) => StoreState} CentralHandlerFn
 */

const handler = (key, fn) => (handlers[key] = fn)
// ? console.warn(`[ERROR]: Handler already exists on ${key}`)
// : (handlers[key] = fn)

const defaultIntentFactory =
  (key) =>
  (...payload) =>
    dispatch(key, ...payload)

/**
 * Adds an "intent" (a redux action) to the store and returns a function to call that action.
 * @param {string} key
 * @param {CentralIntentFn} actionFn
 * @returns {CentralIntentFn}
 */
const intent = (key, actionFn) => {
  intents[key]
    ? console.warn(`[ERROR]: intent already exists on ${key}`)
    : (intents[key] = actionFn || defaultIntentFactory(key))
  return map(key)
}

/**
 * Returns the "intent" (a redux action) for a given key
 * @param {string} key
 * @returns {CentralIntentFn}
 */
const map =
  (key) =>
  (...args) =>
    intents[key](...args)


/**
 * Stores an "intent" (a redux action)
 * and a "handler" (like a reducer but for a single message) under the same key.
 * @param {string} key
 * @param {CentralIntentFn} intentFn
 * @param {CentralHandlerFn} handlerFn
 * @returns {}
 */
const pair = (key, intentFn, handlerFn) => {
  handler(key, handlerFn)
  return intent(key, intentFn)
}

const pairs = (pairDefinitions) =>
  pairDefinitions.forEach(([key, intentFn, handlerFn]) =>
    pair(key, intentFn, handlerFn)
  )

/**
 * Returns the handler function (function that transforms the store) for a given key.
 * @param {string} key
 * @returns {CentralHandlerFn}
 */
const getHandler = (key) => handlers[key] || identity

export const setDispatch = (storeDispatch) => (dispatch = storeDispatch)

export const reducer = (state = initialState(), { type, payload = [] }) =>
  getHandler(type)(state, ...payload)

/**
 * Returns a function that may be an "intent" or a "handler".
 * Tries to call the "intent" (like a redux action), and if it doesn't exist
 * falls back to the "handler" (like a reducer but for a single message)
 * @param {string} key
 */
const action =
  (key) =>
  (...args) =>
  (_dispatch, _getState) =>
    intents[key] ? intents[key](...args) : dispatch(key, ...args)

export default {
  map,
  pair,
  pairs,
  intent,
  handler,
  action
}
