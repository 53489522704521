import * as R from 'ramda'

export const tapLog2 = (...args) => {
  const last = args.pop()
  console.log(...args)
  return last
}

export const tapLog = (...args) => {
  console.log('[]', ...args)
  // console.log(...args)
  return args[args.length - 1]
}

export const tapLogF =
  (a, map = R.identity) =>
  (b) => {
    console.log(a, map(b))
    return b
  }

export const tapIntoLog = (what, fn) =>
  R.compose(
    R.tap(R.partial(tapLog, ['after', what])),
    fn,
    R.tap(R.partial(tapLog, ['before', what]))
  )

console['tapLog'] = tapLog
console['tapLogF'] = tapLogF
console['tapLog2'] = tapLog2
console['tapIntoLog'] = tapIntoLog

// const proxyToHtml = (name = 'log', { style } = {}) => {
//   const consoleLog = console[name]
//   const elementRef = { current: null, count: 0 }
//   console[name] = (...args) => {
//     const log = (elementRef.current = document.createElement('div'))
//     Object.assign(log.style, {
//       fontSize: 24,
//       color: 'white',
//       background: 'black',
//       position: 'fixed',
//       top: ((elementRef.count += 1) - 1) * 46,
//       left: 0,
//       padding: '12px',
//       zIndex: 100000000,
//       ...style,
//     })
//     document.body.appendChild(log)
//     log.innerHTML = args.map((arg) => JSON.stringify(arg)).join(' ')
//     consoleLog.apply(console, args)
//   }
// }

// proxyToHtml('log')
// proxyToHtml('error', { style: { background: 'tomato' } })
