import hooks from '__hooks__'
import View from '__components__/View'
import { back } from 'Canvas/keyboardShortcuts'
import { eventMatches } from '__lib__/web/shortcut'

const backStack = []
const onBackButton = (fn) => {
  backStack.push(fn)
  return () => backStack.splice(backStack.indexOf(fn), 1)
}

export const triggerBackButton = () => {
  const current = backStack[backStack.length - 1]
  current && current()
  return Boolean(current)
}

window.appTriggerBackButton = triggerBackButton

document.addEventListener(
  'keydown',
  (event) => eventMatches(event, back) && triggerBackButton()
)

const mountLinkAsBackButton = ({ ref }) =>
  onBackButton(() => ref.current?.click())

export const Link = hooks(
  hooks.bubbleRef('ref'),
  hooks.effect(mountLinkAsBackButton)
)(View.Generic)

const mountAsBackButton = ({ onPress }) => onBackButton(onPress)

export const Button = hooks(hooks.effect(mountAsBackButton))(View.Generic)

export default Object.assign(Button, { Link })

// import hooks from '__hooks__'
// import View from '__components__/View'

// const Link = hooks()(View.Generic)
// const BackButton = hooks()(View.Generic)

// export default Object.assign(BackButton, { Link })
