import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'react-recompose'
import { getContext } from 'react-recompose'
import { withContext } from 'react-recompose'
import { withHandlers } from 'react-recompose'

import hooks from '__hooks__'
import assign from '__lib__/react/assign'
import refProps from '__lib__/react/refProps'
import lifecycle from '__lib__/react/lifecycle'
import withoutProps from '__lib__/react/withoutProps'
import withInstance from '__lib__/react/withInstance'
import withClassName from '__lib__/react/withClassName'
import objectEqualsKeys from '__lib__/object/equalsKeys'

const StayOnScreen = ({
  margin,
  setRef,
  update,
  instance,
  component: Component = 'div',
  ...props
}) => <Component {...refProps(Component, setRef)} {...props} />

// the component could be used to fit anywhere as long as it is
// provided an outer box, for now the window is sufficient
const getOuterBox = () => ({
  top: 0,
  left: 0,
  right: window.innerWidth,
  bottom: window.innerHeight,
  width: window.innerWidth,
  height: window.innerHeight,
})

const getInnerBox = (ref) => {
  // translate box the innerBox with our already applied translation
  const y = parseInt(ref.style.top, 10) || 0
  const x = parseInt(ref.style.left, 10) || 0
  const { top, left, right, bottom, width, height } =
    ref.getBoundingClientRect()

  return {
    width,
    height,
    top: top - y,
    left: left - x,
    right: right - x,
    bottom: bottom - y,
  }
}

const computeAxis = (iLength, iMin, iMax, oLength, oMin, oMax, margin) =>
  iLength > oLength
    ? oMin - iMin
    : iLength > oLength - margin
    ? oMin - iMin + margin
    : iMin < oMin + margin
    ? oMin - iMin + margin
    : iMax > oMax - margin
    ? oMax - iMax - margin
    : 0

const computeYAxis = (
  { height, top, bottom },
  { height: oHeight, top: oTop, bottom: oBottom },
  margin
) => computeAxis(height, top, bottom, oHeight, oTop, oBottom, margin)

const computeXAxis = (
  { width, left, right },
  { width: oWidth, left: oLeft, right: oRight },
  margin
) => computeAxis(width, left, right, oWidth, oLeft, oRight, margin)

const update =
  ({ instance: { ref }, margin = 16 }) =>
  () => {
    if (!ref) return
    const outerBox = getOuterBox(ref)
    const innerBox = getInnerBox(ref)
    const top = computeYAxis(innerBox, outerBox, margin)
    const left = computeXAxis(innerBox, outerBox, margin)
    Object.assign(ref.style, { left: `${left}px`, top: `${top}px` })
  }

const getChildContext = ({ update }) => ({ onDidUpdate: update })
const childContextTypes = { onDidUpdate: PropTypes.func.isRequired }

const reflowsOnPropsChangeDidUpdateFy =
  (propKeys) => (newProps, oldProps, instance) =>
    (!propKeys || !objectEqualsKeys(newProps, oldProps, propKeys)) &&
    newProps.onDidUpdate(instance)

const setRef =
  ({ instance, update }) =>
  (ref) =>
    update((instance.ref = ref))

export const reflowsOnPropsChange = (propKeys) =>
  compose(
    getContext(childContextTypes),
    lifecycle({ didUpdate: reflowsOnPropsChangeDidUpdateFy(propKeys) }),
    withoutProps(['onDidUpdate'])
  )

const onMount = ({ update }) => {
  window.addEventListener('resize', update)
  return () => window.removeEventListener('resize', update)
}

export default compose(
  assign({ reflowsOnPropsChange }),
  withClassName('relative'),
  withInstance('instance'),
  withHandlers({ update }),
  withContext(childContextTypes, getChildContext),
  withHandlers({ setRef })
)(hooks(hooks.effect(onMount))(StayOnScreen))
