import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'react-recompose'
import { withHandlers } from 'react-recompose'

import $ from '__store__'
import Message from './Message'
import styles from './styles.module.css'
import Transition from 'Transition'
import withoutProps from '__lib__/react/withoutProps'
import withClassName from '__lib__/react/withClassName'
import { getMessagesList, getMessage } from '__store__/messages'

const toggle =
  ({ id, toggle }) =>
  () =>
    toggle(id)

const mapMessageStateToProps = (state, { id }) => getMessage(state, id)

const MessageInList = compose(
  connect(mapMessageStateToProps),
  withHandlers({ toggle }),
  withoutProps(['id', 'dispatch']),
  withClassName('ph2')
)(Message)

const Messages = ({ messages, toggle, ...props }) => (
  <div {...props}>
    <div className="ph3">
      {messages &&
        messages.map((id) => (
          <Transition wrap key={id}>
            <MessageInList toggle={toggle} id={id} />
          </Transition>
        ))}
    </div>
  </div>
)

const mapStateToProps = (state) => ({
  messages: getMessagesList(state),
})

const mapDispatchToProps = () => ({
  toggle: $.messagesRemove,
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withClassName(['w-100 mw-6-ns z-[1000] mt2', styles.root])
)(Messages)
