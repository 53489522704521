import * as R from 'ramda'

import Layout from './Layout'
import Form from '__core__/Form'
import hooks from '__hooks__'
import { View } from 'react-with-style'
import Input from '__components__/Input'
import Button from '__components__/Button'
import { validateEmail } from './validateEmail'
import FormForgeryToken from './FormForgeryToken'
import upperFirst from '__lib__/string/upperFirst'

const LoginOrganization = ({ form, data: { formAction, normalLoginUrl } }) => (
  <Layout rightContainerStyle="bg-drop-1">
    <View style="flex-center w-full flex-col flex-flex">
      <View style="flex-center font-bold text-xl">Organization Login</View>
      <Form
        form={form}
        method="post"
        style="w-full max-w-10"
        action={formAction}
        component={View.Form}
      >
        <View style="flex flex-col laptop:mt-5">
          <Input.Field
            lg
            autoFocus
            tabIndex="1"
            type="email"
            label="Email"
            name="Input.Email"
            validate={validateEmail}
            placeholder="E.g. john.smith@drawboard.com"
          />
          <View style="flex-center laptop:mt-5">
            <FormForgeryToken />
            <Form.Submit
              xl
              plain
              secondary
              text="Continue"
              component={Button}
              style={['rounded-full', { width: 208 }]}
            />
          </View>
        </View>
      </Form>
    </View>
    <View style="flex-center mt-auto py-5 laptop:py-6 flex-stiff">
      <Button
        component="a"
        href={normalLoginUrl}
        style="rounded-full flex-center"
      >
        <View.Text style="text-secondary font-bold text-md ml-4">
          Sign in with personal account
        </View.Text>
      </Button>
    </View>
  </Layout>
)

const INVALID_ATTEMPT = 'Invalid login attempt.'
const ORGANIZATION_IDP_NOT_FOUND = 'OIDPNF'

const setFormErrors = ({ form, data: { formState = [] } }) =>
  form.setFieldsError(
    R.reduce(
      (fieldErrors, { key, errors }) =>
        (key === '' || (errors && errors?.[0]?.errorMessage === INVALID_ATTEMPT)
          ? R.mergeLeft({
              'Input.Email': 'Invalid email',
            })
          : key === ORGANIZATION_IDP_NOT_FOUND
          ? R.mergeLeft({
              'Input.Email':
                'The email domain provided was not recognized for single sign on with Drawboard PDF. If this was unexpected please contact support. Otherwise log in with a Drawboard personal account.',
            })
          : R.identity)(fieldErrors),
      {},
      formState
    )
  )

export const initialState = ({ data: { formData } }) =>
  R.reduce(
    (state, key) => R.assoc('Input.' + upperFirst(key), formData[key], state),
    {},
    R.keys(formData || {})
  )

export default Form.withForm(
  'form',
  initialState
)(hooks(hooks.tapEffect(setFormErrors))(LoginOrganization))
