const lsWorks = (() => {
  try {
    //eslint-disable-next-line no-unused-expressions
    return Boolean(window.localStorage)
  } catch (e) {
    return false
  }
})()

export const save = (key, value) => {
  lsWorks && window.localStorage.setItem(key, value)
  return value
}

export const localStorageSave = save
export const localStorageSetItem = save

export const open = (key, ret = null) =>
  (lsWorks && window.localStorage.getItem(key)) || ret

export const localStorageOpen = open
export const localStorageGetItem = open

export const remove = (key) => lsWorks && window.localStorage.removeItem(key)

export const localStorageRemove = remove
export const localStorageRemoveItem = remove

export const localStorageItem = (key) => ({
  get: localStorageGetItem.bind(null, key),
  set: localStorageSetItem.bind(null, key),
  remove: localStorageRemoveItem.bind(null, key),
  0: localStorageGetItem.bind(null, key),
  1: localStorageSetItem.bind(null, key),
  2: localStorageRemoveItem.bind(null, key),
})
