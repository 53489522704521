import * as R from 'ramda'
import { useStyles, useBreakpoints, useBreakpointMatch } from 'react-with-style'

const styles =
  (stylesDef, ...args) =>
  (props) => {
    const { styles, sideStyleProps } = useStyles(stylesDef, props, ...args)
    return R.pipe(R.mergeLeft(styles), R.omit(sideStyleProps))(props)
  }

const style = (style, ...args) => styles({ style }, ...args)

const breakpoints = (name) => (props) => R.assoc(name, useBreakpoints(), props)

const breakpointMatch = (name, query) => (props) =>
  R.assoc(name, useBreakpointMatch(query), props)

const stylesHooks = {
  style,
  styles,
  breakpoints,
  breakpointMatch,
}

export default stylesHooks
