import * as R from 'ramda'

import hooks from '__hooks__'
import { fpGetUI, setUI } from '__store__/ui'
import StyleProvider from '__components__/StyleProvider'
import { isWebview, sendWebviewRequest } from '__lib__/web/webview'

export const COLOR_HEADER_DARK = '#212730'
export const COLOR_HEADER_LIGHT = '#ffffff'
export const COLOR_MANDATORY_LOGIN = '#14234B'

export const getMode = (state) =>
  R.defaultTo(
    import.meta.env.VITE_ANDROID ? 'dark' : 'light',
    fpGetUI('themeMode')(state)
  )

export const hookMetaThemeColor = (fn) =>
  hooks.effect(
    (props) => {
      const themeColor = document.querySelector('meta[name="theme-color"]')
      if (!themeColor) return
      const currentColor = themeColor.getAttribute('content')
      themeColor.setAttribute('content', fn(props))
      return () => themeColor.setAttribute('content', currentColor)
    },
    [fn]
  )

export const hookMode = (name) => hooks.storeState(name, R.always(getMode))

const StoreStyleProvider = hooks(
  hookMode('mode'),
  hookMetaThemeColor(R.always(COLOR_HEADER_DARK))
)(StyleProvider.Root)

const WebviewStyleProvider = ({ hasTheme, ...props }) =>
  hasTheme ? <StoreStyleProvider {...props} /> : null

const retrieveTheme = () => {
  const onTheme = ({ mode }) =>
    setUI('themeMode', mode === 'dark' ? 'dark' : 'light')
  sendWebviewRequest('getUIColorMode').then(onTheme)
}

export const RootStyleProvider = isWebview()
  ? hooks(
      hooks.storeState('hasTheme', R.always(fpGetUI('themeMode')), Boolean),
      hooks.tapEffect(retrieveTheme)
    )(WebviewStyleProvider)
  : StoreStyleProvider

export default hooks(hookMode('mode'))(StyleProvider)
