const DropboxIcon = ({ isDarkMode, status = 'default', ...props }) => (
  <svg
    width="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.71698 0.547173L0 3.60378L3.28302 6.24529L8 3.30189L4.71698 0.547173ZM0 8.81132L4.71698 11.9057L8 9.15095L3.28302 6.24529L0 8.81132ZM8 9.15095L11.3208 11.9057L16 8.84906L12.7547 6.24529L8 9.15095ZM16 3.60378L11.3208 0.547173L8 3.30189L12.7547 6.24529L16 3.60378ZM8.03774 9.75472L4.71698 12.5094L3.32075 11.566V12.6226L8.03774 15.4528L12.7547 12.6226V11.566L11.3208 12.5094L8.03774 9.75472Z"
      fill={
        status === 'active'
          ? '#ffffff'
          : status === 'inactive'
          ? isDarkMode
            ? '#3E4554'
            : '#CACBCE'
          : '#007EE5'
      }
    />
  </svg>
)

export default DropboxIcon
