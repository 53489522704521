import View from '__components__/View'
import Button from '__components__/Button'
import { appleIdUrl } from '__routes__/external.id'
import Layout, { Header } from './Layout'

const FatalAppleError = () => (
  <Layout>
    <Header
      titleStyle="text-xl"
      descriptionStyle="text-lg"
      title="The Drawboard PDF account connected to your Apple sign in is no longer
      active."
      description="You will need to remove Drawboard PDF from Sign in with Apple to start
      again. To do this please:"
    />
    <View component="ol" style="text-shade-7 m-5">
      <li>
        Log into{' '}
        <Button
          component="a"
          target="_blank"
          href={appleIdUrl}
          style="text-primary font-semibold"
        >
          appleid.apple.com
        </Button>
      </li>
      <li>
        Select "Sign In with Apple" from the "Sign-in and Security" section
      </li>
      <li>Select "Drawboard PDF" from the list</li>
      <li>Select "Stop using Sign in with Apple"</li>
    </View>
    <View.Text style="text-center text-shade-4 max-w-10 w-full">
      When you have done this, you will need to{' '}
      <View.Text style="text-shade-7 font-semibold">
        close this window
      </View.Text>{' '}
      and start again to log into Drawboard PDF.
    </View.Text>
  </Layout>
)

export default FatalAppleError
