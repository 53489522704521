import hooks from '__hooks__'

// import * as R from 'ramda'

// import hooks from '__hooks__'
// import {
//   isElementFocusable,
//   getLabelFocusableElement,
// } from '__core__/FocusTrap'

// const onClick =
//   ({ onPress, onClick, ref, inputRef }) =>
//   (event) => {
//     const target = event.target
//     const label = ref.current
//     if (!isElementFocusable(target, { ancestors: true })) {
//       const element =
//         (R.path(['current', 'click'], inputRef) && inputRef.current) ||
//         getLabelFocusableElement(label)
//       event.stopPropagation()
//       event.preventDefault()
//       element && setTimeout(() => element.click())
//     } else {
//       onPress && onPress(event)
//       onClick && onClick(event)
//     }
//   }

const onClick =
  ({ onPress, onClick }) =>
  (event) => {
    onPress && onPress(event)
    onClick && onClick(event)
  }

export default hooks.component(
  hooks.consume(['inputRef', 'onPress'])(hooks.handler('onClick', onClick)),
  hooks.default('component', 'label')
)('InputLabel')
