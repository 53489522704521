import Dialog from '../Dialog'
import ButtonIcon from '../Button/Icon'
import BackButton from '__components__/BackButton'

const Close = ({ component: Component = ButtonIcon, dialog, ...props }) =>
  dialog ? (
    <Dialog.Toggle
      icon="cross"
      dialog={dialog}
      component={BackButton}
      innerComponent={Component}
      hoverTextStyle="text-shade-5"
      textStyle="text-md text-shade-2"
      styleLeft="m-3 tablet:m-4 p-5 rounded-md"
      {...props}
    />
  ) : (
    <BackButton
      icon="cross"
      component={Component}
      hoverTextStyle="text-shade-5"
      textStyle="text-md text-shade-2"
      styleLeft="m-3 tablet:m-4 p-5 rounded-md"
      {...props}
    />
  )

export default Close
