import clsx from 'clsx'
import * as R from 'ramda'
import { useForm } from '@tanstack/react-form'

import hooks from './core'
import animationFrame from '__lib__/web/animationFrame'

const animationFrameState = (name, setName, defaultValue) =>
  R.pipe(
    hooks.state(name, setName, defaultValue),
    hooks.handler(setName, (_, propsRef) => (value) => {
      propsRef.animationFrame ||= animationFrame()
      propsRef.animationFrame.next(() => propsRef.current[setName](value))
    })
  )

const animationFrameEffect = (fn, deps) =>
  hooks.effect((_, propsRef) => {
    propsRef.animationFrame ||= animationFrame()
    propsRef.animationFrame.next(() => fn(propsRef.current, propsRef))
  }, deps)

// cr classNames are filtered out from the result classNames
const getClassName = (className, cx, cr) => {
  const mergedClassName = clsx(className, cx)
  if (!cr) return mergedClassName
  let result = ''
  const compareCr = (w) =>
    w.endsWith('*') ? R.startsWith(w.slice(0, -1)) : R.equals(w)
  const parsedCr = cr.split(' ').map(compareCr)
  const parsedMerge = mergedClassName.split(' ')
  for (let i = 0; i < parsedMerge.length; ++i) {
    const w = parsedMerge[i]
    if (parsedCr.some((fn) => fn(w))) continue
    result = result.length ? result + ' ' + w : w
  }
  return result
}

const cx =
  (cxParam, crParam, name = 'className') =>
  (props) => {
    const cxFn = cxParam || props.cx
    const crFn = crParam || props.cr
    const cx = R.is(Function, cxFn) ? cxFn(props) : cxFn
    const cr = R.is(Function, crFn) ? crFn(props) : crFn
    const className = getClassName(props[name], cx, cr)
    return R.assoc(name, className, props)
  }

const variants =
  (propName, { defaults = {}, ...config }, clear = true) =>
  (props) => {
    if (props[propName]) return props
    const variants = {}
    const newProps = { ...props, [propName]: variants }
    Object.keys(config).forEach((key) => {
      clear && delete newProps[key]
      const isUnderscore = key === '_'
      const variantValue = R.is(Function, props[key])
        ? props[key](props)
        : props[key]
      variants[key] = variantValue
      config[key].forEach((name) => {
        clear && delete newProps[name]
        if (!isUnderscore && props[name] !== false && variantValue === name)
          variants[name] = true
        else if (props[name] !== undefined)
          variants[name] = R.is(Function, props[name])
            ? props[name](props)
            : props[name]
        if (!isUnderscore && variants[name] && !variants[key])
          variants[key] = name
      })
      if (
        isUnderscore ||
        variants[key] ||
        !defaults[key] ||
        variantValue === null ||
        variants[defaults[key]] === false
      )
        return
      variants[key] = defaults[key]
      variants[defaults[key]] = true
    })
    return newProps
  }

const form = (propName, params = R.identity) =>
  hooks.assoc(propName, (props) =>
    useForm(R.is(Function, params) ? params(props) : params)
  )

export default { animationFrameState, animationFrameEffect, cx, variants, form }
