import * as R from 'ramda'
import { matchPath } from 'react-router-dom'

import config from '__config__'
import handleApiResponse from '__api__/handle'
import pathsArray from '__api__/pathsArray'
import { getPathName } from '__lib__/web/location'
import { trackError } from '__lib__/telemetry/seq'
import {
  currentUserAvatarPath,
  userAvatarPath,
  projectBackgroundPath,
  projectLogoPath,
  projectBackgroundWithDimensionsPath,
} from '__api__/paths'

const ERRORS = {
  notSupported: 'Tried to proxy `window.fetch` but it is not available.',
  already: "Tried to proxy `window.fetch` but it has already been proxy'd.",
}

const ignoredPaths = [
  currentUserAvatarPath,
  userAvatarPath,
  projectBackgroundPath,
  projectLogoPath,
  projectBackgroundWithDimensionsPath,
].map((path) => getPathName(path.route()))

const shouldIgnore = (url) =>
  matchPath(getPathName(url), {
    path: ignoredPaths,
    exact: true,
    strict: true,
  }) || url.includes(config.seqDomain)

const parseAndStrip = (body) => {
  try {
    return R.omit(['password', 'confirmPassword'], JSON.parse(body))
  } catch (e) {
    return body
  }
}

const filterGUID = (string) =>
  /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g.test(string)
    ? string
    : undefined

const filterKind = (string) =>
  string === 'drawing' || string === 'document' ? string : undefined

const handler = (url, data) => (response) => {
  !shouldIgnore(url) &&
    response.clone &&
    handleApiResponse(response.clone()).catch((error) => {
      const match = matchPath(getPathName(url), {
        path: pathsArray,
        exact: true,
        strict: true,
      })

      trackError(`Request to ${url} failed. ${response.status}`, {
        url,
        error,
        bookType: filterKind(R.pathOr(undefined, ['params', 'kind'], match)),
        body: parseAndStrip(data.body),
        method: data.method,
        projectId: R.pathOr(undefined, ['params', 'projectId'], match),
        bookId: filterGUID(R.pathOr(undefined, ['params', 'bookId'], match)),
      })
    })

  return response
}

const proxyFetch = () => {
  if (!fetch) return console.warn(ERRORS.notSupported)
  if (fetch.__seqProxy) return console.warn(ERRORS.already)
  fetch.__seqProxy = true
  const base = fetch
  window.fetch = (url, data) => {
    const handle = handler(url, data)
    return base.apply(window, [url, data]).then(handle, handle)
  }
}

export default proxyFetch
