import View from '__components__/View'
import Button from '__components__/Button'
import { supportRequestUrl } from '__routes__/external.id'
import Layout, { Header } from './Layout'

const IdentityError = ({ data }) => (
  <Layout>
    <Header
      titleStyle="text-lg"
      title=" Unfortunately, an unrecoverable error has been encountered, and we are
      unable to continue."
      description={
        <>
          <Button
            component="a"
            target="_blank"
            href={supportRequestUrl}
            textStyle="text-center text-primary text-lg font-regular"
            text="Please contact support"
          />
          {data && data.correlationId && (
            <View style="text-center text-shade-5 text-md font-semibold mt-6">
              Correlation Id: {data.correlationId}
            </View>
          )}
        </>
      }
    />
  </Layout>
)

export default IdentityError
