import * as R from 'ramda'
import hooks from '__hooks__'
import View from '__components__/View'
import Input from '__components__/Input'
import Button from '__components__/Button'
import Form from '__core__/Form'
import { initialState } from './Login'
import { validateEmail } from './validateEmail'
import FormForgeryToken from './FormForgeryToken'
import ThirdPartyButton, { externalLoginsProps } from './ThirdPartyButton'
import { Footer, Header, LoginButton } from './Layout'

const ALREADY_IN_USE = 'Email address already in use.'

const showEmailError = (props) =>
  props.error === ALREADY_IN_USE || Form.Field.defaultShowError(props)

const RegisterForm = ({
  form,
  header,
  validateEmail,
  data: { formAction, loginUrl, externalLogins = [], usernamePasswordOnly },
}) => (
  <>
    <View style="flex items-center laptop:justify-center w-full flex-col flex-flex">
      {header || (
        <Header
          title="Create an account"
          description="Get started with Drawboard PDF"
        />
      )}
      
      {usernamePasswordOnly ? null : 
        <View style="flex-center justify-around w-full">
          <View
            style="flex flex-col items-center flex-flex -mx-5 laptop:mt-5 w-full"
            // NOTE: implemented like this for IE
            styleRight={{ maxWidth: 220 * 2 + 8 * 3 }}
          >
            {externalLogins.map(({ id, url }) => (
              <ThirdPartyButton
                key={id}
                name={id}
                action={url}
                style="m-4"
                {...externalLoginsProps[id]}
              />
            ))}
          </View>
        </View>
      }
      {usernamePasswordOnly ? null : 
        <View style="flex-center mt-6">
          <View.Text style="text-lg laptop:text-xl font-semibold text-shade-7">
            Or continue with email
          </View.Text>
        </View>
      }
      <Form
        form={form}
        method="post"
        style="w-full max-w-10"
        action={formAction}
        component={View.Form}
      >
        <View style="flex flex-col mt-5">
          <Input.Field
            lg
            autoFocus
            type="email"
            name="Input.Email"
            showError={showEmailError}
            validate={validateEmail}
            placeholder="E.g. john.smith@drawboard.com"
          />
          <View style="flex-center mt-5">
            <FormForgeryToken />
            <Form.Submit
              lg
              plain
              brand
              text="Continue"
              component={Button}
              style={['rounded-full', { width: 208 }]}
            />
          </View>
        </View>
      </Form>
    </View>
    <Footer>
      <LoginButton text="Already have an account?" href={loginUrl} />
    </Footer>
  </>
)

const getValidateEmail = ({ data: { formData, formState } }) => {
  const isAlreadyInUseError = R.both(
    R.pathEq(['key'], 'Input.Email'),
    R.pathEq(['errors', 0, 'errorMessage'], ALREADY_IN_USE)
  )
  const isEmailAlreadyInUse = R.any(isAlreadyInUseError, formState)
  const validateNonAlreadyUsedEmail = (email) => (value) =>
    value === (email || '') && 'Email address already in use.'
  return isEmailAlreadyInUse
    ? [validateNonAlreadyUsedEmail(formData.email), ...validateEmail]
    : validateEmail
}

const setFormErrors = ({ form, data: { formState } }) =>
  form.setFieldsError(
    R.reduce(
      (fieldErrors, { key, errors }) =>
        key !== 'Input.Email' || errors[0].errorMessage !== ALREADY_IN_USE
          ? R.assoc(key, errors[0].errorMessage, fieldErrors)
          : fieldErrors,
      [],
      formState
    )
  )

const sortExternalLogins = ({ data: { externalLogins = [] } }) =>
  externalLogins.sort(
    (a, b) =>
      externalLoginsProps[a.displayName].order -
      externalLoginsProps[b.displayName].order
  )

export default Form.withForm(
  'form',
  initialState
)(
  hooks(
    hooks.tapEffect(setFormErrors),
    hooks.value('validateEmail', getValidateEmail),
    hooks.tapEffect(sortExternalLogins, [])
  )(RegisterForm)
)
