import * as R from 'ramda'

let config = {}

export const getAuthToken = () =>
  (config.getAuthToken || R.always(Promise.resolve(null)))()

export const onAccessDenied = () =>
  (config.onAccessDenied || R.always(Promise.resolve(null)))()

export default (newConfig) => Object.assign(config, newConfig)
