const NO_MARGIN = 0
const DEFAULT_MARGIN = 16

// NOTE the placement syntax is anchor0Anchor1Dialog0Dialog1

const getValue = (value, defaultValue) =>
  value === undefined ? defaultValue : +value

//  Anchor
//  +---+
//  | A |
//  +---+
//  Dialog
//  +-----------------+
//  |                 |
//  |        D        |
//  |                 |
//  +-----------------+

//  +---+-------------+
//  | A |             |
//  +---+    D        |
//  |                 |
//  +-----------------+
const leftTopLeftTopTranslate = '0, 0'
const leftTopLeftTop = ({ left, top }, { x, y }) => ({
  left: left + getValue(x, NO_MARGIN),
  top: top + getValue(y, NO_MARGIN),
})

//  +-----------------+  +---+
//  |                 |  | A |
//  |        D        |  +---+
//  |                 |
//  +-----------------+
const leftTopRightTopTranslate = '-100%, 0'
const leftTopRightTop = ({ left, top }, { x, y }) => ({
  left: left - getValue(x, DEFAULT_MARGIN),
  top: top + getValue(y, NO_MARGIN),
})

//  +-----------------+
//  |                 |
//  |        D        |
//  |                 |
//  +-----------------+
//                    +---+
//                    | A |
//                    +---+
const leftTopRightBottomTranslate = '-100%, -100%'
const leftTopRightBottom = ({ left, top }, { x, y }) => ({
  left: left + getValue(x, NO_MARGIN),
  top: top - getValue(y, DEFAULT_MARGIN),
})

//  +-----------------+
//  |                 |
//  |        D        |
//  |                 |
//  +-----------------+
//  +---+
//  | A |
//  +---+
const leftTopLeftBottomTranslate = '0, -100%'
const leftTopLeftBottom = ({ left, top }, { x, y }) => ({
  left: left + getValue(x, NO_MARGIN),
  top: top - getValue(y, DEFAULT_MARGIN),
})

//  +-------------+---+
//  |             | A |
//  |        D    +---+
//  |                 |
//  +-----------------+
const rightTopRightTopTranslate = '-100%, 0'
const rightTopRightTop = ({ right, top }, { x, y }) => ({
  left: right + getValue(x, NO_MARGIN),
  top: top + getValue(y, NO_MARGIN),
})

//  +---+  +-----------------+
//  | A |  |                 |
//  +---+  |        D        |
//         |                 |
//         +-----------------+
const rightTopLeftTopTranslate = '0, 0'
const rightTopLeftTop = ({ right, top }, { x, y }) => ({
  left: right + getValue(x, DEFAULT_MARGIN),
  top: top + getValue(y, NO_MARGIN),
})

//      +-----------------+
//      |                 |
//      |        D        |
//      |                 |
//      +-----------------+
//  +---+
//  | A |
//  +---+
const rightTopLeftBottomTranslate = '0, -100%'
const rightTopLeftBottom = ({ right, top }, { x, y }) => ({
  left: right + getValue(x, NO_MARGIN),
  top: top - getValue(y, DEFAULT_MARGIN),
})

//  +-----------------+
//  |                 |
//  |        D        |
//  |                 |
//  +-----------------+
//                +---+
//                | A |
//                +---+
const rightTopRightBottomTranslate = '-100%, -100%'
const rightTopRightBottom = ({ right, top }, { x, y }) => ({
  left: right + getValue(x, NO_MARGIN),
  top: top - getValue(y, DEFAULT_MARGIN),
})

//  +-----------------+
//  |                 |
//  +---+    D        |
//  | A |             |
//  +---+-------------+
const leftBottomLeftBottomTranslate = '0, -100%'
const leftBottomLeftBottom = ({ left, bottom }, { x, y }) => ({
  left: left + getValue(x, NO_MARGIN),
  top: bottom + getValue(y, NO_MARGIN),
})

//  +---+
//  | A |
//  +---+
//  +-----------------+
//  |                 |
//  |        D        |
//  |                 |
//  +-----------------+
const leftBottomLeftTopTranslate = '0, 0'
const leftBottomLeftTop = ({ left, bottom }, { x, y }) => ({
  left: left + getValue(x, NO_MARGIN),
  top: bottom + getValue(y, DEFAULT_MARGIN),
})

//                    +---+
//                    | A |
//                    +---+
//  +-----------------+
//  |                 |
//  |        D        |
//  |                 |
//  +-----------------+
const leftBottomRightTopTranslate = '-100%, 0'
const leftBottomRightTop = ({ left, bottom }, { x, y }) => ({
  left: left + getValue(x, NO_MARGIN),
  top: bottom + getValue(y, DEFAULT_MARGIN),
})

//  +-----------------+
//  |                 |
//  |        D        |  +---+
//  |                 |  | A |
//  +-----------------+  +---+
const leftBottomRightBottomTranslate = '-100%, -100%'
const leftBottomRightBottom = ({ left, bottom }, { x, y }) => ({
  left: left - getValue(x, DEFAULT_MARGIN),
  top: bottom + getValue(y, NO_MARGIN),
})

//  +-----------------+
//  |                 |
//  |        D    +---+
//  |             | A |
//  +-------------+---+
const rightBottomRightBottomTranslate = '-100%, -100%'
const rightBottomRightBottom = ({ right, bottom }, { x, y }) => ({
  left: right + getValue(x, NO_MARGIN),
  top: bottom + getValue(y, NO_MARGIN),
})

//  +---+
//  | A |
//  +---+
//      +-----------------+
//      |                 |
//      |        D        |
//      |                 |
//      +-----------------+
const rightBottomLeftTopTranslate = '0, 0'
const rightBottomLeftTop = ({ right, bottom }, { x, y }) => ({
  left: right + getValue(x, NO_MARGIN),
  top: bottom + getValue(y, DEFAULT_MARGIN),
})

//                +---+
//                | A |
//                +---+
//  +-----------------+
//  |                 |
//  |        D        |
//  |                 |
//  +-----------------+
const rightBottomRightTopTranslate = '-100%, 0'
const rightBottomRightTop = ({ right, bottom }, { x, y }) => ({
  left: right + getValue(x, NO_MARGIN),
  top: bottom + getValue(y, DEFAULT_MARGIN),
})

//
//         +-----------------+
//         |                 |
//  +---+  |        D        |
//  | A |  |                 |
//  +---+  +-----------------+
const rightBottomLeftBottomTranslate = '0, -100%'
const rightBottomLeftBottom = ({ right, bottom }, { x, y }) => ({
  left: right + getValue(x, DEFAULT_MARGIN),
  top: bottom + getValue(y, NO_MARGIN),
})

//  +-----------------+
//  |                 |  +---+
//  |        D        |  | A |
//  |                 |  +---+
//  +-----------------+
const leftTranslate = '-100%, -50%'
const left = ({ left, top, width, height }, { x, y }) => ({
  left: left - getValue(x, DEFAULT_MARGIN),
  top: top + height / 2 + getValue(y, NO_MARGIN),
})

//         +-----------------+
//  +---+  |                 |
//  | A |  |        D        |
//  +---+  |                 |
//         +-----------------+
const rightTranslate = '0, -50%'
const right = ({ right, top, width, height }, { x, y }) => ({
  left: right + getValue(x, DEFAULT_MARGIN),
  top: top + height / 2 + getValue(y, NO_MARGIN),
})

//         +---+
//         | A |
//         +---+
//  +-----------------+
//  |                 |
//  |        D        |
//  |                 |
//  +-----------------+
const topTranslate = '-50%, -100%'
const top = ({ left, top, width, height }, { x, y }) => ({
  left: left + width / 2 + getValue(x, NO_MARGIN),
  top: top - getValue(y, DEFAULT_MARGIN),
})

//  +-----------------+
//  |                 |
//  |        D        |
//  |                 |
//  +-----------------+
//         +---+
//         | A |
//         +---+
const bottomTranslate = '-50%, 0'
const bottom = ({ left, bottom, width, height }, { x, y }) => ({
  left: left + width / 2 + getValue(x, NO_MARGIN),
  top: bottom + getValue(y, DEFAULT_MARGIN),
})

//  +-----------------+
//  |      +---+      |
//  |      | D |      |
//  |      +---+      |
//  +-----------------+
const centerTranslate = '-50%, -50%'
const center = ({ left, top, width, height }, { x, y }) => ({
  left: left + width / 2 + getValue(x, NO_MARGIN),
  top: top + height / 2 + getValue(y, NO_MARGIN),
})

//  +------+---+------+
//  |      | D |      |
//  |      +---+      |
//  |                 |
//  +-----------------+
const topCenterTranslate = '-50%, 0'
const topCenter = ({ left, top, width, height }, { x, y }) => ({
  left: left + width / 2 + getValue(x, NO_MARGIN),
  top: top + getValue(y, NO_MARGIN),
})

//  +------+---+------+
//  |      | D |      |
//  |      +---+      |
//  |                 |
//  +-----------------+
const centerTopTranslate = '-50%, -50%'
const centerTop = ({ left, top, width, height }, { x, y }) => ({
  left: left + width / 2 + getValue(x, NO_MARGIN),
  top: top + height / 3 + getValue(y, NO_MARGIN),
})

export const placementMap = {
  top,
  left,
  right,
  bottom,
  center,
  centerTop,
  topCenter,
  leftTopLeftTop,
  leftTopRightTop,
  leftTopRightBottom,
  leftTopLeftBottom,
  rightTopRightTop,
  rightTopLeftTop,
  rightTopLeftBottom,
  rightTopRightBottom,
  leftBottomLeftBottom,
  leftBottomLeftTop,
  leftBottomRightTop,
  leftBottomRightBottom,
  rightBottomRightBottom,
  rightBottomLeftTop,
  rightBottomRightTop,
  rightBottomLeftBottom,
}

const fullPositionMap = {
  ...placementMap,
  topLeftTopLeft: leftTopLeftTop,
  topLeftTopRight: leftTopRightTop,
  topLeftBottomRight: leftTopRightBottom,
  topLeftBottomLeft: leftTopLeftBottom,
  topRightTopRight: rightTopRightTop,
  topRightTopLeft: rightTopLeftTop,
  topRightBottomLeft: rightTopLeftBottom,
  topRightBottomRight: rightTopRightBottom,
  bottomLeftBottomLeft: leftBottomLeftBottom,
  bottomLeftTopLeft: leftBottomLeftTop,
  bottomLeftTopRight: leftBottomRightTop,
  bottomLeftBottomRight: leftBottomRightBottom,
  bottomRight: rightBottomRightBottom,
  bottomRightBottomRight: rightBottomRightBottom,
  bottomRightTopLeft: rightBottomLeftTop,
  bottomRightTopRight: rightBottomRightTop,
  bottomRightBottomLeft: rightBottomLeftBottom,
  bottomCenterTopCenter: bottom,
}

export const getPosition = (anchor, placement, margin) =>
  (fullPositionMap[placement] || fullPositionMap.centerTop)(
    anchor.transitionToBoundingClientRect || anchor.getBoundingClientRect(),
    margin
  )

export const translateMap = {
  top: topTranslate,
  left: leftTranslate,
  right: rightTranslate,
  bottom: bottomTranslate,
  center: centerTranslate,
  centerTop: centerTopTranslate,
  topCenter: topCenterTranslate,
  leftTopLeftTop: leftTopLeftTopTranslate,
  leftTopRightTop: leftTopRightTopTranslate,
  leftTopRightBottom: leftTopRightBottomTranslate,
  leftTopLeftBottom: leftTopLeftBottomTranslate,
  topLeftTopLeft: leftTopLeftTopTranslate,
  topLeftTopRight: leftTopRightTopTranslate,
  topLeftBottomRight: leftTopRightBottomTranslate,
  topLeftBottomLeft: leftTopLeftBottomTranslate,
  rightTopRightTop: rightTopRightTopTranslate,
  rightTopLeftTop: rightTopLeftTopTranslate,
  rightTopLeftBottom: rightTopLeftBottomTranslate,
  rightTopRightBottom: rightTopRightBottomTranslate,
  topRightTopRight: rightTopRightTopTranslate,
  topRightTopLeft: rightTopLeftTopTranslate,
  topRightBottomLeft: rightTopLeftBottomTranslate,
  topRightBottomRight: rightTopRightBottomTranslate,
  leftBottomLeftBottom: leftBottomLeftBottomTranslate,
  leftBottomLeftTop: leftBottomLeftTopTranslate,
  leftBottomRightTop: leftBottomRightTopTranslate,
  leftBottomRightBottom: leftBottomRightBottomTranslate,
  bottomLeftBottomLeft: leftBottomLeftBottomTranslate,
  bottomLeftTopLeft: leftBottomLeftTopTranslate,
  bottomLeftTopRight: leftBottomRightTopTranslate,
  bottomLeftBottomRight: leftBottomRightBottomTranslate,
  rightBottomRightBottom: rightBottomRightBottomTranslate,
  rightBottomLeftTop: rightBottomLeftTopTranslate,
  rightBottomRightTop: rightBottomRightTopTranslate,
  rightBottomLeftBottom: rightBottomLeftBottomTranslate,
  bottomRight: rightBottomRightBottomTranslate,
  bottomRightBottomRight: rightBottomRightBottomTranslate,
  bottomRightTopLeft: rightBottomLeftTopTranslate,
  bottomRightTopRight: rightBottomRightTopTranslate,
  bottomRightBottomLeft: rightBottomLeftBottomTranslate,
}

export const getTranslate = (placement) =>
  placement !== 'free'
    ? `translate(${translateMap[placement] || translateMap.center})`
    : ''
