import * as R from 'ramda'
import { compose } from 'react-recompose'
import { withProps } from 'react-recompose'

import withState from './withState'
import invoke from '__lib__/fn/invoke'
import withoutProps from './withoutProps'
import onPropsChange from './onPropsChange'

export default (getChangedProps, delay) => {
  const hasChanged = (props, prevProps, instance) => {
    const changedProps = Object.keys(
      R.filter(R.identity, getChangedProps(props, prevProps))
    )
    instance.changedProps = changedProps
    return Boolean(changedProps.length)
  }
  const onChange = (props, prevProps, instance) => {
    const { setDelayState } = props
    const propsDelay = invoke(delay)(props)
    const reduce = (stateProps, key) => ({
      ...stateProps,
      [key]: prevProps[key],
    })
    const stateProps = instance.changedProps.reduce(reduce, {})
    instance.delayTimeout && clearTimeout(instance.delayTimeout)
    setDelayState(stateProps)
    instance.delayTimeout = setTimeout(() => setDelayState(null), propsDelay)
  }
  return compose(
    withState('delayState', 'setDelayState', null),
    onPropsChange(hasChanged, onChange),
    withProps(R.prop('delayState')),
    withoutProps(['delayState', 'setDelayState'])
  )
}
