import { withModules } from './core'
import reduxHooks from './redux'
import stylesHooks from './styles'
import routerHooks from './router'
import rxHooks from './rx'
import debugHooks from './debug'
import utilsHooks from './utils'

const hooks = withModules(
  reduxHooks,
  stylesHooks,
  routerHooks,
  rxHooks,
  debugHooks,
  utilsHooks
)

export default hooks
