import React from 'react'
import { compose } from 'react-recompose'

import { withFormContext } from './Form'
import withDecorators from '__lib__/react/withDecorators'

const Submit = ({
  form,
  loading,
  disabled,
  whenDirty,
  component: Component,
  ...props
}) => (
  <Component
    type="submit"
    loading={loading || form.submitInProgress}
    disabled={disabled || !form.isValid || (whenDirty && !form.isDirty)}
    {...props}
  />
)

const submit =
  ({ form }) =>
  () =>
    form.triggerSubmit()

export default compose(
  withFormContext,
  withDecorators({ onPress: submit })
)(Submit)
