import * as R from 'ramda'
import React from 'react'

export const withHooks =
  (...hooks) =>
  (Component) =>
  (props) =>
    <Component {...R.pipe(...hooks)(props)} />

export default withHooks
