import React from 'react'
import { withPropsOnChange } from 'react-recompose'

import Icon from './Icon'
import assign from '__lib__/react/assign'

export const renderIcon = (IconComponent, props) =>
  typeof IconComponent === 'string' ? (
    <Icon type={IconComponent} {...props} />
  ) : IconComponent ? (
    <IconComponent {...props} />
  ) : null

export const withIcon = (Component) =>
  withPropsOnChange(['icon', 'children'], ({ icon, children }) => ({
    children: children ? (
      <React.Fragment>
        {renderIcon(icon)}
        {children}
      </React.Fragment>
    ) : (
      renderIcon(icon)
    ),
  }))(Component)

export default assign({ render: renderIcon })(Icon)
