import React from 'react'
import { compose } from 'react-recompose'
import { withProps } from 'react-recompose'

import Icon from 'Icon'
import Button from './Button'
import styles from './styles.module.css'
import withSize from '__lib__/react/withSize'
import withClassName from '__lib__/react/withClassName'

const ButtonIcon = ({
  icon,
  size,
  children,
  innerStyle,
  iconClassName,
  component: Component = Button,
  ...props
}) => (
  <Component {...props}>
    {children}
    {Icon.render(icon, { size, className: iconClassName, innerStyle })}
  </Component>
)

const flat = ({ flat }) => ({
  flat: flat !== false,
})

const className = ({ size }) => styles[size + 'Icon']

export default compose(
  withSize('md'),
  withProps(flat),
  withClassName(className)
)(ButtonIcon)
