const normalizedPath = (path) =>
  typeof path === 'string' ? path.split('.') : path

export const update = (object, path, fn) => {
  const result = Object.assign({}, object)
  let node = result
  let i = 0
  let part
  path = normalizedPath(path)
  for (i = 0; i < path.length - 1; ++i) {
    part = path[i]
    node = node[part] = Object.assign({}, node[part])
  }
  node[path[i]] = fn(node[path[i]])
  return result
}

export const set = (object, path, value) => update(object, path, () => value)

export const assign = (object, path, extension) =>
  update(object, path, (value) => Object.assign({}, value, extension))

export const get = (object, path) => {
  path = normalizedPath(path)
  for (var part of path) {
    if (!object) return object
    object = object[part]
  }
  return object
}

export const fpGet = (path) => (object) => get(object, path)
export const fpSet = (path) => (value) => (object) => set(object, path, value)
export const fpUpdate = (path) => (fn) => (object) => update(object, path, fn)

/**
 *
 *
 * Returns getter, setter and updater for a path
 * N.B that you can only have one params object, not infinite!
 * So put multiple params inside an object
 *
 */
export function handler(path) {
  return typeof path === 'function'
    ? [
        (state, params) => get(state, path(params)),
        (state, params, value) => set(state, path(params), value),
        (state, params, fn) => update(state, path(params), fn),
      ]
    : [
        (state) => get(state, path),
        (state, value) => set(state, path, value),
        (state, fn) => update(state, path, fn),
      ]
}

const pairWithConstPath = (path) => [
  (state) => get(state, path),
  (state, value) => set(state, path, value),
  (state, fn) => update(state, path, fn),
]

const pairWithDynamicPath = (makePath) => [
  (state, ...args) => get(state, makePath(...args)),
  (state, ...args) => set(state, makePath(...args), args[args.length - 1]),
  (state, ...args) => update(state, makePath(...args), args[args.length - 1]),
]

export const pair = (path) =>
  typeof path === 'function'
    ? pairWithDynamicPath(path)
    : pairWithConstPath(path)
