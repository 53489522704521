export const getDimensions = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
})

export const listenDimensionsChange = (handler) => {
  const handlerMap = () => handler(getDimensions())
  window.addEventListener('resize', handlerMap)
  return () => window.removeEventListener('resize', handlerMap)
}
