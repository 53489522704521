import palette from '__canvas__/color/palette'

// Defines the colors available in the "Edit annotation" popover
export const DEFAULT_COLORS = [
  '#FA3237',
  '#FF9B24',
  '#FFF21C',
  '#06B95F',
  '#2D46FF',
  '#9045F1',
  '#FFFFFF',
]

export const DEFAULT_COLOR_TABLE = [
  [
    palette.red,
    palette.begonia,
    palette.bubbleGum,
    palette.mauvelous,
    palette.fuchsia,
    palette.barbiePink,
    palette.imperialPurple,
    palette.maroon,
  ],
  [
    palette.LemonGlacier,
    palette.amber,
    palette.naplesYellow,
    palette.beer,
    palette.vividTangelo,
    palette.chocolate,
    palette.metallicBrown,
    palette.cinnamonStick,
  ],
  [
    palette.electricGreen,
    palette.kiwi,
    palette.teaGreen,
    palette.greenPigment,
    palette.theGreen,
    palette.swampland,
    palette.myrtle,
    palette.darkGreen,
  ],
  [
    palette.theBlue,
    palette.frenchBlue,
    palette.duckEggBlue,
    palette.vividCerulean,
    palette.aqua,
    palette.purpleHeart,
    palette.patriarch,
    palette.midnightMelancholia,
  ],
  [
    palette.theBlack,
    palette.darkCharcoal,
    palette.hardCoal,
    palette.industrialRevolution,
    palette.nickel,
    palette.fortressGrey,
    palette.discoBall,
    palette.greyPlacidity,
  ],
]

// prettier-ignore
export const COLOR_SET = {
  defaultStroke: ['#FA3237', '#2D46FF', '#000000', '#FFFFFF', '#06B95F', '#9045F1', '#FF9B24'],
  defaultFill: ['none', '#FA3237', '#2D46FF', '#000000', '#FFFFFF', '#06B95F', '#9045F1', '#FF9B24'],
  ink: ['#FA3237', '#2D46FF', '#000000', '#FFFFFF', '#06B95F', '#9045F1', '#FF9B24', '#FFF21C'],
  highlighter: ['#FFFF00', '#FF00FF', '#00FFFF', '#90CE4F', '#FF7578', '#FCBE01', '#9045F1'],
  laser: ['#FA3237', '#01FC01', '#2D46FF', '#9045F1', '#FF9B24', '#FC01FC', '#000000'],
  textReview: ['#FA3237', '#2D46FF', '#06B95F', '#FC01FC', '#9045F1', '#FF9B24', '#FFF21C'],
  text: ['#000000', '#FA3237', '#2D46FF', '#FFF21C', '#9045F1', '#FF9B24', '#FC01FC'],
  measure: ['#FA3237', '#2D46FF', '#06B95F', '#FC01FC', '#9045F1', '#FF9B24', '#01AEED'],
  hyperlink: ['#2D46FF', '#06B95F', '#000000', '#FA3237', '#9045F1', '#FF9B24', '#FC01FC'],
  signature: ['#000000', '#2D46FF', '#FA3237', '#06B95F', '#9045F1', '#FF9B24', '#01AEED'],
  signatureDefault: ['#000000', '#3054B4', '#2F80ED'], // old signature colors
}
