import { compose } from 'react-recompose'

import Link from './Link'
import withoutProps from '__lib__/react/withoutProps'
import withDecorators from '__lib__/react/withDecorators'
import { messageSuccess, messageError } from '__store__/messages'

const onClick =
  ({ errorMessage, successMessage }) =>
  () => {
    errorMessage && messageError(errorMessage)
    successMessage && messageSuccess(successMessage)
  }

export default compose(
  withDecorators({ onClick }),
  withoutProps(['errorMessage', 'successMessage'])
)(Link)
