import { LogoWatermark } from '@drawboard/lib-dbpdf/Logo'
import View from '__components__/View'
import Item1 from './powerfulTools.svg?react'
import Item2 from './syncAcrossDevices.svg?react'
import Item3 from './realTimeCollaboration.svg?react'
import Header from './header.svg?react'

const Item = ({ header, children, headerStyle, ...props }) => (
  <View
    styleLeft={['text-white text-md text-center relative mt-6', { width: 270 }]}
    {...props}
  >
    <View styleLeft="absolute left-0" style={headerStyle}>
      {header}
    </View>
    {children}
  </View>
)

const PanelContent = ({ title, ...props }) => (
  <View styleLeft="flex flex-col items-center full" {...props}>
    <View style="flex-flex flex flex-col items-center justify-center">
      <Header width={200} />
      <View style="flex flex-col text-white text-xxl font-semibold text-center my-5 leading-xl text-line-break">
        {title}
      </View>
      <View style="flex flex-col items-center">
        <Item headerStyle={{ top: -23 }} header={<Item1 />}>
          All the essential tools you need to markup and annotate PDFs
        </Item>
        <Item headerStyle={{ top: -40 }} header={<Item2 />}>
          Work faster, across any device
        </Item>
        <Item style={{ top: 24 }} headerStyle={{ top: -44 }} header={<Item3 />}>
          <View>
            ‍Used by individuals and teams to mark-up, annotate and collaborate
          </View>
          <View style="mt-5">Work in real time sync</View>
        </Item>
      </View>
    </View>

    <View style="py-5 text-white flex-stiff">
      <View style="py-4">
        <LogoWatermark width={120} />
      </View>
    </View>
  </View>
)

export default PanelContent
