import View from '__components__/View'
import { Header } from '__screens__/Layout'
import IntegrationIcon from '__views__/IntegrationIcon'

const IntegrationsHeader = ({ isRegister }) => (
  <View style="flex flex-col text-center text-shade-7 items-center justify-center">
    <View style="flex items-center g-6 mb-4">
      <View component={IntegrationIcon.GoogleDrive} style={{ width: 34 }} />
      <View component={IntegrationIcon.OneDrive} style={{ width: 40 }} />
      <View component={IntegrationIcon.SharePoint} style={{ width: 40 }} />
    </View>
    <Header
      title={
        isRegister
          ? `Open your external cloud\n storage documents`
          : `To open your external\n cloud storage documents`
      }
      description={
        isRegister
          ? 'Create a Drawboard PDF account'
          : 'Choose from your log in options'
      }
    />
  </View>
)

export default IntegrationsHeader
