import hooks from './core'

const rxEffect = (rxFactory, handler, deps) => (props) => {
  const rxEffect = (props, propsRef) => {
    const subscription = rxFactory(props, propsRef).subscribe((value) =>
      handler(propsRef.current, propsRef)(value)
    )
    return () => subscription.unsubscribe()
  }
  return hooks.effect(rxEffect, deps)(props)
}

export default { rxEffect }
