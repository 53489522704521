import React from 'react'

import StopPropagation from 'StopPropagation'
import component from '__lib__/react/component'
import arrayWithout from '__lib__/array/without'
import arrayToObject from '__lib__/array/toObject'
import arrayReverseForEach from '__lib__/array/reverseForEach'
import { cancelActions, eventMatchesStrict } from '__lib__/web/shortcut'

const EVENT_TYPES = ['mousedown', 'touchstart', 'focusin', 'keydown', 'wheel']

const handlerFactory = (eventType) => (event) =>
  arrayReverseForEach(events[eventType].listeners, (listener) =>
    listener(event)
  )

const makeEvent = (eventType) => ({
  listeners: [],
  handler: handlerFactory(eventType),
})

const events = arrayToObject(EVENT_TYPES, null, makeEvent)

let alreadyProcessedEvent = null

const addEventListener = (eventType, listener, ...args) => {
  const event = events[eventType]
  event.listeners.length ||
    window.addEventListener(eventType, event.handler, ...args)
  event.listeners.push(listener)
}

const removeEventListener = (eventType, listener, ...args) => {
  const event = events[eventType]
  event.listeners = arrayWithout(event.listeners, listener)
  event.listeners.length ||
    window.removeEventListener(eventType, event.handler, ...args)
}

class ClickOut extends React.Component {
  componentDidMount() {
    this.mount = true
    this.props.active !== false && this.start()
  }

  componentDidUpdate(prevProps) {
    prevProps.active === false && this.props.active === true && this.start()
    this.props.active === false && prevProps.active === true && this.stop()
  }

  start = () => {
    const { withScroll } = this.props
    addEventListener('focusin', this.update)
    addEventListener('keydown', this.keyDown)
    addEventListener('mousedown', this.update)
    addEventListener('touchstart', this.update)
    withScroll && addEventListener('wheel', this.update, true)
  }

  stop = () => {
    removeEventListener('focusin', this.update)
    removeEventListener('keydown', this.keyDown)
    removeEventListener('mousedown', this.update)
    removeEventListener('touchstart', this.update)
    removeEventListener('wheel', this.update, true)
  }

  componentWillUnmount() {
    this.mount = false
    this.stop()
  }

  onClickOut = (event) => {
    if (alreadyProcessedEvent === event) return
    const { onClickOut } = this.props
    const clickedOut = onClickOut && onClickOut(event)
    ;(clickedOut && clickedOut.clickoutIgnored) ||
      (alreadyProcessedEvent = event)
  }

  keyDown = (event) =>
    cancelActions.some((cancelAction) =>
      eventMatchesStrict(event, cancelAction)
    ) && this.onClickOut(event)

  setRef = (ref) => {
    this.ref = ref
    this.props.innerRef && this.props.innerRef(ref)
  }

  isTransitionScroll = (event) =>
    event &&
    event.target &&
    event.target.getAttribute &&
    event.target.getAttribute('data-transition-scrolled')

  contains = (element) => element === window || this.ref.contains(element)

  update = (event) =>
    this.ref &&
    this.mount &&
    !this.isTransitionScroll(event) &&
    !this.contains(event.target) &&
    this.onClickOut(event)

  render() {
    const { active, innerRef, onClickOut, withScroll, ...props } = this.props
    return <div ref={this.setRef} {...props} />
  }
}

export default Object.assign(ClickOut, {
  Prevent: component(<StopPropagation stopPropagation={EVENT_TYPES} />),
})
