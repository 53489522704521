import React from 'react'
import * as R from 'ramda'

import hooks from '__hooks__'
import LoadingText from './Text'
import Icon from '__components__/Icon'
import View from '__components__/View'
import styles from './styles.module.css'

const gradient = (color, altColor, step = 20) =>
  `linear-gradient(110deg, ${color} ${step}%, ${altColor} ${
    step * 2
  }%, ${color} ${step * 3}%), ${color}`

export const shimmerStyle = {
  light: { background: gradient('#F6F6F6', '#F0F0F0') },
  dark: { background: gradient('#15191F', '#212730') },
  backgroundSize: '33% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '-33% 0',
  animationDuration: '3s',
  animationFillMode: 'forwards',
  animationName: 'shimmerAnimation',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
}

const glowingStyle = {
  // light: { animationName: 'glowingAnimationLight' },
  // dark: { animationName: 'glowingAnimationDark' },
  animationDuration: '2s',
  animationName: 'glowingAnimation',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
}

const viewStyleRight = ({ isLoading, styleRight }) => [
  // isLoading && shimmerStyle,
  isLoading && glowingStyle,
  styleRight,
]

const styleRight = {
  animationDuration: '2s',
  animationName: 'spinAnimation',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
}

const Loading = (props) => (
  <Icon
    styleRight={styleRight}
    styleLeft="text-shade-3"
    icon="loading"
    {...props}
  />
)

export const hookLoadingStyle = R.pipe(
  hooks.assoc('styleRight', viewStyleRight),
  R.omit(['isLoading'])
)

const LoadingView = hooks(hookLoadingStyle)(View, 'LoadingView')

const IconView = ({ children, loadingStyle, isLoading, ...props }) => (
  <View styleLeft="relative" {...props}>
    {isLoading ? (
      <View style="absolute absolute-fill flex-center">
        <Loading style={['relative', loadingStyle]} />
      </View>
    ) : (
      children
    )}
  </View>
)

const Logo = (props) => (
  <svg
    fill="none"
    width="100%"
    viewBox="0 0 139 123"
    className={styles.logo}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M109.426 43.6385C119.77 49.5365 127.153 53.7465 127.153 53.7465C132.513 57.0395 134.138 64.0395 128.653 67.6255L44.746 116.419C42.156 117.915 36.845 116.152 35.349 113.562L9.02503 69.0665C6.25403 64.2675 6.25403 58.3535 9.02503 53.5545L35.224 8.55954C36.72 5.96854 42.281 5.33154 44.871 6.82753C44.871 6.82753 48.504 8.89954 54.243 12.1715Z"
      stroke="#5AE284"
      strokeWidth="13"
      strokeLinejoin="round"
    />
  </svg>
)

export default Object.assign(Loading, {
  Text: LoadingText,
  View: LoadingView,
  IconView,
  Logo,
})
