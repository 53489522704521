import React, { createFactory } from 'react'

export default (name) => (render) => {
  const factory = createFactory(render)
  return class Instance extends React.Component {
    componentWillUnmount() {
      this.didUnmount = true
    }
    render() {
      return factory({ ...this.props, [name]: this })
    }
  }
}
