import * as R from 'ramda'
import React, { useEffect, useContext } from 'react'
import { eventBusFactory } from '__lib__/eventBus'

const newEventProvider = (events) => {
  const eventBus = eventBusFactory()
  const event = (name) => (event) => eventBus.emit(name, event)
  const addEvent = (acc, name) => ({ ...acc, [name]: event(name) })
  return { ...eventBus, events: events.reduce(addEvent, {}) }
}

export const EventProvider = ({
  root,
  events,
  children,
  component: Component = 'div',
  ...props
}) => {
  const [eventProvider] = React.useState(newEventProvider(events))
  const eventsHandlers = eventProvider.events
  // NOTE: since react & our components cannot have react events
  // when no elements are focus, we grab them directly from the body
  // (no need to worry about them possibly getting the propagation stopped
  // by a react handler since well react could not have had access to it)
  // all other events are caught by react so the stopPropagation will
  // work as expected
  useEffect(() => {
    if (!root) return
    const cleanups = R.mapObjIndexed((handler, reactName) => {
      const name = reactName.toLowerCase().slice(2)
      const documentHandler = (event) =>
        event.target === document.body && handler(event)
      document.body.addEventListener(name, documentHandler)
      return () => document.body.removeEventListener(name, documentHandler)
    }, eventsHandlers)
    return () => Object.values(cleanups).forEach((cleanup) => cleanup())
  }, [eventsHandlers, root])

  return (
    <Component {...eventsHandlers} {...props}>
      <EventContext.Provider value={eventProvider}>
        {children}
      </EventContext.Provider>
    </Component>
  )
}

export const EventContext = React.createContext({})

export const useEventEffect = (eventName, eventHandler) => {
  const context = useContext(EventContext)
  useEffect(
    () => context.on(eventName, eventHandler),
    [context, eventHandler, eventName]
  )
}

export const hookEventEffect = (eventName, eventHandler) => (props) => {
  const ref = React.useRef()
  ref.current = props
  useEventEffect(eventName, (event) => eventHandler(ref.current, ref)(event))
  return props
}

export const useEventOutEffect = (eventName, eventHandler, getElement) => {
  const context = useContext(EventContext)
  useEffect(
    () =>
      context.on(eventName, (event) => {
        const element = getElement()
        return (
          element && (element.contains(event.target) || eventHandler(event))
        )
      }),
    [context, eventHandler, eventName, getElement]
  )
}

export default EventProvider
