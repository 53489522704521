import React from 'react'
import * as R from 'ramda'
import memoizeOne from 'memoize-one'
import { useStore, useDispatch, ReactReduxContext } from 'react-redux'

import hooks, { getDeps } from './core'
import memoizeLast from '__lib__/utils/memoizeLast'

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

const lazyStoreState =
  (name, mapState, map = R.identity) =>
  (props) => {
    const store = React.useContext(ReactReduxContext)
    const fnRef = React.useRef((...args) =>
      map(mapState(...args)(store.store.getState()))
    )
    return R.assoc(name, fnRef.current, props)
  }

const withError = (name, fnName, fn) => {
  try {
    return fn()
  } catch (error) {
    console.error(`Error in ${fnName}('${name}')`, error)
  }
}

const useSelector = (selector, name) => {
  const store = useStore()
  const ref = React.useRef()
  const getState = () => selector(store.getState())
  const [_, setState] = React.useState(1)
  ref.current = getState()
  React.useEffect(
    () =>
      store.subscribe(() => {
        const value = getState()
        ref.current !== value && setState(R.add(1))
      }),
    []
  )
  return ref.current
}

const storeState =
  (name, selector, map = R.identity) =>
  (props) => {
    const ref = React.useRef()
    ref.current = props
    const value = useSelector(
      (state) =>
        withError(name, 'storeState', () =>
          map(selector(ref.current)(state), ref.current)
        ),
      name
    )
    return R.assoc(name, value, props)
  }

const dispatcher = (name, action) => (props) => {
  const dispatch = useDispatch()
  const ref = React.useRef((...args) =>
    withError(name, 'dispatcher', () => dispatch(action(...args)))
  )
  return R.assoc(name, ref.current, props)
}

const dispatchHandler = (name, action, fn) => (props) => {
  const { [name]: actionFn } = dispatcher(name, action)({})
  return hooks.handler(name, fn(actionFn))(props)
}

const memoStoreStateMulti =
  (name, mapState, fn, deps = []) =>
  (props) => {
    const ref = React.useRef(
      memoizeOne((...value) => fn(ref.current.props)(...value))
    )
    ref.current.props = props
    const value = useSelector((state) => {
      const { props } = ref.current
      return withError(name, 'memoStoreStateMulti', () => {
        const value = mapState(props)(state)
        return ref.current(...value, ...getDeps(deps, props))
      })
    })
    return R.assoc(name, value, props)
  }

const memoStoreState =
  (name, mapState, fn, deps = []) =>
  (props) => {
    const ref = React.useRef(
      memoizeOne((...value) => fn(ref.current.props)(...value))
    )
    ref.current.props = props
    const value = useSelector((state) => {
      const { props } = ref.current
      return withError(name, 'memoStoreState', () => {
        const value = mapState(props)(state)
        return ref.current(value, ...getDeps(deps, props))
      })
    })
    return R.assoc(name, value, props)
  }

const memoStoreStateEquals =
  (name, mapState, fn, deps = []) =>
  (props) => {
    const ref = React.useRef(
      memoizeOne((...value) => fn(ref.current.props)(...value))
    )
    ref.current.props = props
    const value = useSelector((state) => {
      const { props, prevValue } = ref.current
      return withError(name, 'memoStoreStateEquals', () => {
        const storeValue = mapState(props)(state)
        const value = ref.current(storeValue, ...getDeps(deps, props))
        return !R.equals(value, prevValue)
          ? (ref.current.prevValue = value)
          : prevValue
      })
    })
    return R.assoc(name, value, props)
  }

const storeEffect =
  (mapState, effectFn, deps = [], { multi } = {}) =>
  (props) => {
    const ref = hooks.useLazyRef(() => ({
      value: {},
      props: { current: props, prev: {} },
      fn: memoizeLast(() => {
        const { value, props } = ref.current
        effectFn(props.current, props)(value.current, value)
      }),
    }))
    ref.current.props.current = props
    ref.current.props.prev = ref.current.props.current
    const store = React.useContext(ReactReduxContext).store
    const onChange = () => {
      const { props, fn } = ref.current
      const newValue = mapState(props.current)(store.getState())
      ref.current.value.prev = ref.current.current
      ref.current.value.current = newValue
      multi
        ? fn(...newValue, ...getDeps(deps, props.current))
        : fn(newValue, ...getDeps(deps, props.current))
    }
    React.useEffect(() => {
      return store.subscribe(onChange)
    }, [])
    React.useEffect(
      () => {
        onChange()
      },
      getDeps(deps, props.current)
    )
    return props
  }

const store = (name) => (props) =>
  R.assoc(name, React.useContext(ReactReduxContext).store, props)

export default {
  store,
  dispatcher,
  storeState,
  storeEffect,
  lazyStoreState,
  memoStoreState,
  dispatchHandler,
  memoStoreStateMulti,
  memoStoreStateEquals,
}
