import View from '__components__/View'

const StyledIcon = ({
  withShadow,
  size = 80,
  component: Component,
  iconSize = size / 3 + 10,
  ...props
}) => (
  <View
    styleLeft={[
      'flex-center rounded-full bg-white',
      {
        height: size,
        width: size,
      },
      withShadow && {
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 5px 0px',
      },
    ]}
    {...props}
  >
    <Component width={iconSize} />
  </View>
)

export default StyledIcon
