import PropTypes from 'prop-types'
import { getContext } from 'react-recompose'
import { withContext } from 'react-recompose'

const childContextTypes = {
  activateBackdrop: PropTypes.func,
  deactivateBackdrop: PropTypes.func,
}

const getChildContext = ({ activate, deactivate }) => ({
  activateBackdrop: activate,
  deactivateBackdrop: deactivate,
})

export const withChildContext = getContext(childContextTypes)

export default withContext(childContextTypes, getChildContext)
