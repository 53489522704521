import { createFactory } from 'react'
import { compose } from 'react-recompose'
import { withState } from 'react-recompose'

import invoke from '__lib__/fn/invoke'
import lifecycle from '__lib__/react/lifecycle'

export default (delay) => (BaseComponent) => {
  const factory = createFactory(BaseComponent)
  const didMount = (props, instance) =>
    (instance.timeout = window.setTimeout(
      () => props.setIsDelayPassed(true),
      invoke(delay)(props)
    ))
  const willUnmount = (_, instance) => window.clearTimeout(instance.timeout)
  const MountWithDelay = ({ isDelayPassed, setIsDelayPassed, ...props }) =>
    isDelayPassed ? factory(props) : null
  return compose(
    withState('isDelayPassed', 'setIsDelayPassed', false),
    lifecycle({ didMount, willUnmount })
  )(MountWithDelay)
}
