export default {
  noNav: false,
  noBack: false,
  narrowNav: false,
  expandedNav: false,
  noTrialBanner: false,
  noGuestAccess: false,
  navOrganizationNavId: null,
  navWorkspaceNavId: null,
  noProjectFileUploads: false,
}
