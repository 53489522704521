import equals from '__lib__/object/equals'

const memoizeLastFy = (argsEqual) => (fn) => {
  let lastArgs = null
  let lastResult = null
  return function () {
    if (lastArgs && argsEqual(lastArgs, arguments)) return lastResult
    lastResult = fn.apply(this, arguments)
    lastArgs = arguments
    return lastResult
  }
}

const namedArgsEqual = (lastArgs, newArgs) => equals(lastArgs[0], newArgs[0])

export const memoizeLastNamedArgs = memoizeLastFy(namedArgsEqual)

const argumentsListEqual = (lastArgs, newArgs) =>
  Array.prototype.every.call(
    lastArgs,
    (value, index) => value === newArgs[+index]
  )

export default memoizeLastFy(argumentsListEqual)
