import * as R from 'ramda'
import hooks from '__hooks__'
import fromAnyEvent from '__canvas__/point/fromAnyEvent'

// For some reason button receive the onKeyDown twice and I can't figure out why
let lastKeyEvent = null

const onKeyDown = () => (event) => {
  const { nativeEvent } = event
  if (nativeEvent !== lastKeyEvent && event.key === ' ') {
    event.target.click()
    event.preventDefault()
  }
  lastKeyEvent = nativeEvent
}

const onClick =
  ({ longPressRef, disabled, onPointerUp, onPress, onClick }) =>
  (event) => {
    !disabled && onPointerUp && onPointerUp(event)
    if (!disabled && onPress) {
      longPressRef.preventNextPress || onPress(event)
      longPressRef.preventNextPress && event.stopPropagation()
      longPressRef.preventNextPress = false
    }
    onClick && onClick(event)
  }

const onDoubleClick =
  ({ disabled, onDoublePress, onDoubleClick }) =>
  (event) => {
    !disabled && onDoublePress && onDoublePress(event)
    onDoubleClick && onDoubleClick(event)
  }

const onPointerUp =
  ({ disabled, onPointerUp, longPressRef }) =>
  (event) => {
    longPressRef.timeout && clearTimeout(longPressRef.timeout)
    !disabled && onPointerUp && onPointerUp(event)
  }

const onPointerMove =
  ({ longPressRef }) =>
  (event) => {
    if (!longPressRef.timeout) return
    const { x, y } = fromAnyEvent(event)
    longPressRef.clientX !== x &&
      longPressRef.clientY !== y &&
      clearTimeout(longPressRef.timeout)
  }

// this function triggers twice in Chrome
// something to do with the context menu being triggered when long press
const onPointerDown =
  ({ disabled, onPointerDown, onLongPress, longPressRef }) =>
  (event) => {
    !disabled && onPointerDown && onPointerDown(event)
    if (!onLongPress) return
    event.preventDefault()
    longPressRef.clientX = event.clientX
    longPressRef.clientY = event.clientY
    longPressRef.timeout && clearTimeout(longPressRef.timeout)
    longPressRef.timeout = setTimeout(() => {
      longPressRef.timeout = null
      longPressRef.preventNextPress = true
      onLongPress(event)
    }, 500)
  }

const RoleButtonDiv = hooks(hooks.default('role', 'button'))('div')

const component = ({ component, type, asLabel }) =>
  component ||
  (asLabel ? 'label' : type === 'submit' ? 'button' : RoleButtonDiv)

const tabIndex = ({ asLabel, tabIndex, disabled }) =>
  tabIndex === undefined && !asLabel ? (disabled ? -1 : 0) : tabIndex

export default hooks.component(
  hooks.consume(['asLabel'])(
    hooks.assoc('component', component),
    hooks.assoc('tabIndex', tabIndex)
  ),
  hooks.decorator('onKeyDown', onKeyDown),
  hooks.consume(['onLongPress', 'onPress'])(
    hooks.tap(hooks.value('longPressRef', () => ({})))(
      hooks.handler('onPointerDown', onPointerDown),
      R.ifElse(
        R.prop('onLongPress'),
        R.pipe(
          hooks.handler('onMouseMove', onPointerMove),
          hooks.handler('onTouchMove', onPointerMove)
        ),
        R.identity
      ),
      hooks.handler('onPointerUp', onPointerUp),
      hooks.handler('onClick', onClick)
    )
  ),
  hooks.consume(['onDoublePress'])(
    hooks.handler('onDoubleClick', onDoubleClick)
  )
)('Button')
