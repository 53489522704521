import View from '__components__/View'
import PanelContent from '../Content'
import Underline from './underline.svg?react'

const GoogleDrivePanel = ({ ...props }) => (
  <PanelContent
    title={
      <>
        <View style="relative">
          <View.Text>Benefits of using Google Drive</View.Text>
          <View style="absolute -right-3 -bottom-3 flex">
            <Underline width={146} />
          </View>
        </View>
        <View.Text>with Drawboard PDF</View.Text>
      </>
    }
    {...props}
  />
)

export default GoogleDrivePanel
