import * as R from 'ramda'

const useThemePropFy =
  (defaultPropName, values) =>
  (defaultValue, propName = defaultPropName) =>
  (props) => {
    const size =
      props[propName] !== null
        ? props[propName] || values.find((name) => props[name]) || defaultValue
        : null
    return values.reduce(
      (acc, name) => ({ ...acc, [name]: size === name }),
      props
    )
  }

const SIZES = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']
const COLORS = ['danger', 'success', 'brand', 'primary', 'pro', 'secondary']

const size = useThemePropFy('size', SIZES)
const color = useThemePropFy('color', COLORS)
const omit = R.omit([...SIZES, ...COLORS])

export default { size, color, omit }
