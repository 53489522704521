import * as R from 'ramda'

import typeMap from './typeMap'
import uuid from '__lib__/uuid'
import compose from '__lib__/fn/compose'
import identity from '__lib__/fn/identity'
import matrixIdentity from '../matrix/identity'

const miter = R.compose(
  R.assoc(
    'strokeMiterlimit',
    import.meta.env.VITE_PROJECT === 'pdf' ? 8 : 2
  ),
  R.assoc('strokeLinejoin', 'miter')
)

const strokeInk = R.mergeLeft({
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
})

const line = R.mergeLeft({ fill: 'none' })
const ink =
  import.meta.env.VITE_PROJECT === 'pdf'
    ? R.ifElse(R.prop('strokeOpacity'), strokeInk, R.identity)
    : R.assoc('strokeWidth', 0)
const length = R.assoc('fill', 'none')

// Prior to Text Annotation V2, the UWP app was sending black fill with opacity 1
// With the introduction of Text Annotations with fill and opacity support, we need
// to set the opacity for prior Text Annotations to 0
const VERSION_WITH_OPACITY = 2
const textFillOpacity =
  import.meta.env.VITE_PROJECT === 'projects'
    ? (annotation) => ({
        ...annotation,
        fillOpacity:
          annotation.version >= VERSION_WITH_OPACITY
            ? annotation.fillOpacity
            : 0,
      })
    : R.identity

const textFontFamily =
  '"Noto Sans Hebrew", "Noto Sans", "Noto Sans JP", "Noto Sans KR"'

const text = R.compose(
  R.assoc('fontFamily', textFontFamily),
  textFillOpacity,
  import.meta.env.VITE_PROJECT === 'projects'
    ? R.over(R.lensProp('version'), R.defaultTo(3))
    : R.identity
)

const callout = R.compose(
  R.assoc('fontFamily', textFontFamily),
  ({ startStyle, ...props }) => ({
    ...props,
    // TODO DBPDF UWP provides None so that is to fix it,
    // we need to removes that when UWP fixes its issue
    startStyle: startStyle === 'none' ? 'openArrow' : startStyle,
  }),
  import.meta.env.VITE_PROJECT === 'projects'
    ? R.over(R.lensProp('version'), R.defaultTo(2))
    : R.identity
)

const cloud = R.assoc('strokeLinecap', 'round')
const area = miter
const polygon = miter
const polyLine = miter
const polyLength = miter
const rectangle = miter
const rectangleArea = miter

const textReview = ({ strokeWidth, ...props }) => ({
  strokeWidth: strokeWidth || 1,
  ...props,
})

const textHighlight = ({ blendMode, ...props }) => ({
  blendMode: blendMode || 'multiply',
  ...props,
})

const map = {
  ink,
  text,
  line,
  area,
  cloud,
  length,
  polygon,
  callout,
  polyLine,
  rectangle,
  polyLength,
  rectangleArea,
  textHighlight,
  textStrike: textReview,
  textSquiggly: textReview,
  textUnderline: textReview,
}

const specifics = typeMap(map, identity).invoke

const getFallbackZOrder = () => {
  console.warn('ZORDER FALLBACK', new Error())
  return Math.round(+new Date() / 1000)
}

const common = ({
  id,
  fill,
  page,
  type,
  turns,
  zOrder,
  matrix,
  stroke,
  opacity,
  version,
  endStyle,
  geometry,
  revision,
  blendMode,
  startStyle,
  strokeJoin,
  fillOpacity,
  strokeOpacity,
  childAnnotations,
  ...annotation
}) => ({
  type,
  version,
  blendMode,
  page: page || 1,
  fill: fill || '',
  turns: turns || 0,
  endStyle: endStyle,
  stroke: stroke || '',
  startStyle: startStyle,
  revision: revision || 1,
  geometry: geometry || '',
  id: id || uuid(),
  matrix: matrix || matrixIdentity,
  opacity: opacity == null ? 1 : opacity,
  childAnnotations: childAnnotations || [],
  fillOpacity: fillOpacity == null ? 0 : fillOpacity,
  zOrder: zOrder == null ? getFallbackZOrder() : zOrder,
  strokeOpacity: strokeOpacity == null ? 1 : strokeOpacity,
  ...annotation,
})

export default compose(specifics, common)
