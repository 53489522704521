import round from '../maths/round'

// geom are unconsistent in the database so we have to cover all possibilities here.
// We should store them more consistently so we can parse them more efficiently

// const transform = (x, y, matrix) =>
//   matrix ? transformXY(x, y, matrix) : { x, y }

const M = (type, [x, y], map) => ({ type, ...map(x, y) })
const L = M
const A = (type, [rx, ry, t, la, s, x, y], map) => ({
  type,
  rx,
  ry,
  t,
  la,
  s,
  ...map(x, y),
})
const C = (type, [bx1, by1, bx2, by2, x, y], map) => {
  const { x: x1, y: y1 } = map(bx1, by1)
  const { x: x2, y: y2 } = map(bx2, by2)
  return { type, x1, y1, x2, y2, ...map(x, y) }
}
const Q = (type, [bx1, by1, x, y], map) => {
  const { x: x1, y: y1 } = map(bx1, by1)
  return { type, x1, y1, ...map(x, y) }
}
const Z = (type) => ({ type })
const H = (type, [x], map, { y }) => L('L', [x, y], map)
const V = (type, [y], map, { x }) => L('L', [x, y], map)

const map = { M, L, A, C, Q, Z, H, V }

const NUMBER_REGEXP = /-?[0-9]*\.?[0-9]+(?:e[-+]?\d+)?/gi
const SEGMENT_REGEXP = /([astvzqmhlc])([^astvzqmhlc]*)/gi
export default (path, matrix) => {
  const mapNumber = (x) => Number(x) || 0
  const transform = matrix
    ? (x, y) => transform(mapNumber(x), mapNumber(y), matrix)
    : (x, y) => ({ x: mapNumber(x), y: mapNumber(y) })
  const data = []
  String(path).replace(SEGMENT_REGEXP, (_, type, args) => {
    const values = args.match(NUMBER_REGEXP) || []
    // https://drawboard.sentry.io/issues/5791604283/
    const mapSvgCommand = map[type]
    if (!mapSvgCommand)
      throw new Error(
        `__canvas__/path/fromString: Found SVG command "${type}" which doesn't have a handler in map.\nInput string: ${path}`
      )
    data.push(mapSvgCommand(type, values, transform, data[data.length - 1]))
    return null
  })
  return data
}
