import hooks from '__hooks__'
import Close from './Close'
import Dialog from '../Dialog'
import View from '__components__/View'

const Modal = ({
  header,
  dialog,
  footer,
  children,
  bodyStyle,
  breakpoints,
  footerStyle,
  headerStyle,
  headerBodyStyle,
  withoutBackdrop,
  containerStyle,
  closeStyle,
  ...props
}) => (
  <Dialog
    bodyAnchor
    notIsolated
    dialog={dialog}
    screenMargin={0}
    placement="center"
    withBackdrop={!withoutBackdrop}
    transition={
      breakpoints.has('tablet') ? 'popFadeSmall' : 'slideFadeTopSmall'
    }
    styleLeft="bg-drop-2 full tablet:shadow-md laptop:rounded-lg text-shade-7 transform-center"
    containerStyle={[
      {
        laptop: {
          maxWidth: 'calc(100vw - 32px)',
          maxHeight: 'calc(100vh - 32px)',
        },
      },
      'w-full h-full laptop:w-2/3 laptop:h-auto',
      containerStyle,
    ]}
    {...props}
  >
    {header && (
      <View styleLeft="flex items-center pl-5" style={headerStyle}>
        <View styleLeft="p-5 text-lg" style={headerBodyStyle}>
          {header}
        </View>
        <Close style={closeStyle} dialog={dialog} />
      </View>
    )}
    <View styleLeft="p-6" style={bodyStyle}>
      {children}
    </View>
    {footer && (
      <View styleLeft="p-6 flex items-center justify-end" style={footerStyle}>
        {footer}
      </View>
    )}
  </Dialog>
)

export default hooks(hooks.breakpoints('breakpoints'))(Modal)
