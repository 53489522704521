import * as R from 'ramda'
import { Link, useLocation } from 'react-router-dom'

import hooks from '__hooks__'
import history from '__routes__/history'

const replace = ({ replace }) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  replace || useLocation().state?.transient ? true : replace

const listenClickEvent = ({ ref, back }) => {
  if (!back) return
  const node = ref.current
  if (!node) return
  const handler = (event) => {
    event.stopPropagation()
    event.preventDefault()
    history.go(-(R.is(Number, back) ? back : 1))
  }
  node.addEventListener('click', handler)
  return () => node.removeEventListener('click', handler)
}

// const listenClickEvent = ({ ref, to, state }) => {
//   const node = ref.current
//   if (!node) return
//   const handler = (event) => {
//     event.stopPropagation()
//     event.preventDefault()
//     go(to, state)
//   }
//   node.addEventListener('click', handler)
//   return () => node.removeEventListener('click', handler)
// }

export default hooks(
  hooks.bubbleRef('ref'),
  hooks.consume(['back'])(hooks.effect(listenClickEvent, ['back'])),
  hooks.assoc('replace', replace),
  hooks.rename('ref', 'innerRef')
)(Link)
