import React from 'react'
import * as R from 'ramda'
import memoizeOne from 'memoize-one'

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

const isClassComponent = (Component) =>
  Boolean(
    Component &&
      Component.prototype &&
      typeof Component.prototype.render === 'function'
  )

export const getDeps = (deps, props) =>
  R.is(Array, deps)
    ? deps.map((dep) => (R.is(String, dep) ? R.prop(dep, props) : dep(props)))
    : deps(props)

const stateMap = (name, setName, map, initialValue) => (props) => {
  const valueRef = React.useRef()
  const [value, _setValue] = React.useState(
    valueRef.current ||
      map(R.is(Function, initialValue) ? initialValue(props) : initialValue)
  )
  valueRef.current = value
  const setValue = React.useRef((_newValue) => {
    const newValue = map(_newValue)
    return (
      (R.is(Function, newValue) || newValue !== valueRef.current) &&
      _setValue(newValue)
    )
  }).current
  return R.merge(props, { [name]: value, [setName]: setValue })
}

const state = (name, setName, initialValue) => (props) => {
  const [value, _setValue] = React.useState(
    R.is(Function, initialValue) ? initialValue(props) : initialValue
  )
  const valueRef = React.useRef({})
  valueRef.current = value
  const setValue = React.useRef(
    (newValue) =>
      (R.is(Function, newValue) || newValue !== valueRef.current) &&
      _setValue(newValue)
  ).current
  return R.merge(props, { [name]: value, [setName]: setValue })
}

export const boolSetter = (setter) =>
  Object.assign((value) => setter(value), {
    true: () => setter(true),
    false: () => setter(false),
    toggle: () => setter((value) => !value),
    promise: (promise, mountedRef) => {
      if (!promise?.then) return promise
      ;(!mountedRef || mountedRef.current) && setter(true)
      return promise
        .then(R.tap(() => (!mountedRef || mountedRef.current) && setter(false)))
        .catch((error) => {
          ;(!mountedRef || mountedRef.current) && setter(false)
          return Promise.reject(error)
        })
    },
  })

export const boolState =
  (name, setName, initialValue = false) =>
  (props) => {
    const [value, _setValue] = React.useState(
      R.is(Function, initialValue) ? initialValue(props) : initialValue
    )
    const setValue = React.useMemo(() => boolSetter(_setValue), [])
    return R.merge(props, { [name]: value, [setName]: setValue })
  }

const setState =
  (name, setName, initialValue = () => new Set()) =>
  (props) => {
    const [value, _setValue] = React.useState(
      R.is(Function, initialValue) ? initialValue(props) : initialValue
    )
    const setValue = React.useMemo(
      () =>
        Object.assign((value) => _setValue(value), {
          add: (value) => _setValue((set) => new Set(set).add(value)),
          clear: () => _setValue(new Set()),
          remove: (value) =>
            _setValue((set) => {
              const newSet = new Set(set)
              newSet.delete(value)
              return newSet
            }),
          toggle: (value) =>
            _setValue((set) => {
              const newSet = new Set(set)
              if (set.has(value)) {
                newSet.delete(value)
                return newSet
              } else return newSet.add(value)
            }),
        }),
      []
    )
    return R.merge(props, { [name]: value, [setName]: setValue })
  }

const hook =
  (hook, ...args) =>
  (props) => {
    const value = hook(props, ...args)
    return (R.is(Function, value) ? value : R.mergeLeft(value))(props)
  }

const effect = (fn, deps = [], map = R.identity) =>
  R.tap((props) => {
    const ref = React.useRef({ prev: {} })
    ref.current.current = map(props)
    React.useEffect(
      () => {
        try {
          const cleanup = fn(ref.current.current, ref.current)
          ref.current.prev = ref.current.current
          return cleanup
        } catch (error) {
          if (import.meta.env.VITE_ENV === 'development') {
            console.error('Effect error')
            throw error
          } else {
            console.error('Effect error', error)
          }
        }
      },
      getDeps(deps, props)
    )
  })

const layoutEffect = (fn, deps = [], map = R.identity) =>
  R.tap((props) => {
    const ref = React.useRef({ prev: {} })
    ref.current.current = map(props)
    React.useLayoutEffect(
      () => {
        const cleanup = fn(ref.current.current, ref.current)
        ref.current.prev = ref.current.current
        return cleanup
      },
      getDeps(deps, props)
    )
  })

const tapEffect = (fn, deps = []) =>
  effect(R.compose(R.always(undefined), fn), deps)

const useLazyRef = (fn, decorator) => {
  const value = React.useRef((newValue) => {
    const oldValue = value.current
    value.current.current = newValue
    return decorator ? decorator(newValue, oldValue) : newValue
  })
  value.current.hasOwnProperty('current') || value.current(fn())
  return value.current
}

const ref = (name, initialValue, decorator) => (props) => {
  const value = useLazyRef(
    () => (R.is(Function, initialValue) ? initialValue(props) : initialValue),
    decorator
  )
  return R.mergeLeft({ [name]: value }, props)
}

const value2 = (name, fn) => (props) => {
  const propsRef = React.useRef(props)
  propsRef.current = props
  const [value] = React.useState(() => fn(propsRef.current, propsRef))
  return R.assoc(name, value, props)
}

const value = (name, initialValue, deps, decorator) => (props) => {
  const propsRef = React.useRef({ current: props, prev: {} })
  propsRef.current.prev = propsRef.current.current
  propsRef.current.current = props

  const value = useLazyRef(
    () => ({
      value: R.is(Function, initialValue)
        ? initialValue(propsRef.current.current, propsRef.current)
        : initialValue,
    }),
    decorator
  )
  // useMemo to get this running during rendering
  deps &&
    React.useMemo(
      () => {
        !value.current.initialized
          ? (value.current.initialized = true)
          : (value.current = {
              initialized: true,
              value: R.is(Function, initialValue)
                ? initialValue(propsRef.current.current, propsRef.current)
                : initialValue,
            })
      },
      getDeps(deps, props)
    )
  return R.assoc(name, value.current.value, props)
}

const bubbleRef =
  (name, initialValue, bubblePropName = name) =>
  (props) => {
    const value = useLazyRef(
      () => (R.is(Function, initialValue) ? initialValue(props) : initialValue),
      props[bubblePropName]
    )
    return (props[name] ? R.identity : R.assoc(name, value))(props)
  }

const useMemo = (fn, deps) => {
  const ref = React.useRef({})
  ref.current.memo ||= memoizeOne((...args) =>
    ref.current.fn(ref.current)(args)
  )
  ref.current.fn = fn
  ref.current.value = ref.current.memo(...deps)
  ref.current.deps = deps
  return ref.current.value
}

const memo = (name, fn, deps) => (props) =>
  (R.is(String, name) ? R.assoc(name) : R.mergeLeft)(
    useMemo(
      (previous) => (args) => fn(props, args, previous),
      getDeps(deps, props)
    ),
    props
  )

const memoRef = (name, fn, deps, initialValue) => (props) => {
  const ref = React.useRef({ prevValue: initialValue })
  ref.current.nextProps = props
  const memo = React.useMemo(() => fn(props, ref.current), getDeps(deps, props))
  ref.current.prevValue = memo
  ref.current.prevProps = props
  return R.assoc(name, memo, props)
}

const handler = (name, fn) => (props) => {
  const ref = import.meta.env.PROD
    ? React.useRef((...args) => fn(ref.current.current, ref.current)(...args))
    : React.useRef((...args) => {
        const argsFn = fn(ref.current.current, ref.current)
        return R.is(Function, argsFn)
          ? argsFn(...args)
          : console.error('Hooks.handler', name, 'is not a function')
      })
  ref.current.current = props
  return R.assoc(name, ref.current, props)
}

const context = (name, context) => (props) =>
  R.assoc(name, React.useContext(context), props)

const decorator = (name, fn) =>
  handler(name, (props) => (...args) => {
    fn(props)(...args)
    props[name] && props[name](...args)
  })

const forwardRef = (staticProps, component, fn) => {
  const { displayName, name = displayName } = component

  R.forEachObjIndexed(
    (value, key) => (fn[key] = value),
    !staticProps
      ? { displayName: name || 'Hook' }
      : R.is(String, staticProps)
        ? { displayName: staticProps || name }
        : staticProps
  )

  return Object.assign(React.forwardRef(fn), {
    displayName: fn.displayName || fn.name,
  })
}

const outerRef = (ref) => (ref ? R.mergeLeft({ ref }) : R.identity)

const hoc =
  (...hooks) =>
  (Component) =>
  (props) => <Component {...R.pipe(...hooks)(props)} />

// Do we really need to forward ref all the time? has it got a performance downside?
export const use =
  (...hooks) =>
  (component, staticProps) =>
    R.is(Function, component) && !isClassComponent(component)
      ? forwardRef(staticProps, component, (props, ref) =>
          component(R.pipe(outerRef(ref), ...hooks)(props))
        )
      : forwardRef(staticProps, component, (props, ref) =>
          React.createElement(component, R.pipe(outerRef(ref), ...hooks)(props))
        )

const component =
  (...hooks) =>
  (staticProps, innerComponent) =>
    forwardRef(staticProps, { name: 'Component' }, (props, ref) => {
      const hookedProps = R.pipe(outerRef(ref), ...hooks)(props)
      return React.createElement(
        R.prop('component', hookedProps),
        innerComponent
          ? rename(innerComponent(hookedProps), 'component')
          : R.omit(['component'], hookedProps)
      )
    })

const assign = (fn) => (props) => ({ ...props, ...fn(props) })

const rename = (oldName, newName) => (props) =>
  R.ifElse(
    R.has(oldName),
    R.compose(R.omit([oldName]), R.assoc(newName, R.prop(oldName, props))),
    R.identity
  )(props)

const map = (fn) => (props) => fn(props)
const assoc = (name, fn) => (props) => R.assoc(name, fn(props), props)

const log = (what, map = R.identity) =>
  R.tap((props) => console.log(what, map(props)))

const pipeFn = (fn) => (R.is(Array, fn) ? R.pipe(...fn) : fn)

const objectFilter = (fn) => (object) =>
  R.reduce(
    (acc, key) => {
      const value = object[key]
      fn(value, key, object, acc) && (acc[key] = value)
      return acc
    },
    {},
    R.keys(object)
  )

const tapInto = R.curry((tappedHook, hook) => (props) => {
  const tappedProps = pipeFn(tappedHook)(props)
  const newProps = pipeFn(hook)(tappedProps)
  const filterProp = (value, key) => tappedProps[key] !== value
  return R.mergeLeft(objectFilter(filterProp)(newProps), props)
})

const tap =
  (...tappedHooks) =>
  (...hooks) =>
  (props) => {
    const tappedProps = R.pipe(...tappedHooks)(props)
    const newProps = R.pipe(...hooks)(tappedProps)
    const filterProp = (value, key) => tappedProps[key] !== value
    return R.mergeLeft(objectFilter(filterProp)(newProps), props)
  }

const defaultValue = (name, value) =>
  R.ifElse(R.has(name), R.identity, R.assoc(name, value))

const consume =
  (propNames) =>
  (...hooks) =>
    R.compose(R.omit(propNames), R.pipe(...hooks))

const prev = (name, baseName) => (props) => {
  const { propsRef } = ref('propsRef')({})
  propsRef.current = props[baseName]
  const prev = propsRef.prev
  propsRef.prev = propsRef.current
  return R.assoc(name, prev, props)
}

const branch =
  (...hooks) =>
  (predicate, renderFn, elseRenderFn = R.always(null)) =>
    use(...hooks)(R.ifElse(predicate, renderFn, elseRenderFn), component.name)

const renderComponent = (Component) => (props) => <Component {...props} />

const nativeEventEffect = (handler, eventName, refName) =>
  R.pipe(
    bubbleRef(refName),
    effect((props, propsRef) => {
      const ref = props[refName]
      if (!ref) return
      const eventHandler = (event) => handler(propsRef.current, propsRef)(event)
      const node = ref.current
      node.addEventListener(eventName, eventHandler)
      return () => node.removeEventListener(eventName, eventHandler)
    })
  )

const coreHooks = {
  ref,
  log,
  tap,
  hoc,
  map,
  hook,
  memo,
  branch,
  // chain,
  prev,
  assoc,
  state,
  value,
  value2,
  assign,
  effect,
  rename,
  consume,
  tapInto,
  memoRef,
  context,
  handler,
  stateMap,
  setState,
  bubbleRef,
  component,
  boolState,
  decorator,
  tapEffect,
  useLazyRef,
  layoutEffect,
  renderComponent,
  nativeEventEffect,
  default: defaultValue,
}

export const withModules = (...modules) =>
  Object.assign(use, modules.reduce(R.merge, coreHooks))

export default coreHooks
