import * as R from 'ramda'
import { parsePath } from 'history'
import { parse, stringify } from 'query-string'

import invoke from '__lib__/fn/invoke'
import history from '__routes__/history'
import compose from '__lib__/fn/compose'
import { normalize as normalizeHash } from './hash'

const normalize = (location) => ({
  ...location,
  hash: normalizeHash(location.hash),
})

export const build = (location) => {
  const currentLocation = history.location
  return typeof location === 'function'
    ? normalize(location(currentLocation))
    : Array.isArray(location)
    ? normalize(compose(...location)(currentLocation))
    : typeof location === 'string'
    ? parsePath(location)
    : location
}

export const query = (value) => (location) => ({
  ...location,
  search: stringify(invoke(value)(parse(location.search), location)),
})

export const path = (value) => (location) => ({
  ...location,
  pathname: invoke(value)(location.pathname, location),
})

const enhance = true
  ? R.identity
  : (fn, name) => (location) => {
      console.debug(`[LOCATION] ${name}`, build(location))
      return fn(location)
    }

export const replace = enhance(
  (location) => history.replace(build(location)),
  'replace'
)

export const go = enhance((location) => history.push(build(location)), 'go')

export default go
