import React, { useRef, useContext } from 'react'

import { useEventEffect } from './index'

export const PressedKeyContext = React.createContext({})
export const usePressedKeyContext = () => useContext(PressedKeyContext)

const PressedKeyProvider = (props) => {
  const isPressedKeyRef = useRef(false)
  const eventHandler = (toggle) => (event) =>
    (isPressedKeyRef.current = toggle ? event.key : null)
  useEventEffect('onKeyDown', eventHandler(true))
  useEventEffect('onKeyUp', eventHandler(false))
  return <PressedKeyContext.Provider value={isPressedKeyRef} {...props} />
}

export default PressedKeyProvider
