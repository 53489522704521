import React from 'react'
import { compose } from 'react-recompose'
import { mapProps } from 'react-recompose'

import Dialog from 'Dialog/Permission'
import withDialog from 'Dialog/withDialog'
import objectWithout from '__lib__/object/without'
import withClassName from '__lib__/react/withClassName'

const WithPermission = ({
  onClick,
  children,
  notPermitted,
  permissionDialog,
  unpermittedProps,
  permittedClassName,
  component: Component,
  notPermittedClassName,
  propsWhenNotPermitted,
  onNotPermittedDialogClose,
  dialogComponent: DialogComponent = Dialog,
  ...props
}) => (
  <Component
    {...props}
    {...(notPermitted && propsWhenNotPermitted)}
    onClick={notPermitted ? permissionDialog.open : onClick}
  >
    {children}
    {notPermitted && (
      <DialogComponent
        label={notPermitted}
        dialog={permissionDialog}
        onClose={onNotPermittedDialogClose}
      />
    )}
  </Component>
)

const unpermittedProps = (props) =>
  props.notPermitted && props.unpermittedProps
    ? objectWithout(props, props.unpermittedProps)
    : props

const className = ({
  notPermitted,
  permittedClassName,
  notPermittedClassName = 'c-shade-4',
}) => (notPermitted ? notPermittedClassName : permittedClassName)

export default compose(
  mapProps(unpermittedProps),
  withDialog('permissionDialog'),
  withClassName(className)
)(WithPermission)
