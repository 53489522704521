import * as R from 'ramda'
import { keyboard } from './keyboard'

import isMacLike from '__lib__/utils/isMacLike'

const eventModifiersKeys = {
  alt: 'altKey',
  ctrl: 'ctrlKey',
  meta: 'metaKey',
  shift: 'shiftKey',
}

export const EVENT_KEY_MODIFIERS_KEYS = [
  'altKey',
  'shiftKey',
  'ctrlKey',
  'metaKey',
]

const unknownKey = (key) => console.warn(`Unknown keyboard key [${key}]`)
const unknownModifier = (key) => console.warn(`Unknown modifier [${key}]`)

const mapModifier = (modifier) => {
  const splitModifier = modifier.split('/')
  return (
    eventModifiersKeys[
      splitModifier.length === 1
        ? modifier
        : isMacLike()
        ? splitModifier[1]
        : splitModifier[0]
    ] || unknownModifier(modifier)
  )
}

const keyToKeyNameMap = {
  copy: 'c',
  metaKey: '⌘',
  paste: 'v',
  altKey: 'alt',
  ctrlKey: 'ctrl',
  arrowUp: '↑',
  escape: 'esc',
  shiftKey: 'shift',
  arrowDown: '↓',
  arrowLeft: '←',
  arrowRight: '→',
  arrows: '←/→/↑/↓',
  arrowUpDown: '↑/↓',
  'open bracket': '[',
  'close bracket': ']',
  arrowLeftRight: '←/→',
}

const specialKeys = {
  '0-9': true,
  arrows: true,
  mousedown: true,
  mousewheel: true,
  arrowUpDown: true,
  'hold space': true,
  arrowLeftRight: true,
}

export const formatKey = (key) => keyToKeyNameMap[key] || key

export const modifiers = (...modifiersArgs) => {
  const modifiers = modifiersArgs.map(mapModifier)
  return {
    modifiers,
    getFormattedKeys: R.once(() => modifiers.map(formatKey)),
  }
}

export const shortcut = (key, ...modifiersArgs) => {
  const modifiersData = modifiers(...modifiersArgs)
  return {
    key,
    modifiers: modifiersData.modifiers,
    keyCode: keyboard[key] || specialKeys[key] || unknownKey(key),
    getFormattedModifiers: R.once(() => modifiersData.getFormattedKeys()),
    getFormattedKeys: R.once(() => [
      ...modifiersData.getFormattedKeys(),
      formatKey(key),
    ]),
  }
}

export const key = (key) => shortcut(key)

const codeMatchesKey = (code, key) => code === keyboard[key]

export const eventMatchesModifiers = (event, { modifiers }) =>
  modifiers.every((modifier) => event[modifier])

export const eventMatchesModifiersStrict = (event, { modifiers }) =>
  EVENT_KEY_MODIFIERS_KEYS.every(
    (modifier) =>
      Boolean(event[modifier]) === Boolean(modifiers.includes(modifier))
  )

export const eventMatches = (event, shortcut) =>
  codeMatchesKey(event.keyCode, shortcut.key) &&
  eventMatchesModifiers(event, shortcut)

export const eventMatchesStrict = (event, shortcut) =>
  codeMatchesKey(event.keyCode, shortcut.key) &&
  eventMatchesModifiersStrict(event, shortcut)

export const enterSubmit = shortcut('enter', 'shift')
export const cancelActions = [shortcut('escape'), shortcut('backquote', 'ctrl')]
export const deleteActions = [shortcut('delete'), shortcut('backspace')]

export const mapEventToNumber = ({ keyCode }) =>
  keyCode - (keyCode >= keyboard.numpad0 ? keyboard.numpad0 : keyboard['0'])

export const getKeyCode = (key) => keyboard[key]

const numberKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

const keyPadKeys = [
  'numpad0',
  'numpad1',
  'numpad2',
  'numpad3',
  'numpad4',
  'numpad5',
  'numpad6',
  'numpad7',
  'numpad8',
  'numpad9',
]

export const numberShortcuts = R.once(() => [
  ...keyPadKeys.map(key),
  ...numberKeys.map(key),
])
