import { matchPath } from 'react-router-dom'

export const doesLocationMatch = (
  location,
  path,
  { exact, strict } = { exact: false, strict: false }
) => Boolean(matchPath(location.pathname, { path, exact, strict }))

export const getLocationParams = (
  location,
  path,
  { exact, strict } = { exact: false, strict: false }
) => {
  const matchedPath = matchPath(location.pathname, { path, exact, strict })
  return matchedPath ? matchedPath.params : {}
}

export const getPathName = (url) => {
  let a = document.createElement('a')
  a.href = url
  return a.pathname
}
