const animationFrame = () => {
  const ref = {}
  const cancel = () => {
    ref.current && cancelAnimationFrame(ref.current)
    ref.current = null
  }
  const next = (fn) =>
    ref.current ||
    (ref.current = requestAnimationFrame(() => {
      fn()
      ref.current = null
    }))
  return Object.assign(cancel, { cancel, next, ping: next })
}

export default animationFrame
