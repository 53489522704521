import * as R from 'ramda'

import hooks from '__hooks__'
import { usePressedKeyContext } from '__core__/EventProvider/PressedKeyProvider'

// arrows for <input type="radio"/>
const isFocusKey = R.includes(R.__, [
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
])

export default R.pipe(
  hooks.hook(() => ({ isPressedKeyRef: usePressedKeyContext() })),
  hooks.decorator(
    'onFocus',
    ({ isPressedKeyRef, setIsFocus, mounted }) =>
      (event, element) =>
        (!element || event.target === element) &&
        setTimeout(
          () =>
            (!mounted || mounted.current) &&
            setIsFocus(isFocusKey(isPressedKeyRef.current) ? 'key' : true)
        )
  ),
  R.omit(['isPressedKeyRef'])
)
