import PropTypes from 'prop-types'
import { getContext } from 'react-recompose'
import { withContext } from 'react-recompose'

const childContextTypes = {
  parentDialog: PropTypes.object,
}

const getChildContext = ({ dialogInstance }) => ({
  parentDialog: dialogInstance,
})

export const withChildContext = getContext(childContextTypes)

export default withContext(childContextTypes, getChildContext)
