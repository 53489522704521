import View from '__components__/View'
import Integration from '__screens__/Integration'
import { Header } from '__screens__/Layout'
import PlatformIcon from '__views__/PlatformIcon'

const GoogleDriveHeader = ({ isRegister }) => (
  <View style="flex flex-col text-center text-shade-7">
    <Integration integrationComponent={PlatformIcon.Google} style="mb-5" />
    <Header
      title={`To open your\n Google Drive document`}
      description={
        isRegister
          ? 'Create a Drawboard PDF account'
          : 'Choose from your log in options'
      }
    />
  </View>
)

export default GoogleDriveHeader
