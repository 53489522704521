import { pair } from '__lib__/immutable'
import { dispatch, getState } from '__store__/store/global'

const makePath = (path) => ['ui', path]

export const [getUI, setUI] = pair(makePath)
export const fpSetUI = (path) => (state, value) => setUI(state, path, value)
export const fpGetUI = (path) => (state) => getUI(state, path)
export const setUIIntent = (path, value) =>
  value !== getUI(getState(), path) && dispatch('uiSet', path, value)
export const toggleUI = (state, path) => setUI(state, path, !getUI(state, path))
export const fpToggleUI = (path) => (state) => toggleUI(state, path)
