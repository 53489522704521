export const mString = (x, y) => `M${x},${y}`

export const mStringPoints = ({ x, y }) => mString(x, y)

export const mPoints = ({ x, y }) => ({
  type: 'M',
  x,
  y,
})

export default (x, y) => ({
  type: 'M',
  x,
  y,
})
