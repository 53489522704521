import { mapProps } from 'react-recompose'
import { withProps } from 'react-recompose'
import { defaultProps } from 'react-recompose'

import Anchor from 'Anchor'
import Button from './Button'
import Loading from 'Loading'
import ButtonIcon from './Icon'
import ButtonGroup from './Group'
import LoadingButton from './Loading'
import WithPermission from 'WithPermission'
import ButtonCollapseIcon from './CollapseIcon'
import withLoadingHandler from '__lib__/react/withLoadingHandler'

const ButtonAnchor = withProps({ component: Anchor })(Button)
const ButtonAnchorIcon = withProps({ component: ButtonAnchor })(ButtonIcon)

const loadingIcon = ({ icon, loading, ...props }) => ({
  icon: loading ? Loading : icon,
  ...props,
})

const LoadingIcon = mapProps(loadingIcon)(ButtonIcon)
const ButtonWithPermission = defaultProps({
  component: Button,
  propsWhenNotPermitted: { disabledEffect: true },
})(WithPermission)

const ButtonAnchorWithPermission = defaultProps({
  component: ButtonAnchor,
  unpermittedProps: ['to'],
  propsWhenNotPermitted: { disabledEffect: true },
})(WithPermission)

const AnchorCollapseIcon = withProps({
  buttonIconComponent: ButtonAnchorIcon,
  buttonComponent: ButtonAnchor,
})(ButtonCollapseIcon)

export default Object.assign(Button, {
  WithPermission: ButtonWithPermission,
  Icon: Object.assign(ButtonIcon, {
    Loading: Object.assign(LoadingIcon, {
      OnClick: withLoadingHandler('onClick')(LoadingIcon),
    }),
  }),
  Group: ButtonGroup,
  Anchor: Object.assign(ButtonAnchor, {
    Icon: ButtonAnchorIcon,
    Loading: withProps({ component: ButtonAnchor })(LoadingButton),
    CollapseIcon: Object.assign(AnchorCollapseIcon, {
      WithPermission: defaultProps({
        component: AnchorCollapseIcon,
        unpermittedProps: ['to'],
        propsWhenNotPermitted: { disabledEffect: true },
      })(WithPermission),
    }),
    WithPermission: ButtonAnchorWithPermission,
  }),
  CollapseIcon: ButtonCollapseIcon,
  Loading: Object.assign(LoadingButton, {
    OnClick: withLoadingHandler('onClick')(LoadingButton),
  }),
})
