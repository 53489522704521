import { compose } from 'react-recompose'
import { withState } from 'react-recompose'

import invoke from '__lib__/fn/invoke'
import lifecycle from '__lib__/react/lifecycle'

const MountWithDelay = ({ children, isDelayPassed, useAnimationFrame }) =>
  (isDelayPassed && children) || null

const didMount = (props, instance) =>
  (instance.timeout = (
    props.useAnimationFrame ? window.requestAnimationFrame : window.setTimeout
  )(() => props.setIsDelayPassed(true), invoke(props.delay)(props) || 300))

const willUnmount = ({ useAnimationFrame }, instance) =>
  useAnimationFrame
    ? window.cancelAnimationFrame(instance.timeout)
    : window.clearTimeout(instance.timeout)

export default compose(
  withState('isDelayPassed', 'setIsDelayPassed', false),
  lifecycle({ didMount, willUnmount })
)(MountWithDelay)
