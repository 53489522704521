import React from 'react'
import ReactDOM from 'react-dom'
import { withProps } from 'react-recompose'

import styles from './styles.module.css'
import lazy from '__lib__/fn/lazy'
import invoke from '__lib__/fn/invoke'
import assign from '__lib__/react/assign'

const getTransitionEvent = lazy(() => {
  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  }
  const el = document.createElement('fakeelement')
  const match = Object.keys(transitions).find((t) => el.style[t] !== undefined)
  return match ? transitions[match] : ''
})

const eventIsNotRelated = ({ target, propertyName }, node, changesLayout) =>
  (!changesLayout &&
    propertyName !== 'transform' &&
    propertyName !== 'opacity') ||
  target !== node

const getTransitionDuration = (node) =>
  1000 * (parseFloat(getComputedStyle(node).transitionDuration) || 1)

const listenTransitionEnd = (node, changesLayout, callback) => {
  let hasCaughtEvent = false
  const eventName = getTransitionEvent()
  const theoricalTransitionEnd = getTransitionDuration(node)
  const action = () => {
    if (hasCaughtEvent) return
    hasCaughtEvent = true
    callback()
    cleanup()
  }
  const transitionEndListener = (event) => {
    if (eventIsNotRelated(event, node, changesLayout)) return
    action()
  }
  const safetyNetListener = action
  const cleanup = () => {
    node.removeEventListener(eventName, transitionEndListener)
    clearTimeout(safetyNetListener)
  }
  node.addEventListener(getTransitionEvent(), transitionEndListener)
  setTimeout(safetyNetListener, theoricalTransitionEnd + 50)
}

const phaseEvents = {
  enter: 'onEnter',
  leave: 'onLeave',
  'enter-active': 'onEnterActive',
  'leave-active': 'onLeaveActive',
}

const transit = (node, name, phase, options, cancel) => {
  name && node.classList.add(name + '-' + phase)
  options[phaseEvents[phase]] && options[phaseEvents[phase]](node, { cancel })
  node.getBoundingClientRect() // forces the style to be applied to the node
}

const transitBack = (node, name, phase, options) => {
  name && node.classList.remove(name + '-' + phase)
}

const applyTransition = (node, name, phase, options, then) => {
  if (!node || !node.classList) return
  let hasFinished = false
  const onFinish = () => {
    if (hasFinished) return
    transitBack(node, name, phase)
    transitBack(node, name, phase + '-active')
    hasFinished = true
    then && then()
  }
  transit(node, name, phase, options, onFinish)
  setTimeout(() => {
    transit(node, name, phase + '-active', options, onFinish)
    listenTransitionEnd(node, options.changesLayout, onFinish)
  })
  return onFinish
}

const getScrolledNodes = (node) =>
  node.querySelectorAll('[data-transition-scrolled]')

const updateScroll = (node, clone) =>
  clone && (clone.scrollTop = node.scrollTop)

const fixScroll = (node, clone) => {
  const cloneScrolledNodes = getScrolledNodes(clone)
  Array.prototype.forEach.call(getScrolledNodes(node), (node, index) =>
    updateScroll(node, cloneScrolledNodes[index])
  )
}

const replaceNode = (node) => {
  if (!node.parentNode) return null
  const clone = node.cloneNode(true)
  node.transitionClone = clone
  node.parentNode.insertBefore(clone, node)
  fixScroll(node, clone)
  const prevent = (event) => event.preventDefault()
  clone.addEventListener('mousedown', prevent)
  clone.addEventListener('touchstart', prevent)
  clone.addEventListener('keydown', prevent)
  clone.addEventListener('wheel', prevent)
  node.style.display = 'none'
  return clone
}

class Transition extends React.Component {
  getName = (phase) => {
    const { props } = this
    return !props.noName
      ? styles[invoke(props[phase + 'Name'])(props, this, phase)] ||
          invoke(props.custom)(props, this, phase) ||
          styles[invoke(props.name)(props, this, phase)] ||
          styles['fade']
      : null
  }

  enter = () => {
    const { props } = this
    this.node = ReactDOM.findDOMNode(this)
    const disabled = invoke(props.disabled)(props, this.node)
    if (props.noEnter || disabled || !this.node) return
    applyTransition(
      this.node,
      this.getName('enter'),
      'enter',
      props,
      props.onEnterDone && props.onEnterDone
    )
  }

  runEnter = () => {
    this.node.style.display = ''
    this.attachNodeBack && this.attachNodeBack()
    this.enter()
  }

  leave = () => {
    const { props } = this
    if (props.noLeave || !this.node) return
    this.node = replaceNode(this.node)
    if (!this.node) return
    const onDone = () => {
      this.node.parentNode && this.node.parentNode.removeChild(this.node)
      props.onLeaveDone && props.onLeaveDone()
    }
    applyTransition(this.node, this.getName('leave'), 'leave', props, onDone)
  }

  runLeave = () => {
    const { props } = this
    if (props.noLeave || !this.node) return
    const nodeBeforeLeaving = this.node
    const parentNode = this.node.parentNode
    this.node = replaceNode(this.node)
    this.attachNodeBack = () => parentNode.appendChild(nodeBeforeLeaving)
    this.node &&
      this.node.parentNode &&
      this.node.parentNode.removeChild(this.node)
    this.attachNodeBack = null
    if (!this.node) return
    const onDone = () => {
      this.node &&
        this.node.parentNode &&
        this.node.parentNode.removeChild(this.node)
      this.node = nodeBeforeLeaving
      props.onLeaveDone && props.onLeaveDone()
    }
    applyTransition(this.node, this.getName('leave'), 'leave', props, onDone)
  }

  componentDidMount() {
    this.enter()
  }

  componentWillUnmount() {
    this.leave()
  }

  render() {
    const {
      wrap,
      name,
      custom,
      noEnter,
      noLeave,
      onClone,
      onEnter,
      onLeave,
      children,
      disabled,
      component,
      enterName,
      leaveName,
      onEnterDone,
      onLeaveDone,
      ...props
    } = this.props

    return wrap || component
      ? React.createElement(wrap === true ? 'div' : wrap || component, {
          children,
          ...props,
        })
      : this.props.children
  }
}

const Fade = withProps({ name: 'fade' })(Transition)
const FadeIn = withProps({ noLeave: true })(Fade)

export default assign({ styles, Fade, FadeIn })(Transition)
