import config from '__config__'
import path, { buildQuery } from '__routes__/path'

const v1 = 'v1'
const v2 = 'v2'
const { api, io, apiBase, env } = config.url || {}

export const ioPath = path(io, null, true)
export const apiPath = path(api, null, true)
export const apiV1Path = path(v1, apiPath)
export const apiV2Path = path(v2, apiPath)

export const activationsPath = path('activation', apiV1Path)
export const activationPath = path(':id', activationsPath)
export const activationResendPath = path('resend', activationsPath)

export const authPath = path('auth', apiV1Path)
export const authCheckPath = path('check', authPath)
export const authLoginPath = path('login', authPath)
export const authPasswordResetPath = path('reset-password', authPath)
export const authPasswordUpdatePath = path('update-password', authPath)
export const authRefreshPath = path('refresh', authPath)

export const projectBooksPath = path(':projectId/:kind', apiV1Path)
export const bookPath = path(':bookId', projectBooksPath) // aka book index
export const bookRevisionsPath = path('revision', bookPath) // aka book revision
export const bookRevisionPath = path(':rev', bookRevisionsPath)
export const bookLayerPath = path('layer/:layer', bookRevisionPath)
export const bookUsersPath = path('users', bookLayerPath)
export const bookDeleteBatchPath = path('delete-batch', projectBooksPath)
export const bookExportBatchPath = path('export-batch', projectBooksPath)
export const bookStatusBatchPath = path('status', projectBooksPath)
export const bookAssigneeBatchPath = path('assignee', projectBooksPath)
export const bookSummaryPath = path('thumbnail', bookRevisionPath)
export const bookThumbnailPath = path('thumbnail', bookPath)
export const bookDownloadPath = path('download', bookRevisionPath)
export const bookThumbnailPagePath = path(
  'thumbnail/:page/:dimensions',
  bookRevisionPath
)

export const bookGetExportPath = path('export/:projectId/:key', apiV1Path)
export const bookExportPath = path('export', projectBooksPath)
export const bookStatusPath = path('status', bookPath)
export const bookAssigneePath = path('assignee', bookPath)
export const bookScalesPath = path(
  ':projectId/documents/:bookId/revisions/:rev/scales',
  apiV2Path
)
export const bookScalesUpdatePath = path(
  ':projectId/documents/:bookId/revisions/:rev/scales/:scaleId',
  apiV2Path
)

export const documentsLayersPath = path(
  ':projectId/:kind/get-layers',
  apiV2Path
)
export const documentLayersPath = path(
  ':projectId/:kind/:bookId/revision/:rev/layers',
  apiV2Path
)
export const documentLayerPath = path(':layerId', documentLayersPath)

export const markupLibraryPath = path('markupLibrary', apiV1Path)
export const userMarkupLibraryPath = path('user', markupLibraryPath)
export const userMarkupLibraryIdsPath = path('ids', userMarkupLibraryPath)
export const userMarkupLibraryElementsPath = path(
  'elements',
  userMarkupLibraryPath
)
export const userMarkupLibraryElementPath = path(
  'element/:id',
  userMarkupLibraryPath
)

export const projectMarkupLibraryPath = path(
  'project/:projectId',
  markupLibraryPath
)
export const projectMarkupLibraryIdsPath = path('ids', projectMarkupLibraryPath)
export const projectMarkupLibraryElementsPath = path(
  'elements',
  projectMarkupLibraryPath
)
export const projectMarkupLibraryElementPath = path(
  'element/:id',
  projectMarkupLibraryPath
)

export const bookExportDownloadPath = path(
  ':projectId/:kind/download-single/:exportId',
  apiV1Path
)

export const favoritesPath = path('favorites', apiV1Path)
export const favoritesAddPath = path('add', favoritesPath)
export const favoritesListPath = path('list', favoritesPath)
export const favoritesRemovePath = path('remove', favoritesPath)

export const invitationsPath = path('project', apiV1Path)
export const invitationCreatePath = path(':projectId/invite', invitationsPath)
export const invitationDeletePath = path(':token', invitationCreatePath)
export const invitationAcceptPath = path('accept/:token', invitationsPath)
export const invitationPendingPath = path(
  ':projectId/invites/pending',
  invitationsPath
)

export const licensePath = path('license', apiV1Path)
export const commercialPath = null
export const commercialBreachPath = null
export const allLicensesPath = null
export const trialEligibilityPath = null

export const websocketPath = path('client-messaging', ioPath)

export const bookAnnotationsPath = path(':projectId/annots/:bookId', apiV1Path)
export const pageAnnotationsPath = path(
  ':projectId/:kind/:bookId/revision/:rev/page/:page',
  apiV2Path
) // aka command
export const postAnnotationsBulkPath = () => {
  throw new Error('postAnnotationsBulkPath() not supported in Projects')
}
export const postPageAnnotationsPath = path(
  ':projectId/:kind/:bookId/revision/:rev/page/:page/annotations',
  apiV2Path
) // aka command
// export const postPageAnnotationsPath = pageAnnotationsPath
export const annotationResourcePath = path(
  ':projectId/annots/resources/:key',
  apiV1Path
)
export const annotationResourceUriPath = null
export const pageAnnotationsBatchPath = path(
  'annotations/fetch',
  pageAnnotationsPath
)
export const pageAnnotationsIndexPath = path('index', pageAnnotationsPath)
export const pageAnnotationsHashPath = path('hash', pageAnnotationsPath)
export const pageImagePath = path(
  'thumbnail/:page/:dimensions',
  bookRevisionPath
)
export const pageAnnotationsWSPath = path('FullAnnotations', ioPath)

export const projectsPath = path('project', apiV1Path)
export const projectsV2Path = path('project', apiV2Path)
export const projectCreatePath = path('create', projectsV2Path)
export const projectPath = path(':projectId', projectsPath)
export const projectUsersPath = path('users', projectPath)
export const projectUserReferencesPath = path('user-references', projectPath)
export const projectUserPath = path('user/:userId', projectPath)
export const projectDrawingsFilterPath = path(
  ':projectId/drawing/filter',
  apiV1Path
)
export const projectDocumentsFilterPath = path(
  ':projectId/document/filter',
  apiV1Path
)
export const projectPeekInvitePath = path('peek/:inviteId', projectsV2Path)

export const projectSearchPath = path(':projectId/search', apiV1Path)
export const projectRemoveUserPath = path('remove/user', projectPath)
export const projectOwnerUpdatePath = path('updateOwner', projectPath)
export const projectUpdateUserRolePath = path('updateuser', projectPath)
export const projectLeavePath = path('leave', projectPath)
export const projectUpdateUserPath = path('updateUser', projectPath)
export const projectExportPath = path('export', projectPath)
export const projectLockPath = path('lock', projectPath)
export const projectUnlockPath = path('unlock', projectPath)
export const projectPostLogoPath = path('logo.png', projectPath)
export const projectBackgroundPath = path('background.png', projectPath)
export const projectLogoPath = path('logo/:dimensions', projectPath)
export const projectBackgroundWithDimensionsPath = path(
  'background/:dimensions',
  projectPath
)
export const projectAddWorkspaceUserPath = path('invite', projectPath)

export const organizationsPath = path('organizations', apiV2Path)
export const organizationPath = path('my', organizationsPath)
export const organizationsSamlConfigurationPath = path(
  'configuration/saml',
  organizationsPath
)
export const organizationImagePath = path('profilePicture', organizationsPath)
export const organizationUsersPath = path('users', organizationsPath)
export const organizationUserPath = path(':userId', organizationUsersPath)
export const organizationUserRolePath = path('role', organizationUserPath)
export const organizationProjectsPath = path('projects', organizationsPath)
export const organizationInvitationsPath = path('invites', organizationsPath)
export const organizationsInvitationPath = path(
  'invites/:inviteId',
  organizationsPath
)
export const organizationInvitationPath = path(
  ':inviteId',
  organizationInvitationsPath
)
export const organizationInvitationAcceptPath = path(
  'invites/:inviteId/accept',
  organizationsPath
)
export const organizationSSOInvitationAcceptPath = path(
  'invites/:inviteId/accept/federated',
  organizationsPath
)
export const organizationInvitationAcceptExistingUserPath = path(
  'invites/:inviteId/accept/move',
  organizationsPath
)

export const organizationInvitationPeekPath = path(
  'invites/:inviteId/peek',
  organizationsPath
)
export const organizationManagePlanPath = null
export const organizationAuditLogPath = path('audit-log', organizationsPath)
export const organizationAuditLogMatrixPath = path(
  'audit-log/matrix',
  organizationsPath
)

export const organizationSecuritySettingsPath = path(
  'security-settings',
  organizationsPath
)

export const organizationSecuritySettingsWorkspacePath = path(
  'security-settings/:workspaceId',
  organizationsPath
)

export const organizationWorkspacesPath = path(
  'workspaces',
  organizationsPath
)

export const organizationDomainsPath = path('domains', organizationsPath)
export const organizationAddDomainPath = path(
  '?domain=:domain',
  organizationDomainsPath
)
export const organizationDeleteDomainPath = path(
  '?domain=:domain',
  organizationDomainsPath
)
export const organizationVerifyDomainPath = path(
  'verify?domain=:domain',
  organizationDomainsPath
)

export const organizationApiKeysPath = path('api-keys', organizationsPath)
export const organizationSingleApiKeyPath = path(
  ':keyId',
  organizationApiKeysPath
)

export const organizationWebhooksPath = path('webhooks', organizationsPath)
export const organizationWebhookPath = path(
  ':webhookId',
  organizationWebhooksPath
)
export const organizationWebhooksEventsPath = path(
  'events',
  organizationWebhooksPath
)
export const workspacesPath = path('workspaces', apiV2Path)
export const workspacePath = path(':workspaceId', workspacesPath)
export const workspaceImagePath = path('profilePicture', workspacePath)

export const workspaceUsersPath = path('users', workspacePath)
export const workspaceMissingUsersPath = path('missingUsers', workspacePath)
export const workspaceUserPath = path(':userId', workspaceUsersPath)
export const workspaceUserDefaultProjectAccessLevel = path(
  'default-project-access-level',
  workspaceUserPath
)

export const workspaceUserRolePath = path('role', workspaceUserPath)
export const workspaceProjectsPath = path('projects', workspacePath)
export const workspaceInvitationsPath = path('invites', workspacePath)
export const workspacesInvitationPath = path(
  'invites/:inviteId',
  workspacesPath
)
export const workspaceInvitationPath = path(
  ':inviteId',
  workspaceInvitationsPath
)
export const workspaceInvitationAcceptPath = path(
  'invites/:inviteId/accept',
  workspacesPath
)
export const workspaceTeamImportExampleCSVPath = path(
  'invites/batchImportSampleFile',
  workspacesPath
)
export const workspaceTeamImportCSVPath = path(
  'importUsers',
  workspaceInvitationsPath
)
export const projectWorkspacePath = path('workspace', projectPath)

export const projectUploadPath = path(':projectId/upload', apiV2Path)
export const projectUploadJobPath = path(':jobId', projectUploadPath)
export const projectUploadJobDataPath = path('data', projectUploadJobPath)
export const projectUploadJobLockPath = path('lock', projectUploadJobPath)
export const projectUploadJobCompletePath = path(
  'complete',
  projectUploadJobPath
)
export const projectUploadJobFilePath = path(
  ':fileId/:pageNo/pdf',
  projectUploadJobPath
)

export const projectUploadJobPagePath = path(
  ':fileId/:pageNo',
  projectUploadJobPath
)
export const projectUploadJobPageThumbnailPath = path(
  ':fileId/:pageNo/raster',
  projectUploadJobPath
)
export const projectUploadJobPageInfoPath = path(
  'info',
  projectUploadJobPageThumbnailPath
)
export const projectUploadJobTemplatesPath = path(
  'templates',
  projectUploadJobPath
)
export const projectUploadJobTemplatePath = path(
  ':templateId',
  projectUploadJobTemplatesPath
)
export const projectUploadJobLastTemplateOperationIdPath = path(
  'last-template-operation-id',
  projectUploadJobPath
)
export const projectUploadJobTemplateCancel = path(
  'cancel/:operationId',
  projectUploadJobTemplatePath
)
export const projectUploadJobTemplateApplyPath = path(
  'apply',
  projectUploadJobTemplatePath
)
export const projectUploadJobWordMapPath = path(
  'wordmap',
  projectUploadJobPagePath
)
export const projectUploadJobPublishFilePath = path(
  ':fileId/publish',
  projectUploadJobPath
)
export const projectUploadJobPublishPagePath = path(
  ':fileId/:pageNo/publish',
  projectUploadJobPath
)
export const projectUploadJobDisciplinesPath = path(
  'disciplines',
  projectUploadJobPath
)
export const projectUploadJobDisciplinePath = path(
  ':disciplineId',
  projectUploadJobDisciplinesPath
)
export const projectUploadJobUpdatePagesPath = path(
  'persist',
  projectUploadJobPath
)
export const projectUploadJobValidityPath = path(
  'validity',
  projectUploadJobPath
)

export const usersPath = path('user', apiV1Path)
export const userPath = path(':userId', usersPath)
export const currentUserProjectsPath = path('my', projectsPath)
export const usersCreatePath = path('create', usersPath)
export const usersCreateFederatedPath = path('createFederatedUser', usersPath)
export const userAuthorizationStatusPath = path(
  'authorizationStatus',
  usersPath
)
export const blockUserPath = path('block', userPath)
export const unblockUserPath = path('unblock', userPath)
export const userEmailChangeCallbackPath = path(
  'user/emailchanges/:emailChangeId/accept',
  apiV2Path
)

export const currentUserProfilePath = path('profile', usersPath)
export const currentUserPostAvatarPath = path('profile.png', usersPath)
export const currentUserAvatarPath = path('avatar/:dimensions', usersPath)
export const currentUserContactsPath = path('contacts', usersPath)
export const userAvatarPath = path('avatar/:dimensions', userPath)
export const userIdentitiesPath = path('identities', usersPath)
export const userIdentitiesDeletePath = path(
  ':secondaryUserId',
  userIdentitiesPath
)

export const subscriptionPlansPath = path('plans', apiV1Path)
export const subscriptionPath = path('subscription', apiV1Path)
export const subscriptionCancelPath = path('cancel', subscriptionPath)
export const subscriptionReactivatePath = path('reactivate', subscriptionPath)
export const subscriptionManagePath = path('manage', subscriptionPath)
export const subscriptionCheckoutPath = path('checkout', subscriptionPath)
export const subscriptionSeatCountPath = path('seat-count', subscriptionPath)
export const subscriptionFeatureRequestPath = path(
  'feature-request',
  subscriptionPath
)

export const projectTagsPath = path(':projectId/tags', apiV1Path)
export const projectTagsSuggestPath = path('suggest', projectTagsPath)
export const projectTagsAddPath = path('add', projectTagsPath)
export const projectTagsAddBatchPath = path('addBatch', projectTagsPath)
export const projectTagsRemovePath = path('remove', projectTagsPath)
export const projectTagsRemoveBatchPath = path('removeBatch', projectTagsPath)

export const projectDisciplinesPath = path(':projectId/disciplines', apiV1Path)
export const projectDisciplinePath = path(
  ':disciplineId',
  projectDisciplinesPath
)
export const projectDrawingsDisciplinePath = path(
  ':projectId/drawing/set-discipline',
  apiV1Path
)

export const projectTemplatesPath = path(':projectId/template', apiV1Path)
export const projectTemplatePath = path(':id', projectTemplatesPath)
export const projectTemplateIndexPath = path('index/:id', projectTemplatesPath)

// TODO wrap query in a filter key
export const projectTimelinesPath = path(':projectId/timeline', apiV1Path)
export const projectTimelinePath = path(
  ':wallType/:wallId',
  projectTimelinesPath
)

//Issues
export const issuesPath = path(':projectId/issues', apiV1Path)
export const issuesCreatePath = path('create', issuesPath)
export const issuePath = path(':id', issuesPath)
export const issueCommentsPath = path('comments', issuePath)
export const issueCreateCommentPath = path('comment', issuePath)

// Reports
const reportsPath = path(':projectId/reports', apiV1Path)
export const issuesReportPath = path('issues-flat.csv', reportsPath)
export const booksReportPath = path(':kind', reportsPath)
export const teamReportPath = path('team-members.csv', reportsPath)

export const shareLinksPath = path('shareLinks', apiV1Path)
export const shareLinkPath = path(':id', shareLinksPath)
export const shareLinkTokenPath = path(':token', shareLinksPath)
export const projectShareLinkAcceptPath = path(
  'acceptLink/:token',
  projectsPath
)
export const projectShareLinkPath = path('shareLink', projectPath)
export const projectShareLinksIndexPath = path('shareLinks', projectPath)

export const projectTeamImportCSVPath = path(
  ':projectId/import/team',
  apiV1Path
)
export const projectTeamExampleCSVPath = path(
  'team-example.csv',
  projectTeamImportCSVPath
)

export const stampsPath = path('favorites/stamps', apiV1Path)
export const stampPath = path(':id', stampsPath)

export const procorePath = path('procore', apiV1Path)
export const procoreAuthPath = path('authenticate', procorePath)
export const procoreUserInfoPath = path('userinfo', procorePath)
export const procoreLogoutPath = path('logout', procorePath)
export const procoreCompaniesPath = path('companies', procorePath)
export const procoreProjectsPath = path('projects', procorePath)
export const procoreDrawingsPath = path(
  ':procoreProjectId/drawings',
  procorePath
)
export const procoreProjectUsersPath = path(
  ':procoreProjectId/projectUsers',
  procorePath
)
export const procoreIsAuthenticatedPath = path('isAuthenticated', procorePath)
export const procoreRequestDrawingsExportPath = path(
  ':procoreProjectId/requestDrawingsExport',
  procorePath
)

export const aconexPath = path('aconex', apiV1Path)
export const aconexAuthPath = path('authenticate', aconexPath)
export const aconexUserInfoPath = path('userinfo', aconexPath)
export const aconexLogoutPath = path('logout', aconexPath)
export const aconexCompaniesPath = path('companies', aconexPath)
export const aconexProjectsPath = path('projects', aconexPath)
export const aconexDrawingsPath = path('documents', aconexPath)
export const aconexTransmittalsPath = path('transmittals', aconexPath)
export const aconexProjectSchemaPath = path('projectSchema', aconexPath)
export const aconexIsAuthenticatedPath = path('isAuthenticated', aconexPath)
export const aconexTransmittalDrawingsPath = path(
  'transmittalMetadata',
  aconexPath
)
export const aconexSupersedeStatusPath = path(
  ':aconexProjectId/documents/supersedeStatus',
  aconexProjectsPath
)
export const aconexRequestDrawingsExportPath = path(
  ':bullclipProjectId/requestDrawingsExport',
  aconexPath
)

export const projectIntegrationUploadPath = path(':integration', apiV1Path)
export const uploadIntegrationImportStatusPath = path(
  ':projectId/import/status',
  projectIntegrationUploadPath
)
export const uploadIntegrationImportPath = path(
  ':projectId/requestDrawingsImport',
  projectIntegrationUploadPath
)
export const autodeskPath = path('autodesk', apiV1Path)
export const autodeskThumbnailPath = path('thumbnail', autodeskPath)
export const autodeskAuthPath = path('authenticate', autodeskPath)
export const autodeskUserInfoPath = path('userinfo', autodeskPath)
export const autodeskLogoutPath = path('logout', autodeskPath)
export const autodeskIsAuthenticatedPath = path('isAuthenticated', autodeskPath)
export const autodeskHubsPath = path('hubs', autodeskPath)
export const autodeskProjectsPath = path('projects', autodeskPath)
export const autodeskProjectPath = path('project', autodeskPath)
export const autodeskProjectFolderContentPath = path(
  'projectfoldercontents',
  autodeskPath
)
export const autodeskRequestDrawingsExportPath = path(
  ':bullclipProjectId/requestDrawingsExport',
  autodeskPath
)

export const projectActivityPath = path('activity', projectPath)

export const projectBookStatusesPath = path('bookstatus', projectPath)
export const projectBookStatusesOrderPath = path(
  'index',
  projectBookStatusesPath
)
export const projectBookStatusPath = path(':statusId', projectBookStatusesPath)

export const apiUriWithBase = (uri) =>
  uri.startsWith(apiBase) ? uri : apiBase + uri

export const fromUriWithEnv = (uri, query) =>
  apiBase +
  uri +
  (!~uri.indexOf('?') ? '?' : '&') +
  buildQuery(env ? { ...query, env } : query)

export const signaturesPath = null
export const signaturePath = null
export const clientGroupNotificationsPath = null
export const calibrationPath = null
