import React from 'react'

import Icon from 'Icon'
import Link from './Link'
import { withIconSize } from 'Icon/Icon'

const LinkIcon = ({
  icon,
  size,
  children,
  iconProps,
  iconClassName,
  iconInnerStyle,
  innerIconClassName,
  iconLeftSide = false,
  ...props
}) => (
  <Link {...props}>
    {!iconLeftSide ? (
      <React.Fragment>
        {children}
        {Icon.render(icon, {
          size,
          className: iconClassName,
          innerStyle: iconInnerStyle,
          ...iconProps,
        })}
      </React.Fragment>
    ) : (
      <React.Fragment>
        {Icon.render(icon, {
          size,
          className: iconClassName,
          innerStyle: iconInnerStyle,
          innerClassName: innerIconClassName,
          ...iconProps,
        })}
        {children}
      </React.Fragment>
    )}
  </Link>
)

export default withIconSize(LinkIcon)
