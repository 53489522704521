import hooks from '__hooks__'
import * as path from '__canvas__/path'

// const animateLerpPath = (node, d1, d2, params) => {
//   node.setAttribute('d', d1)
//   setTimeout(() => {
//     const path1 = fromString(d1)
//     const path2 = fromString(d2)
//     animate(
//       (t) => node.setAttribute('d', toString(pathLerp(path1, path2, t))),
//       { duration: 1000, ...params }
//     )
//   }, 2000)
// }

const Splash = {
  timeout: null,
  hide: () => {
    const node = document.querySelector('#app-splash')
    if (!node) return
    if (Splash.timeout) {
      clearTimeout(Splash.timeout)
      Splash.timeout = null
    }
    Object.assign(node.style, {
      opacity: 0,
      transition: 'all 200ms ease-in-out',
      transform: 'translate(-50%, -50%) scale(0.5)',
    })
    Splash.timeout = setTimeout(() => {
      Object.assign(node.style, {
        display: 'none',
        transition: 'none',
      })
      Splash.timeout = null
    }, 500)
  },
  show: () => {
    const node = document.querySelector('#app-splash')
    if (!node) return
    if (Splash.timeout) {
      clearTimeout(Splash.timeout)
      Splash.timeout = null
    }
    Object.assign(node.style, {
      opacity: 0,
      display: 'flex',
      transition: 'all 2000ms ease-in-out',
      transform: 'translate(-50%, -50%) scale(0.5)',
    })
    Splash.timeout = setTimeout(() => {
      Object.assign(node.style, {
        opacity: 1,
        transition: 'all 2000ms ease-in-out',
        transform: 'translate(-50%, -50%)',
      })
      Splash.timeout = null
    })
  },
}

window.path = path

const onMount = () => {
  Splash.show()
  return () => Splash.hide()
}

export default Object.assign(
  hooks(hooks.effect(onMount))(() => null),
  Splash
)
