import './nav'
import './tools'
import './breakpoints'
import Central from '../central'
import { dispatch, getState } from '__store__/store/global'
import { setUI as setUIHandler, fpGetUI, getUI, toggleUI } from './lib'
import {
  canvasSetCurrentToolAction,
  getCurrentTool,
  getTools,
} from '__store__/canvas/tools'
import toolIsFavorite from '__canvas__/tool/isFavorite'

export { getUI, fpGetUI }

const uiSetIntent = (path, value) =>
  getUI(getState(), path) !== value && dispatch('uiSet', path, value)

Central.pair('uiSet', uiSetIntent, setUIHandler)
Central.pair('uiToggle', null, toggleUI)

export const setUI = Central.map('uiSet')
export const setUIAction = Central.action('uiSet')
export const toggleUIAction = Central.action('uiToggle')

export const ACTIVE_TOOLBAR = {
  standard: 'standard',
  favorites: 'favorites',
}
const TOOLBAR_MENU_KEY = 'toolbarMenu'
const INITIAL_PANEL_STATE = { view: null, type: null }
export const TOOLBAR_PANEL_VIEW = {
  PICKER: 'toolPicker',
  EDITOR: 'toolEditor',
  INFO: 'toolInfo',
}
export const TOOL_PICKER_TYPE = {
  ADD: 'new',
  REPLACE: 'replace',
}
export const TOOL_INFO_TYPE = {
  REMINDER: 'toolReminder',
  RESTRICTION: 'toolRestriction',
  PRO: 'toolPro',
}
/**
 * toolbar panel
 * toolbarPanelState {
 *   view: TOOLBAR_PANEL_VIEW,
 *   type: TOOL_PICKER_TYPE | TOOL_INFO_TYPE
 * }
 */
export const getToolbarMenuState = (state) =>
  getUI(state, TOOLBAR_MENU_KEY) || INITIAL_PANEL_STATE

export const closeToolbarMenuAction = () => (dispatch, getState) => {
  const isOpen = getToolbarMenuState(getState()).view
  if (!isOpen) return
  return dispatch(setUIAction(TOOLBAR_MENU_KEY, INITIAL_PANEL_STATE))
}

export const openToolbarMenuAction =
  (view, type = null) =>
  (dispatch) =>
    dispatch(setUIAction(TOOLBAR_MENU_KEY, { view, type }))

export const toggleToolbarMenuAction = (view) => (dispatch, getState) => {
  const currentViewState = getToolbarMenuState(getState()).view
  return dispatch(
    setUIAction(TOOLBAR_MENU_KEY, {
      view: view === currentViewState ? null : view,
      type: null,
    })
  )
}

export const getActiveFavoriteTool = (state) =>
  getUI(state, 'activeFavoriteTool')
export const updateActiveFavoriteToolAction = (toolId) => (dispatch) =>
  dispatch(setUIAction('activeFavoriteTool', toolId))

export const getIsStandardToolbarMinimal = (state) =>
  getUI(state, 'isStandardToolbarMinimal')

export const updateStandardToolbarStatusAction = (status) => (dispatch) =>
  dispatch(setUIAction('isStandardToolbarMinimal', status))

export const getIsToolPanelIsPinned = (state) =>
  getUI(state, 'toolPanelIsPinned')
export const updateToolPanelIsPinnedAction = (status) => (dispatch) =>
  dispatch(setUIAction('toolPanelIsPinned', status))

export const getIsStandardToolbarVisible = (state) => {
  const status = getUI(state, 'showStandardToolbar')
  return status === undefined ? true : status
}

export const updateStandardToolbarVisibleAction =
  (status) => (dispatch, getState) => {
    dispatch(setUIAction('showStandardToolbar', status))
    if (status === false) {
      const currentTool = getCurrentTool(getState())
      if (currentTool !== 'pan') {
        dispatch(canvasSetCurrentToolAction('pan'))
        dispatch(closeToolbarMenuAction())
      }
    }
  }

export const getIsFavoriteToolbarVisible = (state) => {
  const status = getUI(state, 'showFavoriteToolbar')
  if (status === undefined) {
    const tools = Object.values(getTools(state) || {})
    return tools.some((tool) => toolIsFavorite(tool))
  }
  return status
}

export const updateFavoriteToolbarVisibleAction = (status) => (dispatch) => {
  if (status === false) {
    const currentTool = getCurrentTool(getState())
    currentTool !== 'pan' && dispatch(canvasSetCurrentToolAction('pan'))
  }
  dispatch(setUIAction('showFavoriteToolbar', status))
}

export const getGroupSettings = (state) => getUI(state, 'groupSettings') || {}

export const updateGroupSettingAction =
  (groupName, toolId) => (dispatch, getState) => {
    const groupSettings = getGroupSettings(getState())
    return dispatch(
      setUIAction('groupSettings', {
        ...groupSettings,
        [groupName]: toolId,
      })
    )
  }

export const updateGroupSettingsAction = (settings) => (dispatch) =>
  dispatch(setUIAction('groupSettings', settings))

export const getActiveToolGroup = (state) => getUI(state, 'activeToolGroup')
export const updateActiveToolGroupAction =
  (groupName) => (dispatch, getState) => {
    const activeToolGroup = getActiveToolGroup(getState())
    if (activeToolGroup === groupName) return
    return dispatch(setUIAction('activeToolGroup', groupName))
  }

export const getCanvasPinnedPanel = (state) => getUI(state, 'canvasPinnedPanel')
export const getCanvasOpenedPanel = (state) => getUI(state, 'canvasOpenedPanel')
export const setCanvasPinnedPanelAction = (panel) => (dispatch) =>
  dispatch(setUIAction('canvasPinnedPanel', panel))
export const setCanvasOpenedPanelAction = (panel) => (dispatch) =>
  dispatch(setUIAction('canvasOpenedPanel', panel))

export const getIsCanvasHeaderCollapsed = (state) =>
  getUI(state, 'isCanvasHeaderCollapsed')
export const toggleIsCanvasHeaderCollapsedAction = () => (dispatch) =>
  dispatch(toggleUIAction('isCanvasHeaderCollapsed'))

export const getIsUtilityBarOn = (state) => getUI(state, 'showUtilityBar')
export const toggleUtilityBarAction = () => (dispatch) =>
  dispatch(toggleUIAction('showUtilityBar'))

export const getIsUtilityBarCollapsed = (state) =>
  getUI(state, 'isUtilityBarCollapsed')
export const toggleIsUtilityBarCollapsedAction = () => (dispatch) =>
  dispatch(toggleUIAction('isUtilityBarCollapsed'))

export const getIsStandardToolbarExpanded = (state) =>
  getUI(state, 'isStandardToolbarExpanded') &&
  !getIsStandardToolbarMinimal(state)

export const updateStandardToolbarExpandedStatusAction =
  (status) => (dispatch) =>
    dispatch(setUIAction('isStandardToolbarExpanded', status))

// large | small | hidden
export const getCanvasToggleState = (state) =>
  getUI(state, 'canvasToggleState') || 'small'
export const updateCanvasToggleStateAction = (state) => (dispatch) =>
  dispatch(setUIAction('canvasToggleState', state))

export const getIsToolbarSettingShown = (state) =>
  getUI(state, 'toolbarSettingShown')
export const updateIsToolbarSettingShownAction = (state) => (dispatch) =>
  dispatch(setUIAction('toolbarSettingShown', state))

// tool that temporarily replace by shortcut (e.g. space bar)
export const getTemporaryKeyTool = (state) => getUI(state, 'temporaryKeyTool')
export const setTemporaryKeyToolAction = (state) => (dispatch) =>
  dispatch(setUIAction('temporaryKeyTool', state))
