import tool from './'
import * as R from 'ramda'
import annotationTool from './annotation'
import identityScale from '../scale/identity'
import palette from '__canvas__/color/palette'
import defaultStamp from '__canvas__/stamp/default'
import { LINK_STYLES } from '__canvas__/geometry/props'
import { TOOL_GROUP } from './configs'
import { COLOR_SET } from 'Canvas/Tools/PropertyPicker/colorConfigs'

export const CALLOUT_THICKNESS = 16

export const getColorOptions = (colors) => R.take(3, colors)

export const TOOL_COLOR_SETTINGS = {
  strokeSet: [1, 2, 5],
  // Default color set when expanded
  colorSet: COLOR_SET.defaultStroke,
  // Initial color set when in toolbar
  strokes: getColorOptions(COLOR_SET.defaultStroke),
  fills: getColorOptions(COLOR_SET.defaultFill),
}

export const list = R.filter(Boolean, [
  tool('select', {
    noFavorite: true,
    canInReadOnly: true,
    segmentName: 'select',
    group: TOOL_GROUP.select,
    visibleName: 'Select',
  }),

  tool('pan', {
    noFavorite: true,
    canInReadOnly: true,
    segmentName: 'pan',
  }),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('textSelect', {
      fillOpacity: 1,
      strokeWidth: 0,
      strokeOpacity: 0,
      noFavorite: true,
      fill: palette.blue2,
      canInReadOnly: true,
      blendMode: 'multiply',
      type: 'textHighlight',
      segmentName: 'textSelect',
      isForAnnotation: false,
      stroke: palette.greenFive,
      visibleName: 'Text Select',
      group: TOOL_GROUP.select,
    }),

  import.meta.env.VITE_PROJECT === 'projects' &&
    tool('pinIssue', {
      noFavorite: true,
      canInReadOnly: true,
      segmentName: 'pin',
    }),

  annotationTool(
    'ink',
    import.meta.env.VITE_PROJECT === 'pdf'
      ? {
          fill: palette.greyFive,
          fillOpacity: 0,
          segmentName: 'pen',
          strokeOpacity: 1,
          visibleName: 'Pen',
          stroke: COLOR_SET.ink[0],
          group: TOOL_GROUP.pen,
          colorSet: COLOR_SET.ink,
          strokeSet: [1, 5, 10],
        }
      : {
          fillOpacity: 1,
          segmentName: 'pen',
          strokeOpacity: 0,
          visibleName: 'Pen',
          fill: COLOR_SET.ink[0],
          stroke: COLOR_SET.ink[0],
          group: TOOL_GROUP.pen,
          colorSet: COLOR_SET.ink,
          strokeSet: [1, 5, 10],
          fills: getColorOptions(COLOR_SET.ink),
        }
  ),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('highlighter', {
      type: 'ink',
      strokeWidth: 5,
      blendMode: 'multiply',
      visibleName: 'Highlighter',
      shortVisibleName: 'Highlighter',
      fillOpacity: 0,
      strokeOpacity: 0.75,
      stroke: COLOR_SET.highlighter[0],
      group: TOOL_GROUP.pen,
      strokes: getColorOptions(COLOR_SET.highlighter),
      fills: getColorOptions(COLOR_SET.highlighter),
      colorSet: COLOR_SET.highlighter,
      strokeSet: [5, 10, 15],
    }),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('signature', {
      fillOpacity: 0,
      strokeOpacity: 1,
      strokeWidth: null,
      fill: palette.greyFive,
      segmentName: 'signature',
      visibleName: 'Signature',
      stroke: COLOR_SET.signature[0],
      group: TOOL_GROUP.insert,
      strokes: getColorOptions(COLOR_SET.signature),
      colorSet: COLOR_SET.signature,
    }),

  tool('eraser', {
    segmentName: 'eraser',
    canInReadOnly: false,
    group: TOOL_GROUP.pen,
    visibleName: 'Eraser',
  }),

  tool('eraserSelect', {
    segmentName: 'eraser',
    canInReadOnly: false,
    group: TOOL_GROUP.select,
    visibleName: 'Eraser',
    noFavorite: true,
  }),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('textStrike', {
      strokeWidth: 1,
      stroke: COLOR_SET.textReview[0],
      visibleName: 'Strike-through',
      shortVisibleName: 'Strike-through',
      group: TOOL_GROUP.text,
      strokes: getColorOptions(COLOR_SET.textReview),
      colorSet: COLOR_SET.textReview,
    }),
  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('textSquiggly', {
      strokeWidth: 1,
      stroke: COLOR_SET.textReview[0],
      visibleName: 'Squiggle',
      shortVisibleName: 'Squiggle',
      group: TOOL_GROUP.text,
      strokes: getColorOptions(COLOR_SET.textReview),
      colorSet: COLOR_SET.textReview,
    }),
  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('textHighlight', {
      strokeWidth: 0,
      fillOpacity: 1,
      strokeOpacity: 0,
      blendMode: 'multiply',
      fill: COLOR_SET.highlighter[0],
      visibleName: 'Text Highlight',
      shortVisibleName: 'Text Highlight',
      group: TOOL_GROUP.text,
      fills: getColorOptions(COLOR_SET.highlighter),
      colorSet: COLOR_SET.highlighter,
    }),
  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('textUnderline', {
      strokeWidth: 1,
      stroke: COLOR_SET.textReview[0],
      visibleName: 'Underline',
      shortVisibleName: 'Underline',
      group: TOOL_GROUP.text,
      strokes: getColorOptions(COLOR_SET.textReview),
      colorSet: COLOR_SET.textReview,
    }),

  annotationTool('line', {
    segmentName: 'line',
    stroke: COLOR_SET.defaultStroke[0],
    group: TOOL_GROUP.shape,
  }),

  annotationTool('arrow', {
    type: 'line',
    segmentName: 'arrow',
    endStyle: 'openArrow',
    stroke: COLOR_SET.defaultStroke[0],
    group: TOOL_GROUP.shape,
  }),

  annotationTool('rectangle', {
    visibleName: 'Rectangle',
    shortVisibleName: 'Rectangle',
    stroke: COLOR_SET.defaultStroke[0],
    fill: COLOR_SET.defaultFill[0],
    group: TOOL_GROUP.shape,
  }),

  annotationTool('ellipse', {
    stroke: COLOR_SET.defaultStroke[0],
    fill: COLOR_SET.defaultFill[0],
    group: TOOL_GROUP.shape,
  }),

  annotationTool('polygon', {
    stroke: COLOR_SET.defaultStroke[0],
    fill: COLOR_SET.defaultFill[0],
    group: TOOL_GROUP.shape,
  }),

  annotationTool('polyLine', {
    segmentName: 'Polyline',
    visibleName: 'Polyline',
    stroke: COLOR_SET.defaultStroke[0],
    group: TOOL_GROUP.shape,
  }),

  annotationTool('cloud', {
    stroke: COLOR_SET.defaultStroke[0],
    fill: COLOR_SET.defaultFill[0],
    group: TOOL_GROUP.shape,
  }),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('note', {
      fill: palette.greyFour,
      fillOpacity: 1,
      strokeWidth: 0,
      strokeOpacity: 0,
      displayOnly: true,
      group: TOOL_GROUP.insert,
    }),

  annotationTool('text', {
    fill: '#ffffff',
    strokeWidth: 16,
    segmentName: 'textbox',
    stroke: COLOR_SET.text[0],
    group:
      import.meta.env.VITE_PROJECT === 'pdf'
        ? TOOL_GROUP.text
        : TOOL_GROUP.insert,
    strokes: getColorOptions(COLOR_SET.text),
    colorSet: COLOR_SET.text,
  }),

  annotationTool('callout', {
    fill: import.meta.env.VITE_PROJECT === 'pdf' ? 'none' : palette.white,
    startStyle: 'openArrow',
    strokeWidth: CALLOUT_THICKNESS,
    stroke: COLOR_SET.defaultStroke[0],
    proTool: import.meta.env.VITE_PROJECT === 'pdf',

    group:
      import.meta.env.VITE_PROJECT === 'pdf'
        ? TOOL_GROUP.text
        : TOOL_GROUP.insert,
  }),

  import.meta.env.VITE_PROJECT === 'projects' &&
    annotationTool('stamp', {
      stampId: defaultStamp,
      group: TOOL_GROUP.insert,
    }),

  annotationTool('image', {
    fillOpacity: 0,
    strokeOpacity: 0,
    fill: palette.greyOne,
    stroke: palette.greyOne,
    group: TOOL_GROUP.insert,
  }),

  annotationTool('calibration', {
    type: 'line',
    startStyle: 'butt',
    endStyle: 'butt',
    strokeWidth: 1,
    proTool: import.meta.env.VITE_PROJECT === 'pdf',
    segmentName: 'calibrate',
    isForAnnotation: false,
    stroke:
      import.meta.env.VITE_PROJECT === 'pdf' ? palette.black : palette.greyOne,
    visibleName: 'Calibrate',
    group:
      import.meta.env.VITE_PROJECT === 'pdf' ? TOOL_GROUP.measure : undefined,
  }),

  annotationTool('length', {
    visibleName:
      import.meta.env.VITE_PROJECT === 'pdf' ? 'Measure Length' : 'length',
    segmentName: 'linemeasurement',
    stroke: COLOR_SET.measure[0],
    scaleId:
      import.meta.env.VITE_PROJECT === 'projects' ? identityScale.id : null,
    proTool: import.meta.env.VITE_PROJECT === 'pdf',
    group: TOOL_GROUP.measure,
    strokes: getColorOptions(COLOR_SET.measure),
    colorSet: COLOR_SET.measure,
  }),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('polyLength', {
      segmentName: 'polymeasurement',
      visibleName:
        import.meta.env.VITE_PROJECT === 'pdf' ? 'Poly Length' : 'polylength',
      shortVisibleName:
        import.meta.env.VITE_PROJECT === 'pdf' ? 'Poly Length' : 'polylength',
      stroke: COLOR_SET.measure[0],
      proTool: import.meta.env.VITE_PROJECT === 'pdf',
      group: TOOL_GROUP.measure,
      strokes: getColorOptions(COLOR_SET.measure),
      colorSet: COLOR_SET.measure,
    }),

  annotationTool('area', {
    visibleName:
      import.meta.env.VITE_PROJECT === 'pdf' ? 'Polygon Area' : 'poly area',
    shortVisibleName:
      import.meta.env.VITE_PROJECT === 'pdf' ? 'polygon Area' : 'poly area',

    segmentName: 'polygonarea',
    stroke: COLOR_SET.measure[0],
    scaleId:
      import.meta.env.VITE_PROJECT === 'projects' ? identityScale.id : null,
    proTool: import.meta.env.VITE_PROJECT === 'pdf',
    fill: COLOR_SET.defaultStroke[0],
    group: TOOL_GROUP.measure,
    strokes: getColorOptions(COLOR_SET.measure),
    fills: getColorOptions(COLOR_SET.measure),
    colorSet: COLOR_SET.measure,
  }),

  annotationTool('rectangleArea', {
    segmentName: 'rectanglearea',
    visibleName:
      import.meta.env.VITE_PROJECT === 'pdf' ? 'Rectangular Area' : 'rect area',
    shortVisibleName:
      import.meta.env.VITE_PROJECT === 'pdf' ? 'Rectangular Area' : 'rect area',
    stroke: COLOR_SET.measure[0],
    scaleId:
      import.meta.env.VITE_PROJECT === 'projects' ? identityScale.id : null,
    proTool: import.meta.env.VITE_PROJECT === 'pdf',

    fill: COLOR_SET.defaultStroke[0],
    group: TOOL_GROUP.measure,
    strokes: getColorOptions(COLOR_SET.measure),
    fills: getColorOptions(COLOR_SET.measure),
    colorSet: COLOR_SET.measure,
  }),

  annotationTool('markupLibrary', {
    visibleName: 'Markup Library',
    strokeWidth: 0,
    segmentName: 'markuplibrary',

    displayOnly: import.meta.env.VITE_PROJECT === 'pdf',
    proTool: import.meta.env.VITE_PROJECT === 'pdf',
  }),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    tool('bookmark', {
      group: TOOL_GROUP.insert,
    }),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('textCleaner', {
      visibleName: 'Text Cleaner',
      stroke: palette.carmine2,
      displayOnly: true,
      group: TOOL_GROUP.text,
    }),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('imgFromCamera', {
      visibleName: 'Image from Camera',
      displayOnly: true,
      group: TOOL_GROUP.insert,
    }),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('audio', {
      displayOnly: true,
      proTool: true,
      group: TOOL_GROUP.insert,
    }),

  annotationTool('lassoSelect', {
    visibleName: 'Lasso Select',
    canInReadOnly: true,
    group: TOOL_GROUP.select,
  }),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('laserPointer', {
      visibleName: 'Laser Pointer',
      displayOnly: true,
      group: TOOL_GROUP.select,
      stroke: palette.carmine2,
    }),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('snapshot', {
      displayOnly: true,
      proTool: true,
      group: TOOL_GROUP.select,
    }),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('redaction', {
      displayOnly: true,
      proTool: true,
      group: TOOL_GROUP.text,
    }),

  import.meta.env.VITE_PROJECT === 'pdf' &&
    annotationTool('hyperlink', {
      visibleName: 'hyperlink',
      stroke: COLOR_SET.hyperlink[0],
      strokeWidth: 3,
      strokeOpacity: 1,
      strokeStyle: 0,
      linkStyle: LINK_STYLES.stroke,
      proTool: true,
      group: TOOL_GROUP.insert,
      strokes: getColorOptions(COLOR_SET.hyperlink),
      fills: getColorOptions(COLOR_SET.hyperlink),
    }),
])

export const toolAll = list

export default R.indexBy(R.prop('name'), list)
