import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'react-recompose'

import Icon from 'Icon'
import Button from './Button'
import ButtonIcon from './Icon'
import { getBreakpoint } from '__store__/ui/breakpoints'
import withClassName from '__lib__/react/withClassName'

const CollapseIcon = ({
  icon,
  label,
  children,
  dispatch,
  isCollapsed,
  iconClassName,
  expandedChildren,
  expandedIconClassName,
  collapsedIconClassName,
  buttonComponent: ButtonComponent = Button,
  buttonIconComponent: ButtonIconComponent = ButtonIcon,
  ...props
}) =>
  isCollapsed ? (
    <ButtonIconComponent
      icon={icon}
      children={children}
      iconClassName={collapsedIconClassName}
      {...props}
    />
  ) : (
    <ButtonComponent {...props}>
      {Icon.render(icon, { className: expandedIconClassName })} {label}
      {children}
    </ButtonComponent>
  )

const mapState = (state) => ({
  isCollapsed: getBreakpoint(state) === 0,
})

const collapsedIconClassName = ({ iconClassName }) => iconClassName
const expandedIconClassName = ({ iconClassName }) => [iconClassName, 'mr2']

export default compose(
  connect(mapState),
  withClassName({
    expandedIconClassName,
    collapsedIconClassName,
  })
)(CollapseIcon)
