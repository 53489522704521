import React from 'react'
import * as R from 'ramda'

import invoke from '__lib__/fn/invoke'
import arrayFrom from '__lib__/array/from'
import objectWrap from '__lib__/object/wrap'
import arrayFindIn from '__lib__/array/findIn'
import { set, update } from '__lib__/immutable'
import objectWithout from '__lib__/object/without'
import objectProperty from '__lib__/object/fpProperty'
import objectEqualsKeys from '__lib__/object/equalsKeys'
import { objectWithValues } from '__lib__/object/withValues'
import arrayWithout from '__lib__/array/without'

const getValues = objectWithValues(objectProperty('value'))
const makeFields = objectWithValues(objectWrap('value'))

const getFieldError = (validations, value) =>
  arrayFindIn(arrayFrom(validations), (validate) => validate && validate(value))

const someField =
  (map) =>
  ({ fields }) =>
    Object.keys(fields).some((name) => map(fields[name], name, fields))

const isAnyFieldDirty = someField(R.prop('dirty'))
const hasAnyFieldError = someField(R.prop('error'))

const getFormWithComputedState = (form) => ({
  ...form,
  isDirty: isAnyFieldDirty(form),
  isValid: form.isSelfValid && !hasAnyFieldError(form),
})

const getFormWithField = (form, name, props) =>
  update(form, ['fields', name], props)

const getFieldWithValueFactory =
  ({ validate }, value, setDirty) =>
  (field) => ({
    ...field,
    customError: null,
    value: value == null ? '' : value,
    dirty: setDirty ? true : field && field.dirty,
    error: getFieldError(validate, value == null ? '' : value),
  })

const getFormWithFieldValue = (form, field, value, setDirty) =>
  getFormWithField(
    form,
    field.name,
    getFieldWithValueFactory(field, value, setDirty)
  )

const getInitialState = (initialState, props) =>
  makeFields(invoke(initialState)(props) || {})

const getUpdatedFields = (fields, fieldNames, fn) =>
  (fieldNames || Object.keys(fields)).reduce(
    (fields, name) => ({ ...fields, [name]: fn(fields[name]) }),
    fields
  )

const getPristineFields = (fields, fieldNames) =>
  getUpdatedFields(fields, fieldNames, (field) => ({
    ...field,
    dirty: false,
  }))

const getCleanedFieldsError = (fields, fieldNames) =>
  getUpdatedFields(fields, fieldNames, (field) => ({
    ...field,
    customError: null,
  }))

const DEFAULT_FIELD_PROPS = { value: '' }

export default (formName, initialState, { isValid, rerenderOnChange } = {}) =>
  (Component) =>
    class WithForm extends React.Component {
      form = { isSelfValid: true }
      constructor(props) {
        super(props)
        this.fields = {}
        this.fieldsChangeListeners = {}

        const getFieldValues = () => getValues(this.form.fields)
        const stopSubmitInProgress = () =>
          this.form.update({
            ...this.form,
            submitInProgress: false,
          })

        const catchSubmitInProgress = (error) => {
          stopSubmitInProgress()
          throw error
        }

        const submit = (submit, submitOnInvalid) => {
          if (this.form.submitInProgress) return
          if (!this.form.isValid && !submitOnInvalid) {
            this.form.update({ ...this.form, isSubmitted: true })
            return
          }
          const promise = submit(
            getFieldValues(this.form.fields),
            this.form,
            this.fields
          )
          if (!promise || !promise.then) return promise
          this.form.update({
            ...this.form,
            isSubmitted: true,
            submitInProgress: true,
            fields: getCleanedFieldsError(this.form.fields),
          })
          return promise.then(stopSubmitInProgress).catch(catchSubmitInProgress)
        }

        const triggerSubmit = () => this.formInstance.props.onSubmit()

        const update = this.setForm

        const setFormInstance = (formInstance) =>
          (this.formInstance = formInstance)

        const getField = (name) =>
          this.fields[name] ? this.fields[name].props : { name }

        const getFieldProps = (name) =>
          this.form.fields[name] || DEFAULT_FIELD_PROPS

        const setFieldsError = (fieldsError) =>
          update({
            ...this.form,
            fields: Object.keys(fieldsError).reduce(
              (fields, name) => ({
                ...fields,
                [name]: { ...fields[name], customError: fieldsError[name] },
              }),
              this.form.fields
            ),
          })

        const setFieldValue = (name, value, setDirty = true) =>
          update(
            getFormWithFieldValue(this.form, getField(name), value, setDirty)
          )

        const setFieldsValue = (values) =>
          update(
            Object.keys(values).reduce(
              (form, name) =>
                getFormWithFieldValue(
                  form,
                  getField(name),
                  invoke(values[name])(name, this.form)
                ),
              this.form
            )
          )

        const setFieldsPristine = (fields = Object.keys(this.form.fields)) =>
          update({
            ...this.form,
            fields: getPristineFields(this.form.fields, fields),
          })

        const onFieldChange = (name, value) => setFieldValue(name, value)

        const onFieldBlur = (name) =>
          update(set(this.form, ['fields', name, 'touched'], true))

        const mountField = (instance) => {
          const { name, defaultValue } = instance.props
          this.fields[name] = instance
          return setFieldValue(
            name,
            getFieldProps(name).value || defaultValue,
            false
          )
        }

        const getFieldValue = (name) =>
          this.form.fields[name] && this.form.fields[name].value

        const unmountField = (instance) => {
          const { name, persist } = instance.props
          delete this.fields[name]
          persist ||
            update({
              ...this.form,
              fields: objectWithout(this.form.fields, [name]),
            })
        }

        const getUpdatedFields = (fields) =>
          Object.keys(fields).reduce((form, name) => {
            const { value, ...props } = fields[name]
            return getFormWithField(form, name, (field) => ({
              ...getFieldWithValueFactory(getField(name), value)(field),
              ...props,
            }))
          }, this.form)

        const resetProps = { dirty: false, touched: false }

        const getResetFields = () => {
          const state = invoke(initialState)(this.props) || {}
          return Object.keys(this.form.fields).reduce(
            (fields, fieldName) => ({
              ...fields,
              [fieldName]: {
                ...resetProps,
                value: state[fieldName] || '',
              },
            }),
            {}
          )
        }

        const reset = () =>
          update({ ...getUpdatedFields(getResetFields()), isSubmitted: false })

        const addListenerForFieldChange = (fieldName, listenerInstance) => {
          this.fieldsChangeListeners[fieldName] = this.fieldsChangeListeners[
            fieldName
          ]
            ? [...this.fieldsChangeListeners[fieldName], listenerInstance]
            : [listenerInstance]
        }

        const removeListenerForFieldChange = (fieldName, listenerInstance) => {
          this.fieldsChangeListeners[fieldName] &&
            (this.fieldsChangeListeners[fieldName] = arrayWithout(
              this.fieldsChangeListeners[fieldName],
              listenerInstance
            ))
        }

        const getFieldInstance = (name) => this.fields[name]

        this.state = {
          form: this.setForm(
            {
              reset,
              submit,
              update,
              getField: getFieldInstance,
              mountField,
              onFieldBlur,
              unmountField,
              triggerSubmit,
              onFieldChange,
              getFieldValue,
              getFieldProps,
              setFieldValue,
              setFieldsValue,
              setFieldsError,
              name: formName,
              getFieldValues,
              isDirty: false,
              setFormInstance,
              setFieldsPristine,
              submitInProgress: false,
              addListenerForFieldChange,
              removeListenerForFieldChange,
              isSelfValid: this.isSelfValid(),
              fields: getInitialState(initialState, this.props),
            },
            true
          ),
        }
      }

      // NOTE do not rerender the entire form every time,
      // the fields get updated only if they changed
      shouldUpdateForm = (form) =>
        rerenderOnChange ||
        !objectEqualsKeys(
          this.form,
          form,
          'isValid',
          'isDirty',
          'submitInProgress',
          'isSubmitted'
        )

      forceUpdateField = (fieldName, form) => {
        this.fields[fieldName].forceUpdate()
        this.fieldsChangeListeners[fieldName] &&
          this.fieldsChangeListeners[fieldName].forEach((listener) =>
            listener(form)
          )
      }

      shouldUpdateField = ({ fields }, { fields: previousFields }, name) =>
        previousFields[name] !== fields[name]

      updateFieldFy = (form, previousForm) => (name) =>
        this.shouldUpdateField(form, previousForm, name) &&
        this.forceUpdateField(name, form)

      // NOTE
      // - because we can't set things into the state until the component is mounted
      // we store the form into the 'this' and then make sure it's up to date when mounted
      // - the this.form has to be set before rendering so the fields get their
      // up to date props since the getter depends on this.form
      setForm = (baseForm, noUpdate) => {
        const previousForm = this.form
        const form = getFormWithComputedState(baseForm)
        const shouldUpdateForm = !this.didUnmount && this.shouldUpdateForm(form)
        this.form = form
        shouldUpdateForm && !noUpdate && this.setState({ form })
        Object.keys(this.fields).forEach(this.updateFieldFy(form, previousForm))
        return form
      }

      isSelfValid = () =>
        isValid ? isValid(this.props) : this.form ? this.form.isSelfValid : true

      componentWillUnmount() {
        this.didUnmount = true
      }

      componentDidUpdate() {
        if (!isValid) return
        const isSelfValid = isValid(this.props)
        this.form.isSelfValid !== isSelfValid &&
          this.form.update({ ...this.form, isSelfValid })
      }

      render() {
        const { form } = this
        return <Component {...{ ...this.props, [formName]: form }} />
      }
    }
