import React, { createFactory } from 'react'

import invoke from '__lib__/fn/invoke'

export default (name, setName, initialState, { withInstance } = {}) =>
  (Component) => {
    const factory = createFactory(Component)
    class WithState extends React.Component {
      constructor(props) {
        super(props)
        this.state = { [name]: invoke(initialState)(this.props) }
        this.set = (value) => {
          if (this.didUmount || this.state[name] === value) return false
          this.setState({ [name]: value })
          return true
        }

        this.set.reset = () => this.set(invoke(initialState)(this.props))
      }

      componentWillUnmount() {
        this.didUmount = true
      }
    }
    WithState.prototype.render = withInstance
      ? function () {
          return factory({
            ...this.props,
            ...this.state,
            [setName]: this.set,
            [withInstance]: this,
          })
        }
      : function () {
          return factory({
            ...this.props,
            ...this.state,
            [setName]: this.set,
          })
        }
    return WithState
  }
