import * as R from 'ramda'
import React from 'react'
import hooks from '__hooks__'
import withDialog from './withDialog'

const DialogContext = React.createContext({})

const WithDialogDialogContext = withDialog('value')(DialogContext.Provider)

export const hookDialogContext = (name) =>
  R.when(R.complement(R.prop(name)), hooks.context(name, DialogContext))

const Provider = ({ dialog, ...props }) =>
  dialog ? (
    <DialogContext.Provider value={dialog} {...props} />
  ) : (
    <WithDialogDialogContext {...props} />
  )

export default Object.assign(Provider, { hook: hookDialogContext })
