const SvgComponent = ({ fill, ...props }) => (
  <svg
    width="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.827 0.00244141H0V22.831H22.827V0.00244141Z"
      fill={fill || '#F25022'}
    />
    <path d="M47.999 0H25.1719V22.8286H47.999V0Z" fill={fill || '#7FBA00'} />
    <path d="M22.827 25.1714H0V48H22.827V25.1714Z" fill={fill || '#00A4EF'} />
    <path
      d="M47.999 25.1714H25.1719V48H47.999V25.1714Z"
      fill={fill || '#FFB900'}
    />
  </svg>
)

export default SvgComponent
