import React from 'react'
import { createFactory } from 'react'

export default (name, { anchor } = {}) =>
  (Component) => {
    const factory = createFactory(Component)
    return class WithAnchor extends React.Component {
      constructor(props) {
        super(props)
        let anchorInstance
        const attach = (anchor) => (anchorInstance = anchor)
        const detach = () => attach(null)
        const get = () => anchorInstance
        this.anchor = { attach, detach, get }
      }

      render() {
        return factory({ ...this.props, [name]: this.anchor })
      }
    }
  }
