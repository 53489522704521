import hooks from '__hooks__'

const StopPropagation = ({
  action,
  stopSynthetic,
  stopPropagation,
  component: Component = 'div',
  ...props
}) => <Component {...props} />

const eventStopPropagation = (e) => e.stopPropagation()

const getEvents = (events) =>
  typeof events === 'string' ? events.split(' ') : events

const onMount = ({
  ref,
  stopPropagation: events,
  action = eventStopPropagation,
}) => {
  const node = ref.current
  const e = getEvents(events)
  e.forEach((event) => node.addEventListener(event, action))
  return () => e.forEach((event) => node.removeEventListener(event, action))
}

// const syntheticEvents = ({ stopSynthetic, ...props }) => ({
//   ...arrayToObject(stopSynthetic, null, () => eventStopPropagation),
//   ...props,
// })

export default hooks(
  hooks.bubbleRef('ref'),
  hooks.effect(onMount)
  // R.when(R.prop('stopSynthetic'), syntheticEvents)
)(StopPropagation)
