import React from 'react'
import { View, Breakpoint } from 'react-with-style'

import T from '../T'
import hooks from '__hooks__'

const TView = ({
  style,
  styleLeft,
  styleRight,
  className,
  component,
  viewProps,
  ...props
}) => (
  <View.Text
    component={component}
    {...viewProps}
    {...{ style, styleLeft, styleRight, className }}
  >
    <T {...props} />
  </View.Text>
)

const withQuery = (query, component) =>
  hooks(hooks.default('component', component), hooks.default('query', query))

const Fragment = ({ children }) => children || null

const breakpointComponent = (query) =>
  Object.assign(withQuery(query, View)(Breakpoint), {
    Fragment: withQuery(query, Fragment)(Breakpoint),
  })

const Generic = ({ component: Component, innerComponent, ...props }) => (
  <Component
    {...(innerComponent && { component: innerComponent })}
    {...props}
  />
)

export default Object.assign(View, {
  T: Object.assign(TView, { Fragment: T }),
  Breakpoint,
  Fragment,
  Mobile: breakpointComponent('mobile'),
  Phone: breakpointComponent('phone'),
  Tablet: breakpointComponent('tablet'),
  Laptop: breakpointComponent('laptop'),
  Desktop: breakpointComponent('desktop'),
  Monitor: breakpointComponent('monitor'),
  Generic,
})
