import config from '__config__'
import { getState } from '__store__/store/global'
import { isLoggedIn } from '__store__/auth/state'

const init = () =>
  setTimeout(() => {
    if (!isLoggedIn(getState())) return

    if (import.meta.env.VITE_PROJECT !== 'pdf' || !config.veroApiKey) return
    window._veroq = window._veroq || []

    window._veroq.push(['init', { api_key: config.veroApiKey }])
    // import.meta.env.DEV && console.info('Vero initialised')
  }, 1000)

export default init
