import { mediaQueries } from './current'
import { breakpointSet } from '__store__/ui/breakpoints'

// Only update the one that matches now,
// since this also gets fired when an mq stops matching.
const update = ({ mq, value }) => mq.matches && breakpointSet(value)

const breakpoints = {
  initialize() {
    if (this.initialized) return
    this.initialized = true
    mediaQueries().forEach((item) => item.mq.addListener(() => update(item)))
  },
}

export default breakpoints
