import each from './each'

const withValues = (object, fn) => {
  const result = {}
  each(object, (value, key) => {
    result[key] = fn(value, key, object)
  })
  return result
}

export const objectWithTruthyValues = (fn) => (object) => {
  const result = {}
  each(object, (value, key) => {
    const newValue = fn(value, key, object)
    newValue && (result[key] = newValue)
  })
  return result
}

export const objectWithValues = (fn) => (object) => withValues(object, fn)

export default withValues
