import seqInit from './seq/init'
import gtmInit from './gtm/init'
import veroInit from './vero/init'
import sentryInit from './sentry/init'
import amplitudeInit from './amplitude/init'
import segmentAutolink from './segment/autolink'
import segmentStartTracking from './segment/startTracking'

const init = () => {
  seqInit()
  gtmInit()
  veroInit()
  sentryInit()
  amplitudeInit()
  segmentAutolink()
  segmentStartTracking()
}

export default init
