import Layout, { Header } from './Layout'
import Clients from './Clients'

const Landing = ({ data }) => (
  <Layout>
    <Header
      title="Drawboard Log-in"
      description={data.product === ''
        ? "Please choose where you are logging into Drawboard PDF from"
        : "Please choose where you are logging into Drawboard Projects from"}
    />
    <Clients data={data} style="py-6 max-w-10" />
  </Layout>
)

export default Landing
