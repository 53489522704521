import View from '__components__/View'
import assign from '__lib__/react/assign'

import Web from './web'
import Apple from './ios'
import Windows from './windows'
import Android from './android'
import Google from './google'
import Chrome from './chrome.svg?react'
import Edge from './edge.svg?react'

const DEFAULT_SIZE = 16

export const AppleIcon = ({ ...props }) => (
  <View
    component={Apple}
    fill="currentColor"
    styleLeft="text-black dark:text-white"
    width={DEFAULT_SIZE}
    {...props}
  />
)

export const WindowsIcon = ({ ...props }) => (
  <View component={Windows} width={DEFAULT_SIZE} {...props} />
)

export const WebIcon = ({ ...props }) => (
  <View component={Web} width={DEFAULT_SIZE} {...props} />
)

export const AndroidIcon = ({ ...props }) => (
  <View component={Android} width={DEFAULT_SIZE} {...props} />
)

export const GoogleIcon = ({ ...props }) => (
  <View component={Google} width={DEFAULT_SIZE} {...props} />
)

export const ChromeIcon = ({ ...props }) => (
  <View component={Chrome} width={DEFAULT_SIZE} {...props} />
)
export const EdgeIcon = ({ ...props }) => (
  <View component={Edge} width={DEFAULT_SIZE} {...props} />
)

export default assign({
  Web: WebIcon,
  Apple: AppleIcon,
  Google: GoogleIcon,
  Windows: WindowsIcon,
  Android: AndroidIcon,
  Microsoft: WindowsIcon,
  Chrome: ChromeIcon,
  Edge: EdgeIcon,
})('IntegrationIcon')
