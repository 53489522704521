export const lPoint = ({ x, y }) => ({
  type: 'L',
  x,
  y,
})

export const lPoints = lPoint

export default (x, y) => ({
  type: 'L',
  x,
  y,
})
