import { compose } from 'react-recompose'
import { withProps } from 'react-recompose'

import withoutProps from './withoutProps'
import arrayFindIn from '__lib__/array/findIn'

const getThemeFromProps = (props, propNames) =>
  arrayFindIn(propNames, (propName) => props[propName] && propName)

const themeFy = (propNames, defaultValue) => (props) => ({
  theme:
    !props.theme && props.theme !== null
      ? getThemeFromProps(props, propNames) || defaultValue
      : props.theme,
})

export default (propNames, defaultValue) =>
  compose(withProps(themeFy(propNames, defaultValue)), withoutProps(propNames))
