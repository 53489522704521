import tools from '__canvas__/tool/all'
import toolDefault from '__canvas__/tool/default'
import breakpointCurrent from '__lib__/breakpoints/current'
import DEFAULT_NAV_SETTINGS from '__routes__/defaultNavSettings'

export default () => ({
  messages: { collection: {}, list: [] },

  signatures: [],

  canvasThemeIsBlack: true,
  canvasTools: tools,
  canvasCurrentTool: toolDefault,
  canvasAnnotationsList: null,
  canvasAnnotationsCollection: null,
  canvasAnnotationsCreatedQueue: null,
  canvasAnnotationsDeletedQueue: null,
  canvasSelections: {},
  canvasUsersColor: false,
  canvasHiddenUsers: [],
  canvasOverlay: null,

  ui: {
    canvas: true,
    navOpen: false,
    isScreenSideOpen: false,
    isRemoteProcessing: false,
    showArchivedProjects: false,
    projectBooksViewAsList: true,
    breakpoint: breakpointCurrent(),
    projectsScreenLayout: 'largeGrid',
    projectsScreenOrder: 'uploaded.desc',
    ...DEFAULT_NAV_SETTINGS,
  },

  subscriptions: {},
  license: null,
  licenses: {},

  userProjects: null,
  projects: {},

  projectUsers: {},
  projectUserPermissions: {},
  users: {},
  projectInvitations: {},

  books: {},

  tags: {}, // extracted from drawings and documents, keyed with bookId or projectId

  blobs: {},

  timelineEventsIndex: {},
  timelineEvents: {},
  timelineEventsLatest: {},
  timelineEventsOldest: {},

  authToken: null,
  onTouchDevice: false,
  width: window.innerWidth,
})
