import * as R from 'ramda'
import hooks from '__hooks__'
import Form from '__core__/Form'
import View from '__components__/View'
import Input from '__components__/Input'
import Button from '__components__/Button'
import { messageAction } from '__store__/messages'
import { validatePassword, onPasswordChange } from './RegisterFinal'
import Layout, { Footer, Header, LoginButton } from './Layout'
import FormForgeryToken from './FormForgeryToken'

const InputComponent = hooks(R.omit(['onValueChange']))('input')

const ResetPassword = ({
  form,
  onPasswordChange,
  data: {
    code,
    loginUrl,
    formAction,
    formData: { email },
    firstLogin,
  },
  isPasswordConfirmationValid,
}) => (
  <Layout>
    <View style="flex-center w-full flex-col flex-flex">
      <Header
        title={firstLogin ? 'Change password' : 'Reset password'}
        description={
          firstLogin
            ? 'Your password is required to be changed at first login'
            : 'Enter your new password'
        }
      />

      <Form
        form={form}
        method="post"
        style="w-full max-w-10"
        action={formAction}
        component={View.Form}
      >
        <View style="flex flex-col py-6">
          <Input.Field
            noError
            type="hidden"
            name="Input.Code"
            defaultValue={code}
            inputComponent={InputComponent}
          />
          <Input.Field
            noError
            type="hidden"
            name="Input.Email"
            defaultValue={email}
            inputComponent={InputComponent}
          />
          <Input.Field
            noError
            type="hidden"
            name="Input.FirstLogin"
            defaultValue={firstLogin}
            inputComponent={InputComponent}
          />
          <Input.Field
            lg
            autoFocus
            placeholder=""
            type="password"
            label="Password"
            name="Input.Password"
            validate={validatePassword}
            onValueChange={onPasswordChange}
          />
          <Input.Field
            lg
            placeholder=""
            type="password"
            validate={validatePassword}
            name="Input.ConfirmPassword"
            label="Password confirmation"
            onValueChange={onPasswordChange}
            error={
              !isPasswordConfirmationValid &&
              "Password confirmation doesn't match"
            }
          />
          <FormForgeryToken />
          <View style="flex-center mt-5">
            <Form.Submit
              lg
              plain
              brand
              component={Button}
              style="rounded-full"
              text={firstLogin ? 'Change password' : 'Reset password'}
            />
          </View>
        </View>
      </Form>
    </View>
    <Footer>
      <LoginButton
        text={`Password already ${firstLogin ? 'changed' : 'reset'}?`}
        href={loginUrl}
      />
    </Footer>
  </Layout>
)

const INVALID_TOKEN = 'Invalid token.'

const displayFormErrors = ({ data: { formState }, messageError }) => {
  const invalidToken = R.pathEq(
    [0, 'errors', 0, 'errorMessage'],
    INVALID_TOKEN
  )(formState)
  invalidToken && messageError(INVALID_TOKEN)
}

export default R.compose(
  hooks.hoc(
    hooks.state(
      'isPasswordConfirmationValid',
      'setIsPasswordConfirmationValid',
      true
    )
  ),
  Form.withForm('form', null, {
    isValid: R.prop('isPasswordConfirmationValid'),
  })
)(
  hooks(
    hooks.dispatcher('messageError', messageAction.error),
    hooks.tapEffect(displayFormErrors),
    hooks.handler('onPasswordChange', onPasswordChange),
    R.omit(['setIsPasswordConfirmationValid'])
  )(ResetPassword)
)
