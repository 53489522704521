import React from 'react'
import * as R from 'ramda'
import { useLocation } from 'react-router-dom'

import { boolSetter } from './core'
import history from '__routes__/history'
import go, { replace, query } from '__lib__/location/go'
import getQueryFromSearch from '__lib__/web/getQueryFromSearch'

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

const routerLocationQuery =
  (name, map = R.identity) =>
  (props) => {
    const { search } = useLocation()
    return R.assoc(name, map(getQueryFromSearch(search)), props)
  }

const routerLocationQueryEffect = (fn) => (props) => {
  const { search } = useLocation()
  React.useEffect(() => {
    const mapQuery = fn(props)(getQueryFromSearch(search))
    R.is(Function, mapQuery) && replace(query(mapQuery))
  }, [search])
  return props
}

export const routerOmit = R.omit(['location', 'history', 'match'])

export const routerLocationState = (
  name,
  setName,
  defaultValue = null,
  { stateName = name, transient = true, fn = R.identity } = {}
) => {
  const getDefaultValue = R.is(Function, defaultValue)
    ? defaultValue
    : R.always(defaultValue)

  const getState = (props) =>
    R.has(stateName, history.location.state)
      ? history.location.state[stateName]
      : getDefaultValue(props)

  return (props) => {
    useLocation()
    const [set] = React.useState(() =>
      fn((_value) => {
        const currentValue = getState(props)
        const value = R.is(Function, _value) ? _value(currentValue) : _value
        return currentValue === value
          ? null
          : value !== getDefaultValue(props)
            ? go(history.location.pathname, { [stateName]: value, transient })
            : history.goBack()
      })
    )
    return R.mergeLeft({ [name]: getState(props), [setName]: set }, props)
  }
}

export const routerLocationBoolState = (
  name,
  setName,
  defaultValue = false,
  { stateName = name, transient = true } = {}
) =>
  routerLocationState(name, setName, defaultValue, {
    stateName,
    transient,
    fn: boolSetter,
  })

export default {
  routerLocationState,
  routerLocationBoolState,
  routerLocationQuery,
  routerLocationQueryEffect,
}
