import React from 'react'
import { compose } from 'react-recompose'
import { withProps } from 'react-recompose'
import { withHandlers } from 'react-recompose'
import { withRouter } from 'react-router-dom'
import { withPropsOnChange } from 'react-recompose'

import history from '__routes__/history'
import { build } from '__lib__/location/go'
import propsWithRef from '__lib__/react/propsWithRef'
import withClassName from '__lib__/react/withClassName'
import updateOnHistoryChange from './updateOnHistoryChange'

const compositeComponentProps = (component, props) =>
  typeof component === 'string' ? {} : props

const Anchor = ({
  to,
  match,
  inert,
  strict,
  active,
  params,
  routes,
  router,
  history,
  location,
  children,
  renderChildren,
  staticContext,
  isExternalLink,
  activeClassName,
  inactiveClassName,
  component: Component = 'a',
  ...props
}) => (
  <Component
    {...compositeComponentProps(Component, { inert })}
    {...propsWithRef(Component, props)}
  >
    {children}
    {renderChildren && renderChildren({ isActive: active })}
  </Component>
)
const internalLink = (event, action, to) => {
  event.preventDefault()
  if (to) action === 'replace' ? history.replace(to) : history.push(to)
}

const onClick =
  ({ to, inert, action, onClick, disabled, isExternalLink }) =>
  (event) => {
    if (disabled || inert) return event.preventDefault()
    isExternalLink || internalLink(event, action, to)
    onClick && onClick(event)
  }

const to = ({ to: baseTo, href }) => {
  const to = baseTo && build(baseTo)
  return {
    to,
    isExternalLink: Boolean(href),
    href: href || (to && history.createHref(to)) || '',
  }
}

export const active = ({ href, to, active, strict }) => ({
  active:
    active != null
      ? active
      : to && !strict
      ? to.pathname === history.location.pathname
      : href === history.createHref(history.location),
})

const className = ({ inactiveClassName, activeClassName, active }) =>
  active ? activeClassName : inactiveClassName

const inert = ({ inert, href, onClick }) => ({
  inert: inert || (!onClick && href === history.createHref(history.location)),
})

const href = ({ disabled, inert, href }) => ({
  href: disabled || inert ? null : href,
})

export default compose(
  updateOnHistoryChange,
  withRouter,
  withPropsOnChange(['to', 'href', 'location'], to),
  withProps(inert),
  withProps(href),
  withProps(active),
  withHandlers({ onClick }),
  withClassName(className)
)(Anchor)
