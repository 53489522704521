import React from 'react'
import * as R from 'ramda'

import Button from './Button'
import hooks from '__hooks__'
import Confirm from './Confirm'
import ButtonIcon from './Icon'
import Link from '__components__/Link'
import View from '__components__/View'
import assign from '__lib__/object/assign'

const Text = hooks(hooks.rename('children', 'text'))(Button)
const ButtonLink = hooks(R.mergeLeft({ component: Link }))(Button)
const ButtonLinkIcon = hooks(R.mergeLeft({ component: Link }))(ButtonIcon)

const ButtonT = hooks()(({ t, ...props }) => (
  <Button text={(props) => <View.T t={t} {...props} />} {...props} />
))

export default assign({
  Text,
  Confirm,
  T: ButtonT,
  Icon: Object.assign(ButtonIcon, { Icon: ButtonLinkIcon }),
  Link: Object.assign(ButtonLink, { Icon: ButtonLinkIcon }),
  View: hooks(hooks.default('component', View))(Button),
})(Button)
