import React from 'react'
import hooks from '__hooks__'

export const DialogContext = React.createContext({})

const DialogProvider = ({
  ref,
  children,
  component: Component = 'div',
  ...props
}) => (
  <Component ref={ref} {...props}>
    <DialogContext.Provider value={ref}>{children}</DialogContext.Provider>
  </Component>
)

export default hooks(hooks.bubbleRef('ref'))(DialogProvider)
