import * as R from 'ramda'

import hooks from '__hooks__'
import Form from '__core__/Form'
import View from '__components__/View'
import Input from '__components__/Input'
import Button from '__components__/Button'
import required from 'Form/validate/required'
import FormForgeryToken from './FormForgeryToken'
import Layout, { Footer, Header, LoginButton } from './Layout'

const Login = ({ form, data: { formAction, loginUrl } }) => (
  <Layout>
    <View style="flex-center w-full flex-col flex-flex w-full">
      <Header
        title="Forgot password?"
        description="Enter the email associated with your account and we’ll email you a link
        to reset your password"
      />

      <Form
        form={form}
        method="post"
        style="w-full max-w-10"
        action={formAction}
        component={View.Form}
      >
        <View style="flex flex-col mt-6">
          <Input.Field
            lg
            autoFocus
            type="email"
            label="Email"
            name="Input.Email"
            validate={required}
            placeholder="E.g. john.smith@drawboard.com"
          />
          <View style="flex-center mt-5">
            <FormForgeryToken />
            <Form.Submit
              lg
              plain
              brand
              component={Button}
              style="rounded-full"
              text="Send reset password"
            />
          </View>
        </View>
      </Form>
    </View>
    <Footer>
      <LoginButton href={loginUrl} text="Got your password?" />
    </Footer>
  </Layout>
)

const setFormErrors = ({ form, data: { formState } }) => {
  console.log(
    R.reduce(
      (fieldErrors, { key, errors }) =>
        R.assoc(key, errors[0].errorMessage, fieldErrors),
      [],
      formState
    )
  )
  return form.setFieldsError(
    R.reduce(
      (fieldErrors, { key, errors }) => R.assoc(key, errors[0], fieldErrors),
      [],
      formState
    )
  )
}

export default Form.withForm('form')(
  hooks(hooks.tapEffect(setFormErrors))(Login)
)
